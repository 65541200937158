/* eslint-disable camelcase */

import { Color } from "@models/color";
import { Coordinate } from "./cad";

export interface UnitLocation {
  _id: string
  active: boolean
  agencyCode?: string
  agencyName?: string
  altitude: number
  color?: Color
  device_type: string
  id: string
  kindType?: string
  location: Coordinate
  modified: string
  movedAt: string
  opacity?: number
  opAreaCode: string
  opAreaName: string
  sendToCAD: boolean
  source?: string
  speed: number
  state: string
  userId: string
  username: string
  vehicleId?: string,
}

export interface ColoredUnits extends UnitLocation {
  color: Color
}

export interface TableData {
  tableData: {
    id: number
    checked: boolean
  }
}

export const DefaultUnitColor = {
  background: "rgb(93, 93, 93)",
  text: "white",
};
