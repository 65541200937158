import { Select, MenuItem, InputLabel } from "@mui/material";
import canadaFlag from "../img/canada_flag.svg";
import usFlag from "../img/us_flag.svg";
import "./login-page.css";

enum SelectValues {
  "Global" = "US/Global",
  "Canada" = "Canada",
}

export function SelectRegion() {
  const currentPath = window.location.origin;
  const handleClick = (newUrl: SelectValues) => {
    let newOrigin = currentPath;
    if (currentPath.match(/\.ca/) && newUrl === SelectValues.Global) {
      newOrigin = currentPath.replace(/\.ca/, ".com");
    }
    if (currentPath.match(/\.com/) && newUrl === SelectValues.Canada) {
      newOrigin = currentPath.replace(/\.com/, ".ca");
    }
    window.location.assign(newOrigin + window.location.pathname);
  };
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ width: "fit-content" }}>
        <InputLabel style={{ padding: 0, marginBottom: 5 }}>Region</InputLabel>
        <Select
          name="Select Region"
          defaultValue={currentPath.includes("tabletcommand.ca") ? SelectValues.Canada : SelectValues.Global}
          renderValue={(value) => (
            <div className="login_page_region_select" style={{ columnGap: 25 }}>
              {value}
              <img src={value === SelectValues.Global ? usFlag : canadaFlag} width={25} height={25} alt={value + "Flag"} />
            </div>
          )}
          MenuProps={
            {
              anchorOrigin: { horizontal: "center", vertical: "top" },
              transformOrigin: { horizontal: "center", vertical: "top" },
              style: { overflowY: "auto" },
            }
          }
        >
          <MenuItem
            value={SelectValues.Global}
            className="login_page_region_select"
            onClick={() => handleClick(SelectValues.Global)}
          >
            {SelectValues.Global}
            <img src={usFlag} width={25} height={25} alt="US Flag" />
          </MenuItem>
          <MenuItem
            value={SelectValues.Canada}
            className="login_page_region_select"
            onClick={() => handleClick(SelectValues.Canada)}
          >
            {SelectValues.Canada}
            <img src={canadaFlag} width={25} height={25} alt="CA Flag" />
          </MenuItem>
        </Select>
      </div>
    </div>
  );
}
