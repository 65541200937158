import { NavLink } from "react-router-dom";
import {
  IosShare,
} from "@mui/icons-material";
import { Groups } from "@models/tc-incident";
import classes from "../pages/admin/tc-incident-details/managed-incidents-list.module.css";
import { getURL, urls } from "@utils/routing";
import { UnitLabel } from "./unit-label";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { IncidentUnit, SharedSource, SharedToItem } from "@models/cad";
import icon from "../pages/img/rts-disabled-icon.png";
import { TVViewButton } from "./TVViewButton";

export function IncidentTrainingLabel(props: {
  simulation: boolean
}) {
  const { simulation } = props;
  return (
    simulation ? <span className={classes.simulationLabel}>T</span> : null
  );
}

export function IncidentSharedIntoLabel(props: { color: string }) {
  return (
    <span className={classes.simulationLabel} style={{ color: props.color }}>S</span>
  );
}

export function IncidentSharedSource({ sharedSource, cellBgColor }: { sharedSource?: SharedSource, cellBgColor?: string }) {
  if (!sharedSource || !sharedSource.isExternal) {
    return null;
  }

  let bgColor = "#fadea6";
  if (cellBgColor) {
    bgColor = cellBgColor;
  }

  return (<IncidentSharedIntoLabel color={bgColor} />);
}

export function IncidentSharedTo({ sharedTo }: { sharedTo: SharedToItem[] | undefined }) {
  if (!sharedTo || sharedTo.length === 0) {
    return null;
  }

  return (
    <span style={{ margin: "0px 5px" }}>
      <IosShare style={{ height: 18, width: 20 }} />
    </span>
  );
}

export function IncidentExtendedLabel(props: {
  extended: boolean
}) {
  const { extended } = props;
  return (
    extended ? <span className={classes.extendedLabel}>E</span> : null
  );
}

export function IncidentRTSLabel(props: { rts: boolean }) {
  const { rts } = props;
  return !rts ? <img src={icon} alt="Icon unavailable" style={{ width: 20, height: 20, marginLeft: 10 }} /> : null;
}

export function IncidentLabel(prop: {
  channelOwner: string,
  id: string,
  incidentCallTypeDescription: string
  incidentNumber: string
  address: string
  groups: Groups[] | null
  units: IncidentUnit[]
  to?: string
  uniqueKey: string
  rts: boolean
  extended: boolean
  simulation: boolean
  sharedTo?: SharedToItem[],
  sharedSource?: SharedSource,
}) {
  const {
    channelOwner, incidentCallTypeDescription, incidentNumber,
    address, groups, units, to, uniqueKey, extended, simulation, rts,
    sharedSource, sharedTo,
  } = prop;

  return (
    <div className={`${classes.incidentLabel}`} key={uniqueKey}>
      <div className={classes.incidentP}>
        <NavLink
          to={to ?? `${getURL(urls.routingAppPrefix)}/${getURL(urls.adminManagedIncidentDetails, { incidentNumber })}`}
          style={{
            textDecoration: "none",
            color: "black",
            fontWeight: "bold",
          }}
        >
          {incidentCallTypeDescription}
          {" "}
          |
          {incidentNumber}
        </NavLink>
        <IncidentSharedSource sharedSource={sharedSource} />
        <IncidentSharedTo sharedTo={sharedTo} />
        <IncidentTrainingLabel simulation={simulation} />
        <IncidentExtendedLabel extended={extended} />
        <TVViewButton incidentNumber={incidentNumber} style={{ marginLeft: 5 }} />
        <div style={{
          marginLeft: 1, marginTop: 2, marginBottom: 3, display: "flex", flexDirection: "row", alignItems: "center", verticalAlign: "middle",
        }}
        >
          <CheckCircleOutlineIcon style={{ color: "#8b0000", fontSize: "1.25rem" }} />
          <span style={{ color: "#8b0000", paddingLeft: 5, fontSize: 16 }}>{channelOwner}</span>
        </div>
        <IncidentRTSLabel rts={rts} />
      </div>
      <div style={{ marginBottom: 3 }}>
        {address}
      </div>
      {(groups ?? []).map((item) => (
        <span className={`${classes.incidentChild}`} key={item.uuid ? item.uuid + item.units?.length : item.uuid}>
          {item.name}
        </span>
      ))}
      {(units ?? []).map((unit) => (
        <UnitLabel
          color={unit.color}
          key={unit.UnitDispatchNumber ? unit.UnitDispatchNumber + unit.UnitID + unit.uuid : unit.uuid}
          uniqueKey={unit.UnitDispatchNumber ? unit.UnitDispatchNumber + unit.UnitID + unit.uuid : unit.uuid}
          radioName={unit.UnitID}
          styleClassName={`${classes.unitChild}`}
        />
      ))}
    </div>
  );
}
