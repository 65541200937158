import {
  FormLabel, Grid, IconButton, TextField,
} from "@mui/material";
import styles from "./cad-simulator.module.css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { CadSimulatorUnitsTable } from "./cad-simulator-units-table";
import { useEffect, useMemo, useState } from "react";
import { unitsStatuses } from "@utils/static-data";
import { SingleSelect } from "@components/SelectComponent";
import { IncidentUnit, SimulatorIncident, UnitInfo } from "@models/cad";
import moment from "moment";
import { PersonnelDisplay } from "../cad-incident-details/cad-incident-details-unit-table";
import { getActivePersonnelParsed } from "@services/active_personnel";
import { useDataWithoutLoading } from "@hooks/useDataWithoutLoading";
import { useLoading } from "@hooks/useLoading";
import { checkUTF8Characters, detectDuplicateUnits, KeyIsValue } from "@utils/utilsFunctions";
import { Personnel } from "@models/active_personnel";
import { UnitRenderColumn } from "./cad-simulator-units-table-component";

export function CadSimulatorUnits(props: {
  isSubmitting: boolean,
  units: IncidentUnit[] | undefined
  isDataCleared: boolean
  keys: KeyIsValue<SimulatorIncident>
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  submitForm: (() => Promise<void>) & (() => Promise<any>)
  disableInput?: boolean,
  getStatus?: (status: string) => void,
  getAlarm?: (alarm: string) => void,
}) {
  const {
    isDataCleared, submitForm, keys, setFieldValue, units, isSubmitting, disableInput, getStatus, getAlarm,
  } = props;

  const { data: personnel } = useDataWithoutLoading(getActivePersonnelParsed);
  const [status, setStatus] = useState({
    objectProperty: "TimeDispatched",
    FormLabel: "DSP",
  });
  const [isStatus, setIsStatus] = useState(false);
  const [error, setError] = useState(false);
  const { withLoading } = useLoading();
  const [createdUnits, setCreatedUnits] = useState<UnitInfo[]>([]);
  const [unit, setUnit] = useState({
    active: true,
    AlarmAtDispatch: "",
    Personnel: [] as Personnel[] | undefined,
    PersonnelCount: 0,
    status: "",
    TimeArrived: "",
    TimeAtHospital: "",
    TimeCleared: "",
    TimeDispatched: "",
    TimeEnroute: "",
    TimePatient: "",
    TimeStaged: "",
    TimeTransporting: "",
    UnitDispatchNumber: "",
    UnitID: "",
  });

  useEffect(() => {
    units?.splice(0, units?.length);
  }, [isDataCleared]);

  useEffect(() => {
    if (status === null || status.objectProperty === "") {
      setIsStatus(false);
    }

    if (status && status.objectProperty !== "") {
      setIsStatus(true);
    }
  }, [status]);

  useEffect(() => {
    let error = false;
    if (createdUnits.length >= 1) {
      error = detectDuplicateUnits(
        units?.filter((unit) => !unit.TimeCleared).map((el) => el.UnitID?.toLocaleLowerCase()) as string[],
        createdUnits.map((el) => el.UnitID.toLocaleLowerCase()),
      ) || !!createdUnits.filter((unit) => !checkUTF8Characters(unit.UnitID)).length;
    }
    setError(error);
  }, [unit, units, createdUnits]);

  const submitData = withLoading("Loading ...", async (): Promise<void> => {
    if (!error && isStatus) {
      setFieldValue(keys.units, units?.concat(createdUnits));
      setUnit({
        active: true,
        AlarmAtDispatch: "",
        Personnel: [] as Personnel[] | undefined,
        PersonnelCount: 0,
        status: "",
        TimeArrived: "",
        TimeAtHospital: "",
        TimeCleared: "",
        TimeDispatched: "",
        TimeEnroute: "",
        TimePatient: "",
        TimeStaged: "",
        TimeTransporting: "",
        UnitDispatchNumber: "",
        UnitID: "",
      });
      setStatus({
        objectProperty: "TimeDispatched",
        FormLabel: "DSP",
      });
      setCreatedUnits([]);
      submitForm();
    }
  });

  const disableUnitAddButton = error || !unit.UnitID;
  const parseUnitsFromCsv = (fieldInput: string) => {
    const parseCSV = fieldInput.split(",").map((value) => value.trim());
    if (parseCSV.length === 1) {
      return parseCSV;
    }
    const filteredCSV = parseCSV.reduce((prev, value) => {
      const previousLowercase = prev.map((value: string) => value.toLocaleLowerCase());
      const valueExists = previousLowercase.includes(value.toLocaleLowerCase());
      if (valueExists) {
        return prev;
      }
      return [...prev, value];
    }, [] as string[]);
    return filteredCSV;
  };

  const displayUnits = useMemo(() => units?.filter((unit) => !unit.TimeCleared), [units, isSubmitting]);

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.section_header}>
        <p className={styles.section_name}>
          Units
        </p>
        <span className={styles.data_length}>
          {units?.length}
        </span>
      </div>
      <Grid container className={styles.units_container}>
        <Grid item xs={12} sm={4} lg={5} style={{ marginBottom: 11 }}>
          {!disableInput && (
            <div className={styles.comments_section} style={{ alignItems: `${error ? "flex-start" : "center"}` }}>
              <div className={styles.button_container} style={{ backgroundColor: `${disableUnitAddButton ? "gray" : "#bc7b7f"}` }}>
                <IconButton
                  style={{ padding: 0, paddingRight: 4, color: "white" }}
                  onClick={submitData}
                  type="submit"
                  disabled={disableUnitAddButton}
                >
                  <AddCircleIcon />
                </IconButton>
              </div>
              <div className={styles.new_unit_insert_input_wrapper}>
                <TextField
                  variant="outlined"
                  className={styles.textField}
                  style={{ marginLeft: 15 }}
                  value={unit.UnitID}
                  error={error}
                  onChange={(el) => {
                    setUnit({ ...unit, UnitID: el.target.value });
                    const units = parseUnitsFromCsv(el.target.value);
                    const finalUnits = units.map((createdUnit) => ({
                      ...unit,
                      UnitID: createdUnit,
                      UnitDispatchNumber: `${createdUnit}-${moment().unix()}`,
                      Personnel: personnel?.filter((el) => el.radioName === createdUnit),
                      PersonnelCount: personnel?.filter((el) => el.radioName === createdUnit).length as number,
                      [status.objectProperty as string]: moment(new Date()).format(),
                    })) as UnitInfo[];
                    setCreatedUnits(finalUnits);
                  }}
                />
                {error && <p className="errorContainer">Invalid unit</p>}
              </div>
            </div>
          )}
          <p style={{ marginBottom: 0, paddingLeft: 48, marginTop: 10 }}>Comma separated list e.g. E1, E2, E3</p>
        </Grid>
        <Grid item xs={12} sm={4} lg={4} style={{ marginTop: 2 }}>
          <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            verticalAlign: "middle",
            marginBottom: 15,
          }}
          >
            <div style={{ minWidth: 100, textAlign: "right", marginRight: 10 }}>
              <FormLabel>Status:</FormLabel>
            </div>
            <SingleSelect<{ objectProperty: string, FormLabel: string }>
              variant="outlined"
              value={status}
              options={unitsStatuses}
              style={{ backgroundColor: "white", borderRadius: 7 }}
              getOptionSelected={(e, v) => e.FormLabel === v.FormLabel}
              error={!isStatus}
              onChange={(el) => {
                setStatus(el!);
                const newUnit = {
                  ...unit,
                  UnitDispatchNumber: `${unit.UnitID}-${moment().unix()}`,
                  Personnel: personnel?.filter((el) => el.radioName === unit.UnitID),
                  PersonnelCount: personnel?.filter((el) => el.radioName === unit.UnitID).length as number,
                  [el?.objectProperty as string]: moment(new Date()).format(),
                };
                const updatedUnits = createdUnits.map((createdUnit) => ({
                  ...createdUnit,
                  UnitDispatchNumber: `${createdUnit.UnitID}-${moment().unix()}`,
                  Personnel: personnel?.filter((el) => el.radioName === createdUnit.UnitID),
                  PersonnelCount: personnel?.filter((el) => el.radioName === createdUnit.UnitID).length as number,
                  [el?.objectProperty as string]: moment(new Date()).format(),
                })) as UnitInfo[];
                setUnit(newUnit);
                setCreatedUnits(updatedUnits);
                if (getStatus) {
                  getStatus(el?.objectProperty ?? "TimeDispatched");
                }
                setIsStatus(true);
              }}
              renderOption={(el) => el?.FormLabel}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={4} lg={2} style={{ marginTop: 2 }}>
          <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            verticalAlign: "middle",
            marginBottom: 15,
          }}
          >
            <div style={{ minWidth: 100, textAlign: "right", marginRight: 10 }}>
              <FormLabel>Alarm Level</FormLabel>
            </div>
            <TextField
              variant="outlined"
              className={styles.textField}
              value={unit.AlarmAtDispatch}
              onChange={(el) => {
                setUnit({ ...unit, AlarmAtDispatch: el.target.value });
                const updatedUnits = createdUnits.map((unit) => ({
                  ...unit,
                  AlarmAtDispatch: el.target.value,
                }));
                if (getAlarm) {
                  getAlarm(el.target.value);
                }
                setCreatedUnits(updatedUnits);
              }}
            />
          </div>
        </Grid>
      </Grid>
      <div style={{
        marginTop: 10, width: "100%", overflow: "auto", maxHeight: 500,
      }}
      >
        <CadSimulatorUnitsTable<IncidentUnit>
          data={displayUnits ?? []}
          cellAlign="center"
          header={[
            {
              name: "Unit",
              prop: "UnitID",
            }, {
              name: "DSP",
              prop: "TimeDispatched",
              render: (item) => {
                const { TimeDispatched } = item;
                const handleChange = () => {
                  item.TimeDispatched = moment(new Date()).format();
                  submitForm();
                };
                return (
                  <UnitRenderColumn field={TimeDispatched} handleChange={handleChange} key={item.UnitID + (TimeDispatched ?? "TimeDispatched")} />
                );
              },
            }, {
              name: "RSP",
              prop: "TimeEnroute",
              render: (item) => {
                const { TimeEnroute } = item;
                const handleChange = () => {
                  item.TimeEnroute = moment(new Date()).format();
                  submitForm();
                };
                return (
                  <UnitRenderColumn field={TimeEnroute} handleChange={handleChange} key={item.UnitID + (TimeEnroute ?? "TimeEnroute")} />
                );
              },
            }, {
              name: "STG",
              prop: "TimeStaged",
              render: (item) => {
                const { TimeStaged } = item;
                const handleChange = () => {
                  item.TimeStaged = moment(new Date()).format();
                  submitForm();
                };
                return (
                  <UnitRenderColumn field={TimeStaged} handleChange={handleChange} key={item.UnitID + (TimeStaged ?? "TimeStaged")} />
                );
              },
            }, {
              name: "ONS",
              prop: "TimeArrived",
              render: (item) => {
                const { TimeArrived } = item;
                const handleChange = () => {
                  item.TimeArrived = moment(new Date()).format();
                  submitForm();
                };
                return (
                  <UnitRenderColumn field={TimeArrived} handleChange={handleChange} key={item.UnitID + (TimeArrived ?? "TimeArrived")} />
                );
              },
            }, {
              name: "TR",
              prop: "TimeTransporting",
              render: (item) => {
                const { TimeTransporting } = item;
                const handleChange = () => {
                  item.TimeTransporting = moment(new Date()).format();
                  submitForm();
                };
                return (
                  <UnitRenderColumn field={TimeTransporting} handleChange={handleChange} key={item.UnitID + (TimeTransporting ?? "TimeTransporting")} />
                );
              },
            }, {
              name: "AH",
              prop: "TimeAtHospital",
              render: (item) => {
                const { TimeAtHospital } = item;
                const handleChange = () => {
                  item.TimeAtHospital = moment(new Date()).format();
                  submitForm();
                };
                return (
                  <UnitRenderColumn field={TimeAtHospital} handleChange={handleChange} key={item.UnitID + (TimeAtHospital ?? "TimeAtHospital")} />
                );
              },
            }, {
              name: "AVL",
              prop: "TimeCleared",
              render: (item) => {
                const { TimeCleared } = item;
                const handleChange = () => {
                  item.TimeCleared = moment(new Date()).format();
                  submitForm();
                };
                return (
                  <UnitRenderColumn field={TimeCleared} handleChange={handleChange} key={item.UnitID + (TimeCleared ?? "TimeCleared")} />
                );
              },
            }, {
              name: "Personnel",
              prop: "personnel",
              render: (item) => (item.Personnel ?? []).map((el) => <PersonnelDisplay key={el.PersonnelID} el={el} />),
            },
          ]}
        />
      </div>
    </div>
  );
}
