import { CustomMaterialTable } from "@components/custom-material-table";
import { Notes, TCIncident } from "@models/tc-incident";
import moment from "moment";
import { useState } from "react";
import IncidentTableHeaderWithToggle from "../incident-table-header-with-toggle";

export function TCIncidentNotes({
  incident,
}: {
  incident: TCIncident
}) {
  const [expand, setExpand] = useState(true);
  return (
    <CustomMaterialTable<Notes>
      data={incident?.notes ?? []}
      title={<IncidentTableHeaderWithToggle title="Incident Notes" expand={expand} setExpand={setExpand} />}
      hideContent={!expand}
      header={[
        {
          name: "Modified",
          prop: "time",
          render: (item) => (
            <div>
              <div>{moment(item.time * 1000).format("HH:mm:ss")}</div>
              <div style={{ fontSize: 10 }}>{moment(item.time * 1000).format("MM/DD/YYYY")}</div>
            </div>
          ),
        },
        {
          name: "Message",
          prop: "message",
        },
      ]}
    />
  );
}
