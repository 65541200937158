import { useEffect } from "react";

export function useEffectAsync(fn: () => Promise<void>, deps?: any[], cleanupHandler?: () => void) {
  useEffect(() => {
    const asyncEffect = async () => {
      try {
        await fn();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log("asyncEffect error", e);
      }
    };
    asyncEffect();
    return () => {
      if (cleanupHandler) {
        cleanupHandler();
      }
    };
    // -eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
