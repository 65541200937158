import { useEffect, useRef, useState } from "react";
import { ServerError } from "@services/utils";
import { useHistoryState } from "./useHistoryState";
import { useLoading } from "./useLoading";
import { useEffectAsync } from "./utils";

export function useData<T>(dataLoader: (as: AbortSignal) => Promise<T>, deps: any[] = [], {
  message = "Loading data...",
  historyStateKey,
  showLoading,
  enabled = true,
}: {
  message?: string,
  historyStateKey?: string,
  // passing in 'never' will avoid calling the useLoading hook which is not available in all contexts
  showLoading?: "never" | boolean
  enabled?: boolean
} = {}) {
  const [data, setData, hasValue] = useHistoryState<T>(historyStateKey);
  const [error, setError] = useState<string>();
  const [isLoading, setIsLoading] = useState(true);
  const abortionController = useRef<AbortController>();
  const [isComponentUnloaded, setIsComponentUnloaded] = useState(false);
  useEffect(() => () => setIsComponentUnloaded(true), []);
  let reload = async () => {
    // If the component was unmounted ignore future invocations of reload
    if (isComponentUnloaded) return;
    // Only one concurrent request is supported
    // If a previous abortion controller exists it means we had another request pending and we abort it.
    let ac = abortionController.current;
    ac?.abort();
    ac = new AbortController();
    abortionController.current = ac;
    try {
      setIsLoading(true);
      if (enabled) {
        const newData = await dataLoader(ac.signal);
        setData(newData);
      }
      setError(undefined);
    } catch (e) {
      // Abortion happened just ignore all errors
      if (ac.signal.aborted) return;

      if (typeof e === "string") {
        setError(e);
      } else if (e instanceof ServerError) {
        setError(e.message);
      } else {
        setError("An error occurred while getting the data");
      }
    } finally {
      // Abortion happened don't set loading.
      if (!ac.signal.aborted) {
        setIsLoading(false);
      }
    }
  };

  const withLoading = showLoading === "never" ? <T>(_a: any, fn: T) => fn : useLoading().withLoading;
  if (showLoading === undefined || showLoading === true) {
    reload = withLoading(message, reload);
  }
  // eslint-disable-next-line consistent-return
  useEffectAsync(async () => {
    if (!hasValue) {
      await reload();
    }
  }, deps, () => {
    abortionController.current?.abort();
  });

  return {
    data,
    error,
    isLoading,
    reload,
    setData,
  };
}
