import { Agency } from "@models/agencies";
import { Department, UserDepartment } from "@models/department";

export interface Credentials {
  username: string
  password: string
}

export interface OAuthCredentials {
  auth: AuthType // Expecting AuthType.Google
  code: string
}

export interface SAMLCredentials {
  auth: AuthType // Expecting AuthType.SAML
  saml: string
}

export enum AuthType {
  Password = "password",
  Google = "o-google",
  SAML = "saml",
}

export enum UserSessionDeviceTypes {
  browser = "browser",
  ipad = "ipad",
  iphone = "iphone",
  tabletAndroid = "tablet-android",
  android = "android",
  appTabletcommand = "app-tabletcommand",
}

export enum UserTypeEnum {
  admin = "Admin",
  agencyAdmin = "Agency Admin",
}

export interface User {
  _id: string,
  active: boolean,
  admin: boolean,
  agencyId?: string,
  beaconEnabled: boolean,
  departmentId?: string, // Missing for super admin
  email: string,
  id: string
  isPro: boolean,
  isIncidentManager: boolean,
  managedAgencies?: string[],
  mapHidden: boolean,
  mapId: string,
  mobileAccess: boolean,
  // eslint-disable-next-line camelcase
  modified_date: string,
  name: string,
  nick: string,
  // notificationSounds: [],
  offlineMapsEnabled: boolean,
  remoteLoggingEnabled: boolean,
  rtsAuthKey: string,
  sessionCountiPad: number,
  sessionCountiPhone: number,
  shareLocationPhone: boolean,
  shareLocationTablet: boolean,
  superuser: boolean,
  syncLoggingExpireDate?: string,
  token: string,
  tokenExpireAt?: string,
  userContributionEnabled: boolean,
  uuid: string,
  vehicle: null,
  webAccess: boolean,
  when: string,
  offDutyEnabled: boolean,
  logOffEnabled: boolean,
  cadSimulatorAccess: boolean,
  superUserReadOnly: boolean,
  canCreateShareIncidentCode: boolean,
}

export interface Session {
  active: boolean,
  authSource?: string,
  departmentId: string,
  deviceId: string,
  email: string,
  ended: string,
  nick: string,
  remoteAddress: string,
  source: string,
  token: string,
  user: string, // userId
  userAgent: string,
  when: string,
  why: string,
}

export type SessionWithOnline = {
  active: boolean,
  authSource?: string,
  departmentId: string,
  deviceId: string,
  email: string,
  ended?: string,
  nick: string,
  remoteAddress: string,
  source: string,
  token: string,
  user: string, // userId
  userAgentParsed: UserAgentParsed,
  when: string,
  why: string,

  // present for online users
  drift?: number,
  t?: number,
  tt?: string,
}

export type UserAgentParsed = {
  appName: string
  appVersion: string
  buildVersion: string
  osVersion: string
}

export interface UserSettings {
  fireMapperProEnabled: boolean,
  isIncidentManager: boolean,
  isPro: boolean,
  logOffEnabled: boolean,
  offlineMapsEnabled: boolean,
  vehicleId: string,
  webDisclaimer: UserSettingsWebDisclaimer,
}

export interface UserSettingsWebDisclaimer {
  enabled: boolean,
  message: string,
}

export interface UserLoginSuccess {
  ok: true, // ok: bool = true when success
  session: Session,
  user: User,
  settings: UserSettings,
}

export interface UserLoginError {
  ok: false,
  why: string,
}

export interface LoginData {
  loginData: string | null
  setLoginData: (e: string | null) => void
}

export type LoginConfigureItemPassword = {
  type: "password",
};

export type LoginConfigureItemSAML = {
  type: "saml",
  agency: string,
  name: string,
  selector: string,
};

export type LoginConfigureItemGoogle = {
  type: "google",
  name: string,
  urlPath: string,
};

export type LoginConfigureItem = LoginConfigureItemPassword | LoginConfigureItemSAML | LoginConfigureItemGoogle;

export interface SignupVerify {
  department: {
    _id: string
    department: string
    signupKey: string
    agencies: Agency[]
  }
  ok: boolean;
}
export interface AccountSession {
  department: {
    active: boolean,
    department: string,
    signupKey?: string,
    signupDomains?: string[],
  },
  user: {
    _id: string,
    active: boolean,
    admin: boolean,
    agencyId: string,
    departmentId: string,
    email: string,
    id: string,
    managedAgencies?: string[],
    name: string,
    nick: string,
    superuser: boolean,
  },
  session: {
    active: boolean,
    source: string,
    deviceId: string,
    token: string,
    why: string,
  }
}

export type UserEdit = UserDepartment & {
  departmentData?: Department;
  agencyIds: Agency[];
}

export type UserEditFormError = {
  departmentData: string;
  agencyId: string;
  email: string;
  name: string;
  nick: string;
  auth: string;
  mapId: string;
}

export interface ChangePasswordVerify {
  canChangePassword: boolean
  message: string
  webAccess: boolean
  mobileAccess: boolean
  tabletAccess: boolean
  minPasswordLength: number
}

export type UserFeaturesType = {
  mobileAccess?: boolean;
  isPro?: boolean;
  webAccess?: boolean;
  fireMapperProEnabled?: boolean;
  offDutyEnabled?: boolean
  isIncidentManager?: boolean;
  restrictedCommentsEnabled?: boolean
  canAddRemoveVehicle?: boolean
}

export type CustomToggleFiltersValuesType = {
  mobileAccess: number,
  isPro: number,
  webAccess: number,
  fireMapperProEnabled: number,
  offDutyEnabled: number,
  isIncidentManager: number,
  locationToCAD: number,
  vehicle: number,
  restrictedCommentsEnabled: number,
  canAddRemoveVehicle: number,
};

export interface UsersTableInterface extends UserDepartment { userType: string, mapIdDisplayString: string, userCanEdit: boolean, agencyName: string }

export interface UsersTableBulkEditFields {
  agencyId?: Agency,
  mobileAccess?: boolean,
  isPro?: boolean,
  webAccess?: boolean,
  fireMapperProEnabled?: boolean,
  offDutyEnabled?: boolean,
  isIncidentManager?: boolean,
  locationToCAD?: boolean,
  restrictedCommentsEnabled?: boolean,
  mapHidden?: boolean,
  canAddRemoveVehicle?: boolean

}

export enum UsersTableFiltersEnum {
  mobileAccess = "mobileAccess",
  isPro = "isPro",
  webAccess = "webAccess",
  fireMapperProEnabled = "fireMapperProEnabled",
  offDutyEnabled = "offDutyEnabled",
  isIncidentManager = "isIncidentManager",
  locationToCAD = "locationToCAD",
  vehicle = "vehicle",
  restrictedCommentsEnabled = "restrictedCommentsEnabled",
  canAddRemoveVehicle = "canAddRemoveVehicle"
}

export enum UserStealthOptions {
  prohibited = "prohibited",
  visible = "visible",
  hidden = "hidden"
}

// Copied from tabletcommand-sync-settings
export type UserConfiguration = {
  // Lots of other fields
  // ...
  pubNub: {
    enabled: boolean;
    channelPrefix: string;
    init: {
      publishKey: string;
      subscribeKey: string;
      authKeyV1: string;
      authKeyV2: string;
      uuid: string;
    },
    channels: {
      general: string;
      incident: string;
      location: string;
      managed: string;
    }
  },
  // ...
  webNotification: {
    publicKey: "",
  },
  // ...
};
