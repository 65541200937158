import { FormLabel, MenuItem, TextField } from "@mui/material";
import React, { ChangeEvent } from "react";
import styles from "./cad-simulator.module.css";

export function CadSimulatorField(prop: {
  value: string | number | undefined
  fieldKey: string
  handleChange: {
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
    <T = string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>>(field: T): T extends ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ? void : (e: string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  }
  label: string,
  width?: number | string
  disabled?: boolean
  isCloseInField?: boolean
  required?: boolean,
  closeInData?: {
    value: number;
    FormLabel: string;
  }[],
}) {
  const {
    label, width, isCloseInField, closeInData, value, fieldKey, handleChange, disabled, required,
  } = prop;
  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      verticalAlign: "middle",
      marginBottom: 15,
    }}
    >
      <div style={{ minWidth: 135, textAlign: "right", marginRight: 10 }}>
        <FormLabel>
          {label}
          {required ? <span style={{ color: "red" }}>{" * "}</span> : " "}
          :
        </FormLabel>
      </div>
      {!isCloseInField ? (
        <TextField
          variant="outlined"
          value={value}
          id={fieldKey}
          disabled={disabled}
          required={required}
          onChange={handleChange}
          className={styles.textField}
          style={{ width: width ? width : "" }}
        />
      ) : (
        <TextField
          variant="outlined"
          className={`${styles.textField} ${styles.closeIn}`}
          select
          disabled={disabled}
          style={{ width: width ? width : "" }}
        >
          {closeInData?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.FormLabel}
            </MenuItem>
          ))}
        </TextField>
      )}
    </div>
  );
}

export function CadSimulatorUnitsSectionField(prop: {
  label: string
  width?: number | string
  isStatusField?: boolean
  statusData?: {
    FormLabel: string;
  }[]
}) {
  const {
    label, width, isStatusField, statusData,
  } = prop;
  return (
    <div style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      verticalAlign: "middle",
      marginBottom: 15,
    }}
    >
      <div style={{ minWidth: 100, textAlign: "right", marginRight: 10 }}>
        <FormLabel>{`${label}:`}</FormLabel>
      </div>
      {!isStatusField ? (
        <TextField
          variant="outlined"
          className={styles.textField}
          style={{ width: width ? width : "" }}
        />
      ) : (
        <TextField
          variant="outlined"
          className={`${styles.textField} ${styles.closeIn}`}
          select
          style={{ width: width ? width : "" }}
        >
          <div className={styles.units_status_select}>
            {statusData?.map((option) => (
              <MenuItem key={option.FormLabel} value={option.FormLabel}>
                {option.FormLabel}
              </MenuItem>
            ))}
          </div>
        </TextField>
      )}
    </div>
  );
}
