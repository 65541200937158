import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Popover,
  TextField,
} from "@mui/material";
import {
  CancelRounded as FailIcon,
  CheckCircleRounded as SuccessIcon,
  GolfCourseRounded,
  Pending,
} from "@mui/icons-material";

import { useRef, useState } from "react";
import classes from "./JoinIncidentPopup.module.css";
import { joinIncident } from "@services/cad";

enum JoinStatus {
  None = "none",
  Success = "success",
  Fail = "fail",
}

export default function JoinIncidentPopup() {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState<null | HTMLElement>(null);
  const [joinCode, setJoinCode] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [joinStatus, setJoinStatus] = useState<JoinStatus>(JoinStatus.None);

  const handleJoin = async () => {
    setIsPending(true);
    const joinResult = await joinIncident(joinCode);
    if (joinResult) {
      setJoinStatus(joinResult.ok ? JoinStatus.Success : JoinStatus.Fail);
    }
    setIsPending(false);
  };

  const handleClose = async () => {
    setOpen(null);
    setIsOpen(false);
    setJoinCode("");
  };

  const openMenu = () => {
    setIsOpen(true);
    setOpen(ref.current);
  };

  return (
    <>
      <IconButton onClick={openMenu} ref={ref}>
        <GolfCourseRounded
          style={{
            color: "green",
          }}
        />
      </IconButton>
      <Popover
        open={isOpen}
        anchorEl={open}
      >
        <div className={classes.popup_wrapper}>
          <div className={classes.content_wrapper}>
            <DialogTitle id="form-dialog-title">Join Incident</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                fullWidth
                id="code"
                label="Code"
                margin="dense"
                type="text"
                value={joinCode}
                onChange={(e) => {
                  setJoinCode(e.target.value);
                }}
              />
            </DialogContent>
            <DialogActions>
              {isPending ? (<Pending style={{ color: "#b96467" }} />) : null}
              {joinStatus !== JoinStatus.None ? (
                joinStatus === JoinStatus.Success ? (
                  <SuccessIcon color="success" titleAccess="Successfully Joined Incident" />
                ) : (
                  <FailIcon color="error" titleAccess="Failed to Join Incident" />
                )
              ) : null}
              <Button onClick={handleClose} color="secondary">
                Cancel
              </Button>
              <Button onClick={handleJoin} color="primary">
                Join
              </Button>
            </DialogActions>
          </div>
        </div>
      </Popover>
    </>
  );
}
