import {
  useEffect, Suspense, lazy,
} from "react";
import "./main.css";
import "../../unmapped-devices/unmapped-devices.css";
import "../../account-users/account-users.css";
import "../../account-licensing/account-licensing.css";
import { SizeMe } from "react-sizeme";
import MainPageHeader from "../main-page-header";
import { accountSession } from "@services/user";
import { componentLoader } from "@utils/utilsFunctions";
import { Outlet } from "react-router-dom";

const Menu = lazy(() => componentLoader(() => import("../../menu/menu"), 5));

export default function MainPage() {
  useEffect(() => {
    const interval = setInterval(async () => {
      await accountSession();
    }, 45000);

    return clearInterval(interval);
  }, []);

  return (
    <SizeMe>
      {({ size }) => (
        <div className="container-fluid_superadmin">
          <Menu size={size.width} />
          <div className="right-area">
            <MainPageHeader />
            <Suspense fallback={<div />}>
              <div className="right-content">
                <Outlet />
              </div>
            </Suspense>
          </div>
        </div>
      )}
    </SizeMe>
  );
}
