import * as React from "react";
const SVGComponent = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    width={26}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11,1c0.6,0,1,0.4,1,1v12c0,0.6-0.4,1-1,1H5c-0.6,0-1-0.4-1-1V2c0-0.6,0.4-1,1-1H11z M5,0C3.9,0,3,0.9,3,2v12
	c0,1.1,0.9,2,2,2h6c1.1,0,2-0.9,2-2V2c0-1.1-0.9-2-2-2H5z"
    />
    <path
      d="M5.8,5.2c0.1-0.1,0.2,0,0.3,0.1l0,0l0.5,1C7,6.1,7.5,6,8,6s0.9,0.1,1.4,0.3l0.5-1c0.1-0.1,0.2-0.1,0.3-0.1
	c0.1,0.1,0.1,0.2,0.1,0.3l0,0l-0.5,1c1,0.5,1.6,1.4,1.6,2.5H4.6c0-1.1,0.6-2,1.6-2.5l-0.5-1C5.6,5.4,5.7,5.2,5.8,5.2L5.8,5.2z
	 M6.7,8.1c0.3,0,0.4-0.2,0.4-0.4S7,7.3,6.7,7.3c-0.2,0-0.4,0.2-0.4,0.4S6.5,8.1,6.7,8.1z M9.2,8.1c0.2,0,0.4-0.2,0.4-0.4
	S9.5,7.3,9.2,7.3C9,7.3,8.8,7.5,8.8,7.7S9,8.1,9.2,8.1z"
    />
  </svg>
);
export default SVGComponent;
