import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IncidentsTab, RouteParamIncidentsList,
} from "@models/routing";
import {
  AppBar,
  Tab,
  Tabs,
} from "@mui/material";
import active from "../../img/tab-icons/active_managed_incidents_icon.svg";
import inactive from "../../img/tab-icons/inactive_managed_incidents_icon.svg";
import monitor from "../../img/tab-icons/monitor.svg";
import { TabPanel } from "@components/tab-panel";
import { getURL, urls } from "@utils/routing";
import classes from "../tc-incident-details/managed-incidents-list.module.css";
import { ActiveIncidents } from "./active-incidents";
import { InactiveIncidents } from "./inactive-incidents";
import { getValidIncidentsTab } from "@utils/utilsFunctions";
import { IncidentsSearch } from "./search-incidents";

// eslint-disable-next-line no-redeclare
type Tab = {
  label: string;
  value: IncidentsTab;
  icon: string;
};

const tabs: Tab[] = [
  {
    label: "Active Incidents",
    value: IncidentsTab.Active,
    icon: active,
  },
  {
    label: "Inactive Incidents",
    value: IncidentsTab.Inactive,
    icon: inactive,
  },
  {
    label: "Search Incidents",
    value: IncidentsTab.Search,
    icon: monitor,
  },
];

export function IncidentsList() {
  const { tab: paramTab } = useParams<RouteParamIncidentsList>();
  const selectedTab = getValidIncidentsTab(paramTab, IncidentsTab.Active);
  const [selectedValue, setSelectedValue] = useState(tabs[0].value);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === `${getURL(urls.routingAppPrefix)}/${getURL(urls.adminIncidentsList, { tab: IncidentsTab.Active })}`) {
      setSelectedValue(IncidentsTab.Active);
    } else if (location.pathname === `${getURL(urls.routingAppPrefix)}/${getURL(urls.adminIncidentsList, { tab: IncidentsTab.Inactive })}`) {
      setSelectedValue(IncidentsTab.Inactive);
    } else if (location.pathname === `${getURL(urls.routingAppPrefix)}/${getURL(urls.adminIncidentsList, { tab: IncidentsTab.Search })}`) {
      setSelectedValue(IncidentsTab.Search);
    } else {
      // do nothing
    }
  }, [location.pathname]);

  const handleChange = (
    event: React.ChangeEvent<{}>,
    newValue: string | symbol,
  ) => {
    if (newValue === IncidentsTab.Active) {
      navigate(`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminIncidentsList, { tab: IncidentsTab.Active })}`);
    } else if (newValue === IncidentsTab.Inactive) {
      navigate(`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminIncidentsList, { tab: IncidentsTab.Inactive })}`);
    } else {
      navigate(`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminIncidentsList, { tab: IncidentsTab.Search })}`);
    }
  };

  const renderTab = (tab: Tab["value"]) => {
    switch (tab) {
      case IncidentsTab.Active:
        return <ActiveIncidents />;
      case IncidentsTab.Inactive:
        return <InactiveIncidents />;
      case IncidentsTab.Search:
        return <IncidentsSearch />;
      default:
        return (
          <div>
            Unhandled tab:
            {tab}
            .
          </div>
        );
    }
  };

  return (
    <AppBar position="static" color="default">
      <Tabs
        className={`${classes.tab}`}
        variant="fullWidth"
        scrollButtons
        value={selectedValue}
        centered
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
        onChange={handleChange}
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.label}
            label={tab.label}
            value={tab.value}
            icon={(
              <img
                src={tab.icon}
                alt=""
                style={{
                  width: 20,
                  height: 17,
                  marginBottom: 0,
                }}
              />
            )}
          />
        ))}
      </Tabs>
      {tabs.map((tab) => (
        <TabPanel key={tab.label} value={selectedTab} index={tab.value}>
          {renderTab(tab.value)}
        </TabPanel>
      ))}
    </AppBar>
  );
}
