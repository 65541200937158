/* eslint-disable react/no-array-index-key */
import { Typography } from "@mui/material";
import moment from "moment";
import { Changes, TCIncident } from "@models/tc-incident";
import classes from "./managed-incidents-list.module.css";
import { useState } from "react";
import IncidentTableHeaderWithToggle from "../incident-table-header-with-toggle";
import { CustomMaterialTable } from "@components/custom-material-table";

export function TCIncidentChanges({ incident }: {
  incident: TCIncident
}) {
  const [expand, setExpand] = useState(false);
  return (
    <div className={`${classes.changes}`}>
      <CustomMaterialTable<Changes>
        cellClassName={classes.cellWidth}
        hideContent={!expand}
        data={(incident.changes ?? []).sort((a, b) => (b.time ?? 0) - (a.time ?? 0))}
        title={<IncidentTableHeaderWithToggle title="Changes" expand={expand} setExpand={setExpand} />}
        cellAlign="left"
        header={
          [{
            name: "Modified",
            prop: "",
            render: (item) => (
              <div style={{
                display: "flex", columnGap: 10, alignItems: "center",
              }}
              >
                <div>
                  <div>{moment((item.time ?? 0) * 1000).format("HH:mm:ss")}</div>
                  <div style={{ fontSize: 10 }}>{moment((item.time ?? 0) * 1000).format("MM/DD/YYYY")}</div>
                </div>
                <Typography variant="body2">{`[${item.user?.username}]`}</Typography>
              </div>
            ),
          },
          {
            name: "Type",
            prop: "type",
          },
          {
            name: "Message",
            prop: "message",
          },
          {
            name: "New",
            prop: "new",
          },
          {
            name: "Old",
            prop: "old",
          },
          ]
        }
      />
    </div>
  );
}
