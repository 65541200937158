
export function UnmappedDeviceMarker() {
  return (
    <svg id="green_marker_vehicle" xmlns="http://www.w3.org/2000/svg" width="21" height="30" viewBox="0 0 21 30">
      <path id="Path_789" data-name="Path 789" d="M330.16,2765.844a10.5,10.5,0,1,0-21,0,10.942,10.942,0,0,0,2.343,6.8c.033.042.078.073.113.114l8.821,12.284,7.437-12.474h-.058A10.882,10.882,0,0,0,330.16,2765.844Z" transform="translate(-309.16 -2755.043)" fill="#57ac34" />
      <rect id="Rectangle_1352" data-name="Rectangle 1352" width="6.483" height="1" transform="translate(4.332 6.109)" fill="#fff" />
      <path id="Path_790" data-name="Path 790" d="M324.729,2762.348v-.7h-.8v.7h-2.939v1.271h-7.495v5.3h1.039a2,2,0,0,1,3.981,0h2.619a2,2,0,0,1,3.981,0h.818v-3.077h-3.267v-1.986h3.267v-1.5Z" transform="translate(-309.16 -2755.043)" fill="#fff" />
      <path id="Path_791" data-name="Path 791" d="M316.548,2768.327a1.067,1.067,0,1,0,1.067,1.066A1.066,1.066,0,0,0,316.548,2768.327Z" transform="translate(-309.16 -2755.043)" fill="#fff" />
      <path id="Path_792" data-name="Path 792" d="M323.1,2768.327a1.067,1.067,0,1,0,1.067,1.066A1.067,1.067,0,0,0,323.1,2768.327Z" transform="translate(-309.16 -2755.043)" fill="#fff" />
    </svg>


  );
}
