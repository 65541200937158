import { Color } from "@models/color";

export function UnitLabel({
  cleared,
  color,
  radioName,
  styleClassName,
  fullScreenView,
  uniqueKey,
}: {
  cleared?: boolean
  color?: Color
  radioName?: string
  styleClassName: string
  fullScreenView?: boolean
  uniqueKey?: string
}) {
  let backgroundColor = "rgb(93, 93, 93)";
  if (cleared) {
    backgroundColor = "#ddd";
  } else if (color && color.background) {
    backgroundColor = color.background;
  }

  return (
    <span
      className={styleClassName}
      key={uniqueKey}
      style={{
        backgroundColor,
        color: color?.text ?? "white",
        fontSize: fullScreenView ? 21 : 16,
      }}
      title={radioName}
    >
      {radioName}
    </span>
  );
}
