/* eslint-disable no-useless-escape */
import moment from "moment-timezone";
import React, { SetStateAction } from "react";

import { Color } from "@models/color";
import {
  CadStatus,
  CadStatusMap,
  CodeCSS,
  NewCadData,
  NewCadStatus,
} from "@models/cad-statuses";

import {
  HeartBeatIncidentDetailed,
  HeartBeat,
} from "@models/dashboard";

import { NotificationSounds } from "@models/notification-devices";
import { SafetyPriorityKeyword, UserDepartment } from "@models/department";
import { CADIncident, Coordinate, LocationBound } from "@models/cad";
import {
  ArcGISMap,
} from "@models/arcgis-maps";
import { TCIncident } from "@models/tc-incident";

import blueHeart from "../pages/img/superadmin-dashboard-icons/hear_blue_icon.svg";
import okIcon from "../pages/img/superadmin-dashboard-icons/green_check_round_icon.svg";
import warningIcon from "../pages/img/superadmin-dashboard-icons/alert_red_icon.svg";
import alertIcon from "../pages/img/superadmin-dashboard-icons/alert_yellow_round_icon.svg";
import { IncidentsTab } from "@models/routing";
import { Incident } from "@models/incident";
import { VehiclesStatus } from "@models/cad-vehicles-status";

import { ArcGIsIncidentGroup, GraphicOptions } from "@models/arcgis";
import { Filter } from "material-table";
import { TableData, UnitLocation } from "@models/location";

export type KeyIsValue<T> = { [P in keyof T]-?: T[P] extends Array<any> ? P : T[P] extends object ? KeyIsValue<T[P]> : P };

export type ValueIsOne<T> = { [P in keyof T]-?: T[P] extends Array<any> ? number : T[P] extends object ? ValueIsOne<T[P]> : number };

export type FieldType<T> = { format: (value: T) => string, validator: (value: T | undefined) => value is T }

export const nonEmptyStringField: FieldType<string> = {
  format: (s) => s,
  validator: (s): s is string => !!s && s !== "",
};

export const dateField: FieldType<number> = {
  format: (n) => moment((n ?? 0) * 1000).format(
    "HH:mm:ss, MMMM DD YYYY",
  ),
  validator: (n): n is number => !!n && n > 0,
};

export const stringDateField: FieldType<string> = {
  format: (n) => moment(n).format(
    "HH:mm:ss, MMMM DD YYYY",
  ),
  validator: (n): n is string => !!n && n !== "",
};

export function componentLoader(lazyComponent: Function, attemptsLeft: number) {
  return new Promise<{ default: React.ComponentType<any>; }>((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch(() => {
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            // Force reload the page of resources are not found
            window.location.reload();
          }
          componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
        }, 1500);
      });
  });
}

export function isObject(obj: any) {
  return typeof obj === "object" && obj !== null && !Array.isArray(obj);
}

export function getKeys<T extends object>(p: ValueIsOne<T>): KeyIsValue<T> {
  const r: Partial<Record<keyof T, unknown>> = {};

  for (const k of Object.keys(p) as Array<keyof T>) {
    const value = p[k];
    if (value && isObject(value)) {
      r[k] = getKeys(value as ValueIsOne<T>);
    } else {
      r[k] = k;
    }
  }
  return r as KeyIsValue<T>;
}

export function arrayContainsText(array: string[], text: string) {
  const found = array.reduce((r, key) => r && text.toLocaleLowerCase().includes(key.toLocaleLowerCase()), true as boolean);
  return found;
}

export function arrayContainsText2(array: string[], text: string, isSelectFiltering: boolean) {
  for (const key of array) {
    if (key.toLocaleLowerCase().includes(text) && isSelectFiltering || text.toLocaleLowerCase().includes(key)) {
      return true;
    }
  }

  return false;
}

export function MapsArcGISSearch(data: ArcGISMap[], searchInput: string) {
  const searchSplit = searchInput.toLowerCase().split(" ").filter((el) => el !== "");
  const searchMap = data.filter((item) => arrayContainsText(searchSplit, item.title.toLowerCase()));
  const mappedItems = searchMap.map((item) => item);
  return mappedItems;
}

export function valueIsDefined<T>(value: T | undefined): value is T {
  return value !== undefined;
}

export function CadStatusLabels(cadStatusData: CadStatus[], statusMapData: CadStatusMap[]) {
  const items: NewCadData[][] = [];
  cadStatusData.forEach((element) => {
    let newData: NewCadData[] = [];
    const nextItems = statusMapData.filter((statusMap) => statusMap.fromStatusId === element.statusId);

    const statusMap = nextItems[0];
    const toStatusIdsMap: Record<number, boolean> = {};
    const toStatusIds = statusMap?.toStatusIds?.map((statusMapItem) => {
      toStatusIdsMap[statusMapItem.statusId] = statusMapItem.userEnabled;
      return statusMapItem.statusId;
    });
    const toStatusesFull = toStatusIds?.map((id) => cadStatusData.find((status) => status.statusId === id))?.filter(valueIsDefined);

    const toStatusesAvailable = toStatusesFull?.map((el) => {
      let userEnabled = false;
      if (toStatusIdsMap[el.statusId]) {
        userEnabled = toStatusIdsMap[el.statusId];
      }
      const code = (el.codeDisplay && el.codeDisplay !== "") ? el.codeDisplay : el.code;
      const codeTitle = code + (userEnabled ? " enabled" : " disabled");

      return {
        code,
        codeTitle,
        userEnabled,
        fromStatusMapId: statusMap._id,
        fromStatusId: element.statusId,
        fromStatusCode: element.code,
        toStatusId: el.statusId ?? [],
      };
    }) ?? [];
    newData = [...toStatusesAvailable];
    items.push(newData);
  });

  return items.flat();
}

export function colorAsCSS(color: Color) {
  const style: CodeCSS = {
    "background-color": "#5d5d5d",
    color: "#F8F9F9",
  };

  if (color === null || color === undefined) {
    return style;
  }

  if ((typeof color?.background === "string" && color?.background !== "")) {
    style["background-color"] = color.background;
  }

  if (typeof color?.text === "string" && color?.text !== "") {
    style.color = color.text;
  }

  return style;
}

export function colorIsSet(item: Color, key: keyof Color) {
  const isNull = (item === null);
  const isUndefined = (typeof item === "undefined");
  if (isNull || isUndefined) {
    return false;
  }
  const isString = (typeof item[key] === "string");
  const isSet = isString && item[key] !== "";
  return isSet;
}

export function classForColor(color: Color) {
  const textIsSet = colorIsSet(color, "text");
  const backgroundColorIsSet = colorIsSet(color, "background");
  if (textIsSet && backgroundColorIsSet) {
    return "status-color-enabled";
  }
  return "status-color-disabled";
}

export function cadStatusesModified(cadStatus: CadStatus[], cadStatusMap: CadStatusMap[]) {
  const newData: NewCadStatus[] = cadStatus.map((status) => ({
    code: status.code,
    codeDisplay: status.codeDisplay,
    color: status.color,
    codeCSS: colorAsCSS(status.color),
    departmentId: status.departmentId,
    modifiedDate: status.modifiedDate,
    name: status.name,
    normalized: status.normalized,
    options: status.options,
    roaming: status.roaming,
    selfAssignable: status.selfAssignable,
    status: status.status,
    statusId: status.statusId,
    uuid: status.uuid,
    _id: status._id,
    toStatuses: CadStatusLabels(cadStatus, cadStatusMap).filter((el) => el.fromStatusId === status.statusId),
  }));

  return newData;
}

export function filteredCadStatuses(cadStatus: CadStatus[], cadStatusMap: CadStatusMap[], searchedWord: string) {
  const filteredData = cadStatusesModified(cadStatus, cadStatusMap).filter((item) => item.code.toLowerCase() === searchedWord.toLowerCase()
    || item.code.toLowerCase().includes(searchedWord.toLowerCase())
    || item.name.toLowerCase().includes(searchedWord.toLowerCase())
    || item.status.toLowerCase().includes(searchedWord.toLowerCase())
    || item.normalized?.toLowerCase().includes(searchedWord.toLowerCase())
    || item.toStatuses.map((statusLabel) => statusLabel.code.toLowerCase()).some((e) => e.includes(searchedWord.toLowerCase())));

  return (!searchedWord ? cadStatusesModified(cadStatus, cadStatusMap) : filteredData);
}

export function getCustomFilterAndSearch<T>(getString: (obj: T) => string) {
  return {
    customFilterAndSearch: (term: string, r: T) => (getString(r).toLocaleLowerCase()).indexOf(term.toLocaleLowerCase()) !== -1,
    customSort: (a: T, b: T) => getString(a).localeCompare(getString(b)),
  };
}

export function lastHeartbeatUnix(items: HeartBeatIncidentDetailed[keyof HeartBeatIncidentDetailed]) {
  if (!Array.isArray(items)) {
    return 0;
  }
  if (items.length === 0) {
    return 0;
  }
  const item = items[0];

  return item.RcvTime;
}

export function lastHeartbeat(items: HeartBeatIncidentDetailed[keyof HeartBeatIncidentDetailed]) {
  if (!Array.isArray(items)) {
    return "?";
  }

  if (items.length === 0) {
    return "-";
  }

  const item = items[0];
  return item.timeAgo;
}

export function isAlive(unixTime: number) {
  const item = moment.unix(unixTime);
  const timeAgo = moment().subtract(5, "minutes");
  return timeAgo.isBefore(item);
}

export function heartbeatState(item: HeartBeat) {
  const incidentUnixTime = lastHeartbeatUnix(item.heartbeat.incident);
  const statusUnixTime = lastHeartbeatUnix(item.heartbeat.status);
  const locationUnixTime = lastHeartbeatUnix(item.heartbeat.location);

  if (incidentUnixTime <= 0) {
    return "info";
  }
  if (!isAlive(incidentUnixTime)) {
    return "error";
  }
  if (statusUnixTime > 0 && !isAlive(statusUnixTime)) {
    return "warning";
  }
  if (locationUnixTime > 0 && !isAlive(locationUnixTime)) {
    return "warning";
  }
  return "success";
}

export function timeElapsed(seconds: number) {
  let hr: string | number = Math.floor(seconds / 3600);
  let min: string | number = Math.floor((seconds - (hr * 3600)) / 60);
  let sec: string | number = Math.floor(seconds - (hr * 3600) - (min * 60));

  if (hr < 10) {
    hr = `0${hr}`;
  }

  if (min < 10) {
    min = `0${min}`;
  }

  if (sec < 10) {
    sec = `0${sec}`;
  }

  if (!hr) {
    hr = "00";
  }

  return `${hr}:${min}:${sec}`;
}

export function sound(item: NotificationSounds | undefined, os: keyof NotificationSounds) {
  return item && item[os]?.sound ? item[os]?.sound : "-";
}

export function doExtend(user: UserDepartment | undefined) {
  if (!user) {
    return;
  }

  let currentDate = new Date();
  if (user?.syncLoggingExpireDate
    && user?.syncLoggingExpireDate !== ""
    && moment(user?.syncLoggingExpireDate).isValid()
    && moment(user?.syncLoggingExpireDate).valueOf() > currentDate.valueOf()) {
    currentDate = moment(user?.syncLoggingExpireDate).toDate();
  }
  const extendInterval = 1000 * 60 * 60 * 24; // 24h
  user.syncLoggingExpireDate = new Date(currentDate.getTime() + extendInterval).toISOString();
}

export function doClearExtend(userData: UserDepartment | undefined) {
  if (!userData) {
    return;
  }

  userData.syncLoggingExpireDate = undefined;
}

export function showSyncExtendedDate(user: UserDepartment | undefined) {
  const isValid = user?.syncLoggingExpireDate
    && user?.syncLoggingExpireDate !== ""
    && moment(user?.syncLoggingExpireDate).isValid();
  if (!isValid) {
    return "Disabled";
  }

  const maybeDate = moment(user?.syncLoggingExpireDate).toDate();
  if (maybeDate.valueOf() < (new Date()).valueOf()) {
    return "Expired";
  }

  return `Log until ${maybeDate.toString()}.`;
}

export function getDifferenceInDays(date1: string): number {
  if (!date1) {
    return 0;
  }
  const firstDate = moment(); // today\
  const secondDate = moment(date1);
  const difference = firstDate.diff(secondDate, "days");

  return difference;
}

export function getDiffInMinutes(date: string): number {
  if (!date) {
    return 0;
  }

  const today = moment();
  const datetime = moment(date);
  const diff = today.diff(datetime, "minutes");

  return diff;
}

export function getDiffInHours(date: string): number {
  if (!date) {
    return 0;
  }

  const today = moment();
  const datetime = moment(date);
  const diff = today.diff(datetime, "hours");

  return diff;
}

export function getDiffInSeconds(date: number): number {
  if (!date) {
    return 0;
  }

  const today = moment();
  const datetime = moment.unix(date);
  const diff = today.diff(datetime, "seconds");

  return diff;
}

export function minutesDiff(date: number): number {
  if (!date) {
    return 0;
  }

  const today = moment();
  const datetime = moment.unix(date);
  const diff = today.diff(datetime, "minutes");

  return diff;
}

export function startTimer(timer: number, setTimer: (e: SetStateAction<number>) => void) {
  const countMinutes = Math.floor(timer / 60);
  const seconds = timer % 60;
  setTimer(timer - 1);

  return {
    countMinutes,
    seconds,
  };
}

export function updateTime(date1: number) {
  const startTime = moment.unix(date1);
  const currentTime = moment();

  let diff = Math.round(currentTime.diff(startTime) / 1000);
  const d = Math.floor(diff / (24 * 60 * 60));
  diff -= (d * 24 * 60 * 60);
  const h = Math.floor(diff / (60 * 60));
  diff -= (h * 60 * 60);
  const m = Math.floor(diff / (60));
  diff -= (m * 60);
  const s = diff;

  return {
    hours: h,
    minutes: m,
    seconds: s,
  };
}

export function updateChannels(incident: CADIncident | TCIncident | Incident): {
  name: string
  channel: string
}[] {
  const channels = [];
  if (incident.CommandChannel && incident.CommandChannel !== "") {
    channels.push({
      name: "Command",
      channel: incident.CommandChannel,
    });
  }
  if (incident.TacticalChannel && incident.TacticalChannel !== "") {
    channels.push({
      name: "Tactical",
      channel: incident.TacticalChannel,
    });
  }
  if (incident.TacticalAltChannel && incident.TacticalAltChannel !== "") {
    channels.push({
      name: "TacticalAlt",
      channel: incident.TacticalAltChannel,
    });
  }
  return channels;
}

export function getIncidentCustomFilterAndSearchValue(item: Incident): string {
  const parts: string[] = [];
  if (item.incidentNumber) {
    parts.push(item.incidentNumber);
  }
  if (item.address) {
    parts.push(item.address);
  }
  if (item.incidentCallTypeDescription) {
    parts.push(item.incidentCallTypeDescription);
  }
  if (item.units && item.units.length > 0) {
    item.units.forEach((u) => {
      if (u && u.UnitID) {
        parts.push(u.UnitID);
      }
    });
  }
  if (item.ReportNumber && (item.ReportNumber instanceof Array) && item.ReportNumber.length > 0) {
    item.ReportNumber.forEach((rn) => {
      if (rn && rn.name && rn.number) {
        parts.push(`${rn.name} ${rn.number}`);
      }
    });
  }
  return parts.join(" ");
}

export function getCADIncidentCustomFilterAndSearchValue(item: CADIncident): string {
  const parts: string[] = [];
  if (item.IncidentNumber) {
    parts.push(item.IncidentNumber);
  }
  if (item.full_address) {
    parts.push(item.full_address);
  }
  if (item.AgencyIncidentCallTypeDescription) {
    parts.push(item.AgencyIncidentCallTypeDescription);
  }
  if (item.units && item.units.length > 0) {
    item.units.forEach((u) => {
      if (u && u.UnitID) {
        parts.push(u.UnitID);
      }
    });
  }
  if (item.ReportNumber && (item.ReportNumber instanceof Array) && item.ReportNumber.length > 0) {
    item.ReportNumber.forEach((rn) => {
      if (rn && rn.name && rn.number) {
        parts.push(`${rn.name} ${rn.number}`);
      }
    });
  }
  return parts.join(" ");
}

export function generateSecretKey(length: number): string {
  let text = "";
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export function channelFromDepartment(department: string) {
  const channelPrefix = department.replace(/\s/g, "").replace(/[\W]/g, "");
  return channelPrefix.toUpperCase() + generateSecretKey(3).toUpperCase();
}

export async function getRandomTransactionId(): Promise<string> {
  const randomStr = generateSecretKey(4);
  return `TC-${new Date().toISOString()}-${randomStr}`;
}

export function formatFileSize(bytes: number, decimalPoint: number = 2) {
  if (bytes === 0) {
    return "0 B";
  }
  const k = 1024;
  const dm = decimalPoint || 2;
  const sizes = [
    "B",
    "KB",
    "MB",
    "GB",
    "TB",
    "PB",
    "EB",
    "ZB",
    "YB",
  ];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const bytesNumber = parseFloat((bytes / k ** i).toFixed(dm));
  const bytesSize = sizes[i];
  return `${bytesNumber} ${bytesSize}`;
}

export function averageGeolocation(coords: Array<{ latitude: number, longitude: number }>) {
  if (coords.length === 1) {
    return coords[0];
  }

  let x = 0.0;
  let y = 0.0;
  let z = 0.0;

  for (const coord of coords) {
    const latitude = coord.latitude * Math.PI / 180;
    const longitude = coord.longitude * Math.PI / 180;

    x += Math.cos(latitude) * Math.cos(longitude);
    y += Math.cos(latitude) * Math.sin(longitude);
    z += Math.sin(latitude);
  }

  const total = coords.length;

  x = x / total;
  y = y / total;
  z = z / total;

  const centralLongitude = Math.atan2(y, x);
  const centralSquareRoot = Math.sqrt(x * x + y * y);
  const centralLatitude = Math.atan2(z, centralSquareRoot);

  return {
    latitude: centralLatitude * 180 / Math.PI,
    longitude: centralLongitude * 180 / Math.PI,
  };
}

export const getStringFromObj = <T extends object>(
  value: T,
  key: keyof T,
  render?: ((obj: T) => JSX.Element | string | undefined) | null,
) => {
  if (render) {
    const renderValue = render(value);
    if (typeof renderValue === "string") {
      return renderValue;
    }
    // cannot retrieve text from an jsx element so just return the text
    return JSON.stringify(value[key] ?? "");
  }
  return JSON.stringify(value[key] ?? "");
};

export const getNameFontSize = (length: number): number => {
  const fonSize = length <= 6 ? 14
    : length > 6 && length <= 13 ? 14 - (length - 6)
      : length > 13 && length <= 15 ? 15 - (length - 6)
        : length >= 16 && length <= 17 ? 16 - (length - 6)
          : length >= 18 ? 18 - (length - 6) : 14;

  return fonSize;
};

export const getRectColor = (unitsNumber: number, groupSize: number) => ({ fill: unitsNumber >= groupSize ? "#71b72b" : "#242424" });

export function MoveArray<T extends { position: number }>(arr: T[], from: number, to: number) {
  if (from !== to) {
    const positions = arr.map((a) => a.position);
    const min = Math.min(from, to);
    const max = Math.max(from, to);
    const before = arr.slice(0, min);
    const after = arr.slice(max + 1, arr.length);
    const changingElement = arr[from];

    if (from < to) {
      const rhs = arr.slice(min + 1, max + 1);
      return [...before, ...rhs, changingElement, ...after].map((el, i) => ({
        ...el,
        position: positions[i],
      }));
    }

    const rhs = arr.slice(min, max);
    return [...before, changingElement, ...rhs, ...after].map((el, i) => ({
      ...el,
      position: positions[i],
    }));
  }
  return arr;
}

export function parseLocation(value: string | undefined, defaultValues: [lat: number, long: number] = [0, 0]): [lat: number, long: number] {
  if (!value) return defaultValues;
  const index = value.indexOf(",");
  const latStr = value.substr(0, index);
  const longStr = value.substr(index + 1);
  return [
    latStr ? +latStr : defaultValues[0],
    longStr ? +longStr : defaultValues[1],
  ];
}

export function getHeartbeatsColor(value: HeartBeat): string {
  const color: string = heartbeatState(value) === "info" ? "#88a6ab"
    : heartbeatState(value) === "error" ? "#9f3135"
      : heartbeatState(value) === "warning" ? "#eeac3f" : "#52782c";

  return color;
}

export function getHeartbeatsIcon(value: HeartBeat): string {
  const icon: string = heartbeatState(value) === "info" ? blueHeart
    : heartbeatState(value) === "error" ? warningIcon
      : heartbeatState(value) === "warning" ? alertIcon : okIcon;

  return icon;
}

export function checkLastSelectedMapLayer(lastLayerName: string, currentLayerName: string) {
  if (lastLayerName === currentLayerName) {
    return true;
  }
  return false;
}

export function getUSAndCanadaTimezones(): string[] {
  const usTimezones = moment.tz.zonesForCountry("US");
  const canadaTimezones = moment.tz.zonesForCountry("CA");
  const australiaTimezones = moment.tz.zonesForCountry("AU");
  const timezones = usTimezones.concat(canadaTimezones).concat(australiaTimezones);

  const filteredCountries = moment.tz.countries().filter((el) => el !== "US" && el !== "CA" && el !== "AU");
  const filteredCountriesTimezones = filteredCountries.map((el) => moment.tz.zonesForCountry(el)).flat();
  const allTimezones = timezones.concat(filteredCountriesTimezones);

  return allTimezones;
}

export async function handleDownload(dataGetter: () => Promise<string>, name: string) {
  const data = await dataGetter();
  const blob = new Blob([data!]);
  const download = document.createElement("a");
  download.href = URL.createObjectURL(blob);
  download.download = `${name}.csv`;
  download.click();
}

export function getMilesPerHour(speed: number) {
  const newValue = Math.round(speed * 2.236936);

  return newValue;
}

export function getFeet(altitude: number) {
  const newValue = Math.round(altitude * 3.2808);

  return newValue;
}

export function matchVehicle(vehicles: string[], currentVehicle: string) {
  for (const vehicle of vehicles) {
    if (vehicle === currentVehicle) {
      return true;
    }
  }
  return false;
}

export function getDeviceVersion(version: string, filterKeyVersion?: string) {
  const newVersion = version.split("/");
  const specialVersion = filterKeyVersion ? version.substring(filterKeyVersion.length) : "";
  const devicesVersions = newVersion[0].split(" ");
  const deviceVersion = newVersion.length < 2 && devicesVersions[0] === "iOS" ? devicesVersions[1] : devicesVersions[2];

  return filterKeyVersion ? specialVersion : (newVersion.length >= 2 ? newVersion[1] : deviceVersion);
}

export function customSorting<T>(el1: T, el2: T, key: keyof T, sortType: string): number {
  if (sortType === "asc" && el1[key] < el2[key]) {
    return 1;
  }
  if (sortType === "asc" && el1[key] > el2[key]) {
    return -1;
  }
  if (sortType === "desc" && el1[key] < el2[key]) {
    return -1;
  }
  if (sortType === "desc" && el1[key] > el2[key]) {
    return 1;
  }
  return 0;
}

export function detectDuplicateUnits(existingData: string[], newData: string[]) {
  const checkedElements = new Set(existingData);

  for (const element of newData) {
    if (checkedElements.has(element)) {
      return true;
    }
  }

  return false;
}

export function getValidIncidentsTab(maybeTab: IncidentsTab | null | undefined, defaultValue: IncidentsTab): IncidentsTab {
  let nextTabValue = defaultValue;
  switch (maybeTab) {
    case IncidentsTab.Active:
    case IncidentsTab.Inactive:
    case IncidentsTab.Search:
      nextTabValue = maybeTab;
      break;

    default:
      break;
  }
  return nextTabValue;
}

export function getUnitStatusAbbreviation(status: string): string {
  switch (status) {
    case "Dispatched":
      return "DSP";
    case "Enroute":
      return "RSP";
    case "Staged":
      return "STG";
    case "Arrived":
      return "ONS";
    case "Transporting":
      return "TR";
    case "AtHospital":
      return "AH";
    case "Cleared":
      return "AVL";
    default:
      return "";
  }
}

export function getObjectDifferences<T extends object>(oldObject: T, newObject: T) {
  // last element from oldObjectValues array contains unnecessary data from material-table
  const oldObjectValues = Object.values(oldObject);
  const objectKeys = Object.keys(newObject);
  const newObjectValues = Object.values(newObject);
  const objectWithDifferences: Record<string, T> = {};

  newObjectValues.forEach((el, index) => {
    if (el !== oldObjectValues[index]) {
      objectWithDifferences[objectKeys[index]] = el;
    }
  });

  return objectWithDifferences;
}

export function fitMarkers(
  incidents: Coordinate[],
  avlMarkers: {
    color: Color;
    username: string;
    device_type: string;
    location: Coordinate;
  }[],
): LocationBound {
  const y: Coordinate[] = (avlMarkers ?? []).filter((el) => el.location.latitude !== 0 && el.location.longitude !== 0).map((el) => ({
    latitude: el.location.latitude,
    longitude: el.location.longitude,
  }));

  const x: Coordinate[] = [];
  incidents.forEach((el) => {
    if (Math.abs(el.latitude) <= 5 && Math.abs(el.longitude) <= 5) {
      return;
    }

    x.push(el);
  });

  const coordinates = y.concat(x);
  const fitBounds = coordinates.reduce(
    (acc, geom) => (
      {
        xmin: Math.min(acc.xmin, geom.longitude),
        ymin: Math.min(acc.ymin, geom.latitude),
        xmax: Math.max(acc.xmax, geom.longitude),
        ymax: Math.max(acc.ymax, geom.latitude),
      }
    ),
    {
      xmin: Number.MAX_SAFE_INTEGER,
      ymin: Number.MAX_SAFE_INTEGER,
      xmax: Number.MIN_SAFE_INTEGER,
      ymax: Number.MIN_SAFE_INTEGER,
    },
  );

  return fitBounds;
}

export function fitMarkers2(
  locations: Set<GraphicOptions>,
): LocationBound {
  const fitBounds = {
    xmin: Number.MAX_SAFE_INTEGER,
    ymin: Number.MAX_SAFE_INTEGER,
    xmax: Number.MIN_SAFE_INTEGER,
    ymax: Number.MIN_SAFE_INTEGER,
  };
  locations.forEach(
    (geom) => {
      if (!geom.id.includes("label")) {
        fitBounds.xmin = Math.min(fitBounds.xmin, geom.geometry.longitude);
        fitBounds.ymin = Math.min(fitBounds.ymin, geom.geometry.latitude);
        fitBounds.xmax = Math.max(fitBounds.xmax, geom.geometry.longitude);
        fitBounds.ymax = Math.max(fitBounds.ymax, geom.geometry.latitude);
      }
    },
  );
  return fitBounds;
}

export function getValidCoordinate(maybeLatitude: number | string | undefined | null, maybeLongitude: number | string | undefined | null): Coordinate | null {
  const maxLat = 90;
  const maxLon = 180;
  if (maybeLatitude
    && Number.isFinite(parseFloat(`${maybeLatitude}`))
    && !Number.isNaN(parseFloat(`${maybeLatitude}`))
    && Math.abs(parseFloat(`${maybeLatitude}`)) <= maxLat
    && maybeLongitude
    && Number.isFinite(parseFloat(`${maybeLongitude}`))
    && !Number.isNaN(parseFloat(`${maybeLongitude}`))
    && Math.abs(parseFloat(`${maybeLongitude}`)) <= maxLon) {
    return {
      latitude: parseFloat(`${maybeLatitude}`),
      longitude: parseFloat(`${maybeLongitude}`),
    };
  }
  return null;
}

export function filterHasValues(filter: Filter<UnitLocation & TableData>[]) {
  for (const element of filter) {
    if (element.value.length > 0) {
      return true;
    }
  }

  return false;
}

export function matchURL(baseURL: string, urls: string[]) {
  for (const url of urls) {
    if (baseURL.match(url)) { return true; }
  }
  return false;
}

export function VehicleStatusSearch(data: VehiclesStatus[], input: string, filterAssignableByUser: boolean) {
  const sortedData = data
    .filter((el) => el.requestTime > el.responseTime || el.owner === "user")
    .sort((a, b) => b.modifiedDate - a.modifiedDate)
    .concat(
      data
        .filter((el) => (el.requestTime < el.responseTime || el.requestTime === el.responseTime) && el.owner !== "user")
        .sort((a, b) => b.modifiedDate - a.modifiedDate),
    );

  const filterOnAssignableToUser = sortedData.filter((item) => {
    if (filterAssignableByUser) {
      return item.assignableByUser === filterAssignableByUser;
    }

    return true;
  });

  // No search, return all the filtered items
  if (!input) {
    return filterOnAssignableToUser;
  }

  const searchedData: VehiclesStatus[] = filterOnAssignableToUser
    .filter((value) => value.vehicleId.toLowerCase().includes(input.toLowerCase())
      || value.statusCode.toLowerCase().includes(input.toLowerCase())
      || value.radioName.toLocaleLowerCase().includes(input.toLocaleLowerCase())
      || value.status.toLowerCase().includes(input.toLowerCase())
      || value.incidentNumber.toLowerCase().includes(input.toLowerCase()));

  return searchedData;
}

export function escapeRegExp(string: string) {
  // eslint-disable-next-line no-useless-escape
  return string.replace(/[~!@#$%^&*()_+\-=\[\]{}|\\:;"'<>,.?\/\s]/g, "\\$&");
}

const getBoundaryByCharacter = (character: string) => {
  if (character.match("\\W")) {
    return "\\b";
  }
  return "\\B";
};

export function parseRegexForKeyword(keyword: string) {
  const lowercaseKeyword = keyword.toLocaleLowerCase();
  const firstBoundary = getBoundaryByCharacter(lowercaseKeyword.charAt(0));
  const lastBoundary = getBoundaryByCharacter(lowercaseKeyword.charAt(lowercaseKeyword.length - 1));
  return new RegExp(`(?!${firstBoundary}\\S)(?:` + escapeRegExp(lowercaseKeyword) + `)(?!${lastBoundary}\\S)`, "gm");
}
export function checkKeywordMatch(word: string, words: string[]) {
  // eslint-disable-next-line no-useless-escape
  const matchedWord = words.filter((el) => word?.toLocaleLowerCase().match(parseRegexForKeyword(el)));
  return matchedWord.length > 0 ? true : false;
}

export function getWordPriorityColor(priorityKeywords: SafetyPriorityKeyword[], word: string) {
  const color = {
    text: "#000000",
    background: "",
  };

  for (const priorityKeyword of priorityKeywords) {
    if (checkKeywordMatch(word, priorityKeyword.keywords)) {
      color.background = `#${priorityKeyword.hexColor}`;
      color.text = "#ffffff";
      break;
    }
  }

  return color;
}

export function getArcGISTokenValidity(currentDate: Date, skipPrefix: boolean, expireAt: string) {
  const expireAtDate = moment(new Date(expireAt));
  const current = moment(currentDate);

  const years = current.diff(expireAtDate, "years");
  const months = current.diff(expireAtDate, "months");
  const days = current.diff(expireAtDate, "days");
  const hours = current.diff(expireAtDate, "hours");
  const minutes = current.diff(expireAtDate, "minutes");

  const isExpired = expireAtDate < current;
  const multiply: number = isExpired ? 1 : -1;

  let timeInterval = `${multiply * years} years`;
  if (years === 0) {
    timeInterval = `${multiply * months} months`;
    if (months === 0) {
      timeInterval = `${multiply * days} days`;
      if (days === 0) {
        timeInterval = `${multiply * hours} hours`;
        if (hours === 0) {
          timeInterval = `${multiply * minutes} minutes`;
        }
      }
    }
  }

  const prefix = skipPrefix ? "" : (isExpired ? "Expired " : "Valid for ");

  return {
    prefix,
    timeInterval,
    isExpired: isExpired ? " ago." : ".",
  };
}

export function checkIfFullscreen() {
  return !!document.fullscreenElement
    || !!(document as any).webkitFullscreenElement
    || !!(document as any).mozFullScreenElement;
}

export function openFullscreen() {
  const elem = document.documentElement;
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if ((elem as any).webkitRequestFullscreen) { /* Safari */
    (elem as any).webkitRequestFullscreen();
  } else if ((elem as any).msRequestFullscreen) { /* IE11 */
    (elem as any).msRequestFullscreen();
  }
}

export function closeFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) { /* Safari */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) { /* IE11 */
    document.msExitFullscreen();
  }
}

export function getDuplicates<T extends object & { username: string }>(data: T[]) {
  const passedValues = new Map<string, T>();
  const duplicates = new Set<T>();

  data.forEach((element) => {
    if (!passedValues.has(element.username)) {
      passedValues.set(element.username, element);
    } else {
      // duplicate
      data.filter((el) => el.username === element.username).forEach((el) => duplicates.add(el));
    }
  });

  return [...duplicates.values()];
}

// TODO: Create a generic for this one as it is used in more places for other types
export function personnelTotalNumber(group: ArcGIsIncidentGroup) {
  let personnel = 0;
  group?.units?.forEach((unit) => {
    const unitPersonnel = unit.personnelOnScene ?? 0;
    personnel += unitPersonnel;
  });

  return personnel;
}

export function randomIntFromInterval(min: number = 1, max: number = 6) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function debounce<P extends any[]>(
  cb: (...args: P) => void,
  delay = 1500,
) {
  let timeout: NodeJS.Timeout;
  return (...args: P) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      cb(...args);
    }, delay);
  };
}

// TODO: use this were we compare strings as it makes code more readable for inline comparisons
export function equalStrings(stringA: string, stringB: string): boolean {
  const sanitizedStringA = stringA.trim().toLocaleLowerCase();
  const sanitizedStringB = stringB.trim().toLocaleLowerCase();
  return sanitizedStringA === sanitizedStringB;
}

export function getLocaleDistanceFromMeters(value: string | number, measureUnit: boolean, getMiles?: boolean) {
  const feetWeight = 3.2808;
  const milesWeight = 5280;
  const locale = navigator?.language;
  if (locale.toLowerCase() === "en-us") {
    let localeDistance = 0;

    // if value is passed as string
    if (typeof value === "string") {
      localeDistance = Math.round((parseFloat(value) * feetWeight));
    } else {
      localeDistance = Math.round(value * feetWeight);
    }

    // if we want miles from feet
    if (getMiles) {
      if (measureUnit) {
        return (localeDistance / milesWeight).toFixed(1).toString() + " miles";
      }
      return (localeDistance / milesWeight).toFixed(1).toString();
    }

    if (measureUnit) {
      return localeDistance.toString() + " ft";
    }
    return localeDistance.toString();
  }

  if (measureUnit) {
    return value.toString() + " m";
  }
  return value.toString();
}

export function getDaysFromSeconds(value: number) {
  const secondsInDay = 86400;
  return Math.round(value / secondsInDay);
}

export function getSecondsFromDays(value: number) {
  const secondsInDay = 86400;
  return Math.round(value * secondsInDay);
}

export function checkUTF8Characters(value: string) {
  const utf8Regex = /^[\u0020-\u007E]*$/u;
  return utf8Regex.test(value);
}
