import React, { useCallback, useMemo, useState } from "react";
import MaterialTable from "material-table";
import { Typography } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";

import { UnitLabel } from "@components/unit-label";
import CADVehicleStatusHistoryItems from "@components/cad-status/CADVehicleStatusHistory";
import CADVehicleStatusPersonnelColumn, { CADVehicleStatusModifiedDateColumns } from "@components/cad-status/cad-status-components";
import { TabletButton } from "@components/tablet-button/custom-button";

import { useDataWithoutLoading } from "@hooks/useDataWithoutLoading";
import { useHeight } from "@hooks/useHeight";
import { useInterval } from "@hooks/useInterval";
import { useTitle } from "@hooks/useTitle";

import { getActivePersonnelParsed } from "@services/active_personnel";
import { confirmCadVehicleStatus, getVehiclesStatus } from "@services/cad-vehicles-status";

import { VehicleStatusSearch } from "@utils/utilsFunctions";

import classes from "./cad-status.module.css";
import CADVehiclesStatusTableHeader from "./cad-vehicles-status-table-header";
import { getCadStatus } from "@services/cad-statuses";
import { getCADVehicles } from "@services/cad-vehicles";
import { VehiclesStatusList } from "@models/cad-vehicles";
import StatusInfo from "./cad-status-information";
import OptionInfo from "./cad-options-information";

export default function CADVehiclesStatus({ restricted = false }: { restricted?: boolean }) {
  useTitle("CAD Vehicles Status");
  const height = useHeight();
  const [filterAwaitingConfirmation, setFilterAwaitingConfirmation] = useState(false);
  const [filterAssignable, setFilterAssignable] = useState(false);
  const {
    data: vehiclesStatus,
    reload: reloadVehicles,
  } = useDataWithoutLoading((as) => getVehiclesStatus(filterAwaitingConfirmation, as), [filterAwaitingConfirmation]);
  const { data: cadStatus, reload: reloadCadStatus } = useDataWithoutLoading((as) => getCadStatus(as));

  const [startInterval, setStartInterval] = useState<boolean>(JSON.parse(localStorage.getItem("hideAutoRefresh")!) ?? true);
  const { data: staffing, reload: reloadStaffing } = useDataWithoutLoading((as) => getActivePersonnelParsed(as), [], { enabled: restricted });
  const { data: cadVehicles, reload: reloadCadVehicles } = useDataWithoutLoading((as) => getCADVehicles(as));

  useInterval(async () => {
    await reloadVehicles();
    await reloadStaffing();
    await reloadCadStatus();
    await reloadCadVehicles();
  }, [startInterval, filterAwaitingConfirmation], {
    enabled: startInterval,
    reloadInterval: 10000,
  });

  const setRefreshForDropdown = (state: boolean) => {
    setStartInterval(state);
  };

  const vehiclesStatuses = useMemo(() => VehicleStatusSearch(vehiclesStatus ?? [], "", filterAssignable), [vehiclesStatus, filterAssignable, cadStatus]);

  const mergedVehicleStatuses = useMemo(() => {
    const dataWithPersonnel = vehiclesStatuses.map((status) => {
      const vehicleDetails = cadVehicles?.find((vehicle) => status.vehicleId === vehicle.vehicleId);
      const cadStatusDetail = cadStatus?.find((cs) => cs.code === status.statusCode);
      return {
        ...status,
        cadStatusDetail,
        station: vehicleDetails?.station?.name,
        locationToCad: vehicleDetails?.locationToCAD,
        modifiedDate: status.modifiedDate,
      };
    });
    return dataWithPersonnel;
  }, [vehiclesStatuses, staffing, cadVehicles]) as unknown as VehiclesStatusList[];

  const getTablePanelArrow = useCallback(() => (restricted ? null : <KeyboardArrowRight />), [restricted]);

  if (!vehiclesStatus) {
    return <div />;
  }

  return (
    <div>
      <MaterialTable<VehiclesStatusList>
        data={mergedVehicleStatuses}
        title={(
          <CADVehiclesStatusTableHeader
            restricted={restricted}
            filterAwaitingConfirmation={filterAwaitingConfirmation}
            filterAssignable={filterAssignable}
            startInterval={startInterval}
            setStartInterval={setStartInterval}
            setFilterAssignable={setFilterAssignable}
            setFilterAwaitingConfirmation={setFilterAwaitingConfirmation}
          />
        )}
        options={{
          maxBodyHeight: height + 50,
          paging: false,
        }}
        // eslint-disable-next-line react/no-unstable-nested-components
        detailPanel={[{
          render: (rowData) => (
            <CADVehicleStatusHistoryItems vehicleId={rowData.vehicleId ?? ""} setRefresh={setRefreshForDropdown} />
          ),
          icon: getTablePanelArrow,
          openIcon: KeyboardArrowDown,
          disabled: restricted,
        }]}
        columns={[{
          title: "Radio Name",
          field: "radioName",
          render: (rowData) => {
            const { radioName } = rowData;
            return <Typography variant="body1">{radioName}</Typography>;
          },
          sorting: true,
          searchable: true,
          width: "10%",
        },
        {
          title: "Vehicle Id",
          field: "vehicleId",
          render: (rowData) => {
            const { vehicleId } = rowData;
            return <Typography variant="body1">{vehicleId}</Typography>;
          },
          sorting: true,
          searchable: true,
          width: "10%",
        },
        {
          title: "Status Code",
          field: "statusCode",
          render: (rowData) => {
            const {
              color, statusCode, cadStatusDetail, options,
            } = rowData;
            return (
              <div>
                <UnitLabel color={color} radioName={cadStatusDetail?.codeDisplay || statusCode} styleClassName={classes.vehicle_status_code} />
                <div style={{ marginTop: 10 }}>
                  {
                restricted && !!rowData.station && (
                  <StatusInfo detail={rowData.station} iconType="home" />
                )
              }
                  <OptionInfo options={options} cadStatusDetail={cadStatusDetail} restricted={restricted} />
                  {
               rowData.destination?.address ? (
                 <StatusInfo detail={rowData.destination.address} iconType="nearMe" />
               ) : rowData.destination?.name ? (
                 <StatusInfo detail={rowData.destination.name} iconType="nearMe" />
               ) : null
              }
                </div>
              </div>
            );
          },
          sorting: true,
          searchable: true,
          width: "10%",

        },
        {
          title: "Incident Number",
          field: "incidentNumber",
          render: (rowData) => {
            const { incidentNumber } = rowData;
            return <Typography className={`${classes.incident_number}`}>{incidentNumber === "0" ? "" : incidentNumber}</Typography>;
          },
          sorting: true,
          searchable: true,
          width: "10%",
        },
        {
          title: "Personnel",
          field: "",
          render: (rowData) => <CADVehicleStatusPersonnelColumn staffing={staffing ?? []} status={filterAwaitingConfirmation} vehicleStatus={rowData} />,
          sorting: false,
          searchable: false,
          hidden: !restricted,
        },
        {
          title: "Vehicle Status",
          field: "",
          render: (rowData) => {
            const { owner, responseTime, requestTime } = rowData;
            return (
              <div className={`${classes.right_container}`}>
                <Typography className={`${classes.cad_vehicle_status_message}`} style={{ color: requestTime > responseTime || owner === "user" ? "#FCA90E" : "#44a744", marginRight: 27, fontWeight: "bold" }}>{requestTime > responseTime || owner === "user" ? "Awaiting CAD Confirmation" : "Confirmed"}</Typography>
              </div>
            );
          },
          sorting: false,
          searchable: false,
          hidden: restricted,
        },
        {
          title: "Modified",
          field: "modifiedDate",
          render: (rowData) => {
            const {
              assignableByUser,
              modifiedDate,
              options,
              owner,
            } = rowData;
            return (
              <CADVehicleStatusModifiedDateColumns
                assignableByUser={assignableByUser}
                modifiedDate={modifiedDate}
                options={options}
                owner={owner}
              />
            );
          },
          sorting: false,
          searchable: false,
        },
        {
          title: "",
          field: "",
          render: (rowData) => {
            const { requestTime, responseTime, owner } = rowData;
            return requestTime > responseTime || owner === "user" ? (
              <div className={`${classes.confirm_button}`}>
                <TabletButton
                  onClick={async () => {
                    await confirmCadVehicleStatus(rowData);
                    await reloadVehicles();
                  }}
                >
                  Confirm
                </TabletButton>
              </div>
            ) : <></>;
          },
          hidden: restricted,
          sorting: false,
          searchable: false,
          width: "10%",
        },
        ]}

      />
    </div>
  );
}
