import { TabletButton } from "@components/tablet-button/custom-button";
import { CADCannedSimulationSlim, Sequence } from "@models/cad";
import {
  Delete, KeyboardArrowDown, KeyboardArrowRight, Save,
} from "@mui/icons-material";
import {
  FormLabel, IconButton, TextField, Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { FormikErrors } from "formik";
import classes from "./cad-simulator-canned.module.css";
import { checkUTF8Characters, detectDuplicateUnits } from "@utils/utilsFunctions";

const defaultSequence = {
  alarm: "0",
  comments: [],
  title: "",
  unitsConfig: {
    units: [],
    alarmLevelAtDispatch: "0",
  },
};

function CommentsField({ sequence, setSequence, userEmail }: { sequence: Sequence, setSequence: React.Dispatch<React.SetStateAction<Sequence>>, userEmail: string }) {
  const [comment, setComment] = useState("");
  const handleClick = () => {
    const commentToAdd = comment.trim();
    if (commentToAdd) {
      setSequence({ ...sequence, comments: [...sequence.comments, { source: userEmail, comment: commentToAdd }] });
      setComment("");
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <FormLabel style={{ display: "flex", columnGap: 5 }}>
        Comments
        {" "}
        {!!comment.length
          && (
            <Typography variant="body1" style={{ color: "grey", fontWeight: "bold" }}>
              - To save comment click +
            </Typography>
          )}
      </FormLabel>
      <div className={classes.canned_simulator_sequences_form_sequence_row_comments_input_container}>
        <IconButton
          disabled={!comment.length}
          style={{ padding: 0, color: comment.length ? "#6d1418" : "grey" }}
          onClick={handleClick}
        >
          <AddCircleIcon />
        </IconButton>
        <TextField
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleClick();
            }
          }}
          style={{ backgroundColor: "white", borderRadius: 8, width: "100%" }}
          size="small"
          value={comment}
          onChange={(event) => setComment(event?.target.value)}
        />
      </div>
    </div>
  );
}

function UnitsField({ sequence, setSequence }: { sequence: Sequence, setSequence: React.Dispatch<React.SetStateAction<Sequence>> }) {
  const [unit, setUnit] = useState("");
  const [error, setError] = useState(false);
  const handleClick = () => {
    const unitsToAdd = unit.trim();
    if (unitsToAdd) {
      // No duplicated and we trim strings
      const splitUnits = unit.split(",").map((value) => value.trim());
      setSequence({
        ...sequence,
        unitsConfig: {
          ...sequence.unitsConfig,
          units: [...sequence.unitsConfig.units, ...splitUnits],
        },
      });
    }
    setUnit("");
  };

  useEffect(() => {
    let error = false;
    if (unit.length >= 1) {
      const splitUnits = unit.split(",").map((value) => value.trim());
      error = detectDuplicateUnits(
        splitUnits.map((el) => el.toLocaleLowerCase()) as string[],
        sequence.unitsConfig.units.map((el) => el.toLocaleLowerCase()),
      ) || !!splitUnits.filter((unit) => !checkUTF8Characters(unit)).length;
    }
    setError(error);
  }, [unit, unit, sequence.unitsConfig.units]);

  return (
    <div style={{ width: "100%" }}>
      <FormLabel style={{ display: "flex", columnGap: 5 }}>
        Units
        {" "}
        {!!unit.length && !error
          && (
            <Typography variant="body1" style={{ color: "grey", fontWeight: "bold" }}>
              - To save units click +
            </Typography>
          )}
      </FormLabel>
      <div className={classes.canned_simulator_sequences_form_sequence_row_units_input_container}>
        <IconButton
          disabled={!unit.length || error}
          style={{ padding: 0, color: (unit.length && !error) ? "#6d1418" : "grey" }}
          onClick={handleClick}
        >
          <AddCircleIcon />
        </IconButton>
        <div style={{
          display: "flex", flexDirection: "column", rowGap: 5, width: "100%",
        }}
        >
          <TextField
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                handleClick();
              }
            }}
            placeholder="Comma separated list e.g. E1, E2, E3"
            style={{ backgroundColor: "white", borderRadius: 8, width: "100%" }}
            size="small"
            value={unit}
            error={error}
            onChange={(event) => setUnit(event?.target.value)}
          />
          {error && <Typography className="errorContainer" style={{ margin: 0 }}>Invalid unit</Typography>}
        </div>
      </div>
    </div>
  );
}

export function SimulationSequence(
  {
    userEmail,
    sequence,
    sequenceIndex,
    handleUpdateSequenceByIndex,
    deleteSequenceByIndex,
  }: {
    userEmail: string,
    sequence: Sequence,
    sequenceIndex: number,
    handleUpdateSequenceByIndex: (sequence: Sequence, index: number) => void,
    deleteSequenceByIndex: (index: number) => void,
  },
) {
  const [expand, setExpand] = useState(false);
  const [sequenceCopy, setSequenceCopy] = useState<Sequence>(sequence);
  const handleUpdate = () => {
    handleUpdateSequenceByIndex({ ...sequenceCopy, title: sequenceCopy.title ?? sequenceIndex }, sequenceIndex);
  };

  const handleRemoveSequenceComment = useCallback((index: number) => {
    setSequenceCopy({ ...sequenceCopy, comments: sequenceCopy.comments.filter((v, i) => i !== index) });
  }, [sequenceCopy]);

  const handleRemoveSequenceUnit = useCallback((index: number) => {
    setSequenceCopy({ ...sequenceCopy, unitsConfig: { ...sequenceCopy.unitsConfig, units: sequenceCopy.unitsConfig.units.filter((v, i) => i !== index) } });
  }, [sequenceCopy]);

  return (
    <div className={classes.canned_simulator_sequences_form_sequence_row}>
      <div className={classes.canned_simulator_sequences_form_sequence_row_header}>
        <div className={classes.display_flex_align_center}>
          <div className={classes.display_flex_align_center}>
            <IconButton onClick={() => setExpand(!expand)}>{expand ? <KeyboardArrowDown /> : <KeyboardArrowRight />}</IconButton>
            <Typography variant="h6">{`Sequence ${sequenceCopy.title || (sequence.sequenceId + 1)}`}</Typography>
          </div>
          <IconButton onClick={() => deleteSequenceByIndex(sequenceIndex)}><Delete /></IconButton>
        </div>
        <TabletButton style={{ backgroundColor: "#6d1418", alignItems: "center" }} onClick={handleUpdate} endIcon={<Save />}>Save</TabletButton>
      </div>
      {expand && (
        <div style={{ padding: "10px 20px", backgroundColor: "lightgrey" }}>
          <div className={classes.canned_simulator_sequences_form_sequence_row_body}>
            <div className={classes.display_flex_align_center}>
              <FormLabel className={classes.sequence_form_field_label}>Title</FormLabel>
              <TextField className={classes.sequence_form_field_input} size="small" value={sequenceCopy.title} onChange={(event) => setSequenceCopy({ ...sequenceCopy, title: event.target.value })} />
            </div>
            <div className={classes.display_flex_align_center}>
              <FormLabel className={classes.sequence_form_field_label}>Alarm</FormLabel>
              <TextField className={classes.sequence_form_field_input} size="small" value={sequenceCopy.alarm} onChange={(event) => setSequenceCopy({ ...sequenceCopy, alarm: event.target.value })} />
            </div>
          </div>
          <div className={classes.canned_simulator_sequences_form_sequence_row_body_units_container}>
            <UnitsField sequence={sequenceCopy} setSequence={setSequenceCopy} />
            {!!sequenceCopy.unitsConfig.units.length && (
              <div className={classes.canned_simulator_sequences_form_sequence_row_body_units}>
                {sequenceCopy.unitsConfig?.units.map((unit, index) => (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={unit + index + "unit"}
                    className={classes.canned_simulator_sequences_form_sequence_row_body_unit_tag}
                  >
                    <Typography variant="body1" fontSize={18}>{unit}</Typography>
                    <IconButton style={{ padding: 0 }} onClick={() => handleRemoveSequenceUnit(index)}><Delete /></IconButton>
                  </div>
                ))}
              </div>
            )}
          </div>
          <div className={classes.canned_simulator_sequences_form_sequence_row_body_comments_container}>
            <CommentsField sequence={sequenceCopy} setSequence={setSequenceCopy} userEmail={userEmail} />
            {!!sequenceCopy.comments.length && (
              <div className={classes.canned_simulator_sequences_form_sequence_row_body_comments}>
                {sequenceCopy.comments.map((comment, index) => (
                  <div
                    // eslint-disable-next-line react/no-array-index-key
                    key={comment.comment + comment.source + index + "comment"}
                    className={classes.canned_simulator_sequences_form_sequence_row_body_comment_row}
                  >
                    <Typography fontSize={18}>
                      {comment.comment}
                    </Typography>
                    <IconButton onClick={() => handleRemoveSequenceComment(index)}><Delete /></IconButton>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export function SimulatorSequencesForm(
  {
    values,
    userEmail,
    setFieldValue,
  }: {
    values: CADCannedSimulationSlim,
    userEmail: string,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void> | Promise<FormikErrors<CADCannedSimulationSlim>>,
  },
) {
  const handleUpdateSequenceByIndex = (sequence: Sequence, index: number) => {
    const temp = [...values.sequences];
    temp[index] = sequence;
    setFieldValue("sequences", temp);
  };

  const deleteSequenceByIndex = (index: number) => {
    if (values.sequences.length > 1) {
      const temp = [...values.sequences];
      setFieldValue("sequences", temp.filter((v, i) => i !== index));
    }
  };

  const handleAddSequence = () => {
    const temp = [...values.sequences];
    temp.push({ ...defaultSequence, sequenceId: temp.length });
    setFieldValue("sequences", temp);
  };

  const handleReset = () => {
    const temp = [{ ...defaultSequence, sequenceId: 0 }];
    setFieldValue("sequences", temp);
  };

  return (
    <div style={{ padding: "0px 20px 10px 20px" }}>
      <div className={classes.canned_simulator_sequences_form_header}>
        <Typography
          variant="h6"
        >
          Sequences
        </Typography>
        <TabletButton
          style={{ color: "white", backgroundColor: "#6d1418" }}
          size="small"
          onClick={handleAddSequence}
        >
          Add Sequence
        </TabletButton>
      </div>
      <div style={{ paddingBottom: 10 }}>
        <div className={classes.canned_simulator_sequences_form_body}>
          {!!values.sequences.length && (
            <div className={classes.canned_simulator_sequences_form_sequences_container}>
              {values.sequences.map((sequence, index) => (
                <SimulationSequence
                  // eslint-disable-next-line react/no-array-index-key
                  key={sequence.title + sequence.alarm + index + "sequence-row"}
                  userEmail={userEmail}
                  sequence={sequence}
                  sequenceIndex={index}
                  handleUpdateSequenceByIndex={handleUpdateSequenceByIndex}
                  deleteSequenceByIndex={deleteSequenceByIndex}
                />
              ))}
            </div>
          )}
          <div className={classes.canned_simulator_sequences_form_sequences_actions}>
            <TabletButton onClick={handleReset}>Reset</TabletButton>
          </div>
        </div>
      </div>
    </div>
  );
}
