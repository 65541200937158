import {
  useState, useMemo,
  useEffect,
} from "react";
import {
  ColoredUnits,
  DefaultUnitColor,
  UnitLocation,
} from "@models/location";
import { getFocusedUnitLocations } from "@services/location";
import { MapUnit, MapDetails } from "@models/arcgis";
import { useQuery } from "@tanstack/react-query";

export function useAVLUnits(hours: number, refetchData: boolean, mapDetails: MapDetails) {
  const mapDiagonal = useMemo(() => (mapDetails.zoom <= 7 ? 0 : Math.round(mapDetails.diagonal)), [mapDetails.diagonal, mapDetails.zoom]);

  const {
    data: focusedUnitsLocations, refetch: reloadAVLUnits, isError,
  } = useQuery({
    queryKey: ["focusedAVL", mapDetails.latitude, mapDetails.longitude, mapDetails.diagonal],
    retry: false,
    refetchInterval: refetchData ? 10000 : false,
    queryFn: async ({ signal }) => await getFocusedUnitLocations(mapDetails.latitude, mapDetails.longitude, mapDiagonal, hours, signal),
    placeholderData: (prev) => prev,
  });
  const [units, setUnits] = useState<UnitLocation[]>([]);

  // INFO: Reference to keep old data in case of error;
  useEffect(() => {
    if (!isError) {
      setUnits(focusedUnitsLocations ?? []);
    }
  }, [focusedUnitsLocations, isError]);

  const avlUnitsLocations: MapUnit[] = useMemo(() => {
    const avlMarkers: ColoredUnits[] = (units ?? [])?.map((el) => {
      let color = DefaultUnitColor;
      // If no color is set or empty text/background, use the default unit color
      if (el.color && el.color?.text !== "" && el.color?.background !== "") {
        color = el.color;
      }
      return {
        ...el,
        color,
      };
    });
    return avlMarkers.map((el) => ({
      agencyCode: el.agencyCode,
      agencyName: el.agencyName,
      altitude: el.altitude,
      color: el.color,
      device_type: el.device_type,
      id: el._id,
      kindType: el.kindType,
      location: el.location,
      modified: el.modified,
      opAreaCode: el.opAreaCode,
      source: el.source,
      speed: el.speed,
      state: el.state,
      username: el.username,
    })).sort((a, b) => (a.username < b.username ? 1 : -1));
  }, [units]);

  return {
    avlUnitsLocations,
    reloadAVLUnits,
  };
}
