/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
import {
  Checkbox, FormControlLabel, MenuItem, Select, TextField, Typography,
} from "@mui/material";
import { EditComponentProps, MTableAction } from "material-table";
import {
  RefObject, useEffect, useMemo, useState,
} from "react";
import { Agency } from "@models/agencies";
import { Role } from "@contexts/login-context";
import { SingleSelect } from "./SelectComponent";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { UnitKindTypes } from "@models/battalion";
import { AccountIndustry } from "@models/department";

export const customAddAction = (props: any, addActionRef: RefObject<HTMLDivElement>) => {
  const { action } = props;
  if (
    typeof action === typeof Function
    || action.tooltip !== "Add"
  ) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MTableAction {...props} />;
  }
  return <div ref={addActionRef} role="button" tabIndex={0} onClick={action.onClick} />;
};

export function customEditComponent<T extends object>(props: EditComponentProps<T>, property: keyof T, userRole: Role) {
  const { rowData } = props;
  return (
    <div>
      {userRole === Role.User ? (<div />) : (
        <FormControlLabel
          control={(
            <Checkbox
              checked={rowData[property] as unknown as boolean}
              onChange={(e) => props.onChange(e.target.checked)}
              color="secondary"
            />
          )}
          label={rowData[property] ? "true" : "false"}
        />
      )}
    </div>
  );
}

export function customEditAgency<T extends object>(props: EditComponentProps<T>, property: keyof T, options: Agency[], initialValue?: Agency, returnOnlyAgencyId?: boolean) {
  const { rowData } = props;
  const data = options.filter((el) => el._id === (typeof rowData[property] !== "string" ? initialValue?._id : rowData[property] as unknown as string));
  const [selectedValue, setSelectedValue] = useState(() => (initialValue ? initialValue : rowData[property] as unknown as Agency));

  useEffect(() => {
    if (initialValue && data.length) {
      if (initialValue._id !== data[0]._id) {
        setSelectedValue(data[0]);
        if (returnOnlyAgencyId) {
          props.onChange(data[0]._id);
        } else {
          props.onChange(data[0]);
        }
      }
    }
  }, [initialValue]);

  const select = useMemo(() => (
    <SingleSelect
      disableClearable
      value={selectedValue}
      getOptionSelected={(o, v) => o && v && o._id === v._id}
      onChange={(a) => {
        props.onChange(a!._id);
      }}
      renderOption={(agency) => agency?.name}
      options={options}
      required
    />
  ), [selectedValue]);

  return (
    <>
      {select}
    </>
  );
}
export function customKindTypeEdit<T extends object>(props: EditComponentProps<T>, property: keyof T) {
  const { rowData } = props;
  const data = Object.values(UnitKindTypes);

  const select = useMemo(() => (
    <Select
      MenuProps={
        {
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          transformOrigin: { horizontal: "center", vertical: "center" },
        }
      }
      style={{ width: "100%" }}
      value={rowData[property] ? rowData[property] as unknown as UnitKindTypes.Vehicle : UnitKindTypes.Vehicle}
      onChange={(a) => {
        props.onChange(a.target.value);
      }}
    >
      {Object.values(data).map((val) => <MenuItem key={val} value={val}><Typography style={{ textTransform: "capitalize" }}>{val}</Typography></MenuItem>)}
    </Select>
  ), [rowData[property]]);

  return (
    <>
      {select}
    </>
  );
}
export function customAlignedTextField<T extends object>(props: EditComponentProps<T> & { helperText?: string }) {
  const {
    error, helperText, onChange, value,
  } = props;
  return (
    <TextField
      id="standard-basic"
      error={error}
      onChange={(e) => onChange(e.target.value)}
      value={value}
      helperText={helperText}
      inputProps={{ style: { textAlign: "right" } }}
    />
  );
}

export function customEditDateField<T extends object>(props: EditComponentProps<T>) {
  const {
    onChange, value,
  } = props;

  const dateValue = value ? moment(value) : moment();

  return (
    <DatePicker value={dateValue} onChange={onChange} format="MM/DD/YYYY" />
  );
}

export function multilineEditTextField<T extends object>(props: EditComponentProps<T>) {
  const {
    onChange, value, error,
  } = props;

  return (
    <TextField style={{ width: "100%" }} multiline onChange={(e) => onChange(e.target.value)} value={value} error={error} />
  );
}

export function customAccountIndustryEdit<T extends object>(props: EditComponentProps<T>, property: keyof T, departmentIndustry: AccountIndustry) {
  const { rowData } = props;
  const data = Object.values(AccountIndustry);

  const select = useMemo(() => (
    <Select
      MenuProps={
        {
          anchorOrigin: { horizontal: "center", vertical: "bottom" },
          transformOrigin: { horizontal: "center", vertical: "center" },
        }
      }
      style={{ width: "100%" }}
      value={rowData[property] ? rowData[property] as unknown as AccountIndustry : departmentIndustry}
      onChange={(a) => {
        props.onChange(a.target.value);
      }}
    >
      {Object.values(data).map((val) => <MenuItem key={val} value={val}><Typography style={{ textTransform: "capitalize" }}>{val}</Typography></MenuItem>)}
    </Select>
  ), [rowData[property]]);

  return (
    <>
      {select}
    </>
  );
}
