import { useState } from "react";
import { Collapse } from "react-collapse";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// TODO: Fix for svg to display correctly
import AdminFeatures from "../img/menu-icons/Admin_Features.svg?react";
import DeviceMapping from "../img/menu-icons/device_mapping.svg?react";
import Users from "../img/menu-icons/users.svg?react";

import { MenuLink } from "@components/menu-links";
import { urls, getURL } from "@utils/routing";
import { SavedUser } from "@contexts/login-context";
import { Department } from "@models/department";

export function MenuAgencyAdmin(prop: {
  departmentData: Department | undefined,
  user: SavedUser | null,
  handleCollapse: () => void
}) {
  const { handleCollapse } = prop;
  const [collapse, setCollapse] = useState(false);

  return (
    <>
      <MenuLink
        label="Admin Features"
        icon={AdminFeatures}
        icon2={ExpandMoreIcon}
        onClick={() => setCollapse(!collapse)}
      />
      <Collapse isOpened={collapse}>
        <MenuLink
          to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminDeviceMapping)}`}
          label="Device Mapping"
          icon={DeviceMapping}
          onClick={handleCollapse}
        />
        <MenuLink
          to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminUsers)}`}
          label="Users"
          icon={Users}
          onClick={handleCollapse}
        />
      </Collapse>
    </>
  );
}
