import {
  ArcGISAuthCheckResult,
  ArcGISAuthDeleteResult,
  ArcGISClientToken,
  ArcGISCredentials,
  ArcGISGroup,
  ArcGISGroupUpdateAction,
  ArcGISMap,
  ArcGISMapAction,
  ArcGISOutsiderUser,
  ArcGISRootConfig,
  ClearCredentials,
  DebugMap,
  DepartmentArcGISGroupConfigUpdateAction,
  EsriConfig,
  EsriServices,
  FireMapperCredentials,
} from "@models/arcgis-maps";

import { apiUrl, appFetch } from "./config";

export const getMaps = async (signal: AbortSignal): Promise<ArcGISMap[]> => {
  const response: Response = await appFetch("api/v7/esri/maps", { signal });
  return response.json();
};

export const markMapOffline = async (itemId: string, name: string, action: ArcGISMapAction): Promise<{
  success: boolean,
  reason: string,
}> => {
  const response: Response = await appFetch(`api/v7/esri/maps/${itemId}`, {
    method: "POST",
    body: JSON.stringify({
      action,
      name,
    }),
  });
  return response.json();
};

export const getEsriServices = async (signal: AbortSignal): Promise<EsriServices> => {
  const response = await appFetch("api/v7/esri/services/", { signal });
  return response.json();
};

export const getEsriConfiguration = async (signal: AbortSignal): Promise<EsriConfig> => {
  const response = await appFetch("api/v7/esri/config/", { signal });
  return response.json();
};

export const saveArcGISUser = async (item: ArcGISCredentials | ClearCredentials) => {
  const response: Response = await appFetch("api/v7/esri/esri-auth", {
    method: "POST",
    body: JSON.stringify(item),
  });
  return response.json();
};

export const clearArcGISUser = async () => {
  const item = {
    clear: true,
  };
  return await saveArcGISUser(item);
};

export const saveFireMapperUser = async (data: FireMapperCredentials | ClearCredentials) => {
  const response: Response = await appFetch("api/v7/esri/auth/firemapper", {
    method: "POST",
    body: JSON.stringify(data),
  });

  return response.json();
};

export const clearFireMapperUser = async () => {
  const item = {
    clear: true,
  };
  return await saveFireMapperUser(item);
};

export const generateToken = async (): Promise<ArcGISClientToken> => {
  const response: Response = await appFetch("api/v7/esri/generateToken", {
    method: "POST",
  });
  return response.json();
};

export const getArcGISGroups = async (signal: AbortSignal): Promise<ArcGISGroup[]> => {
  const response: Response = await appFetch("api/v7/esri/groups", { signal });
  return response.json();
};

export const getArcGISGroupConfigByDepartmentId = async (departmentId: string, signal: AbortSignal): Promise<ArcGISRootConfig> => {
  const response: Response = await appFetch(`api/v7/esri/groups/config/${departmentId}`, { signal });
  return response.json();
};

export const linkArcGISGroupToDepartment = async (departmentId: string, groupId: string) => {
  const body = JSON.stringify({
    groupId,
    action: "add",
  });
  const response: Response = await appFetch(`api/v7/esri/groups/link/${departmentId}`, {
    method: "POST",
    body,
  });
  return response.json();
};

export const unlinkArcGISGroupFromDepartment = async (departmentId: string, groupId: string) => {
  const body = JSON.stringify({
    groupId,
    action: "remove",
  });
  const response: Response = await appFetch(`api/v7/esri/groups/link/${departmentId}`, {
    method: "POST",
    body,
  });
  return response.json();
};

export async function scheduleArcGISRefresh() {
  const response: Response = await appFetch("api/v7/esri/refresh", {
    method: "POST",
  });
  return response.json();
}

export async function changeArcGISConfig(departmentId: string, action: DepartmentArcGISGroupConfigUpdateAction) {
  const body = JSON.stringify({
    action,
  });
  const response: Response = await appFetch(`api/v7/esri/groups/config/${departmentId}`, {
    method: "POST",
    body,
  });
  return response.json();
}

export async function getMapsDebuggingData(): Promise<DebugMap[]> {
  const response = await appFetch("api/v7/esri/debug-maps");
  return response.json();
}

export async function searchArcGISOutsider(groupId: string, username: string): Promise<ArcGISOutsiderUser[]> {
  const url = apiUrl("api/v7/esri/groups/searchOutsider");
  url.searchParams.append("groupId", groupId);
  url.searchParams.append("username", username);
  const response = await appFetch(url);
  return response.json();
}

export async function inviteArcGISOutsider(groupId: string, username: string): Promise<{
  success: boolean,
  reason: string,
}> {
  const body = JSON.stringify({
    groupId,
    username,
  });
  const response: Response = await appFetch("api/v7/esri/groups/inviteOutsider", {
    method: "POST",
    body,
  });
  return response.json();
}

export async function createArcGISGroup(name: string): Promise<{
  success: boolean,
  reason: string,
}> {
  const body = JSON.stringify({
    name,
  });
  const response: Response = await appFetch("api/v7/esri/groups/createGroup", {
    method: "POST",
    body,
  });
  return response.json();
}

export async function updateArcGISGroup(groupId: string, action: ArcGISGroupUpdateAction): Promise<{
  success: boolean,
  reason: string,
}> {
  const body = JSON.stringify({
    action,
  });
  const response: Response = await appFetch(`api/v7/esri/groups/${groupId}`, {
    method: "POST",
    body,
  });
  return response.json();
}

export async function checkArcGISAccount(userId: string): Promise<ArcGISAuthCheckResult> {
  const response = await appFetch(`api/v7/esri/check-auth/${userId}`);
  return response.json();
}

export async function deleteArcGISAccount(userId: string): Promise<ArcGISAuthDeleteResult> {
  const response = await appFetch(`api/v7/esri/delete-auth/${userId}`, {
    method: "POST",
  });
  return response.json();
}
