import classes from "./cad.module.css";
import { useState } from "react";
import IncidentTableHeaderWithToggle from "../incident-table-header-with-toggle";
import { CustomMaterialTable } from "@components/custom-material-table";
import { CADIncident, SharedToItem } from "@models/cad";
import { ShareToButton } from "./cad-incident-details-fields";
import { Tooltip, Typography } from "@mui/material";
import { strDateFormat } from "@components/incident-details-shared-to";

export function CADIncidentSharedTo({
  incident, isAdmin, incidentId, reload, departmentName,
}: {
  incident: CADIncident
  isAdmin: boolean,
  incidentId: string,
  departmentName: string,
  reload: () => Promise<void>
}) {
  const [expand, setExpand] = useState(false);

  const incidentHasShare = (incident.sharedSource && incident.sharedSource.isExternal) || !!incident.sharedTo?.length;
  const tabletTitle = incident.sharedSource && incident.sharedSource.isExternal ? incident.sharedSource.name : departmentName;
  return (
    <div style={{ margin: "20px 0" }}>
      <CustomMaterialTable<SharedToItem>
        cellClassName={classes.cellWidth}
        hideContent={!expand}
        data={incident.sharedSource && incident.sharedSource.isExternal ? [{ ...(incident.sharedSource as unknown as SharedToItem) }] : (incident.sharedTo ?? [])}
        title={<IncidentTableHeaderWithToggle title={incidentHasShare ? `Incident Shared By ${tabletTitle}` : "Sharing"} expand={expand} setExpand={setExpand} />}
        cellAlign="left"
        customHeader={(
          <div>
            <span className={classes.custom_header_label}>{incident.sharedTo?.length}</span>
          </div>
        )}
        header={
          [{
            name: "Department",
            prop: "name",
            render: (item) => (
              <Tooltip title={item.name}>
                <Typography
                  variant="body2"
                  style={{
                    maxWidth: 100, whiteSpace: "break-spaces",
                  }}
                >
                  {item.name}
                </Typography>
              </Tooltip>
            ),
          },
          {
            name: "Start",
            prop: "startAt",
            render: (item) => strDateFormat(item.startAt),
          },
          {
            name: "Expire",
            prop: "expireAt",
            render: (item) => strDateFormat(item.expireAt),
          },
          {
            name: "Reasons",
            prop: "",
            render: (item) => (
              <ul style={{ listStyleType: "none", padding: 0 }}>
                {item.reasons.map((reason) => (
                  <li key={`${reason.date}-${reason.name}`}>
                    {reason.name}
                  </li>
                ))}
              </ul>
            ),
          },
          {
            name: "Share options",
            prop: "",
            render: (item) => (
              incident.sharedTo?.length ? (
                <ShareToButton
                  incidentId={incidentId}
                  isAdmin={isAdmin}
                  item={item}
                  key={item.departmentId}
                  onChange={reload}
                />
              ) : <div />
            ),
          },
          ]
        }
      />
    </div>
  );
}
