import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import classes from "./incidents-table-advanced-search.module.css";

interface IncidentAdvancedSearchPaginationProps {
  totalIncidents: number;
  incidentLimitPerPage: number;
  page: number;
  loading: boolean;
  handleNext: () => void;
  handlePrev: () => void;
}

export function IncidentAdvancedSearchPagination({
  totalIncidents, incidentLimitPerPage, page, loading, handleNext, handlePrev,
}: IncidentAdvancedSearchPaginationProps) {
  const totalPages = Math.ceil(totalIncidents / incidentLimitPerPage);

  return (
    <div className={classes.search_pagination_wrapper}>
      <IconButton onClick={handlePrev} disabled={(page === 1) || loading}><ChevronLeft /></IconButton>
      <Typography>
        {page}
        /
        {totalPages}
      </Typography>
      <IconButton onClick={handleNext} disabled={(page === totalPages) || loading}><ChevronRight /></IconButton>
    </div>
  );
}
