import { useMemo, useState } from "react";
import { SearchedIncident } from "@models/incident";
import { useTitle } from "@hooks/useTitle";
import moment from "moment";
import { CADIncidentsTable } from "../cad-incidents/cad-incidents-table";
import { CADIncident } from "@models/cad";
import { CADIncidentsTab } from "@models/routing";
import { IncidentsTableSearchHeader } from "@components/incidents-table-advanced-search/incidents-table-search-header";

const noSearchMessage = "Type to search for incident";
const noIncidentsMessage = "No incidents found";

export function IncidentsSearch() {
  useTitle("Incidents");
  const [loading, setLoading] = useState(false);
  const [incidents, setIncidents] = useState<SearchedIncident[] | null>(null);

  const parsedIncidents = useMemo(() => {
    if (incidents?.length) {
      // INFO: Parsed data to match table
      // INFO: RTS is set to true to hide the icon
      return incidents.map((incident) => ({
        ...incident,
        rts: true,
        simulation: false,
        modified_unix_date: moment(incident.modified).unix(),
      })).sort((a, b) => b.scoreDetails.value - a.scoreDetails.value);
    }
    return [];
  }, [incidents]);

  const getTableMessage = useMemo(() => {
    if (incidents && !loading) {
      return noIncidentsMessage;
    }
    return noSearchMessage;
  }, [incidents, loading]);

  return (
    <div>
      <IncidentsTableSearchHeader loading={loading} setIncidents={setIncidents} setLoading={setLoading} />
      {/* {!!parsedIncidents.length && <IncidentAdvancedSearchPagination incidentLimitPerPage={incidentLimitPerPage} totalIncidents={parsedIncidents.length} />} */}
      {/* INFO: We use for now the CADIncidentsTable as endpoint returns only CAD incidents */}
      <CADIncidentsTable CADIncidents={parsedIncidents as unknown as CADIncident[]} displayTooltipsScore tableTitle={CADIncidentsTab.Search} isLoading={loading} tableNoDataMessage={getTableMessage} />
    </div>
  );
}
