import moment from "moment";
import classes from "./cad.module.css";

import { Typography } from "@mui/material";
import { Changes } from "@models/cad";
import { useState } from "react";
import IncidentTableHeaderWithToggle from "../incident-table-header-with-toggle";
import { CustomMaterialTable } from "@components/custom-material-table";

export default function IncidentChanges({ changes }: { changes: Changes[] | undefined }) {
  const [expand, setExpand] = useState(false);
  if (!changes || changes.length === 0) {
    return null;
  }

  return (
    <div className={`${classes.cad_changes}`} style={{ marginBottom: 20 }}>
      <CustomMaterialTable<Changes>
        hideContent={!expand}
        data={changes.sort((a, b) => b.time - a.time) ?? []}
        title={<IncidentTableHeaderWithToggle title="Changes" expand={expand} setExpand={setExpand} />}
        cellAlign="left"
        header={
          [{
            name: "Modified",
            prop: "",
            render: (item) => (
              <div style={{
                display: "flex", columnGap: 10, alignItems: "center",
              }}
              >
                <div>
                  <div>{moment(item.time * 1000).format("HH:mm:ss")}</div>
                  <div style={{ fontSize: 10 }}>{moment(item.time * 1000).format("MM/DD/YYYY")}</div>
                </div>
                <Typography variant="body2">{`[${item.user.username}]`}</Typography>
              </div>
            ),
          },
          {
            name: "Type",
            prop: "type",
          },
          {
            name: "Message",
            prop: "message",
            cellWidth: 100,
          },
          ]
        }
      />
    </div>
  );
}
