import { Paper } from "@mui/material";
import moment from "moment";

import { IncidentHeader } from "./incident-header/incident-header";
import {
  SharedSource,
} from "@models/cad";
import classes from "../pages/admin/tc-incident-details/managed-incidents-list.module.css";

export function canStopShare(now: Date, expireAt: string | null | undefined): boolean {
  if (!expireAt) {
    return true;
  }

  if (!moment(expireAt, true).isValid()) {
    return true;
  }

  return moment(now.toISOString(), true) < moment(expireAt, true);
}

export function strDateFormat(date: string | null | undefined) {
  if (!date || date === "") {
    return "";
  }

  if (!moment(date, true).isValid()) {
    return "";
  }

  return (
    <div>
      <div>{moment(date).format("HH:mm:ss")}</div>
      <div style={{ fontSize: 10 }}>{moment(date).format("MM/DD/YYYY")}</div>
    </div>
  );
}

export function IncidentSharedSource({ source }: {
  source: SharedSource | undefined
}) {
  if (!source || !source.isExternal) {
    return null;
  }

  const isExpired = canStopShare(new Date(), source.expireAt);
  const isExpiredClass = isExpired ? classes.still_shared : classes.expired;
  const isExpiredStr = isExpired ? "Expire at" : "Expired at";

  return (
    <div className={`${classes.changes}`} style={{ margin: "20px 0" }}>
      <IncidentHeader
        title="Shared By"
      />
      <Paper className={classes.paper_content}>
        <div className={isExpiredClass}>
          <div className={`${classes.changes_item}`}>
            {source.name}
          </div>
          <div>
            <span>Start at:</span>
            {strDateFormat(source.startAt)}
          </div>
          <div>
            <span>{isExpiredStr}</span>
            <span>{strDateFormat(source.expireAt)}</span>
          </div>
          <div>
            <span>Reasons:</span>
            <ul>
              {(source.reasons ?? []).map((el) => (
                <li key={`${el.date}-${el.name}`}>
                  {strDateFormat(el.date)}
                  :
                  {el.name}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Paper>
    </div>
  );
}
