/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import {
  RefObject, useCallback, useContext, useEffect, useRef, useState,
} from "react";
import { destroyView, loadView } from "./utils";
import MapView from "@arcgis/core/views/MapView";
import Expand from "@arcgis/core/widgets/Expand";
import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
import LayerList from "@arcgis/core/widgets/LayerList";
import Search from "@arcgis/core/widgets/Search";
import { IconButton, MenuItem } from "@mui/material";
import Portal from "@arcgis/core/portal/Portal";
import esriId from "@arcgis/core/identity/IdentityManager";
import Point from "@arcgis/core/geometry/Point";
import { useData } from "../useData";
import {
  AssignmentTurnedInOutlined,
  CheckOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  LocalShipping,
  Map as MapIcon,
} from "@mui/icons-material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

import { useWatch } from "./useWatches";
import { ILoadViewOptions, SimpleArcGISMap } from "@models/arcgis";
import { UserMapSettings } from "@models/user-web-map";
import { generateToken, getMaps } from "@services/arcgis-maps";
import { useFullScreen } from "../useFullScreen";
import { closeFullscreen, openFullscreen } from "@utils/utilsFunctions";
import { useMapSideMenu } from "../useMapSideMenu";
import { useEffectAsync } from "@hooks/utils";
import { MapLayersContext } from "@contexts/map-layers-context";
import { createRoot } from "react-dom/client";
import { useDataWithoutLoading } from "@hooks/useDataWithoutLoading";
import { useInterval } from "@hooks/useInterval";
import { ArcGISClientToken } from "@models/arcgis-maps";
import moment from "moment";
import { Coordinate } from "@models/cad";

const mapBaseId = "55ebf90799fa4a3fa57562700a68c405";

export function useView(options: ILoadViewOptions): [ref: RefObject<HTMLDivElement>, view: MapView] {
  const elRef = useRef<HTMLDivElement>(null);
  const [mapId, setMapId] = useState(() => {
    const userInfo = localStorage.getItem("userMapSettings");
    if (userInfo !== null) {
      const userSettings: UserMapSettings = JSON.parse(userInfo);

      if (userSettings.mapId !== undefined) {
        return userSettings.mapId;
      }
    }
    return mapBaseId;
  });
  const [view, setView] = useState<MapView>(() => new MapView());
  const initialArguments = useRef({ mapId, options });
  const [mapGallery, setMapGallery] = useState(false);
  const [tokenData, setToken] = useState<Omit<ArcGISClientToken, "ip">>({ token: "", expireAt: "" });
  const [maps, setMaps] = useState<SimpleArcGISMap[]>([]);
  const { data, reload: reloadToken } = useDataWithoutLoading(() => generateToken());
  const { data: mapData } = useData((as) => getMaps(as));
  const [zoom, setZoom] = useState(0);
  const [portal, setPortal] = useState<Portal | undefined>(undefined);
  const { isBrowserOnFullScreen } = useFullScreen();
  const { isSideMenuOpen, setSideMenuState } = useMapSideMenu();
  const [mapLayersState, setMapLayersState] = useContext(MapLayersContext);
  const searchWidgetContainer = document.getElementById("searchWidget") ?? null;
  const mapGalleryButtonRef = useRef<HTMLButtonElement>(null);

  const [center, setCenter] = useState<Coordinate>({
    latitude: 0,
    longitude: 0,
  });

  // DISABLED
  // HACK: closing the "Please sign in to access the item on ..." dialog box
  // triggered by some random layers
  // https://community.esri.com/t5/arcgis-javascript-maps-sdk-questions/disable-v4-x-identitymanager-login-prompt/m-p/1143224
  esriId.on("dialog-create", () => {
    // eslint-disable-next-line no-console
    // console.log("Closed sign in box...");
    esriId.dialog.destroy();
  });

  // register token in order to access custom maps
  useEffectAsync(async () => {
    if (tokenData.token === "") {
      return;
    }
    try {
      esriId.registerToken({
        server: "https://www.arcgis.com/sharing/rest",
        token: tokenData.token,
      });
      const p = new Portal({
        authMode: "immediate",
      });
      await p.load();

      if (p.user) {
        setPortal(p);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Error in view useEffectAsync:", e);
    }
  }, [tokenData]);

  useEffect(() => {
    if (mapData) {
      const cleanMaps: SimpleArcGISMap[] = (mapData ?? [])
        .filter((m) => m.type === "Web Map")
        .map((m) => ({
          itemId: m.itemId,
          name: m.title,
        }))
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      setMaps(cleanMaps);
    }
  }, [mapData]);

  useEffect(() => {
    if (data) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { ip, ...tokenData } = data;
      setToken(tokenData);
    }
  }, [data]);

  // INFO: Verify each 10 minutes the token expiration, if the time passed refetch it.
  useInterval(async () => {
    const now = moment();
    const tokenExpireAtParsed = moment(tokenData.expireAt).subtract(30, "minutes");
    if (tokenExpireAtParsed.diff(now, "seconds") <= 0) {
      await reloadToken();
    }
  }, [tokenData], { reloadInterval: 60000 });

  const ChangeMapButton = useCallback(() => (
    <div style={{ textAlign: "right" }}>
      {mapGallery && (
        <div style={{
          backgroundColor: "white",
          height: 300,
          overflow: "scroll",
        }}
        >
          {maps.map((el) => {
            const isSelected = el.itemId === mapId;
            // eslint-disable-next-line no-console
            return (
              <MenuItem
                key={el.itemId}
                onClick={() => {
                  setMapId(el.itemId);
                  initialArguments.current.mapId = el.itemId;
                  setMapGallery(false);
                }}
                style={{
                  backgroundColor: isSelected ? "lightGray" : "inherit",
                }}
              >
                {el.name}
                {isSelected ? <CheckOutlined /> : null}
              </MenuItem>
            );
          })}
        </div>
      )}
      <IconButton
        ref={mapGalleryButtonRef}
        className="map_icon_button"
        onClick={() => setMapGallery(!mapGallery)}
        disabled={tokenData.token === ""}
      >
        <MapIcon />
      </IconButton>
    </div>
  ), [mapGallery, maps, tokenData, mapId]);

  const ControlSideMenu = useCallback(
    () => (
      <IconButton
        className="map_icon_button"
        onClick={() => {
          setSideMenuState();
        }}
      >
        <AssignmentTurnedInOutlined className="map-side-menu-button" />
      </IconButton>
    ),
    [view, tokenData, maps, isSideMenuOpen],
  );

  const ControlSharedAVL = useCallback(
    () => (
      <IconButton
        style={{
          width: "32px", height: "32px", padding: 0, backgroundColor: mapLayersState?.sharedAVL ? "grey" : "#242424", borderRadius: 0, border: "2px solid #242424",
        }}
        onClick={() => {
          const newMapLayerState = { ...mapLayersState, sharedAVL: !mapLayersState?.sharedAVL };
          setMapLayersState(newMapLayerState);
        }}
      >
        <LocalShipping style={{ color: mapLayersState?.sharedAVL ? "black" : "#adadad" }} />
      </IconButton>
    ),
    [view, tokenData, maps, mapLayersState],
  );

  const OpenFullScreen = useCallback(
    () => (
      <IconButton
        className="map_icon_button"
        onClick={() => {
          openFullscreen();
        }}
      >
        <FullscreenOutlined />
      </IconButton>
    ),
    [view, tokenData, maps],
  );

  const ExitFullscreen = useCallback(() => (
    <IconButton
      style={{}}
      className="map_icon_button"
      onClick={() => {
        closeFullscreen();
      }}
    >
      <FullscreenExitOutlined />
    </IconButton>
  ), [view, tokenData, maps]);

  const ResetToDepartmentLocationButton = useCallback(() => (
    <IconButton
      className="map_icon_button"
      onClick={() => {
        if (!options?.view.zoomToIncident) {
          view.zoom = zoom;
          const point = new Point({
            longitude: center.longitude,
            latitude: center.latitude,
          });
          view.center = point;
        }
        options?.view.resetMapView();
      }}
    >
      <KeyboardBackspaceIcon />
    </IconButton>
  ), [mapGallery, maps, tokenData, view, center, zoom]);

  useEffect(() => {
    if (!options?.view.zoomToIncident && !options?.view.noStoreData && options?.view.zoom > 0) {
      setZoom(options?.view.zoom);
      setCenter({
        longitude: options?.view.center[0],
        latitude: options?.view.center[1],
      });
    }

    if (!options?.view.userDragMap || options?.view.zoomToIncident) {
      view.zoom = options?.view.zoom;
      const point = new Point({
        longitude: options?.view.center[0],
        latitude: options?.view.center[1],
      });
      view.center = point;
    }
  }, [options?.view.zoomToIncident, options?.view.zoom, options?.view.centerOnIncident, options?.view.userDragMap, options?.view.center, mapId]);

  useEffect(() => {
    if (!options?.view.zoomToIncident) {
      if (options?.view.zoom && options?.view.zoom === 18) {
        view.zoom = options?.view.zoom;
        const point = new Point({
          longitude: options?.view.center[0],
          latitude: options?.view.center[1],
        });
        view.center = point;
      } else {
        const userInfo = localStorage.getItem("userMapSettings");
        if (userInfo !== null) {
          const userSettings: UserMapSettings = JSON.parse(userInfo);
          const center = Object.keys(userSettings.center).length > 0 ? [userSettings.center.longitude, userSettings.center.latitude] : [0, 0];
          view.zoom = userSettings.zoom;
          const point = new Point({
            longitude: center[0],
            latitude: center[1],
          });
          view.center = point;
          setCenter({
            longitude: center[0],
            latitude: center[1],
          });
          setZoom(userSettings.zoom);
        }
      }
    }
  }, [mapId, options?.view.zoomToIncident, view]);

  // update view based on options map
  useEffect(() => {
    initialArguments.current.options = options;
    if (JSON.stringify([center.latitude, center.longitude]) !== JSON.stringify([0, 0]) && zoom > 0 && !options?.view.zoomToIncident && !options?.view.noStoreData) {
      localStorage.setItem("userMapSettings", JSON.stringify({
        center: {
          latitude: center.latitude ?? 0,
          longitude: center.longitude ?? 0,
        },
        zoom,
        mapId,
      }));
    }
  }, [zoom, center, mapId]);

  const cleanupSearchWidgetContainer = useCallback(() => {
    if (searchWidgetContainer && searchWidgetContainer.childNodes.length) {
      searchWidgetContainer.childNodes.forEach((child) => child.remove());
    }
  }, [searchWidgetContainer]);

  useEffect(() => {
    if (!view.ui || !options?.view.getSearchResults || !searchWidgetContainer) {
      return;
    }
    cleanupSearchWidgetContainer();
    const search = new Search({
      view, container: "searchWidget", popupEnabled: false, resultGraphicEnabled: false, id: "tabletcommand-search-widget",
    });
    search.on("search-complete", (results) => {
      const features = results.results[0].results[0].feature;
      if (features.geometry && features.attributes) {
        options?.view.getSearchResults(features);
      }
    });
    return () => cleanupSearchWidgetContainer();
  }, [view, searchWidgetContainer, options?.view.getSearchResults]);

  useEffect(() => {
    let cancelled = false;
    let _view: MapView;

    const { options } = initialArguments.current;
    // eslint-disable-next-line prefer-const
    _view = loadView(mapId, portal, options);

    if (cancelled) {
      return;
    }
    // show the view at the element & add it to the state
    _view.container = elRef.current as HTMLDivElement;
    setView(_view);

    return () => {
      cancelled = true;
      _view.map = null as any;
      destroyView(_view);
    };
  }, [mapId, portal, view.destroyed]);

  useWatch(view, "zoom", options?.view.zoomToIncident, setZoom);
  useWatch(view, "center", options?.view.zoomToIncident, ({ latitude, longitude }) => {
    setCenter({ latitude: latitude ? latitude : 0, longitude: longitude ? longitude : 0 });
  });

  // INFO: Top right buttons
  useEffect(() => {
    if (!view.ui) {
      return;
    }

    // BASEMAP
    const basemapGallery = new BasemapGallery({
      view,
    });
    const expandButton = new Expand({
      view,
      autoCollapse: true,
      content: basemapGallery,
    });
    view.ui.add(expandButton, {
      position: "top-right",
    });

    // LAYER LIST
    const layersList = new LayerList({
      view,
    });
    const layersButton = new Expand({
      view,
      content: layersList,
    });
    view.ui.add(layersButton, {
      position: "top-right",
    });

    // SIDE MENU
    const menuButtonHandler = document.createElement("div");
    const sideMenuButtonRoot = createRoot(menuButtonHandler);
    menuButtonHandler.className = "map-side-menu-button";
    if (options?.view.hasSideMenuButton) {
      view.ui.add(menuButtonHandler, {
        position: "top-right",
      });
      sideMenuButtonRoot.render(<ControlSideMenu />);
    }

    // SHARED AVL
    const sharedAVLButtonHandler = document.createElement("div");
    const sharedAVLButtonRoot = createRoot(sharedAVLButtonHandler);
    sharedAVLButtonHandler.style.backgroundColor = "#242424";
    sharedAVLButtonHandler.className = "map-shared-avl-button";

    if (options?.view.sharedAVLButton) {
      view.ui.add(sharedAVLButtonHandler, {
        position: "top-right",
      });
      sharedAVLButtonRoot.render(<ControlSharedAVL />);
    }

    // INFO: Click handles to collapse menus when clicking outside of them.
    const basemapGalleryEvent = view.on("click", () => expandButton.collapse());
    const layerListEvent = view.on("click", () => layersButton.collapse());

    return () => {
      if (view.ui) {
        view.ui.remove(expandButton);
        view.ui.remove(layersButton);
        view.ui.remove(menuButtonHandler);
        view.ui.remove(sharedAVLButtonHandler);
      }
      if (basemapGalleryEvent) {
        basemapGalleryEvent.remove();
      }
      if (layerListEvent) {
        layerListEvent.remove();
      }
      // TODO: Hackish way to remove warning from console, might not be the best way but there's no info on it so far.
      setTimeout(() => {
        sideMenuButtonRoot?.unmount();
        sharedAVLButtonRoot?.unmount();
      });
    };
  }, [view, tokenData, ChangeMapButton, mapId, isSideMenuOpen, options?.view.hasSideMenuButton, mapLayersState?.sharedAVL, options?.view.sharedAVLButton]);

  useEffect(() => {
    if (!view.ui) {
      return;
    }

    // RESET MAP
    const resetMapButtonNode = document.createElement("div");
    const resetMapButtonRoot = createRoot(resetMapButtonNode);
    if (options?.view.resetButton) {
      view.ui.add(resetMapButtonNode, {
        position: "bottom-right",
      });
      resetMapButtonRoot.render(<ResetToDepartmentLocationButton />);
    }

    // TOGGLE FULLSCREEN
    const fullscreenMapHandler = document.createElement("div");
    const fullscreenMapButtonRoot = createRoot(fullscreenMapHandler);
    if (options?.view.hasFullscreenButton) {
      view.ui.add(fullscreenMapHandler, {
        position: "bottom-right",
      });
      if (isBrowserOnFullScreen) {
        fullscreenMapButtonRoot.render(<ExitFullscreen />);
      } else {
        fullscreenMapButtonRoot.render(<OpenFullScreen />);
      }
    }

    // MAP GALLERY
    const userMapGalleryNode = document.createElement("div");
    const userMapGalleryRoot = createRoot(userMapGalleryNode);
    userMapGalleryNode.className = "map-gallery-container";
    view.ui.add(userMapGalleryNode, {
      position: "bottom-right",
      index: 10,
    });
    userMapGalleryRoot.render(<ChangeMapButton />);

    const mapChangeButtonEvent = view.on("click", () => {
      if (mapGalleryButtonRef.current && mapGallery) {
        mapGalleryButtonRef.current.click();
      }
    });

    return () => {
      if (view.ui) {
        view.ui.remove(resetMapButtonNode);
        view.ui.remove(fullscreenMapHandler);
        view.ui.remove(userMapGalleryNode);
      }
      if (mapChangeButtonEvent) {
        mapChangeButtonEvent.remove();
      }
      // TODO: Hackish way to remove warning from console, might not be the best way but there's no info on it so far.
      setTimeout(() => {
        resetMapButtonRoot?.unmount();
        fullscreenMapButtonRoot?.unmount();
        userMapGalleryRoot?.unmount();
      });
    };
  }, [view, tokenData, mapId, mapGallery, options?.view.resetButton, isBrowserOnFullScreen, options?.view.hasFullscreenButton]);

  return [elRef, view];
}
