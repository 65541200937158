/* eslint-disable import/no-useless-path-segments */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Collapse } from "react-collapse";
import { UIStateContext } from "@contexts/ui-state-context";
import { LoginContext, roleIsAccountAdmin } from "@contexts/login-context";
import { useLoadingMessage } from "@hooks/useLoading";
import { clearLocalStorage, userLogout } from "@services/user";
import { getURL, urls } from "@utils/routing";
import spinner from "../img/spinner.svg";

export default function MainPageHeader() {
  const [state] = useContext(UIStateContext);

  const [collapse, setCollapse] = useState(false);
  const navigate = useNavigate();
  const [user, setUser] = useContext(LoginContext);

  useEffect(() => {
    let timeout: NodeJS.Timeout = null!;
    if (collapse) {
      timeout = setTimeout(() => setCollapse(false), 5000);
    }

    return () => clearTimeout(timeout);
  }, [collapse]);

  const handleLogOut = async () => {
    clearLocalStorage();
    setUser(null);
    await userLogout();
    navigate("/login");
  };
  const loadingMessage = useLoadingMessage();
  const isAccountAdmin = roleIsAccountAdmin(user?.role);
  return (
    <div className="topHeaderWrapper">
      <div className={`loadingSpinner ${loadingMessage ? "visible" : "hidden"} ${state}`}>
        <img src={spinner} className="spinnerImage" alt="spinner" title="spinner" />
      </div>
      <div className="right-header">
        <div className="headerAreaWrapper">
          <div className="headerTitle" />
          <div>
            <div className={`user-info ${collapse ? "collapsed" : "normal"}`}>
              <p className="user-name" onClick={() => setCollapse(!collapse)} style={{ margin: 0 }}>{user?.email}</p>
              <div
                className="icon"
                onClick={() => {
                  if (user && user.userId && isAccountAdmin) {
                    navigate(`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.editUserAccount, { userId: user.userId })}`);
                  }
                }}
              >
                <PermIdentityOutlinedIcon fontSize="small" />
              </div>
              <div className="icon2">
                <ExpandMoreIcon onClick={() => setCollapse(!collapse)} />
              </div>
            </div>
            <div className={`userAction ${collapse ? "visible" : "normal"}`}>
              <Collapse isOpened={collapse}>
                <Link to="/login" onClick={handleLogOut} style={{ textDecoration: "none" }}>
                  <div className="collapseMenu">
                    <div><PowerSettingsNewIcon onClick={handleLogOut} /></div>
                    <p className="action" onClick={handleLogOut}>Log Out</p>
                  </div>
                </Link>
              </Collapse>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
