import { ArcGIsIncidentGroup } from "@models/arcgis";
import classes from "./managed-incidents.module.css";
import { UnitPopup } from "./unit-popup";
import truck from "../../img/managed-incidents/vehicle_icon_managed_incidents.svg";
import user from "../../img/managed-incidents/user_icon_managed_incidents.svg";
import { personnelTotalNumber } from "@utils/utilsFunctions";

export function ManagedIncidentGroupMarkerGrid({ group }: { group: ArcGIsIncidentGroup }) {
  return (
    <>
      <div style={{ display: "flex", width: "fit-content", padding: "0 0 8px 8px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <img className={classes.icon} src={truck} alt="truck icon" />
          <span className={classes.info} style={{ marginLeft: "5px", fontWeight: "bold" }}>
            {group.units.length}
          </span>
        </div>
        <div className={classes.item}>
          <img className={classes.icon} src={user} alt="personnel icon" />
          <span className={classes.info} style={{ marginLeft: "5px", fontWeight: "bold" }}>
            {personnelTotalNumber(group)}
          </span>
        </div>
      </div>
      <div>
        <div className="grid">
          {group.units.map((unit, index) => (
            <div className="card" key={`${unit.UnitID ?? "" + index}`}>
              <UnitPopup
                name={unit.UnitID ?? ""}
                time={unit.air_time ?? ""}
                isSupervisor={unit.isSupervisor ?? false}
                unit={unit}
                history={group.history}
                group={group.name ?? ""}
                signal={group.signal}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
