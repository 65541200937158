/* eslint-disable react/jsx-closing-tag-location */
import Graphic from "@arcgis/core/Graphic";
import Point from "@arcgis/core/geometry/Point";
import PictureMarkerSymbol from "@arcgis/core/symbols/PictureMarkerSymbol";
import SimpleMarkerSymbol from "@arcgis/core/symbols/SimpleMarkerSymbol";
import SimpleFillSymbol from "@arcgis/core/symbols/SimpleFillSymbol";
import SimpleLineSymbol from "@arcgis/core/symbols/SimpleLineSymbol";
import TextSymbol from "@arcgis/core/symbols/TextSymbol";
import Circle from "@arcgis/core/geometry/Circle";

import { useMemo } from "react";
import { createRoot } from "react-dom/client";

import {
  getDeviceTypeMarkerUrl,
  getSharedAvlMarkerUrl,
  hazardMarkerByType,
  incidentGroupMarker,
  unassignedUnitsOnMapMarker,
  unmappedDeviceMarker,
} from "@utils/icon-utils";
import marker from "../../pages/img/marker.png";
import AVLPopUp from "@components/avl-popup";
import ReactDOMServer from "react-dom/server";
import PopupTemplate from "@arcgis/core/PopupTemplate.js";
import CustomContent from "@arcgis/core/popup/content/CustomContent.js";
import {
  ArcGISHazard,
  ArcGIsIncidentGroup,
  ArcGIsUnassignedUnit,
  GraphicOptions,
  IncidentLocation,
  MapUnit,
} from "@models/arcgis";
import { DeviceMapping } from "@models/device-mapping";
import { DefaultUnitColor } from "@models/location";
import { getURL, urls } from "@utils/routing";
import { ManagedIncidentGroupMarkerGrid } from "../../pages/admin/managed-incidents/managed-incidents-group-marker-grid";
import { Caller } from "@models/cad";

const markerAVLUrlCache = new Map<string, string>();
const markerSharedAVLUrlCache = new Map<string, string>();

export function useLocations(
  incidentMarkers: IncidentLocation[],
  avlMarkers: MapUnit[],
  sharedAVLMarkers: MapUnit[],
  hazardMarkers: ArcGISHazard[],
  callersMarkers: Caller[],
  unmappedDeviceMarkers: DeviceMapping[],
  incidentGroupsMarkers: ArcGIsIncidentGroup[],
  unassignedUnitsOnMapMarkers: ArcGIsUnassignedUnit[],
) {
  const incidentNavigationUrl = (type: string, incidentNumberDisplay: string) => {
    const reviewBasePath = window.env.PUBLIC_URL;
    let urlBase = getURL(urls.routingAppPrefix);
    // INFO:  If there is a special (review links) public url we pre-append it at the start.
    if (reviewBasePath !== "/" && !!reviewBasePath) {
      urlBase = reviewBasePath + urlBase;
    }
    if (type === "Managed") {
      return urlBase.concat(`/${getURL(urls.adminManagedIncidentDetails, { incidentNumber: incidentNumberDisplay })}`);
    }
    return urlBase.concat(`/${getURL(urls.adminCADIncidentDetails, { incidentNumber: incidentNumberDisplay })}`);
  };

  const incidentsGraphics = useMemo(() => {
    const data = new Set<GraphicOptions>();
    incidentMarkers.forEach((incident) => {
      const itemAnchor = incidentNavigationUrl(incident.type, incident.incidentNumberDisplay);
      // INFO: Incident conversion to marker
      const item = {
        id: `incident-${incident.incidentNumberDisplay}-${incident.incidentCallTypeDescription}-${incident.type}`,
        name: incident.incidentNumberDisplay,
        geometry: new Point({
          latitude: incident.latitude,
          longitude: incident.longitude,
        }),
        symbol: new PictureMarkerSymbol({
          url: marker,
          height: 23,
          width: 20,
        }),
        popupTemplate: new PopupTemplate({
          content: [new CustomContent({
            outFields: ["*"],
            creator: () => {
              const anchor = document.createElement("a");
              anchor.href = itemAnchor;
              anchor.rel = "noopener";
              anchor.style.color = "white";
              anchor.innerText = `${incident.incidentNumberDisplay} - ${incident.incidentCallTypeDescription}`;
              return anchor;
            },
          })],
          title: `${incident.incidentCallTypeDescription} - ${incident.incidentNumberDisplay}`,
        }),
      };

      data.add(item);
    });
    return data;
  }, [incidentMarkers]);

  const avlUnitsGraphics = useMemo(() => {
    const data = new Set<GraphicOptions>();
    const unitsWithLocation = avlMarkers.filter((el) => el.location.latitude !== 0 && el.location.longitude !== 0);
    unitsWithLocation.forEach((el) => {
      // INFO: AVL unit conversion to marker
      const rectangleWidth = el.username.length < 4 ? 25 : (25 + ((el.username.length - 3) * 5.25));
      const rectangleHeight = 15;
      const triangleHeight = 15;
      const point = new Point({
        longitude: el.location.longitude,
        latitude: el.location.latitude,
      });

      const itemBackground = {
        id: `avl-unit-background-${el.id}-${el.username}`,
        name: el.username,
        geometry: point,
        symbol: new PictureMarkerSymbol({
          url: getDeviceTypeMarkerUrl(el, markerAVLUrlCache, el.color),
          width: rectangleWidth,
          height: rectangleHeight + triangleHeight,
          angle: 0,
          xoffset: rectangleWidth / 2,
          yoffset: 15,
        }),
        popupTemplate: new PopupTemplate({
          content: ReactDOMServer.renderToStaticMarkup(<AVLPopUp avl={el} />),
          title: el.agencyCode ? `${el.username} ${el.state}-${el.opAreaCode}-${el.agencyCode}` : `${el.username} ${el.state}-${el.opAreaCode}`,
        }),
      };

      const itemLabel = {
        id: `avl-unit-label-${el.id}-${el.username}`,
        name: el.username,
        geometry: point,
        symbol: new TextSymbol({
          text: el.username,
          color: el.color.text ?? DefaultUnitColor.text,
          font: {
            size: 10,
            family: "Arial",
            weight: "bold",
          },
          xoffset: (rectangleWidth / 2),
          yoffset: 19,
        }),
      };

      data.add(itemBackground);
      data.add(itemLabel);
    });
    return data;
  }, [avlMarkers]);

  const sharedAVLGraphics = useMemo(() => {
    const data = new Set<GraphicOptions>();
    const unitsWithLocation = sharedAVLMarkers.filter((el) => el.location.latitude !== 0 && el.location.longitude !== 0);
    unitsWithLocation.forEach((el) => {
      // INFO: Shared AVL unit conversion to marker
      const iconLongestText = Math.max(el.username.length, (el.agencyCode ? el.agencyCode : el.opAreaCode).length);
      const rectangleWidth = iconLongestText < 4 ? 25 : (25 + ((iconLongestText - 3) * 5.25));
      const rectangleHeight = 25;
      const triangleHeight = 15;
      const point = new Point({
        longitude: el.location.longitude,
        latitude: el.location.latitude,
      });

      const itemBackground = {
        id: `shared-avl-unit-background-${el.id}-${el.username}`,
        name: `${el.id}-${el.username}`,
        geometry: point,
        symbol: new PictureMarkerSymbol({
          url: getSharedAvlMarkerUrl(el, markerSharedAVLUrlCache),
          width: rectangleWidth + 2,
          height: rectangleHeight + triangleHeight,
          angle: 0,
          xoffset: rectangleWidth / 2,
          yoffset: 18,
        }),
        popupTemplate: new PopupTemplate({
          // assign to popup content
          content: ReactDOMServer.renderToStaticMarkup(<AVLPopUp avl={el} />),
          title: el.agencyCode ? `${el.username} ${el.state}-${el.opAreaCode}-${el.agencyCode}` : `${el.username} ${el.state}-${el.opAreaCode}`,
        }),
      };
      const itemLabel = {
        id: `shared-avl-unit-label-${el.id}-${el.username}`,
        name: `${el.id}-${el.username}`,
        geometry: point,
        symbol: new TextSymbol({
          text: el.username,
          color: el.color.text ?? DefaultUnitColor.text,
          font: {
            size: 10,
            family: "Arial",
            weight: "bold",
          },
          xoffset: (rectangleWidth / 2),
          yoffset: 18,
        }),
      };

      const itemAgencyLabel = {
        id: `shared-avl-unit-agency-${el.id}-${el.username}`,
        name: `${el.id}-${el.username}`,
        geometry: point,
        symbol: new TextSymbol({
          text: el.agencyCode ? el.agencyCode : el.opAreaCode,
          color: el.color.text ?? DefaultUnitColor.text,
          font: {
            size: 10,
            family: "Arial",
            weight: "bold",
          },
          xoffset: (rectangleWidth / 2),
          yoffset: 29,
        }),
      };

      data.add(itemBackground);
      data.add(itemLabel);
      data.add(itemAgencyLabel);
    });
    return data;
  }, [sharedAVLMarkers]);

  const hazardGraphics = useMemo(() => {
    const data = new Set<GraphicOptions>();
    hazardMarkers.forEach((hazard) => {
      // INFO: Hazard conversion to marker
      const item = {
        id: `hazard-${hazard.id}-${hazard.name}`,
        name: hazard.name,
        geometry: new Point({
          latitude: hazard.latitude,
          longitude: hazard.longitude,
        }),
        symbol: new PictureMarkerSymbol({
          ...hazardMarkerByType(hazard.name, hazard.color),
        }),
        popupTemplate: new PopupTemplate({
          content: hazard.note ?? "",
          title: hazard.name,
        }),
      };

      data.add(item);
    });
    return data;
  }, [hazardMarkers]);

  const callersGraphics = useMemo(() => {
    const data = new Set<GraphicOptions>();
    callersMarkers.forEach((caller) => {
      // INFO: Caller conversion to marker
      const item: GraphicOptions = {
        id: `caller-${caller.CallerId}`,
        name: caller.CallerId,
        geometry: new Point({
          latitude: caller.Latitude,
          longitude: caller.Longitude,
        }),
        symbol: new SimpleMarkerSymbol({
          style: "diamond",
          color: [30, 30, 30], // Dark grey
          size: "24px", // pixels
          outline: {
            color: [240, 240, 200], // Light yellow
            width: 1.25,
          },
        }),
        popupTemplate: new PopupTemplate({
          content: caller.Address ?? "",
          title: caller.CallerId,
        }),
      };

      if (caller.UncertaintyRadius && caller.UncertaintyRadius > 0) {
        const circle = new Circle({
          center: {
            latitude: caller.Latitude,
            longitude: caller.Longitude,
          },
          geodesic: true,
          numberOfPoints: 100,
          radius: caller.UncertaintyRadius,
          radiusUnit: "meters",
        });
        const symbol = new SimpleFillSymbol();
        symbol.set("color", null);
        symbol.set("outline", new SimpleLineSymbol({ style: "dash", color: "rgb(0, 0, 0)" }));
        item.secondaryGraphic = new Graphic({
          geometry: circle,
          symbol,
        });
      }
      data.add(item);
    });
    return data;
  }, [callersMarkers]);

  const unmappedDeviceGraphics = useMemo(() => {
    const data = new Set<GraphicOptions>();
    unmappedDeviceMarkers.forEach((device) => {
      const deviceContent = `${device.remoteAddress} : ${device.mapId}`;
      // INFO: Unmapped device conversion to marker
      const item = {
        id: `unmapped-device-${device._id}-${device.departmentId}-${device.active}`,
        name: device.deviceId,
        geometry: new Point({
          latitude: device.location.latitude,
          longitude: device.location.longitude,
        }),
        symbol: new PictureMarkerSymbol({
          url: unmappedDeviceMarker(),
          width: 20,
          height: 30,
        }),
        popupTemplate: new PopupTemplate({
          content: deviceContent,
          title: device.deviceId,
        }),
      };
      data.add(item);
    });
    return data;
  }, [unmappedDeviceMarkers]);

  const incidentGroupsGraphics = useMemo(() => {
    const data = new Set<GraphicOptions>();
    incidentGroupsMarkers.forEach((group) => {
      const popupNode = document.createElement("div");
      const root = createRoot(popupNode);
      // INFO: Incident group conversion to marker
      const item = {
        id: `incident-group-${group.id}-${group.name}`,
        name: group.name,
        geometry: new Point({
          latitude: group.latitude,
          longitude: group.longitude,
        }),
        symbol: new PictureMarkerSymbol(
          {
            url: incidentGroupMarker(group),
            width: 70,
            height: 50,
          },
        ),
        popupTemplate: new PopupTemplate({
          content: popupNode as any,
          title: group.name,
        }),
      };
      // TODO: Remake this as it throws warning in console.
      // eslint-disable-next-line function-paren-newline
      root.render(<ManagedIncidentGroupMarkerGrid group={group} />);
      data.add(item);
    });
    return data;
  }, [incidentGroupsMarkers]);

  const unassignedUnitsGraphics = useMemo(() => {
    const data = new Set<GraphicOptions>();
    unassignedUnitsOnMapMarkers.forEach((unassignedUnit) => {
      // INFO: Unassigned unit conversion to marker
      const item = {
        id: `unassigned-unit-marker-${unassignedUnit.id}-${unassignedUnit.name}`,
        name: unassignedUnit.name,
        geometry: new Point({
          latitude: unassignedUnit.latitude,
          longitude: unassignedUnit.longitude,
        }),
        symbol: new PictureMarkerSymbol(
          {
            url: unassignedUnitsOnMapMarker(unassignedUnit),
            width: 70,
            height: 50,
          },
        ),
      };

      data.add(item);
    });
    return data;
  }, [unassignedUnitsOnMapMarkers]);

  return {
    incidentsGraphics,
    avlUnitsGraphics,
    sharedAVLGraphics,
    hazardGraphics,
    callersGraphics,
    unmappedDeviceGraphics,
    incidentGroupsGraphics,
    unassignedUnitsGraphics,
  };
}
