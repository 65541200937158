import {
  useCallback, useContext, useMemo, useState,
} from "react";
import { CadSimulatorField } from "./cad-simulator-field";
import { Grid } from "@mui/material";
import { useData } from "@hooks/useData";
import { LoginContext } from "@contexts/login-context";
import { getDepartmentById } from "@services/department";
import * as locator from "@arcgis/core/rest/locator.js";
import { useEffectAsync } from "@hooks/utils";
import { IncidentLocation } from "@models/arcgis";
import { WebMap } from "@components/arcgis-map-with-hooks/map";
import { Caller } from "@models/cad";

const reverseGeocoderUrl = "https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer";

const defaultLat = 36.06199804030064;
const defaultLong = -115.03954633447312;

export function CadSimulatorMap(props: {
  values: { Latitude: string | number, Longitude: string | number, IncidentNumber: string, AgencyIncidentCallTypeDescription: string, callers?: Caller[] }
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  // Canned simulator has different fields naming than incident
  isCannedSimulator?: boolean,
}) {
  const {
    values,
    setFieldValue,
    isCannedSimulator,

  } = props;

  const [incidentSearchPoint, setIncidentSearchPoint] = useState<__esri.Point | null>(null);

  const [user] = useContext(LoginContext);
  const { data: department } = useData((as) => getDepartmentById(user!.departmentId, as));

  const getLat = useMemo(() => (lat: number | string | undefined) => {
    if (lat) {
      if (typeof lat === "number") {
        return lat;
      }
      const floatLat = parseFloat(lat);
      if (!Number.isNaN(floatLat)) {
        return floatLat;
      }
    }
    return department?.defaultMapPosition.latitude ?? defaultLat;
  }, [department]);

  const getLong = useMemo(() => (long: number | string | undefined) => {
    if (long) {
      if (typeof long === "number") {
        return long;
      }
      const floatLong = parseFloat(long);
      if (!Number.isNaN(floatLong)) {
        return floatLong;
      }
    }
    return department?.defaultMapPosition.longitude ?? defaultLong;
  }, [department]);

  const mapIncident: IncidentLocation[] = values.Latitude && values.Longitude ? [{
    latitude: getLat(values.Latitude),
    longitude: getLong(values.Longitude),
    type: "CAD",
    incidentNumberDisplay: values.IncidentNumber,
    incidentCallTypeDescription: values.AgencyIncidentCallTypeDescription,
  }] : [];

  // TODO: Make those functions smarter
  // INFO: We set the latitude and longitude directly to not create an unnecessary rerender of the map
  const getSearchResults = useCallback((resultsFeatures: __esri.Graphic) => {
    // INFO: We cast to Point because most of properties overlap but __esri.Geometry misses some important properties even if it returns them
    const resultsGeometry = resultsFeatures.geometry as __esri.Point;
    if (isCannedSimulator) {
      setFieldValue("lat", resultsGeometry.latitude ?? defaultLat);
      setFieldValue("lon", resultsGeometry.longitude ?? defaultLong);
    } else {
      setFieldValue("Latitude", resultsGeometry.latitude ?? defaultLat);
      setFieldValue("Longitude", resultsGeometry.longitude ?? defaultLong);
    }
    // Fill in street/city, etc.
    // If successful, the locator.locationToAddress will overwrite these
    const attributes = resultsFeatures.attributes;
    if (attributes) {
      if (isCannedSimulator) {
        setFieldValue("streetName", attributes.Address ?? "");
        setFieldValue("city", attributes.City ?? "");
        setFieldValue("locationComment", attributes.Match_addr ?? "");
        setFieldValue("state", attributes.RegionAbbr ?? attributes.Subregion ?? "");
      } else {
        setFieldValue("StreetName", attributes.Address ?? "");
        setFieldValue("CityOrLocality", attributes.City ?? "");
        setFieldValue("CommonPlaceName", attributes.Match_addr ?? "");
        setFieldValue("StateOrProvince", attributes.RegionAbbr ?? attributes.Subregion ?? "");
      }
    }
    setIncidentSearchPoint(resultsGeometry);
  }, [setFieldValue, setIncidentSearchPoint, isCannedSimulator]);

  const getClickResults = useCallback((result: __esri.ViewHit) => {
    if (isCannedSimulator) {
      setFieldValue("lat", result.mapPoint.latitude ?? defaultLat);
      setFieldValue("lon", result.mapPoint.longitude ?? defaultLong);
    } else {
      setFieldValue("Latitude", result.mapPoint.latitude);
      setFieldValue("Longitude", result.mapPoint.longitude);
    }
    setIncidentSearchPoint(result.mapPoint);
  }, [setFieldValue, setIncidentSearchPoint, isCannedSimulator]);

  useEffectAsync(async () => {
    if (incidentSearchPoint) {
      const reverseGeocoderResult = await locator.locationToAddress(reverseGeocoderUrl, { location: incidentSearchPoint });
      const attributes = reverseGeocoderResult.attributes;
      if (attributes) {
        if (isCannedSimulator) {
          setFieldValue("streetName", attributes.Address ?? "");
          setFieldValue("city", attributes.City ?? "");
          setFieldValue("locationComment", attributes.Match_addr ?? "");
          setFieldValue("state", attributes.RegionAbbr ?? attributes.Subregion ?? "");
        } else {
          setFieldValue("StreetName", attributes.Address ?? "");
          setFieldValue("CityOrLocality", attributes.City ?? "");
          setFieldValue("CommonPlaceName", attributes.Match_addr ?? "");
          setFieldValue("StateOrProvince", attributes.RegionAbbr ?? attributes.Subregion ?? "");
        }
      }
    }
    setIncidentSearchPoint(null);
  }, [incidentSearchPoint, isCannedSimulator]);

  const resetMapView = useCallback(() => { }, []);

  return (
    <div>
      <WebMap
        incidents={mapIncident}
        avlMarkers={[]}
        zoomToIncident={false}
        incidentZoom={18}
        incidentCenter={[getLong(values.Longitude), getLat(values.Latitude)]}
        height={485}
        callers={values.callers ?? []}
        resetMapView={resetMapView}
        getSearchResults={getSearchResults}
        onMarkerClickHandlers={[getClickResults]}
        noStoreData
      />
      <div style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        flexWrap: "wrap",
        marginTop: 10,
        marginRight: 30,
      }}
      >
        <Grid item xs={12} sm={6} lg={6}>
          <CadSimulatorField fieldKey="" value={values.Latitude} handleChange={() => { }} disabled label="Lat" />
        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
          <CadSimulatorField fieldKey="" value={values.Longitude} handleChange={() => { }} disabled label="Lng" />
        </Grid>
      </div>
    </div>
  );
}
