import { appFetch } from "./config";
import {
  ActionChangeShareIncident,
  CADCannedSimulation,
  CADIncident,
  IncidentMap,
  IncidentNotifiedLog,
  IncidentQuery,
  IncidentShareCode,
  SimulatorIncident,
  SimulatorIncidentPayload,
} from "@models/cad";

export const postSimulatorIncident = async (item: SimulatorIncidentPayload): Promise<{ ok: boolean }> => {
  const response: Response = await appFetch("api/a1/incident", {
    method: "POST",
    body: JSON.stringify(item),
  });
  return response.json();
};

export const getCADIncidentById = async (id: string, signal?: AbortSignal): Promise<CADIncident> => {
  const response: Response = await appFetch(`api/a1/incident/${id}`, { signal });
  return response.json();
};

// INFO: Duplicate for canned simulations, remove at later date
export const getCannedCADIncidentById = async (id: string, signal?: AbortSignal): Promise<SimulatorIncident> => {
  const response: Response = await appFetch(`api/a1/incident/${id}`, { signal });
  return response.json();
};

export const getCADIncidentsByQuery = async (
  p: IncidentQuery,
  signal: AbortSignal,
): Promise<CADIncident[]> => {
  const response: Response = await appFetch(`api/a1/incident?${(Object.keys(p) as Array<keyof IncidentQuery>)
    .reduce((acc, key) => `${acc}${key}=${p[key]}&`, "")
    .slice(0, -1)}`, { signal });
  return response.json();
};

export const getActiveCADIncidents = async (limit: number, skip: number, signal: AbortSignal): Promise<CADIncident[]> => await getCADIncidentsByQuery({
  active: true,
  skip,
  limit,
}, signal);

export const getAllActiveCADIncidents = async (signal: AbortSignal): Promise<CADIncident[]> => await getCADIncidentsByQuery({
  active: true,
}, signal);

export const getInactiveCADIncidents = async (limit: number, skip: number, signal: AbortSignal): Promise<CADIncident[]> => await getCADIncidentsByQuery({
  active: false,
  limit,
  skip,
}, signal);

export const getIncidentNotifiedLog = async (incidentNumber: string, signal?: AbortSignal): Promise<IncidentNotifiedLog[]> => {
  const response: Response = await appFetch(`api/a1/incident/notified/${incidentNumber}`, { signal });
  return response.json();
};

export const getMapIncidents = async (signal: AbortSignal): Promise<IncidentMap[]> => {
  const response: Response = await appFetch("api/a1/incident/map", { signal });
  return response.json();
};

export const ignoreIncident = async (incidentId: string, signal?: AbortSignal) => {
  await appFetch(`api/a1/incident/ignore/${incidentId}`, {
    method: "POST",
    signal,
  });
};

export const getCannedSimulation = async (signal?: AbortSignal): Promise<CADCannedSimulation[] | []> => {
  const response: Response = await appFetch("api/a1/cad-simulation/list", { signal });
  return response.json();
};

export const getCannedSimulationById = async (id: string, signal?: AbortSignal): Promise<CADCannedSimulation> => {
  const response: Response = await appFetch(`api/a1/cad-simulation/get/${id}`, { signal });
  return response.json();
};

export type PayloadChangeShareIncident = {
  action: ActionChangeShareIncident,
  departmentId: string,
  incidentId: string | null | undefined,
};

export const changeShareIncident = async (payload: PayloadChangeShareIncident, signal?: AbortSignal, atDate = new Date()) => {
  const {
    action,
    departmentId,
    incidentId,
  } = payload;

  await appFetch(`api/a1/incident/share/${incidentId}`, {
    method: "POST",
    body: JSON.stringify({
      departmentId,
      incidentId,
      action,
      atDate,
    }),
    signal,
  });
};

export const addCannedSimulation = async (departmentId: string, simulationPayload: Partial<CADCannedSimulation>) => {
  await appFetch(`api/a1/cad-simulation/add/${departmentId}`, {
    method: "POST",
    body: JSON.stringify(simulationPayload),
  });
};

export const updateCannedSimulation = async (simulationId: string, simulationPayload: Partial<CADCannedSimulation>) => {
  await appFetch(`api/a1/cad-simulation/update/${simulationId}`, {
    method: "POST",
    body: JSON.stringify(simulationPayload),
  });
};

export const getIncidentShareCode = async (departmentId: string, incidentNumber: string, signal?: AbortSignal): Promise<IncidentShareCode> => {
  const response: Response = await appFetch(`api/v3/incident/view-invite/${departmentId}/${incidentNumber}`, { signal });
  return response.json();
};

export const generateIncidentShareCode = async (IncidentNumber: string, expire = false) => {
  await appFetch("api/v3/incident/create-invite", {
    method: "POST",
    body: JSON.stringify({ IncidentNumber, expire }),
  });
};

export const joinIncident = async (code: string): Promise<{ ok: boolean }> => {
  const response: Response = await appFetch("api/v3/incident/join", {
    method: "POST",
    body: JSON.stringify({ code }),
  });
  return response.json();
};
