/* eslint-disable react/no-array-index-key */
import {
  AssignmentIndOutlined,
  Schedule,
} from "@mui/icons-material";
import {
  Typography,
} from "@mui/material";
import moment from "moment";
import { useMemo } from "react";

import { CADVehicleOption, VehiclesStatus } from "@models/cad-vehicles-status";
import { Personnel } from "@models/active_personnel";

import classes from "./cad-status-components.module.css";
import CADVehicleStatusRequestedByIcon from "./CADVehicleStatusRequestedByIcon";

export function CADVehicleStatusModifiedDateColumns(
  {
    assignableByUser,
    modifiedDate,
    options,
    owner,
  }:
    {
      assignableByUser: boolean,
      modifiedDate: number,
      options: CADVehicleOption[],
      owner: string,
    },
) {
  const assignableIconColor = options.length > 0 ? "#008b8b" : "#aeaeae";
  const m = moment(modifiedDate * 1000);
  const titleAccess = (assignableByUser ? "Assignable By User" : "Changed By CAD Only") + (options.length > 0 ? " - with options" : "");

  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
      <div className={`${classes.user_icon}`} style={{ marginRight: 10 }}>
        <CADVehicleStatusRequestedByIcon requestedBy={owner} />
      </div>
      <div className={`${classes.date_section}`}>
        <div>
          {assignableByUser ? (
            <AssignmentIndOutlined
              style={{
                color: assignableIconColor,
                marginRight: 12,
              }}
              titleAccess={titleAccess}
            />
          ) : (
            <Schedule
              style={{
                color: assignableIconColor,
                marginRight: 12,
              }}
              titleAccess={titleAccess}
            />
          )}
        </div>
        <Typography className={`${classes.last_update}`} title={m.toISOString()}>
          {m.fromNow()}
        </Typography>
      </div>
    </div>
  );
}

export default function CADVehicleStatusPersonnelColumn(prop: {
  vehicleStatus: VehiclesStatus;
  status: boolean;
  staffing: Personnel[];
}) {
  const {
    vehicleStatus: vehicle,
    staffing,
  } = prop;
  const parsedStaffingForUnit = useMemo(() => {
    if (staffing?.length) {
      return staffing.filter((staff) => staff.radioNames.includes(vehicle.radioName));
    }
    return [];
  }, [staffing]);

  return (
    <div>
      <div
        style={{
          rowGap: 5, display: "flex", flexDirection: "column", textAlign: "left",
        }}
      >
        {!!parsedStaffingForUnit.length
          && parsedStaffingForUnit.map((staff, index) => (
            <div key={vehicle.radioName + staff.PersonnelName + staff.PersonnelRank + staff.shiftStart + index} style={{ display: "flex", flexDirection: "column" }}>
              <Typography style={{ fontWeight: "bold", fontSize: 14 }}>
                {staff.PersonnelRank
                  && (
                    <span>
                      {staff.PersonnelRank}
                      {", "}
                    </span>
                  )}
                {staff.PersonnelName && <span>{staff.PersonnelName}</span>}
              </Typography>
              <Typography style={{ fontSize: 11, paddingLeft: 10 }}>
                {`${moment(staff.shiftStart).format("MM/DD/YY")} ${moment(staff.shiftStart).format("HH:mm")} `}
                -
                {` ${moment(staff.shiftEnd).format("MM/DD/YY")} ${moment(staff.shiftEnd).format("HH:mm")}`}
              </Typography>
            </div>
          ))}
      </div>
      {!!parsedStaffingForUnit.length && (
        <Typography style={{ fontSize: 11 }}>
          {`Updated ${moment.unix(vehicle.modifiedDate).format("MM/DD/YY")} ${moment.unix(vehicle.modifiedDate).format("HH:mm")}`}
        </Typography>
      )}
    </div>
  );
}
