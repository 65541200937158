/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import { TextField, TextFieldProps } from "@mui/material";
import { useRef, useState } from "react";

export type TimedOutTextFieldType = TextFieldProps & { timeout: number }

export function TimedOutTextField(p: TimedOutTextFieldType) {
  const [search, setSearch] = useState("");
  const timeout = useRef<NodeJS.Timeout>(null!);
  return (
    <TextField
      {...p}
      value={timeout.current ? search : (p.value ?? "")}
      onChange={(e) => {
        if (timeout.current) {
          clearTimeout(timeout.current);
        }
        const val = e.target.value;
        setSearch(val);
        timeout.current = setTimeout(() => {
          if (p.onChange) {
            p.onChange(e);
          }
          timeout.current = null!;
        }, p.timeout);
      }}
      size="small"
    />
  );
}
