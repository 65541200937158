export const apiUrl = (path: string, searchParams: Record<string, number | undefined | string> = {}) => {
  const url = Object.assign(new URL(path, window.env.API_URL), {
    hasBaseAppPath: true,
  } as const);
  Object.entries(searchParams).forEach(([key, value]) => {
    if (value == null) return;
    url.searchParams.append(key, value.toString());
  });
  return url;
};

const defaultFetchOptions = {
  mode: "cors",
  credentials: "include",
  headers: {
    "Content-Type": "application/json",
  },
} as const;

export const appFetch = (url: string | (URL & { hasBaseAppPath: true }), opt?: RequestInit) => {
  url = typeof url === "string" ? apiUrl(url) : url;
  return fetch(url.toString(), {
    ...defaultFetchOptions,
    ...opt,
    headers: {
      ...defaultFetchOptions.headers,
      ...opt?.headers,
    },
  });
};
