import {
  Grid,
  Paper,
  Link,
} from "@mui/material";

import ContactInfo from "@components/ContactInfo";

import logo from "../img/logo.png";
import signInGoogle from "../img/btn_google_signin_dark_normal_web.png";
import signInGoogle2x from "../img/btn_google_signin_dark_normal_web@2x.png";

import { useTitle } from "@hooks/useTitle";

import {
  signInWithGoogleURL,
} from "@services/user";

export default function OAuthJump() {
  useTitle("Select Auth With Account");

  const pageJSX: JSX.Element = (
    <div className="login-page container login-background">
      <Grid container>
        <Grid item xs={12} lg={4} className="left-side">
          <img src={logo} alt="Tablet Command Logo" className="logo" />
        </Grid>
        <Grid item xs={12} lg={7} className="right-side">
          <Paper className="paperBody">
            <div className="head">
              <Link
                href={signInWithGoogleURL()}
                rel="noopener"
                underline="none"
              >
                <img
                  src={signInGoogle}
                  srcSet={`${signInGoogle2x} 2x`}
                  alt="Sign In With Google"
                />
              </Link>
            </div>
          </Paper>
          <ContactInfo />
        </Grid>
      </Grid>
    </div>
  );

  return pageJSX;
}
