import moment from "moment";
import { Button } from "@mui/material";
import { getUsersRegistrationsData } from "@services/department";
// TODO: Fix for svg to display correctly
import UserRegistrationIcon from "./img/menu-icons/User-Registration.svg?react";
import { handleDownload } from "@utils/utilsFunctions";

export default function UserRegistration() {
  return (
    <Button
      onClick={() => handleDownload(getUsersRegistrationsData, `user-registration-${moment().format("YYYY-MM-DD")}`)}
      style={{ padding: 0 }}
    >
      <div className="side-menu-items">
        <UserRegistrationIcon className="user-registration" />
        <p
          className="info"
          style={{
            fontSize: 16,
            fontFamily: "Roboto Condensed, sans-serif",
          }}
        >
          User Registration
        </p>
      </div>
    </Button>
  );
}
