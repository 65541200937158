/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide, { SlideProps } from "@mui/material/Slide";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import WarningIcon from "@mui/icons-material/Warning";
import HistoryIcon from "@mui/icons-material/History";
import DescriptionIcon from "@mui/icons-material/Description";
import { getDiffInHours, updateTime } from "@utils/utilsFunctions";
import { Units, History } from "@models/tc-incident";
import { Unit } from "../../img/SVG-components/unit-svg";
import arrows from "../../img/managed-incidents/arrows_left_right_point_center.svg";
import cad from "../../img/managed-incidents/cad_grey_icon.svg";

const Transition = React.forwardRef((
  props: SlideProps,
  ref: React.Ref<unknown>,
  // eslint-disable-next-line react/jsx-props-no-spreading
) => <Slide direction="up" ref={ref} {...props} />);

export function UnitPopup(prop: {
  name: string
  time: string
  isSupervisor: boolean
  unit: Units
  history: History[]
  group: string
  signal: {
    openPopup: boolean
    unitName: string
  }
}) {
  const {
    unit, history, name, time, isSupervisor, group, signal,
  } = prop;
  const [open, setOpen] = useState(false);
  const [unitTime, setUnitTime] = useState(updateTime(new Date(unit.time ?? "").getTime()));
  const [hours, setHours] = useState(0);

  useEffect(() => {
    setHours(getDiffInHours(unit.time ?? ""));
    setUnitTime(updateTime(new Date(unit.time ?? "").getTime()));
  }, [open]);

  useEffect(() => {
    if (signal.unitName === name) {
      setOpen(true);
    }
  }, [signal]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const filterHistory = history.filter((el) => el.entity_type === 11 || el.entity_type === 12 || el.entity_type === 23);
  const unitHistory = filterHistory.filter((el) => el.message?.includes(prop.name));

  return (
    <div className="unit-popup">
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <div
        style={{ paddingLeft: 6, paddingRight: 5, cursor: "pointer" }}
        role="button"
        tabIndex={0}
        onClick={handleClickOpen}
      >
        <Unit name={name} time={time} isSupervisor={isSupervisor} />
      </div>
      <Dialog
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
      >
        <DialogTitle className="note-popup-content-open" id="alert-dialog-slide-title" style={{ textAlign: "center" }}>Unit Overview</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {unit.UnitID ?? ""}
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            {unit.personnelOnScene ? (
              <div>
                <div className="content">
                  <div><PeopleIcon style={{ paddingRight: 5 }} /></div>
                  <p>
                    Personnel
                    {unit.personnelOnScene}
                  </p>
                </div>
                {(unit.Personnel ?? []).map((el) => (
                  <div key={el.PersonnelID + el.PersonnelName + el.PersonnelNote + el.PersonnelWorkCode + el.PersonnelRank} className="personnel-data">
                    <div><PersonIcon style={{ paddingRight: 5 }} /></div>
                    <p style={{ margin: 0 }}>
                      {el.PersonnelRank}
                      {el.PersonnelName}
                    </p>
                  </div>
                ))}
              </div>
            ) : ""}
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            {unit.note && unit.note !== "" ? (
              <div className="content">
                <div><DescriptionIcon style={{ paddingRight: 5 }} /></div>
                <p>{unit.note}</p>
              </div>
            ) : ""}
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            {unit.status ? (
              <div className="content">
                <div style={{ paddingRight: 5 }}><img src={cad} alt="" /></div>
                <p style={{ margin: 0 }}>
                  {" "}
                  {" "}
                  {`${unit.status} `}
                </p>
              </div>
            ) : ""}
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="content">
              <div style={{ paddingRight: 5 }}><img src={arrows} alt="" /></div>
              {group}
              {" - "}
              {hours > 0 && `${hours}`}
              :
              {`${unitTime.minutes}:${unitTime.seconds}`}
            </div>
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="content">
              <div><WarningIcon style={{ paddingRight: 5 }} /></div>
              <p style={{ margin: 0 }}>
                Status:
                {" "}
                {unit.warning === 0 ? "Normal" : "Alert"}
              </p>
            </div>
          </DialogContentText>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="content">
              <div><HistoryIcon style={{ paddingRight: 5 }} /></div>
              <p style={{ margin: 0 }}>History: </p>
            </div>
            {unitHistory.map((el) => <p key={el.entity_id + el.time + el.entity_type + el.message} style={{ marginLeft: 30 }}>{el.message}</p>)}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  );
}
