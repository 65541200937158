import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { getActiveIncidents } from "@services/incident";
import { useDataWithoutLoading } from "@hooks/useDataWithoutLoading";
import { useInterval } from "@hooks/useInterval";
import { useTitle } from "@hooks/useTitle";
import IconButton from "@mui/material/IconButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useEffectAsync } from "@hooks/utils";
import { useHistoryState } from "@hooks/useHistoryState";
import { IncidentsTable } from "./incidents-table";

export function ActiveIncidents() {
  useTitle("Active Incidents");
  const pageLimit = 30;
  const [sort, setSort] = useState("");
  const [date, setDate] = useState("");
  const [page, setPage] = useHistoryState("active-table-page", 1);
  const { data: activeIncidents, reload } = useDataWithoutLoading((as) => getActiveIncidents(pageLimit, (page! - 1) * pageLimit, date, sort, as), []);

  const [isButtonTriggered, setIsButtonTriggered] = useState(false);

  useEffectAsync(async () => {
    if (isButtonTriggered) {
      await reload();
    }
  }, [page, date, sort]);

  useEffect(() => {
    if (page === 1) {
      setDate("");
      setSort("");
    }
  }, [page]);

  const nextPage = () => {
    if (activeIncidents?.length === pageLimit) {
      setPage(page! + 1);
      setSort("ASC");
      setDate(activeIncidents![activeIncidents!.length - 1] ? activeIncidents![activeIncidents!.length - 1].startTime : "");
    }
    setIsButtonTriggered(true);
  };
  const previousPage = () => {
    if (page! > 1) {
      setPage(page! - 1);
      setSort("DESC");
      setDate(activeIncidents![0] ? activeIncidents![0].startTime : "");
    }
    setIsButtonTriggered(true);
  };

  useInterval(async () => {
    await reload();
  }, [page, date, sort], { reloadInterval: 15000 });

  const sortedIncidents = useMemo(() => {
    if (activeIncidents?.length) {
      return activeIncidents?.sort((a, b) => (moment(b.startTime).unix()) - (moment(a.startTime).unix()));
    }
    return [];
  }, [activeIncidents]);

  return (
    <>
      <div style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "white",
      }}
      >
        <IconButton disabled={page! <= 1} onClick={previousPage}><ArrowBackIcon /></IconButton>
        <p>{page!}</p>
        <IconButton
          disabled={activeIncidents?.length !== pageLimit}
          onClick={nextPage}
        >
          <ArrowForwardIcon />
        </IconButton>
      </div>
      <IncidentsTable incidents={sortedIncidents} tableTitle="Active Incidents" reload={reload} />
    </>
  );
}
