import {
  CadStatus, CadStatusMap, FeaturesSettings, NewCadData, NewCadStatus,
} from "@models/cad-statuses";
import { appFetch } from "./config";

export const getCadStatus = async (signal: AbortSignal): Promise<CadStatus[]> => {
  const response: Response = await appFetch("api/v2/cad-status/?", { signal });

  return response.json();
};

export const getCadStatusMap = async (signal: AbortSignal): Promise<CadStatusMap[]> => {
  const response = await appFetch("api/v2/cad-status-map/?", { signal });

  return response.json();
};

export const updateCadStatus = async (data: NewCadStatus) => {
  const response: Response = await appFetch("api/v2/cad-status/", {
    method: "POST",
    body: JSON.stringify(data),
  });

  return response.json();
};

export const createAny2AnySuperAdmin = async (departmentId: string): Promise<void> => {
  const response: Response = await appFetch(`api/v2/cad-status-map/any2any/${departmentId}`, {
    method: "POST",
  });
  return response.json();
};

export const updateCadStatusLabel = async (data: NewCadData) => {
  const response: Response = await appFetch("api/v2/cad-status-map/toggle/", {
    method: "POST",
    body: JSON.stringify(data),
  });

  return response.json();
};

export const getCadStatusSuperAdmin = async (departmentId: string, backupRequested: boolean, signal: AbortSignal): Promise<CadStatus[]> => {
  const response: Response = await appFetch(`api/a1/admin/cad-status/${departmentId}?backupRequested=${backupRequested}`, { signal });

  return response.json();
};

export const clearCadStatusSuperAdmin = async (departmentId: string, restore: boolean): Promise<void> => {
  const response: Response = await appFetch(`api/a1/admin/cad-status/clear/${departmentId}?restore=${restore}`, {
    method: "POST",
  });
  return response.json();
};

export const getCadStatusMapSuperAdmin = async (departmentId: string, backupRequested: boolean, signal: AbortSignal): Promise<CadStatusMap[]> => {
  const response: Response = await appFetch(`api/a1/admin/cad-status-map/${departmentId}?backupRequested=${backupRequested}`, { signal });

  return response.json();
};

export const clearCadStatusMapSuperAdmin = async (departmentId: string, restore: boolean): Promise<void> => {
  const response: Response = await appFetch(`api/a1/admin/cad-status-map/clear/${departmentId}?restore=${restore}`, {
    method: "POST",
  });
  return response.json();
};

export const getStatusSettings = async (): Promise<FeaturesSettings[]> => {
  const response: Response = await appFetch("api/sync/setting-feature");
  return response.json();
};
