import { createContext, useState } from "react";

export const DepartmentContext = createContext<[string | null, React.Dispatch<string>]>([null!, () => null!]);

export function DepartmentContextProvider(props: { children: JSX.Element }) {
  const [departmentId, setDepartmentId] = useState("");

  return (
    <DepartmentContext.Provider value={[departmentId, setDepartmentId]}>
      {props.children}
    </DepartmentContext.Provider>
  );
}
