import {
  ChangeUserPassword,
  Department,
  DepartmentInvite,
  LicensingListRecord,
  ManagedIncidentMode,
  ManagedIncidentPriority,
  PasswordReset,
  SearchUserResult,
  SwapVehicle,
  SwapVehicleBody,
  UserDepartment,
  VerifiedUser,
  WebDisclaimer,
} from "@models/department";
import { apiUrl, appFetch } from "./config";
import { SessionWithOnline } from "@models/user";

export interface SearchUserQuery {
  search: string;
  limit?: number;
  skip?: number;
}

export const departments = async (signal?: AbortSignal): Promise<Department[]> => {
  const response: Response = await appFetch("api/a1/admin/list-departments", !signal ? undefined : { signal });
  return await response.json();
};

export const searchUsers = async (search: string, page: number, signal?: AbortSignal): Promise<SearchUserResult> => {
  if (search === "") {
    return {
      items: [],
      complete: true,
    };
  }

  const itemsPerPage = 30;
  const skip = page * itemsPerPage;

  const url = apiUrl("api/v7/account/search-users");
  url.searchParams.append("search", search);
  url.searchParams.append("limit", itemsPerPage.toString());
  if (skip > 0) {
    url.searchParams.append("skip", skip.toString());
  }

  const response: Response = await appFetch(url, { signal });
  return response.json();
};

export const getDepartmentUsers = async (departmentId: string | undefined, signal?: AbortSignal): Promise<UserDepartment[]> => {
  const response: Response = await appFetch(`api/v7/account/department-users/${departmentId ?? ""}`, { signal });
  return response.json();
};

export const updateDepartmentById = async (departmentId: string, department: Department): Promise<{ ok: boolean }> => {
  const response: Response = await appFetch(`api/a1/admin/department/${departmentId}`, {
    method: "POST",
    body: JSON.stringify(department),
  });
  return response.json();
};

export const updateDepartmentLoginDisclaimer = async (departmentId: string, loginDisclaimer: WebDisclaimer): Promise<{ ok: boolean }> => {
  const response: Response = await appFetch(`api/a1/admin/department/login-disclaimer/${departmentId}`, {
    method: "POST",
    body: JSON.stringify(loginDisclaimer),
  });
  return response.json();
};

export const getDepartmentById = async (departmentId: string | undefined, signal?: AbortSignal): Promise<Department> => {
  const response: Response = await appFetch(`api/a1/admin/department/${departmentId ?? ""}`, { signal });
  return response.json();
};

export const getDepartmentLicensingDetail = async (departmentId: string | undefined, signal: AbortSignal): Promise<LicensingListRecord[]> => {
  const response: Response = await appFetch(`api/a1/admin/licensing-detail/${departmentId}`, { signal });

  return response.json();
};

export const getDepartmentLicensingList = async (signal: AbortSignal): Promise<LicensingListRecord[]> => {
  const response: Response = await appFetch("api/a1/admin/licensing-list", { signal });

  return response.json();
};

export const getUserById = async (userId: string, signal: AbortSignal): Promise<UserDepartment> => {
  const response: Response = await appFetch(`api/a1/admin/user/${userId}`, { signal });
  return response.json();
};

export const getSessionsUser = async (userId: string, signal: AbortSignal): Promise<SessionWithOnline[]> => {
  const response: Response = await appFetch(`api/v7/session/${userId}`, { signal });
  return response.json();
};

export const updateUserData = async (userId: string | undefined, departmentId: string | undefined, user: UserDepartment) => {
  const response: Response = await appFetch(`api/a1/admin/user/${userId}/${departmentId}`, {
    method: "POST",
    body: JSON.stringify(user),
  });
  return response.json();
};

export const deleteUser = async (userId: string | undefined) => {
  const response: Response = await appFetch(`api/a1/admin/remove/${userId}`, {
    method: "POST",
  });
  return response.json();
};

export const clearRateLimit = async (userId: string | undefined) => {
  const response: Response = await appFetch(`api/a1/admin/clear-rate-limit/${userId}`, {
    method: "POST",
  });

  return response.json();
};

export const changePassword = async (item: PasswordReset | ChangeUserPassword): Promise<{
  ok: boolean,
  error?: string | null,
  user?: UserDepartment | null,
}> => {
  const response = await appFetch("api/v7/account/change-password", {
    method: "POST",
    body: JSON.stringify(item),
  });
  return response.json();
};

export const updateMapStatus = async (userId: string, status: boolean, deviceType?: string) => {
  const response: Response = deviceType ? await appFetch(`api/a1/admin/toggle_map_status/${userId}/${status}`, {
    method: "POST",
    body: JSON.stringify({ device_type: deviceType }),
  }) : await appFetch(`api/a1/admin/toggle_map_status/${userId}/${status}`, {
    method: "POST",
  });
  return response.json();
};

export const resetSignUpKey = async () => {
  const response: Response = await appFetch("api/v7/account/reset-signup-key", {
    method: "POST",
  });
  return response.json();
};

export const updateSignUpDomains = async (domains: string[]) => {
  const response = await appFetch("api/v7/account/update-signup-domains", {
    method: "POST",
    body: JSON.stringify(domains),
  });

  return response.json();
};

export const swapVehicles = async (departmentId: string | undefined, signal?: AbortSignal): Promise<SwapVehicle[]> => {
  const query = !departmentId ? "" : `?departmentId=${departmentId}`;
  const response = await appFetch(`api/v2/swap-vehicle${query}`, !signal ? undefined : { signal });
  return response.json();
};

export const swapVehicle = async (item: SwapVehicleBody) => {
  const response: Response = await appFetch("api/v2/swap-vehicle", {
    method: "POST",
    body: JSON.stringify(item),
  });
  return response.json();
};

export const verifyUser = async (email: string): Promise<VerifiedUser> => {
  const response: Response = await appFetch(`api/a1/admin/rate-limit/${email}`);
  return response.json();
};

export const getUsersList = async (departmentId: string) => {
  const response: Response = await appFetch(`api/v7/department/export-users/${departmentId}`);
  return response.text();
};

export const exportActiveSessions = async (departmentId?: string) => {
  const itemId = departmentId ?? "";
  const response: Response = await appFetch(`api/v7/department/export-active-sessions/${itemId}`);
  return response.text();
};

export const getUsersRegistrationsData = async (signal?: AbortSignal): Promise<string> => {
  const response = await appFetch("api/v6/user-registration/export", { signal });
  return response.text();
};

export const departmentInvite = async <T>(data: T): Promise<DepartmentInvite> => {
  const response: Response = await appFetch("api/v7/department/invite", {
    method: "POST",
    body: JSON.stringify(data),
  });
  return response.json();
};

export const updateSafetyPriorityKeyword = async (priority: number, keywords: string[]) => {
  const object = {
    priority,
    keywords,
  };

  const response: Response = await appFetch("api/v7/account/update-safety-priority-keywords", {
    method: "POST",
    body: JSON.stringify(object),
  });

  return response.json();
};

export const updateZonehavenLayer = async (visible: boolean, fadeZoomLevel: number) => {
  const object = {
    visible,
    fadeZoomLevel,
  };

  const response: Response = await appFetch("api/v7/account/update-zonehaven-layer", {
    method: "POST",
    body: JSON.stringify(object),
  });

  return response.json();
};

export const updateManagedIncidentPriority = async (item: ManagedIncidentPriority) => {
  const response: Response = await appFetch("api/v7/account/update-managed-incident-priority", {
    method: "POST",
    body: JSON.stringify(item),
  });

  return response.json();
};

export const updateManagedIncidentMode = async (item: ManagedIncidentMode) => {
  const response: Response = await appFetch("api/v7/account/update-managed-incident-mode", {
    method: "POST",
    body: JSON.stringify(item),
  });

  return response.json();
};
