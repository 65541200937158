import React, { createContext, useState } from "react";
import { Department } from "@models/department";

export const MessagesContext = createContext<[Department | null, React.Dispatch<Department>]>([null!, () => null!]);

export function MessagesContextProvider(props: { children: JSX.Element }) {
  const [department, setDepartment] = useState<Department | null>(null!);

  return (
    <MessagesContext.Provider value={[department, setDepartment]}>
      {props.children}
    </MessagesContext.Provider>
  );
}
