import { FormatListBulletedRounded } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import classes from "./cad-status.module.css";
import { CADVehicleOption } from "@models/cad-vehicles-status";
import { CadStatus } from "@models/cad-statuses";
import { getLocaleDistanceFromMeters } from "@utils/utilsFunctions";

export enum CADVehicleStatusOptionKeys {
  DistanceTraveled = "distanceTraveled",
  Destination = "premiseCode"
}

interface OptionInfoProps {
  options?: CADVehicleOption[];
  restricted: boolean;
  cadStatusDetail: CadStatus;

}

function OptionInfo({ options, restricted, cadStatusDetail }: OptionInfoProps) {
  return (
    <Box display="flex" alignItems="center" gap={1} className={`${classes.status_group}`}>
      <FormatListBulletedRounded className={`${classes?.status_group_icon}`} />
      <Box>
        {restricted
          && options?.map((option) => {
            const cadStatus = cadStatusDetail?.options?.find(
              (op) => op.cadKey === option.key,
            );
            return (
              <Typography key={option.key + option.name + option.value} className={`${classes.status_group_text}`}>
                {option.key === CADVehicleStatusOptionKeys.Destination ? option.name : cadStatus?.name}
                :
                {" "}
                {option.key === CADVehicleStatusOptionKeys.DistanceTraveled
                  ? (getLocaleDistanceFromMeters(option.value, false, true) + " miles")
                  : option.value}
              </Typography>
            );
          })}
      </Box>
    </Box>
  );
}

export default OptionInfo;
