import { History, TCIncident } from "@models/tc-incident";
import classes from "./managed-incidents-list.module.css";
import { Button } from "@mui/material";
import moment from "moment";
import { getHistoryType } from "@utils/tc-incidents-helpers";
import { useCallback, useMemo, useState } from "react";
import { getWordPriorityColor, parseRegexForKeyword } from "@utils/utilsFunctions";
import { SafetyPriorityKeyword } from "@models/department";
import { CADComment, CADIncident } from "@models/cad";
import { CommentOpt, renderLineBreaks } from "../cad-incident-details/cad-incident-details";
import { CustomMaterialTable } from "@components/custom-material-table";
import IncidentTableHeaderWithToggle from "../incident-table-header-with-toggle";
import { strDateFormat } from "@components/incident-details-shared-to";

enum ListType {
  Priority = "priority",
  History = "history",
  Comments = "comments",
}

type PriorityCommentsType = {
  priority: number;
  word: string;
  color: string;
  filtered: History[];
}

function CommentDisplay({
  cadIncident,
  priorityKeywords,
}: {
  cadIncident?: CADIncident,
  priorityKeywords: SafetyPriorityKeyword[],
}) {
  const [expand, setExpand] = useState(true);

  return (
    <CustomMaterialTable<CADComment>
      hideContent={!expand}
      data={(cadIncident?.Comment ?? []).sort((a, b) => (b.CommentUnixTime ?? 0) - (a.CommentUnixTime ?? 0))}
      title={<IncidentTableHeaderWithToggle title="Comments" expand={expand} setExpand={setExpand} />}
      cellAlign="left"
      header={
        [{
          name: "Modified",
          prop: "",
          render: (item) => (strDateFormat(item.CommentDateTime)),
          cellColors: (item) => ({ backgroundColor: getWordPriorityColor(priorityKeywords, item.Comment).background, color: getWordPriorityColor(priorityKeywords, item.Comment).text }),
        },
        {
          name: "Source",
          prop: "CommentSource",
          cellWidth: 100,
          render: (item) => item.CommentSource.replaceAll(".com", ""), // Might need to extend this
          cellColors: (item) => ({ backgroundColor: getWordPriorityColor(priorityKeywords, item.Comment).background, color: getWordPriorityColor(priorityKeywords, item.Comment).text }),
        },
        {
          name: "Message",
          prop: "message",
          cellWidth: 150,
          render: (item) => (
            <span style={{ fontSize: 14 }}>
              {renderLineBreaks(item.Comment)}
              {CommentOpt(item)}
            </span>
          ),
          cellColors: (item) => ({ backgroundColor: getWordPriorityColor(priorityKeywords, item.Comment).background, color: getWordPriorityColor(priorityKeywords, item.Comment).text }),
        },
        ]
      }
    />
  );
}

function HistoryDisplay({
  incident,
  priorityKeywords,
}: {
  incident: TCIncident
  priorityKeywords: SafetyPriorityKeyword[],
}) {
  const [expand, setExpand] = useState(true);
  const sortedIncidentHistory = useMemo(() => {
    if (incident.history) {
      return incident.history.sort((a, b) => {
        const time1 = b.time ?? 0;
        const time2 = a.time ?? 0;
        return time1 - time2;
      });
    }
    return [];
  }, [incident]);

  return (
    <CustomMaterialTable<History>
      hideContent={!expand}
      data={sortedIncidentHistory}
      title={<IncidentTableHeaderWithToggle title="History" expand={expand} setExpand={setExpand} />}
      cellAlign="left"
      header={
        [{
          name: "Modified",
          prop: "",
          render: (item) => (
            <div>
              <div>{moment(item.time * 1000).format("HH:mm:ss")}</div>
              <div style={{ fontSize: 10 }}>{moment(item.time * 1000).format("MM/DD/YYYY")}</div>
            </div>
          ),
          cellColors: (item) => ({ backgroundColor: getWordPriorityColor(priorityKeywords, item.message).background, color: getWordPriorityColor(priorityKeywords, item.message).text }),
        },
        {
          name: "Type",
          prop: "",
          render: (item) => (
            <span>{getHistoryType(item)}</span>
          ),
          cellColors: (item) => ({ backgroundColor: getWordPriorityColor(priorityKeywords, item.message).background, color: getWordPriorityColor(priorityKeywords, item.message).text }),
        },
        {
          name: "Message",
          prop: "message",
          cellWidth: 200,
          cellColors: (item) => ({ backgroundColor: getWordPriorityColor(priorityKeywords, item.message).background, color: getWordPriorityColor(priorityKeywords, item.message).text }),
        },
        ]
      }
    />
  );
}

function PriorityDisplay({
  priorityComments,
}: {
  priorityComments: PriorityCommentsType[],
}) {
  const [expand, setExpand] = useState(true);
  const parsedData = priorityComments.flatMap((kind) => kind.filtered.map((item) => ({ ...item, color: kind.color })));
  return (
    <>
      <CustomMaterialTable<{
        color: string;
        entity_id: number;
        entity_type: number;
        message: string;
        time: number;
        user: string;
      }>
        hideContent={!expand}
        data={parsedData}
        title={<IncidentTableHeaderWithToggle title="Priority" expand={expand} setExpand={setExpand} />}
        cellAlign="left"
        header={
          [{
            name: "Modified",
            prop: "",
            render: (item) => (
              <div>
                <div>{moment(item.time * 1000).format("HH:mm:ss")}</div>
                <div style={{ fontSize: 10 }}>{moment(item.time * 1000).format("MM/DD/YYYY")}</div>
              </div>
            ),
            cellColors: (item) => ({ backgroundColor: `#${item.color}`, color: "white" }),
          },
          {
            name: "Type",
            prop: "",
            render: (item) => (
              <span>{getHistoryType(item)}</span>
            ),
            cellColors: (item) => ({ backgroundColor: `#${item.color}`, color: "white" }),
          },
          {
            name: "Message",
            prop: "message",
            cellWidth: 250,
            cellColors: (item) => ({ backgroundColor: `#${item.color}`, color: "white" }),
          },
          ]
        }
      />
    </>
  );
}

export function TCIncidentHistory({ incident, priorityKeywords, cadIncident }: { incident: TCIncident, priorityKeywords: SafetyPriorityKeyword[], cadIncident?: CADIncident }) {
  const [listType, setListType] = useState<ListType>(ListType.History);
  const { priorityComments } = useMemo(() => {
    let priorityComments: PriorityCommentsType[] = [];
    if (listType === ListType.Priority) {
      priorityComments = (priorityKeywords ?? []).map((el) => el.keywords.map((keyword) => {
        const filteredMessages = (incident?.history ?? []).filter((el) => !!el.message?.toLocaleLowerCase().match(parseRegexForKeyword(keyword))?.length);
        return {
          priority: el.priority,
          word: keyword,
          color: el.hexColor,
          // TODO: Extend the regex check function to also accept History type | create separate one for that
          filtered: filteredMessages,
        };
      })).flat();
    }

    return { priorityComments };
  }, [priorityKeywords, incident, listType]);

  const displayList = useCallback(() => {
    switch (listType) {
      case ListType.Comments:
        return <CommentDisplay cadIncident={cadIncident} priorityKeywords={priorityKeywords} />;
      case ListType.Priority:
        return <PriorityDisplay priorityComments={priorityComments} />;
      case ListType.History:
      default:
        return <HistoryDisplay incident={incident} priorityKeywords={priorityKeywords} />;
    }
  }, [listType, incident, cadIncident, priorityComments]);

  return (
    <div style={{ marginBottom: 20 }}>
      <div className={classes.buttons_wrapper}>
        <Button onClick={() => setListType(ListType.Priority)} className={listType === ListType.Priority ? classes.active : ""}>Priority</Button>
        <Button onClick={() => setListType(ListType.History)} className={listType === ListType.History ? classes.active : ""}>History</Button>
        <Button onClick={() => setListType(ListType.Comments)} className={listType === ListType.Comments ? classes.active : ""}>CAD</Button>
      </div>
      {displayList()}
    </div>
  );
}
