import { IncidentQuery } from "@models/cad";
import {
  AdvancedSearchProps, Incident, SearchedIncident, SearchPaginationProps,
} from "@models/incident";
import { apiUrl, appFetch } from "./config";

export const getIncidentsByQuery = async (
  p: IncidentQuery,
  signal?: AbortSignal,
): Promise<Incident[]> => {
  const response: Response = await appFetch(`api/a1/incident/composite?${(Object.keys(p) as Array<keyof IncidentQuery>)
    .reduce((acc, key) => `${acc}${key}=${p[key]}&`, "")
    .slice(0, -1)}`, !signal ? undefined : { signal });
  return response.json();
};

export const getActiveIncidents = async (limit: number, skip: number, date?: string, direction?: string, signal?: AbortSignal): Promise<Incident[]> => (date && direction && date !== "" && direction !== "" ? await getIncidentsByQuery({
  active: true,
  skip,
  limit,
  date,
  direction,
}, signal) : await getIncidentsByQuery({
  active: true,
  skip,
  limit,
}, signal));

export const getInactiveIncidents = async (limit: number, skip: number, date?: string, direction?: string, signal?: AbortSignal): Promise<Incident[]> => (date && direction && date !== "" && direction !== "" ? await getIncidentsByQuery({
  active: false,
  skip,
  limit,
  date,
  direction,
}, signal) : await getIncidentsByQuery({
  active: false,
  skip,
  limit,
}, signal));

export const getAllActiveIncidents = async (skip?: number, limit?: number, signal?: AbortSignal): Promise<Incident[]> => await getIncidentsByQuery({
  active: true,
  skip,
  limit,
}, signal);

export const getAllInactiveIncidents = async (signal?: AbortSignal): Promise<Incident[]> => await getIncidentsByQuery({
  active: false,
}, signal);

export const getSearchedIncidents = async (searchString: string, paginationProps: SearchPaginationProps, advancedSearch?: AdvancedSearchProps): Promise<SearchedIncident[]> => {
  const url = apiUrl("api/a1/incident/search");
  if (searchString.length) {
    url.searchParams.append("searchTerm", searchString);
  } else if (advancedSearch) {
    Object.entries(advancedSearch).forEach(([k, value]) => {
      if (value.length) {
        url.searchParams.append(k, value);
      }
    });
  }
  Object.entries(paginationProps).forEach(([k, value]) => {
    if (value) {
      url.searchParams.append(k, value);
    }
  });
  const response: Response = await appFetch(url);
  return response.json();
};
