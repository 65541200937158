/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useEffect, useState } from "react";
import { urls, useRouting } from "@utils/routing";

export enum Role {
  Admin,
  SuperAdmin,
  User,
  AgencyAdmin,
  SuperUserReadOnly
}

export type SavedUser = {
  username: string;
  email: string;
  token: string;
  departmentId: string;
  role: Role;
  managedAgencies: string[];
  userId: string;
  agencyId: string; // empty if superuser
  cadSimulatorAccess: boolean;
  isPro: boolean;
  canCreateShareIncidentCode: boolean;
};

export const LoginContext = createContext<
  [SavedUser | null, React.Dispatch<SavedUser | null>]
>([null, () => null]);

export function LoginContextProvider(props: { children: JSX.Element }) {
  const { routeTo } = useRouting();
  const [user, setUser] = useState<SavedUser | null>(() => {
    const userInfo = localStorage.getItem("user");

    if (userInfo !== null) {
      return JSON.parse(userInfo);
    }
    return null;
  });

  useEffect(() => {
    const updateLocalStorage = () => {
      const userInfo = localStorage.getItem("user");
      if (userInfo !== null) {
        const userData: SavedUser = JSON.parse(userInfo);
        setUser(userData);
        if (userData.role === Role.SuperAdmin) {
          routeTo(urls.dashboardFullPath);
        } else if (userData.role === Role.SuperUserReadOnly) {
          routeTo(urls.accountsList);
        } else {
          routeTo(urls.adminDashboardFullPath);
        }
      } else {
        setUser(null);
      }
    };

    window.addEventListener("storage", updateLocalStorage);

    return () => window.removeEventListener("storage", updateLocalStorage);
  }, []);

  return (
    <LoginContext.Provider value={[user, setUser]}>
      {props.children}
    </LoginContext.Provider>
  );
}

interface CurrentUser {
  superuser?: boolean
  superUserReadOnly?: boolean
  admin?: boolean
  managedAgencies?: string[]
}

// eslint-disable-next-line arrow-body-style
export const roleIsAnyAdmin = (role?: Role): boolean => {
  return role === Role.Admin || role === Role.AgencyAdmin || role === Role.SuperAdmin;
};

// eslint-disable-next-line arrow-body-style
export const roleIsAdmin = (role?: Role): boolean => {
  return role === Role.Admin;
};

// eslint-disable-next-line arrow-body-style
export const roleIsAccountAdmin = (role?: Role): boolean => {
  return role === Role.Admin || role === Role.AgencyAdmin;
};

export const userCanEdit = (user?: SavedUser | null, agencyId?: string, resourceUserId?: string): boolean => {
  if (!user) {
    return false;
  }

  let canEdit = false;
  switch (user.role) {
    case Role.SuperAdmin: // Fallback
    case Role.Admin:
      canEdit = true;
      break;
    case Role.SuperUserReadOnly:
      canEdit = false;
      break;

    case Role.AgencyAdmin:
      if (agencyId && agencyId !== "") {
        canEdit = user.managedAgencies.indexOf(agencyId) >= 0;
      }
      break;

    case Role.User:
      canEdit = resourceUserId !== undefined && resourceUserId !== "" && resourceUserId === user.userId;
      break;
    default:
      break;
  }

  return canEdit;
};

export function getUserRole(user: CurrentUser): Role {
  if (user.superUserReadOnly) {
    return Role.SuperUserReadOnly;
  }
  if (user.superuser) {
    return Role.SuperAdmin;
  }
  if (user.admin) {
    return Role.Admin;
  }
  if ((user.managedAgencies ?? []).length > 0) {
    return Role.AgencyAdmin;
  }
  return Role.User;
}
