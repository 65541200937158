import { TimedOutTextField } from "../TimedOutTextField";
import {
  useCallback, useMemo, useRef, useState,
} from "react";
import classes from "./incidents-table-advanced-search.module.css";
import { Clear, Search, Tune } from "@mui/icons-material";
import {
  Grid,
  IconButton, Popover, Typography,
} from "@mui/material";
import { TabletButton } from "../tablet-button/custom-button";
import { IncidentsTableAdvancedSearchInput } from "./incidents-table-advanced-search-input";
import { AdvancedSearchProps, SearchedIncident } from "@models/incident";
import { getSearchedIncidents } from "@services/incident";
import { useEffectAsync } from "@hooks/utils";
import { IncidentAdvancedSearchPagination } from "./incidents-table-advanced-search-pagination";
import { useHistoryState } from "@hooks/useHistoryState";
import JoinIncidentPopup from "@components/JoinIncidentPopup";

interface IncidentsTableSearchProps {
  loading: boolean
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
  setIncidents: React.Dispatch<React.SetStateAction<SearchedIncident[] | null>>
}

const advancedSearchDefault: AdvancedSearchProps = {
  incidentNumber: "",
  callType: "",
  unitId: "",
  address: "",
};

const incidentsPerPage = 15;

const historyStateKey = "cad-incidents-search";

export function IncidentsTableSearchHeader({
  loading, setLoading, setIncidents,
}: IncidentsTableSearchProps) {
  const [history, setHistory] = useHistoryState<AdvancedSearchProps & { search: string }>(historyStateKey && (`${historyStateKey}-basic-and-advanced`), { ...advancedSearchDefault, search: "" });

  const [search, setSearch] = useState(() => history.search);
  const [initialSearch, setInitialSearch] = useState(true);
  const [externalSearch, setExternalSearch] = useState(false);
  const [open, setOpen] = useState<null | HTMLElement>(null);
  const ref = useRef(null);
  const [page, setPage] = useState(1);
  const [pagesNumber, setPagesNumber] = useState<number>(0);
  const [navigationTokens, setNavigationTokens] = useState<{ prevToken: string, nextToken: string }>({ nextToken: "", prevToken: "" });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [advancedSearch, setAdvancedSearch] = useState<AdvancedSearchProps>(() => { const { search, ...rest } = history; return rest; });
  const advancedSearchIsUsed = useMemo(() => Object.values(advancedSearch).some((value) => !!value.trim().length), [advancedSearch]);
  const handleSearchInit = async (direction?: "ASC" | "DESC", paginationItemReference?: string) => {
    setLoading(true);
    const defaultSearch = advancedSearchIsUsed ? "" : search;
    // IF search is done by textfield advanced search is empty thus the function does not get impacted
    const results = await getSearchedIncidents(defaultSearch, { limit: incidentsPerPage, direction, paginationItemReference }, advancedSearch);

    if (results?.length) {
      setIncidents(results);
      setPagesNumber(results[0].meta.count.total ?? 0);
      setNavigationTokens({ nextToken: results[results.length - 1].paginationToken, prevToken: results[0].paginationToken });
    } else {
      setIncidents(null);
      setPagesNumber(0);
    }

    setLoading(false);
  };

  const handleNext = useCallback(async () => {
    await handleSearchInit("ASC", navigationTokens.nextToken);
    setPage(page + 1);
  }, [navigationTokens, page]);
  const handlePrev = useCallback(async () => {
    await handleSearchInit("DESC", navigationTokens.prevToken);
    setPage(page - 1);
  }, [navigationTokens, page]);

  useEffectAsync(async () => {
    if (initialSearch) {
      if (advancedSearchIsUsed) {
        setExternalSearch(true);
        await handleSearchInit();
        const searchString = Object.values(advancedSearch).filter((value) => !!value);
        setSearch(searchString.toString().replace(",", " "));
      } else if (search) {
        await handleSearchInit();
      }
    }
    setInitialSearch(false);
  }, []);

  useEffectAsync(async () => {
    if (!externalSearch && !initialSearch) { // we don't want this to trigger from advanced search or at first search
      if (search) {
        await handleSearchInit();
      } else {
        setIncidents(null);
        setPagesNumber(0);
      }
      setPage(1);
    }
  }, [search]);

  const handleSearchClick = async () => {
    if (advancedSearchIsUsed) {
      setExternalSearch(true);
      await handleSearchInit();
      // Make textfield reflect what was searched
      const searchString = Object.values(advancedSearch).filter((value) => !!value);
      setSearch(searchString.toString().replace(",", " "));
      setPage(1);
      setOpen(null);
    }
  };

  const clearIncidents = () => {
    setAdvancedSearch(advancedSearchDefault);
    setSearch("");
    setIncidents(null);
    setPagesNumber(0);
    setExternalSearch(false);
    setHistory(({ ...advancedSearchDefault, search: "" }));
  };

  const handleClose = () => {
    setExternalSearch(advancedSearchIsUsed);
    setOpen(null);
  };

  const fieldSetAdvancedSearch = (fieldKey: keyof AdvancedSearchProps, value: string) => {
    setAdvancedSearch({ ...advancedSearch, [fieldKey]: value });
    setHistory(({ ...history, [fieldKey]: value }));
  };

  const openMenu = () => {
    setOpen(ref.current);
  };

  return (
    <div>
      <div className={classes.advanced_search_wrapper}>
        <div className={classes.advanced_search_bar} ref={ref}>
          {search.length || advancedSearchIsUsed
            ? <IconButton onClick={() => clearIncidents()}><Clear /></IconButton>
            : <IconButton onClick={() => { }} style={{ pointerEvents: "none" }}><Search /></IconButton>}
          <TimedOutTextField
            timeout={1000}
            autoCorrect="off"
            fullWidth
            InputProps={{ disableUnderline: true }}
            variant="standard"
            placeholder={advancedSearchIsUsed ? "Advanced Search has values" : "Search Incident"}
            value={search}
            disabled={externalSearch}
            onChange={(event) => { setSearch(event.target.value); setHistory(({ ...history, search: event.target.value })); }}
          />
          <IconButton onClick={openMenu}><Tune /></IconButton>
          <JoinIncidentPopup />
        </div>
        <Popover
          open={!!open}
          anchorEl={open}
          onClose={() => setOpen(null)}
        >
          <div className={classes.advanced_search_menu}>
            <Typography variant="h6">Advanced Incident Search</Typography>
            <Grid className={classes.advanced_search_menu_inputs} container>
              {Object.entries(advancedSearch).map(([key, value]) => (
                <IncidentsTableAdvancedSearchInput
                  key={`input-${key}`}
                  fieldKey={key as keyof AdvancedSearchProps}
                  value={value}
                  fieldSetAdvancedSearch={fieldSetAdvancedSearch}
                />
              ))}
            </Grid>
            <div className={classes.advanced_search_menu_actions}>
              <TabletButton onClick={handleClose}>Close</TabletButton>
              <TabletButton style={{ backgroundColor: !advancedSearchIsUsed ? "lightgrey" : "#7a849a" }} disabled={!advancedSearchIsUsed} onClick={handleSearchClick}>Search</TabletButton>
            </div>
          </div>
        </Popover>
      </div>
      <div>
        {!!pagesNumber
          && (
            <IncidentAdvancedSearchPagination
              incidentLimitPerPage={incidentsPerPage}
              loading={loading}
              totalIncidents={pagesNumber}
              page={page}
              handleNext={handleNext}
              handlePrev={handlePrev}
            />
          )}
      </div>
    </div>
  );
}
