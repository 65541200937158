import {
  Box,
  Grid,
  IconButton,
  Modal,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

import {
  useContext, useEffect, useRef, useState,
} from "react";
import { useParams } from "react-router-dom";

import { getCADIncidentById, getIncidentNotifiedLog } from "@services/cad";
import { getDepartmentById } from "@services/department";

import classes from "./cad.module.css";
import IncidentEvents from "@components/incident-details-events";
import {
  canStopShare,
  strDateFormat,
} from "@components/incident-details-shared-to";

import { useDataWithoutLoading } from "@hooks/useDataWithoutLoading";
import { LoginContext, Role, roleIsAccountAdmin } from "@contexts/login-context";
import { useInterval } from "@hooks/useInterval";
import { useTitle } from "@hooks/useTitle";

import { CADComment, CADIncident, IncidentNotifiedLog as IncidentNotifiedLogType } from "@models/cad";
import { SafetyPriorityKeyword } from "@models/department";
import { getWordPriorityColor } from "@utils/utilsFunctions";

import { Fields } from "./cad-incident-details-fields";
import { IncidentMap } from "./cad-incident-details-map";
import IncidentChanges from "./cad-incident-details-changes";
import { UnitsTable } from "./cad-incident-details-unit-table";
import PriorIncidentsTable from "./cad-incident-details-prior-incident";
import { useSharedAVLUnits } from "@hooks/shared-avl-units/useSharedAVLUnits";
import { CADIncidentPDFDocument } from "./cad-incident-pdf/cad-incident-pdf-document";
import { pdf } from "@react-pdf/renderer";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { MapDetails } from "@models/arcgis";
import IncidentNotifiedLog from "@components/incident-notified-log";
import IncidentTableHeaderWithToggle from "../incident-table-header-with-toggle";
import { CADIncidentSharedTo } from "./cad-incident-details-sharedTo";
import { CustomMaterialTable } from "@components/custom-material-table";
import { useAVLUnits } from "@hooks/avl-units/useAVLUnits";
import { useQuery } from "@tanstack/react-query";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
  backgroundColor: "lightgrey",
};

export function renderLineBreaks(text: string) {
  return text?.split(/\n/).map((line, index) => {
    if (index === 0) {
      return <span key={line}>{line}</span>;
    }
    return <div key={line}>{line}</div>;
  });
}

export function CommentOpt(comment: CADComment | undefined) {
  if (!comment || !comment?.CommentOpts || !comment?.CommentOpts?.type || !comment?.CommentOpts?.item) {
    return null;
  }

  if (comment?.CommentOpts?.type === "ack" && comment?.CommentOpts?.item !== "") {
    const ackItem = comment?.CommentOpts?.item;
    return (
      <div title={`Acknowledge request "${ackItem}"`} style={{ display: "inline-block" }}>
        &nbsp;
        <div
          style={{
            backgroundColor: "red",
            color: "white",
            display: "inline-block",
            paddingLeft: "5px",
            paddingRight: "5px",
          }}
        >
          {`ACK ${ackItem}`}
        </div>
        &nbsp;
      </div>
    );
  }

  return null;
}

export function Comments({ cadIncident, priorityKeywords }: { cadIncident: CADIncident, priorityKeywords: SafetyPriorityKeyword[] }) {
  const [expand, setExpand] = useState(true);
  return (
    <div className={`${classes.cad_comments}`}>

      <CustomMaterialTable<CADComment>
        hideContent={!expand}
        data={cadIncident.Comment?.sort((a, b) => (b.CommentUnixTime ?? 0) - (a.CommentUnixTime ?? 0)) ?? []}
        title={<IncidentTableHeaderWithToggle title="Comments" expand={expand} setExpand={setExpand} />}
        cellAlign="left"
        header={
          [{
            name: "Modified",
            prop: "",
            render: (item) => (
              <Typography variant="body2">{strDateFormat(item.CommentDateTime)}</Typography>

            ),
            cellColors: (item) => ({ backgroundColor: getWordPriorityColor(priorityKeywords, item.Comment).background, color: getWordPriorityColor(priorityKeywords, item.Comment).text }),
          },
          {
            name: "Source",
            prop: "CommentSource",
            cellColors: (item) => ({ backgroundColor: getWordPriorityColor(priorityKeywords, item.Comment).background, color: getWordPriorityColor(priorityKeywords, item.Comment).text }),
          },
          {
            name: "Comments",
            prop: "",
            cellWidth: 500,
            render: (item) => (
              <div
                style={{ backgroundColor: getWordPriorityColor(priorityKeywords, item.Comment).background, color: getWordPriorityColor(priorityKeywords, item.Comment).text }}
              >
                {renderLineBreaks(item.Comment)}
                {CommentOpt(item)}
              </div>
            ),
            cellColors: (item) => ({ backgroundColor: getWordPriorityColor(priorityKeywords, item.Comment).background, color: getWordPriorityColor(priorityKeywords, item.Comment).text }),
          },
          ]
        }
      />
    </div>
  );
}

export default function CADIncidentDetails() {
  useTitle("CAD Incident Details");
  const { incidentNumber } = useParams<{ incidentNumber: string }>();
  const [locationStaleMinutes, setLocationStaleMinutes] = useState(0);
  const [user] = useContext(LoginContext);
  const { data: cadIncident, reload } = useDataWithoutLoading((as) => getCADIncidentById(incidentNumber!, as));
  const mapView = useRef<string>("");
  const [pdfModal, setPDFModal] = useState(false);
  const [pdfSource, setPDFSource] = useState<string | null>(null);
  const small = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const [isExpired, setIsExpired] = useState<boolean>(false);

  const [shareIncidentEnabled, setShareIncidentEnabled] = useState<boolean>(false);
  const [canCreateShareIncidentCode, setCanCreateShareIncidentCode] = useState<boolean>(false);
  const [allowedIgnoreAge, setAllowedIgnoreAge] = useState<number>(21);

  const [mapDetails, setMapDetails] = useState<MapDetails>({
    diagonal: 0, latitude: 0, longitude: 0, zoom: 14,
  });

  const {
    data: departmentData,
  } = useQuery({
    queryKey: ["user-department", user?.departmentId],
    retry: false,
    refetchInterval: 20000,
    enabled: !!user?.departmentId,
    queryFn: () => getDepartmentById(user?.departmentId),
    placeholderData: (prev) => prev,
  });

  const { isSharedAVLEnabled, sharedAVLLocations } = useSharedAVLUnits("cad-details", mapDetails, departmentData?.shareAVL?.fadeZoomLevel);
  const { avlUnitsLocations } = useAVLUnits(locationStaleMinutes, true, mapDetails);

  const isAdmin = roleIsAccountAdmin(user?.role);

  const fetchMapDetails = (props: MapDetails) => {
    setMapDetails((prev) => {
      if (props.latitude !== prev.latitude || props.longitude !== prev.longitude) {
        return props;
      }
      return prev;
    });
  };

  useEffect(() => {
    if (departmentData) {
      setLocationStaleMinutes(departmentData.locationStaleMinutes / 60);
      setAllowedIgnoreAge(departmentData.cadAllowIgnoreAfterDays ?? 21);
      setShareIncidentEnabled(departmentData.shareIncident?.enabled ?? false);
    }
  }, [departmentData]);

  useEffect(() => {
    const userCanShareCode = (user && user.canCreateShareIncidentCode === true) ?? false;
    const cadIncidentCanShareCode = (cadIncident && cadIncident.sharedSource && cadIncident.sharedSource.isExternal === false) ?? false;
    setCanCreateShareIncidentCode(userCanShareCode && cadIncidentCanShareCode);
  }, [cadIncident]);

  useInterval(async () => {
    if (cadIncident?.closed_unix_date === 0) {
      await reload();
    }
    setIsExpired(!canStopShare(new Date(), cadIncident?.sharedSource?.expireAt));
  }, [locationStaleMinutes, cadIncident?.closed_unix_date, isExpired], { reloadInterval: 7000 });

  if (!cadIncident || !departmentData) {
    return <div />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={6}>
        <div className={`${classes.cad_incident_detail_header}`}>
          {isExpired ? (
            <p className={`${classes.cad_incident_expired}`}>Incident no longer available</p>
          ) : (
            <>
              <p className={`${classes.cad_incident_detail_title}`}>Incident: </p>
              <span className={`${classes.cad_incident_detail_label}`}>
                {cadIncident.IncidentNumberDisplay}
              </span>
              {cadIncident.ignored ? (
                <span className={`${classes.cad_incident_detail_blacklist_label}`}>
                  Ignored
                </span>
              ) : null}
            </>
          )}
          <div style={{ marginLeft: "auto" }}>
            <IconButton
              className={classes.cad_incident_detail_pdf_button}
              disabled={!mapView.current.length}
              onClick={async () => {
                if (mapView.current.length) {
                  try {
                    setPDFModal(!small);
                    let incidentNotification: IncidentNotifiedLogType[] = [];
                    if (isAdmin) {
                      incidentNotification = await getIncidentNotifiedLog(incidentNumber ?? "-");
                    }
                    const blob = await pdf(<CADIncidentPDFDocument incidentNotification={incidentNotification} priorityKeywords={departmentData.safetyPriorityKeywords} incident={cadIncident} imageUrl={mapView.current ?? ""} />).toBlob();
                    if (!small) {
                      setPDFSource(URL.createObjectURL(blob));
                    } else {
                      const downloadAnchor = document.createElement("a");
                      downloadAnchor.href = URL.createObjectURL(blob);
                      downloadAnchor.download = `${cadIncident.IncidentNumber}.pdf`;
                      document.body.appendChild(downloadAnchor);
                      downloadAnchor.click();
                      document.body.removeChild(downloadAnchor);
                    }
                  } catch (error) {
                    // eslint-disable-next-line no-console
                    console.log("PDF error generation error", error);
                  }
                }
              }}
            >
              {mapView.current ? "Generate" : "Loading"}
              <PictureAsPdfIcon style={{ marginLeft: 5 }} />
            </IconButton>
          </div>
        </div>
        <Fields
          allowedIgnoreAge={allowedIgnoreAge}
          cadIncident={cadIncident}
          permissions={{
            isAdmin,
            canCreateShareIncidentCode,
            shareIncidentEnabled,
          }}
          onChange={reload}
        />
        {
          isExpired ? null : (
            <>
              <UnitsTable cadIncident={cadIncident} />
              <Comments cadIncident={cadIncident} priorityKeywords={departmentData.safetyPriorityKeywords} />
              <PriorIncidentsTable priorIncidents={cadIncident.PriorIncident} />
            </>
          )
        }
      </Grid>
      <Grid item xs={12} lg={6}>
        {isExpired ? null : (
          <IncidentMap
            cadIncident={cadIncident}
            unitsLocation={avlUnitsLocations}
            sharedAVLMarkers={isSharedAVLEnabled ? sharedAVLLocations : []}
            sharedAVLButton={departmentData.shareAVL?.enabled}
            refetchData={fetchMapDetails}
            mapViewRef={mapView}
          />

        )}
        <CADIncidentSharedTo incident={cadIncident} departmentName={departmentData.department} incidentId={cadIncident.id} isAdmin={isAdmin} reload={reload} />
        {user?.role === Role.Admin && <IncidentChanges changes={cadIncident.changes} />}
        {user?.role === Role.Admin && <IncidentEvents events={cadIncident.events ?? []} />}

        {isExpired ? null : (user?.role === Role.Admin && (
          <div className={`${classes.cad_changes}`}>
            <IncidentNotifiedLog
              incidentNumber={cadIncident?.IncidentNumber ?? "-"}
              isAdmin={isAdmin}
            />
          </div>
        )
        )}
      </Grid>
      <Modal open={pdfModal} id="pdf-iframe-modal" onClose={() => { setPDFModal(false); setPDFSource(null); }}>
        <Box sx={style}>
          <div style={{ width: 900, height: 700, backgroundColor: "white" }}>
            {pdfSource ? <iframe title="PDF Iframe Container" id="pdf-iframe-container" width={900} height={700} key={pdfSource} src={pdfSource} /> : <Typography>Loading PDF...</Typography>}
          </div>
        </Box>
      </Modal>
    </Grid>
  );
}
