import { History } from "@models/tc-incident";

export const getMessageType = (history: History): string => {
  // Get these from IncidentHistory.h
  let type = "";
  switch (history.entity_type) {
    case 1:
      type = "Assignment";
      break;

    case 2:
      type = "Battalion";
      break;

    case 3:
      type = "Checklist";
      break;

    case 4:
      type = "Checklist Item";
      break;

    case 5:
      type = "Group/Division";
      break;

    case 6:
      type = "Hazard";
      break;

    case 7:
      type = "Incident";
      break;

    case 8:
      type = "Incident Checklist";
      break;

    case 9:
      type = "Incident Resource";
      break;

    case 10:
      type = "Specialty";
      break;

    case 11:
      type = "Unit";
      break;

    case 12:
      type = "Unit2Specialty";
      break;

    case 13:
      type = "Note";
      break;

    case 14:
      type = "CAD";
      break;

    case 15:
      type = "Preview CAD";
      break;

    case 16:
      type = "Template";
      break;

    case 17:
      type = "Takeover";
      break;

    case 18:
      type = "User Comment";
      break;

    case 19:
      type = "Incident Created";
      break;

    case 20:
      type = "Stopwatch";
      break;

    case 21:
      type = "PAR Unit";
      break;

    case 22:
      type = "PAR Complete";
      break;

    case 23:
      type = "Unit Propagated";
      break;

    case 24:
      type = "Columns Position Changed";
      break;

    case 25:
      type = "Incident Extended";
      break;

    default:
      type = "Unknown";
      break;
  }

  return type;
};

export const getHistoryType = (history: History): string => getMessageType(history);
