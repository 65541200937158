import classes from "./cad.module.css";
import moment from "moment";

import {
  Personnel,
  IncidentUnit,
  CADIncident,
} from "@models/cad";

import { CustomMaterialTable } from "@components/custom-material-table";
import { useState } from "react";
import IncidentTableHeaderWithToggle from "../incident-table-header-with-toggle";

export function renderTimeColumn<T>(field: keyof T) {
  return {
    prop: field,
    render(item: T) {
      const { [field]: value } = item;
      return (value && typeof value === "string" && value !== ""
        ? (
          <div>
            <div>{moment(value).format("HH:mm:ss")}</div>
            <div style={{ fontSize: 10 }}>{moment(value).format("MM/DD/YYYY")}</div>
          </div>
        )
        : "");
    },
  };
}
export function PersonnelDisplay({ el }: { el: Personnel }) {
  return (
    <p
      key={el.PersonnelName}
      style={{
        marginTop: 0, marginBottom: 0, fontSize: 12, minWidth: 120,
      }}
    >
      {el.PersonnelRank && el.PersonnelRank !== null && el.PersonnelRank !== "" ? `${el.PersonnelRank} - ${el.PersonnelName}` : el.PersonnelName}
    </p>
  );
}

export function UnitIdDisplay({ unit }: { unit: IncidentUnit }) {
  const title = `${unit.UnitID ?? ""} (${unit.UnitDispatchNumber ?? ""}) - ${unit.uuid ?? ""}`;
  let backgroundColor: string = "rgb(93, 93, 93)";
  let text = "white";
  if (!unit.active || (unit.TimeCleared !== undefined && unit.TimeCleared !== "")) {
    backgroundColor = "#ddd";
    text = unit.color?.text as string;
  } else if (unit.color) {
    backgroundColor = unit.color?.background;
    text = unit.color?.text;
  }

  return (
    <div>
      <p
        style={{
          backgroundColor,
          color: text,
          margin: 0,
          borderRadius: 6,
          width: "fit-content",
          paddingRight: 10,
          paddingLeft: 10,
        }}
        title={title}
      >
        {unit?.UnitID}
      </p>
    </div>
  );
}

export function UnitsTable({ cadIncident }: { cadIncident: CADIncident }) {
  const [expand, setExpand] = useState(true);
  return (
    <div className={`${classes.cad_units}`}>
      <CustomMaterialTable<IncidentUnit>
        cellClassName={`${classes.cellWidth} ${classes.alignTop}`}
        data={cadIncident?.units ?? []}
        hideContent={!expand}
        title={<IncidentTableHeaderWithToggle title="Units" expand={expand} setExpand={setExpand} />}
        header={[
          {
            name: "Unit",
            prop: "UnitID",
            render: (item) => <UnitIdDisplay unit={item} />,
          },
          {
            name: "DSP",
            ...renderTimeColumn("TimeDispatched"),
          },
          {
            name: "RSP",
            ...renderTimeColumn("TimeEnroute"),
          },
          {
            name: "STG",
            ...renderTimeColumn("TimeStaged"),
          },
          {
            name: "ONS",
            ...renderTimeColumn("TimeArrived"),
          },
          {
            name: "TR",
            ...renderTimeColumn("TimeTransporting"),
          },
          {
            name: "AH",
            ...renderTimeColumn("TimeAtHospital"),
          },
          {
            name: "AVL",
            ...renderTimeColumn("TimeCleared"),
          },
          {
            name: "Personnel",
            prop: "Personnel",
            cellWidth: 120,
            render: (item) => (item.Personnel ?? []).map((el) => <PersonnelDisplay key={el.PersonnelID} el={el} />),
          },
        ]}
      />
    </div>
  );
}
