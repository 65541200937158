import React, { CSSProperties } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { AutocompleteRenderGroupParams } from "@mui/material/Autocomplete";

// TODO: Refactor this if possible to use the props of autocomplete so we don't manually add them
export function SingleSelect<T>(props: {
  autoFocus?: boolean;
  initialValue?: T;
  options: T[];
  value?: T;
  error?: boolean;
  required?: boolean;
  disableCloseOnSelect?: boolean;
  getOptionDisabled?: ((option: T) => boolean) | undefined;
  disabled?: boolean;
  getOptionSelected: (option: T, value: T) => boolean;
  renderOption: (option: T, options?: T[]) => string;
  onChange: (value: T | null) => void;
  disableClearable?: boolean;
  renderGroup?: (option: AutocompleteRenderGroupParams) => React.ReactNode;
  groupBy?: (option: T) => string;
  variant?: "standard" | "filled" | "outlined"
  style?: CSSProperties
  inputPropsStyles?: CSSProperties
  autocompleteInputPropsStyles?: CSSProperties
  className?: string
}) {
  const {
    required,
    onChange,
    autoFocus,
    value,
    options,
    initialValue,
    renderOption,
    disableCloseOnSelect,
    disabled,
    getOptionSelected,
    groupBy,
    renderGroup,
    disableClearable,
    error,
    variant,
    style,
    autocompleteInputPropsStyles,
    inputPropsStyles,
    className,
    getOptionDisabled,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [option, setOption] = React.useState(options);
  const [fieldValue, setFieldValue] = React.useState<T | null>(
    initialValue ?? null,
  );

  React.useEffect(() => {
    setFieldValue(initialValue ?? null);
    setOption(options);
  }, [options]);

  React.useEffect(() => {
    setFieldValue(value ?? null);
  }, [value]);

  return (
    <Autocomplete<T>
      groupBy={groupBy}
      renderGroup={renderGroup}
      data-testid="single-select"
      className={`${error && "autocompleteError"}`}
      disabled={disabled}
      fullWidth
      getOptionDisabled={getOptionDisabled}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(change, val) => {
        setFieldValue(val);
        onChange(val);
      }}
      disableCloseOnSelect={disableCloseOnSelect}
      value={fieldValue}
      disableClearable={disableClearable as any}
      isOptionEqualToValue={getOptionSelected}
      getOptionLabel={(option) => renderOption(option, options)}
      options={option}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          fullWidth
          // error={true}
          style={style ?? {}}
          className={className ?? "select-component-textfield"}
          required={required ?? false}
          autoFocus={autoFocus ?? false}
          autoComplete="off"
          variant={variant ? variant : "standard"}
          inputProps={{
            ...params.inputProps,
            "data-testid": "single-select-textfield",
            style: {
              ...inputPropsStyles,
            },
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          InputProps={{
            style: {
              color: renderOption(fieldValue as T, options)?.includes("VEHICLE DOES NOT EXIST FROM CAD") ? "red" : "black",
              ...autocompleteInputPropsStyles,
            },
            ...params.InputProps,
            endAdornment: (
              <>{params.InputProps.endAdornment}</>
            ),
          }}
        />
      )}
    />
  );
}
