import React, {
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

interface LoadingContextData {
  currentMessage: string | undefined,
  promises: Array<{
    promise: Promise<any>,
    message: string
  }>
}

const LoadingContext = React.createContext<LoadingService>(null!);
interface LoadingService extends ReturnType<typeof useCreateLoadingProviderService> {
}

export function useCreateLoadingProviderService() {
  const [loadingData, setLoadingData] = useState<LoadingContextData>({
    currentMessage: undefined,
    promises: [],
  });

  const loading = useCallback(<T, >(message: string, promise: Promise<T>) => {
    async function queuePromise(promise: Promise<any>) {
      try {
        await promise;
      } catch (e) {
        //
      }
      setLoadingData((ld) => {
        const [, ...rest] = ld.promises;
        const [next] = rest;
        if (next) {
          queuePromise(next?.promise);
        }
        return {
          currentMessage: next?.message,
          promises: rest,
        };
      });
    }

    setLoadingData((ld) => {
      if (!ld.currentMessage) {
        queuePromise(promise);
      }
      return {
        currentMessage: ld.currentMessage ?? message,
        promises: [
          ...ld.promises, {
            message,
            promise,
          },
        ],
      };
    });
    return promise;
  }, [setLoadingData]);

  const service = useMemo(() => ({
    currentMessage: loadingData.currentMessage,
    loading,
    withLoading<A extends any[], R>(message: string, fn: (...args: A) => Promise<R>): (...args: A) => Promise<R> {
      return (...args: A) => loading(message, fn(...args));
    },
  }), [loadingData.currentMessage, loading]);
  return service;
}

export function LoadingProvider(p: React.PropsWithChildren<{}>) {
  const service = useCreateLoadingProviderService();
  const { children } = p;
  return (
    <LoadingContext.Provider value={service}>
      {children}
    </LoadingContext.Provider>
  );
}

export function useLoading() {
  const { loading, withLoading } = useContext(LoadingContext);
  return { loading, withLoading };
}

export function useLoadingMessage() {
  const { currentMessage } = useContext(LoadingContext);
  return currentMessage;
}
