import { UnitLocation } from "@models/location";
import { appFetch } from "./config";

export const getUnitsLocation = async (hours: number, signal: AbortSignal): Promise<UnitLocation[]> => {
  const response: Response = await appFetch(`api/a1/location/units?hours=${hours}`, { signal });
  return response.json();
};

export const getUnitsLocationAdmin = async (hours: number, signal: AbortSignal): Promise<UnitLocation[]> => {
  const response: Response = await appFetch(`api/a1/location/units?hours=${hours}&showHidden=true`, { signal });
  return response.json();
};

export const getSharedAVLLocationAdmin = async (latitude: number, longitude: number, diagonal: number, page: string, signal: AbortSignal): Promise<UnitLocation[]> => {
  const response: Response = await appFetch(`api/a1/location/units-shared?showHidden=true&latitude=${latitude}&longitude=${longitude}&diagonal=${diagonal}&src=${page}`, { signal });
  if (!response.ok) {
    throw new Error("Error fetching shared AVL.");
  }
  return response.json();
};

export const getFocusedUnitLocations = async (latitude: number, longitude: number, diagonal: number, hours: number, signal: AbortSignal): Promise<UnitLocation[]> => {
  let url = `api/a1/location/units-focused?hours=${hours}&showHidden=true`;
  if (diagonal) {
    url = url.concat(`&latitude=${latitude}&longitude=${longitude}&diagonal=${diagonal}`);
  }
  const response: Response = await appFetch(url, { signal });
  if (!response.ok) {
    throw new Error("Error fetching units.");
  }
  return response.json();
};

export const deleteLocation = async (location: UnitLocation, id: string) => {
  const response: Response = await appFetch(`api/a1/location/${id}`, {
    method: "POST",
    body: JSON.stringify(location),
  });
  return response.text();
};
