import { SignUp, UserDepartment } from "@models/department";
import { apiUrl, appFetch } from "./config";

export const addNewUserAccount = async <T>(newUser: T): Promise<UserDepartment> => {
  const response: Response = await appFetch("api/v7/account/register", {
    method: "POST",
    body: JSON.stringify(newUser),
  });
  return response.json();
};

export const userSignupWithKey = async (newUser: SignUp, signupKey?: string): Promise<SignUp> => {
  const response: Response = await appFetch(`api/v7/account/register?signupKey=${signupKey}`, {
    method: "POST",
    body: JSON.stringify(newUser),
  });
  return response.json();
};

export const updateMobileWebSecurity = async (id: string) => {
  const response: Response = await appFetch(`api/a1/admin/update-department-features/mobile-web-security/${id}`, {
    method: "POST",
  });
  return response.json();
};

export const updateRTS = async (id: string, value: boolean) => {
  const response: Response = await appFetch(`api/a1/admin/toggle-feature/${id}`, {
    method: "POST",
    body: JSON.stringify({
      feature: "rts",
      value,
    }),
  });
  return response.json();
};

export const checkEmailAvailable = async (email: string, userId?: string): Promise<{ ok: boolean }> => {
  const urlUserId = (userId && userId !== "") ? userId : "0";
  const response = await appFetch(`api/v7/account/validate-email/${urlUserId}/${email}`);
  return response.json();
};

export const checkEmailAvailableWithSignupKey = async (email: string, signupKey?: string): Promise<{ ok: boolean }> => {
  // userId=0 for signupKey
  const response = await appFetch(`api/v7/account/validate-email/0/${email}?signupKey=${signupKey}`);
  return response.json();
};

export const getGeoJSONUrl = (filename: string): string => apiUrl(`/esri/tc-file/rest/services/${filename}/FeatureServer`).toString();

export const propagateFireMapperChanges = async (departmentId: string) => {
  const response: Response = await appFetch(`api/a1/incident/propagate-firemapper-changes/${departmentId}`, {
    method: "POST",
  });
  return response.json();
};
