/* eslint-disable camelcase */
import moment from "moment";
import { CustomMaterialTable } from "@components/custom-material-table";
import { CADIncident, IncidentUnit } from "@models/cad";
import { ModifiedUnit } from "@models/incident";
import { TCIncident, Units } from "@models/tc-incident";
import { getDiffInMinutes, getUnitStatusAbbreviation } from "@utils/utilsFunctions";
import { PersonnelDisplay, renderTimeColumn, UnitIdDisplay } from "../cad-incident-details/cad-incident-details-unit-table";
import classes from "./managed-incidents-list.module.css";
import { useState, useMemo } from "react";
import { Button } from "@mui/material";
import IncidentTableHeaderWithToggle from "../incident-table-header-with-toggle";

function UnitsTable(props: {
  type: string
  incident: TCIncident
  cadIncident?: CADIncident
}) {
  const { type, incident, cadIncident } = props;
  const [expand, setExpand] = useState(true);
  const personnelTotalNumber = (units?: Units[]) => {
    let personnel = 0;

    if (units) {
      units?.forEach((unit) => {
        const unitPersonnel = unit.personnelOnScene ?? 0;
        personnel += unitPersonnel;
      });

      return personnel;
    }
    incident?.units?.forEach((unit) => {
      const unitPersonnel = unit.personnelOnScene ?? 0;
      personnel += unitPersonnel;
    });

    return personnel;
  };

  const units = incident?.groups?.map((el) => ({
    title: el.name ?? "",
    note: el.note ?? "",
    unitsCount: el.units?.length ?? 0,
    personnelCount: personnelTotalNumber(el.units),
    units: el.units ?? [],
  })) as ModifiedUnit[];

  return (
    type === "assignments" ? (
      <CustomMaterialTable<Units>
        cellClassName={classes.cellWidth}
        hideContent={!expand}
        data={incident.units ?? []}
        title={<IncidentTableHeaderWithToggle title="Units" expand={expand} setExpand={setExpand} />}
        cellAlign="center"
        customHeader={(
          <div>
            <span style={{ paddingRight: 4 }}>Units</span>
            <span className={classes.custom_header_label}>{incident.units?.length}</span>
            <span style={{ paddingRight: 4 }}>Personnel</span>
            <span className={classes.custom_header_label}>{personnelTotalNumber()}</span>
          </div>
        )}
        groups={
          [{
            title: "Unassigned",
            note: "",
            unitsCount: incident.units?.filter((el) => el.column_position === 0).length ?? 0,
            personnelCount: personnelTotalNumber(incident.units?.filter((el) => el.column_position === 0)),
            units: incident.units?.filter((el) => el.column_position === 0) ?? [],
          }].concat(units)
        }
        header={
          [
            {
              name: "Unit",
              prop: "UnitID",
            },
            {
              name: "Alarm Level",
              prop: "AlarmAtDispatch",
            },
            {
              name: "Status",
              prop: "status",
              render: (item) => (
                <div>
                  <div>{getUnitStatusAbbreviation(item.status ?? "")}</div>
                  {item.time && item.time !== "" ? <div style={{ fontSize: 12 }}>{moment(item.time).format("HH:mm:ss")}</div> : <div />}
                </div>
              ),
            },
            {
              name: "Task",
              prop: "checked",
              render: (item) => (item.assignment
                ? <div>{item.assignment.name}</div> : <div />
              ),
            },
            {
              name: "Time",
              prop: "time",

              render: (item) => {
                const { air_time } = item;
                return (air_time && air_time !== ""
                  ? (
                    <div style={{
                      display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center",
                    }}
                    >
                      <div>
                        {moment(air_time).format("HH:mm:ss")}
                      </div>
                      <div style={{
                        height: 15,
                        width: 15,
                        backgroundColor: air_time && air_time !== ""
                          ? (getDiffInMinutes(air_time) >= 0 && getDiffInMinutes(air_time) <= 10
                            ? "#009900" : getDiffInMinutes(air_time) > 10 && getDiffInMinutes(air_time) <= 15
                              ? "#fbe52e" : "red") : "red",
                        borderRadius: "30px",
                      }}
                      />
                    </div>
                  ) : ""
                );
              },
            },
            {
              name: "Personnel",
              prop: "personnelOnScene",
              render: (item) => (
                <div style={{
                  display: "flex", flexDirection: "row", alignItems: "center", verticalAlign: "middle", minWidth: 120,
                }}
                >
                  <div style={{ paddingRight: 7 }}>{item.personnelOnScene}</div>
                  <div>
                    {(item.Personnel ?? []).map((el) => <PersonnelDisplay key={el.PersonnelID} el={el} />)}
                  </div>
                </div>
              ),
            },
          ]
        }
      />
    ) : (
      <CustomMaterialTable<IncidentUnit>
        cellClassName={classes.cellWidth}
        data={cadIncident?.units ?? []}
        hideContent={!expand}
        title={<IncidentTableHeaderWithToggle title="Units" expand={expand} setExpand={setExpand} />}
        header={[
          {
            name: "Unit",
            prop: "UnitID",
            render: (item) => <UnitIdDisplay unit={item} />,
          },
          {
            name: "DSP",
            ...renderTimeColumn("TimeDispatched"),
          },
          {
            name: "RSP",
            ...renderTimeColumn("TimeEnroute"),
          },
          {
            name: "STG",
            ...renderTimeColumn("TimeStaged"),
          },
          {
            name: "ONS",
            ...renderTimeColumn("TimeArrived"),
          },
          {
            name: "TR",
            ...renderTimeColumn("TimeTransporting"),
          },
          {
            name: "AH",
            ...renderTimeColumn("TimeAtHospital"),
          },
          {
            name: "AVL",
            ...renderTimeColumn("TimeCleared"),
          },
          {
            name: "Personnel",
            prop: "Personnel",
            cellWidth: 120,
            render: (item) => (item.Personnel ?? []).map((el) => <PersonnelDisplay key={el.PersonnelID} el={el} />),
          },
        ]}
      />
    )
  );
}

export function UnitsTableContainer({ managedIncident, cadIncident }: { managedIncident: TCIncident, cadIncident?: CADIncident }) {
  const [tableType, setTableType] = useState("assignments");
  const unitsTable = useMemo(() => {
    if (managedIncident) {
      return <UnitsTable type={tableType} incident={managedIncident} cadIncident={cadIncident} />;
    }
    return <></>;
  }, [managedIncident, tableType, cadIncident]);
  return (
    <>
      <div className={classes.buttons_wrapper}>
        <Button onClick={() => setTableType("assignments")} className={tableType === "assignments" ? classes.active : ""}>Assignments</Button>
        <Button onClick={() => setTableType("times")} className={tableType === "times" ? classes.active : ""}>Times</Button>
      </div>
      {unitsTable}
    </>
  );
}
