/* eslint-disable react/jsx-props-no-spreading */
import {
  IconButton, IconButtonProps,
} from "@mui/material";
import TVIcon from "@mui/icons-material/Tv";
import { useNavigate } from "react-router-dom";
import { getURL, urls } from "@utils/routing";

export function TVViewButton({ incidentNumber, ...iconButtonProps }: IconButtonProps & { incidentNumber: string, }) {
  const navigate = useNavigate();
  const handleTVViewButton = (incidentNumber: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    event.stopPropagation();
    navigate(`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminManagedIncidentLargeScreenView, { incidentNumber })}`);
  };
  return (
    <IconButton
      {...iconButtonProps}
      className={`${iconButtonProps.className ?? ""} incident_card_button_tv`}
      onClick={(event) => handleTVViewButton(incidentNumber, event)}
    >
      <TVIcon style={{ marginRight: "5px", width: "20px", height: "20px " }} />
      TV View
    </IconButton>
  );
}
