import MapView from "@arcgis/core/views/MapView";
import { useEffect, useRef } from "react";
import { WatchTypeValue } from "@models/arcgis";

export function useWatch<T extends keyof WatchTypeValue>(view: MapView, name: T, zoomToIncident: boolean, callback: (eventValue: WatchTypeValue[T]) => void) {
  const timeout = useRef<NodeJS.Timeout>(null!);

  useEffect(() => {
    if (!view) {
      return;
    }
    const handler = view.watch(name, (eventValue) => {
      if (timeout.current) {
        clearTimeout(timeout.current);
      }

      timeout.current = setTimeout(() => {
        timeout.current = null!;
        if (eventValue !== null && !zoomToIncident) {
          callback(eventValue);
        }
      }, 500);
    });

    // eslint-disable-next-line consistent-return
    return () => {
      handler.remove();
    };
  }, [view, name, callback, zoomToIncident]);
}
