/* eslint-disable prefer-const */
import Graphic from "@arcgis/core/Graphic";
import MapView from "@arcgis/core/views/MapView";
import { useEffect, useRef } from "react";
import { GraphicOptions } from "@models/arcgis";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer.js";
import { useEffectAsync } from "@hooks/utils";

export function useGraphics(
  view: MapView,
  incidentsGraphics: Set<GraphicOptions>,
  avlUnitsGraphics: Set<GraphicOptions>,
  sharedAVLGraphics: Set<GraphicOptions>,
  hazardGraphics: Set<GraphicOptions>,
  callerGraphics: Set<GraphicOptions>,
  incidentGroupsGraphics: Set<GraphicOptions>,
  unassignedUnitsGraphics: Set<GraphicOptions>,
  unmappedDeviceGraphics: Set<GraphicOptions>,
) {
  // INFO: ORDER MATTERS FOR RENDERING
  const { current: incidentSpecificsLayer } = useRef(new GraphicsLayer({ id: "incident-specifics-layer", listMode: "hide" }));
  const { current: sharedAVLUnitsLayer } = useRef(new GraphicsLayer({ id: "shared-avl-units-layer", listMode: "hide" }));
  const { current: incidentsLayer } = useRef(new GraphicsLayer({ id: "incidents-layer", listMode: "hide" }));
  const { current: avlUnitsLayer } = useRef(new GraphicsLayer({ id: "avl-units-layer", listMode: "hide" }));

  useEffectAsync(
    async () => {
      if (view.map) {
        // HACK: Load layer only when the view is ready, this way we are sure that our layer will top the ones from client maps.
        // eslint-disable-next-line no-console
        await view.when(() => view.map.add(incidentSpecificsLayer), () => console.log("Error loading incident specifics layer"));
        // eslint-disable-next-line no-console
        await view.when(() => view.map.add(incidentsLayer), () => console.log("Error loading incidents layer"));
        // eslint-disable-next-line no-console
        await view.when(() => view.map.add(sharedAVLUnitsLayer), () => console.log("Error loading shared avl units layer"));
        // eslint-disable-next-line no-console
        await view.when(() => view.map.add(avlUnitsLayer), () => console.log("Error loading avl units layer"));
      }
    },
    [view.map],
    () => {
      if (view.map) {
        view.map.remove(incidentSpecificsLayer);
        view.map.remove(incidentsLayer);
        view.map.remove(sharedAVLUnitsLayer);
        view.map.remove(avlUnitsLayer);
      }
    },
  );

  useEffect(() => {
    const arrayFromUnits = [
      ...hazardGraphics,
      ...callerGraphics,
      ...incidentGroupsGraphics,
      ...unassignedUnitsGraphics,
      ...unmappedDeviceGraphics,
    ];

    if (!view || !incidentSpecificsLayer || !arrayFromUnits.length) {
      return;
    }
    const existingGraphicsIds = incidentSpecificsLayer.graphics.map((graphic: Graphic & { id?: string }) => graphic.id);
    arrayFromUnits.forEach((unit) => {
      const g = new Graphic(unit);
      const existingGraphic = incidentSpecificsLayer.graphics.find((graphic: Graphic & { id?: string }) => graphic.id === unit.id);
      if (existingGraphic) {
        existingGraphic.geometry = g.geometry;
        existingGraphic.symbol = g.symbol;
        existingGraphic.popupTemplate = g.popupTemplate;
      } else {
        incidentSpecificsLayer.add(g);
      }

      // Secondary graphics
      // Somewhat surprised that these items are not removed
      // even though they're not found by the code below that calls `.remove()`
      if (unit.secondaryGraphic) {
        // unit.secondaryGraphic
        const existingSecondary = incidentSpecificsLayer.graphics.find((graphic: Graphic & { id?: string }) => graphic.id === unit.id + "-2nd");
        if (existingSecondary) {
          existingSecondary.geometry = unit.secondaryGraphic?.geometry;
          existingSecondary.symbol = unit.secondaryGraphic?.symbol;
        } else {
          incidentSpecificsLayer.add(unit.secondaryGraphic);
        }
      }
    });
    existingGraphicsIds.forEach((existingId) => {
      if (!arrayFromUnits.find((unit) => unit.id === existingId)) {
        const graphicToRemove = incidentSpecificsLayer.graphics.find((graphic: Graphic & { id?: string }) => graphic.id === existingId);
        incidentSpecificsLayer.remove(graphicToRemove);
        graphicToRemove.destroy();
      }
    });
  }, [
    hazardGraphics,
    callerGraphics,
    incidentGroupsGraphics,
    unassignedUnitsGraphics,
    unmappedDeviceGraphics,
    incidentSpecificsLayer,
  ]);

  useEffect(() => {
    if (!sharedAVLGraphics.size && sharedAVLUnitsLayer.graphics.length) {
      sharedAVLUnitsLayer.graphics.removeAll();
      return;
    }
    if (!view || !sharedAVLGraphics.size || !sharedAVLUnitsLayer) {
      return;
    }
    const arrayFromUnits = Array.from(sharedAVLGraphics);
    const existingGraphicsIds = sharedAVLUnitsLayer.graphics.map((graphic: Graphic & { id?: string }) => graphic.id);

    sharedAVLGraphics.forEach((unit) => {
      const g = new Graphic(unit);
      const existingGraphic = sharedAVLUnitsLayer.graphics.find((graphic: Graphic & { id?: string }) => graphic.id === unit.id);
      if (existingGraphic) {
        existingGraphic.geometry = g.geometry;
        existingGraphic.symbol = g.symbol;
        existingGraphic.popupTemplate = g.popupTemplate;
      } else {
        sharedAVLUnitsLayer.add(g);
      }
    });
    existingGraphicsIds.forEach((existingId) => {
      if (!arrayFromUnits.find((unit) => unit.id === existingId)) {
        const graphicToRemove = sharedAVLUnitsLayer.graphics.find((graphic: Graphic & { id?: string }) => graphic.id === existingId);
        sharedAVLUnitsLayer.remove(graphicToRemove);
        graphicToRemove.destroy();
      }
    });
  }, [sharedAVLGraphics, sharedAVLUnitsLayer]);

  useEffect(() => {
    if (!view || !incidentsGraphics.size || !incidentsLayer) {
      incidentsLayer.graphics.removeAll();
      return;
    }
    const arrayFromIncidents = Array.from(incidentsGraphics);
    const existingGraphicsIds = incidentsLayer.graphics.map((graphic: Graphic & { id?: string }) => graphic.id);

    incidentsGraphics.forEach((incident) => {
      const g = new Graphic(incident);
      const existingGraphic = incidentsLayer.graphics.find((graphic: Graphic & { id?: string }) => graphic.id === incident.id);
      if (existingGraphic) {
        existingGraphic.geometry = g.geometry;
        existingGraphic.symbol = g.symbol;
        existingGraphic.popupTemplate = g.popupTemplate;
      } else {
        incidentsLayer.add(g);
      }
    });
    existingGraphicsIds.forEach((existingId) => {
      if (!arrayFromIncidents.find((incident) => incident.id === existingId)) {
        const graphicToRemove = incidentsLayer.graphics.find((graphic: Graphic & { id?: string }) => graphic.id === existingId);
        incidentsLayer.remove(graphicToRemove);
        graphicToRemove.destroy();
      }
    });
  }, [incidentsGraphics, incidentsLayer]);

  useEffect(() => {
    if (!view || !avlUnitsGraphics.size || !avlUnitsLayer) {
      avlUnitsLayer.graphics.removeAll();
      return;
    }
    const arrayFromUnits = Array.from(avlUnitsGraphics);
    const existingGraphicsIds = avlUnitsLayer.graphics.map((graphic: Graphic & { id?: string }) => graphic.id);

    avlUnitsGraphics.forEach((unit) => {
      const g = new Graphic({ ...unit });
      const existingGraphic = avlUnitsLayer.graphics.find((graphic: Graphic & { id?: string }) => graphic.id === unit.id);
      if (existingGraphic) {
        existingGraphic.geometry = g.geometry;
        existingGraphic.symbol = g.symbol;
        existingGraphic.popupTemplate = g.popupTemplate;
      } else {
        avlUnitsLayer.add(g);
      }
    });
    existingGraphicsIds.forEach((existingId) => {
      if (!arrayFromUnits.find((unit) => unit.id === existingId)) {
        const graphicToRemove = avlUnitsLayer.graphics.find((graphic: Graphic & { id?: string }) => graphic.id === existingId);
        avlUnitsLayer.remove(graphicToRemove);
        graphicToRemove.destroy();
      }
    });
  }, [avlUnitsGraphics, avlUnitsLayer]);
}
