/* eslint-disable prefer-const */
import { Grid } from "@mui/material";
import { Form, Formik, FormikHelpers } from "formik";
import moment from "moment";
import { SimulatorIncident } from "@models/cad";
import { useLoading } from "@hooks/useLoading";
import { useTitle } from "@hooks/useTitle";
import { getKeys } from "@utils/utilsFunctions";
import { CadSimulatorComments } from "./cad-simulator-comments";
import { CadSimulatorIncidentDetails } from "./cad-simulator-incident-details";
import { CadSimulatorUnits } from "./cad-simulator-units";
import {
  postSimulatorIncident,
} from "@services/cad";
import { useContext, useState } from "react";
import { LoginContext } from "@contexts/login-context";

export function CadSimulator() {
  useTitle("CAD Simulator");
  const { withLoading } = useLoading();
  const [resetData, setResetData] = useState(false);
  const [user] = useContext(LoginContext);
  const [submitFormCount, setSubmitFormCount] = useState(0);

  const onSubmit = withLoading(
    "loading...",
    async (obj: SimulatorIncident, onSubmitProps: FormikHelpers<SimulatorIncident>) => {
      onSubmitProps.resetForm({
        values: submitFormCount > 0 ? obj : { ...obj, EntryDateTime: moment(new Date()).format() },
      });
      const { units, ...payload } = obj;
      await postSimulatorIncident({ ...payload, Unit: units });
      onSubmitProps.setSubmitting(false);
      setSubmitFormCount(submitFormCount + 1);
    },
  );

  const onReset = (obj: SimulatorIncident) => {
    obj?.units?.splice(0, obj.units?.length);
    obj?.Comment?.splice(0, obj.Comment?.length);
    setResetData(!resetData);
  };

  const entryDate = moment(new Date()).format();
  let closeDate = moment(entryDate).add(4, "m").format();
  let [initialValues] = useState<SimulatorIncident>({
    IncidentNumber: "",
    EntryDateTime: entryDate,
    ClosedDateTime: "",
    expiration_date: closeDate,
    AgencyID: "WEBTEST",
    CADSimulator: user?.email ?? "",
    TransactionID: new Date().getTime(),
    closeIn: 3,
    StreetNumber: "",
    StreetName: "",
    CallerNumber: "",
    CommandChannel: "",
    Suite: "",
    CityOrLocality: "",
    StateOrProvince: "",
    CommonPlaceName: "",
    cross_streets: "",
    AgencyIncidentCallTypeDescription: "",
    Latitude: "",
    Longitude: "",
    FireMap: "",
    MapPages: "",
    TacticalChannel: "",
    units: [],
    Comment: [],
    notify: false,
    rts: true,
    simulation: true,
    callers: [],
  });

  const keys = getKeys<SimulatorIncident>({
    IncidentNumber: 1,
    EntryDateTime: 1,
    ClosedDateTime: 1,
    expiration_date: 1,
    CallerNumber: 1,
    AgencyID: 1,
    TransactionID: 1,
    CommonPlaceName: 1,
    CommandChannel: 1,
    FireMap: 1,
    CityOrLocality: 1,
    cross_streets: 1,
    MapPages: 1,
    AgencyIncidentCallTypeDescription: 1,
    CADSimulator: 1,
    Latitude: 1,
    Suite: 1,
    Longitude: 1,
    StateOrProvince: 1,
    StreetName: 1,
    StreetNumber: 1,
    TacticalChannel: 1,
    closeIn: 1,
    units: 1,
    Comment: 1,
    notify: 1,
    rts: 1,
    simulation: 1,
    simulationId: 1,
    callers: 1,
    simulationSequences: 0,
  });

  return (
    <div>
      <Formik<SimulatorIncident>
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          values, handleChange, setFieldValue, resetForm, dirty, submitForm, submitCount, isSubmitting,
        }) => (
          <Form>
            <Grid container spacing={3} style={{ width: "100%", margin: 0 }}>
              <CadSimulatorIncidentDetails
                isSubmitting={resetData}
                closeDate={closeDate}
                dirty={dirty}
                values={values}
                keys={keys}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                formSubmitCount={submitCount}
                handleResetForm={async () => {
                  // eslint-disable-next-line no-alert
                  const confirmed = window.confirm("Are you sure want to close this incident ?");
                  if (confirmed) {
                    values.ClosedDateTime = moment(new Date()).format();
                    const { units, ...payload } = values;
                    await postSimulatorIncident({ ...payload, Unit: units });
                    resetForm({
                      values: {
                        ...initialValues,
                        EntryDateTime: moment(new Date()).format(),
                      },
                    });
                    onReset(initialValues);
                  }
                }}
              />
              <CadSimulatorComments comments={values.Comment ?? []} isDataCleared={resetData} submitForm={submitForm} />
              <CadSimulatorUnits units={values.units} isSubmitting={isSubmitting} isDataCleared={resetData} submitForm={submitForm} keys={keys} setFieldValue={setFieldValue} />
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
}
