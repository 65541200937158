import { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getURL, urls } from "@utils/routing";
import { CADIncidentsTab, IncidentsTab, ManagedIncidentsTab } from "@models/routing";
import { matchURL } from "@utils/utilsFunctions";

export const TablesPageContext = createContext<[number | null, React.Dispatch<number>]>([null!, () => null!]);

export function TablesPageContextProvider(props: { children: JSX.Element }) {
  const [page, setPage] = useState(1);
  const location = useLocation();

  useEffect(() => {
    if (!matchURL(location.pathname, [
      getURL(urls.adminCADIncidentDetails, { incidentNumber: "" }),
      getURL(urls.adminCADIncidents, { tab: CADIncidentsTab.Active }),
      getURL(urls.adminCADIncidents, { tab: CADIncidentsTab.Inactive }),
      getURL(urls.adminManagedIncidentsList, { tab: ManagedIncidentsTab.Inactive }),
      getURL(urls.adminManagedIncidentsList, { tab: ManagedIncidentsTab.Active }),
      getURL(urls.adminManagedIncidentDetails, { incidentNumber: "" }),
      getURL(urls.adminIncidentDetails, { incidentNumber: "", incidentId: "" }),
      getURL(urls.adminIncidentsList, { tab: IncidentsTab.Active }),
      getURL(urls.adminIncidentsList, { tab: IncidentsTab.Inactive }),
    ])) {
      setPage(1);
    }
  }, [location.pathname]);

  return (
    <TablesPageContext.Provider value={[page, setPage]}>
      {props.children}
    </TablesPageContext.Provider>
  );
}
