import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PersonIcon from "@mui/icons-material/Person";
import FastForwardIcon from "@mui/icons-material/FastForward";
import classes from "./managed-incidents-list.module.css";
import { TCIncident, Transfers } from "@models/tc-incident";
import moment from "moment";
import { useState } from "react";
import IncidentTableHeaderWithToggle from "../incident-table-header-with-toggle";
import { CustomMaterialTable } from "@components/custom-material-table";

export function TCIncidentTransfers({ incident }: {
  incident: TCIncident
}) {
  const [expand, setExpand] = useState(true);
  return (
    <div className={classes.transfers}>

      <CustomMaterialTable<Transfers>
        cellClassName={classes.cellWidth}
        hideContent={!expand}
        data={incident.transfers ?? []}
        title={<IncidentTableHeaderWithToggle title="Transfers" expand={expand} setExpand={setExpand} />}
        cellAlign="left"
        header={
          [{
            name: "Owner",
            prop: "",
            render: (item) => (
              !!(item.new_owner && item.old_owner) && (
                <span style={{
                  marginRight: 5, fontSize: 14, display: "flex", alignItems: "center", columnGap: 2,
                }}
                >
                  {item.old_owner}
                  <ArrowForwardIcon />
                  {item.new_owner}
                </span>
              )
            ),
          },
          {
            name: "Status",
            prop: "status",
          },
          {
            name: "User",
            prop: "",
            render: (item) => (
              <span
                style={{
                  marginRight: 5, fontSize: 14, display: "flex", alignItems: "center",
                }}
              >
                <PersonIcon />
                {item.owner}
              </span>
            ),
          },
          {
            name: "Modified",
            prop: "",
            render: (item) => (
              <span style={{
                marginRight: 5, fontSize: 14, display: "flex", alignItems: "center",
              }}
              >
                {item.request_time ? moment(item.request_time * 1000).format("HH:mm:ss") : ""}

                <FastForwardIcon />

                {item.response_time ? moment(item.response_time * 1000).format("HH:mm:ss") : ""}
              </span>
            ),
          },
          ]
        }
      />
    </div>
  );
}
