import { Checkbox } from "@mui/material";
import moment from "moment";

export function UnitRenderColumn({ field, handleChange }: { field: string | undefined, handleChange: () => void }) {
  return (
    <div>
      {
        !!field && typeof field === "string"
          ? (
            <div>
              <div>{moment(field).format("HH:mm:ss")}</div>
              <div style={{ fontSize: 10 }}>{moment(field).format("MM/DD/YYYY")}</div>
            </div>
          ) : (
            <Checkbox
              onChange={handleChange}
            />
          )
      }
    </div>
  );
}
