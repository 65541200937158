import p from "../../package.json";

const { version } = p;

export default function Version() {
  return (
    <div
      title="Version Number"
      style={{
        position: "fixed",
        bottom: 0,
        right: 0,
        color: "#a8a8a8",
        fontSize: "13px",
        padding: "0px 3px",
        zIndex: 20000,
      }}
      className="no-print"
    >
      {`v${version}`}
    </div>
  );
}
