import { useEffect, useState } from "react";
import { getDiffInMinutes } from "@utils/utilsFunctions";

export function Unit(prop: {
  name: string
  isSupervisor: boolean
  time: string
}) {
  const { time, isSupervisor, name } = prop;
  const [, setDiff] = useState(getDiffInMinutes(time));

  useEffect(() => {
    const interval = setInterval(() => {
      setDiff(getDiffInMinutes(prop.time));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div
        className={`unitBox ${isSupervisor ? "supervisorUnit" : "simpleUnit"}`}
        style={{
          width: 70,
          fontSize: name.length > 6 && name.length <= 10 ? 12
            : name.length > 10 && name.length <= 13 ? 10
              : name.length > 13 ? 8
                : 16
        }}>
        <p className={`unitName ${isSupervisor ? "supervisor" : "simple"}`}>{name}</p>
        <span className="circleUnit" style={{ background: time !== "" ? (getDiffInMinutes(time) >= 0 && getDiffInMinutes(time) <= 10 ? "#009900" : getDiffInMinutes(time) > 10 && getDiffInMinutes(time) <= 15 ? "#fbe52e" : "red") : "red" }} />
      </div>
    </>
  );
}
