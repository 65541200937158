import { useContext, useEffect } from "react";
import { ArcGISMapMapContext } from "@contexts/arcgis-map-context";
import { checkIfFullscreen } from "@utils/utilsFunctions";

export function useFullScreen() {
  const [mapSettings, setMapSettings] = useContext(ArcGISMapMapContext);

  const checkFullScreen = () => {
    const noWindowDifferences = window.innerHeight === window.screen.height;
    const isFullScreenViaApi = checkIfFullscreen();

    setMapSettings({ ...mapSettings, isFullscreen: isFullScreenViaApi || noWindowDifferences });
  };

  useEffect(() => {
    checkFullScreen();

    window.addEventListener("resize", checkFullScreen);
    return () => window.removeEventListener("resize", checkFullScreen);
  }, []);

  return { isBrowserOnFullScreen: mapSettings.isFullscreen };
}
