import linkIcon from "../../pages/img/vehicles_status_icons/owner_cad.svg";
import ackIcon from "../../pages/img/vehicles_status_icons/owner_ack.svg";
import peopleIcon from "../../pages/img/people_icon_10E.svg";
import { CSSProperties } from "react";

export default function CADVehicleStatusRequestedByIcon(props: {
  requestedBy?: string,
  style?: CSSProperties,
}) {
  const { requestedBy, style } = props;

  if (!requestedBy) {
    return (<></>);
  }

  let requestedByIconSrc = "";
  switch (requestedBy.toLocaleLowerCase()) {
    case "ack":
      requestedByIconSrc = ackIcon;
      break;
    case "cad":
      requestedByIconSrc = linkIcon;
      break;
    case "user":
      requestedByIconSrc = peopleIcon;
      break;
    default:
      break;
  }
  return (
    <img
      src={requestedByIconSrc}
      alt={`Requested by ${requestedBy}`}
      title={`Requested by ${requestedBy}`}
      style={style}
    />
  );
}
