import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { getCADIncidentById } from "@services/cad";
import { getIncidentById } from "@services/tc-incidents";
import { useDataWithoutLoading } from "@hooks/useDataWithoutLoading";
import { useInterval } from "@hooks/useInterval";
import CADIncidentDetails from "../cad-incident-details/cad-incident-details";
import ManagedIncidentDetails from "../tc-incident-details/tc-incident-details";

export function IncidentDetails() {
  const { incidentNumber } = useParams<{ incidentNumber: string }>();
  const { data: incident, reload } = useDataWithoutLoading((as) => getIncidentById(incidentNumber as string, as));
  const { data: cadIncident, reload: reloadCadIncident } = useDataWithoutLoading((as) => getCADIncidentById(incidentNumber as string, as));

  useInterval(async () => {
    if (incident?.end_unix_time === 0 || cadIncident?.closed_unix_date === 0) {
      await reload();
      await reloadCadIncident();
    }
  }, [incident?.end_unix_time, cadIncident?.closed_unix_date], { reloadInterval: 4000 });

  const typeOfIncident = useMemo(() => (incident === undefined ? <CADIncidentDetails /> : <ManagedIncidentDetails />), [incident, cadIncident]);

  return (
    <>
      {typeOfIncident}
    </>
  );
}
