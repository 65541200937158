import { Events } from "@models/cad";
import { TCIncident } from "@models/tc-incident";
import { appFetch } from "./config";

function buildIncidentsParams(active: boolean, limit?: number, skip?: number) {
  const params: Record<string, string> = {
    active: active ? "1" : "0",
  };
  if (limit) {
    params.limit = `${limit}`;
  }
  if (skip) {
    params.skip = `${skip}`;
  }
  const item = new URLSearchParams(params);
  return item.toString();
}

export async function getManagedIncidentsByPage(active: boolean, limit: number, skip: number, signal: AbortSignal): Promise<TCIncident[]> {
  const paramsStr = buildIncidentsParams(active, limit, skip);
  const response: Response = await appFetch(`api/a1/managed-incident?${paramsStr}`, { signal });
  return response.json();
}

export async function getManagedIncidents(active: true, signal: AbortSignal): Promise<TCIncident[]> {
  const paramsStr = buildIncidentsParams(active);
  const response: Response = await appFetch(`api/a1/managed-incident?${paramsStr}`, { signal });
  return response.json();
}

export async function getIncidentById(id?: string, signal?: AbortSignal): Promise<TCIncident> {
  const response: Response = await appFetch(`api/a1/managed-incident/${id}`, { signal });
  return response.json();
}

export enum ChangeManagedState {
  Close = "close",
  Restore = "restore",
}

export async function changeManagedIncidentState(id: string, nextState: ChangeManagedState) {
  if (id === "") {
    return;
  }

  await appFetch(`api/a1/managed-incident/change-state/${nextState}/${id}`, {
    method: "POST",
  });
}

export const getIncidentEvents = async (incidentNumber: string, signal: AbortSignal): Promise<Events[]> => {
  const response: Response = await appFetch(`api/sync/events/${incidentNumber}`, { signal });
  return response.json();
};
