import {
  Button,
  FormLabel,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import "../pages/login/login-page.css";

import { SetStateAction } from "react";
import { resetPassword } from "@services/reset-password";
import { useTitle } from "@hooks/useTitle";
import logo from "../pages/img/logo.png";
import resetPasswordIcon from "../pages/img/reset_password_icon.svg";
import ContactInfo from "./ContactInfo";

export default function ResetPassword(prop: {
  email: string,
  setEmail: (e: SetStateAction<string>) => void,
  setSend: () => void
}) {
  useTitle("Reset Password");
  const { email } = prop;

  return (
    <div className="login-page container login-background">
      <Grid container>
        <Grid item xs={12} lg={7} className="left-side">
          <img src={logo} alt="" className="logo" />
        </Grid>
        <Grid item xs={12} lg={4} className="right-side">
          <Paper className="paperBody">
            <div className="head">
              <img
                src={resetPasswordIcon}
                alt=""
                style={{ marginRight: 10, height: 21 }}
              />
              <p className="title2">Reset Password</p>
            </div>
            <div className="reset-password-message-content">
              <p className="reset-password-message">
                Please enter here your email address
                <br />
                for which you need a new password
              </p>
            </div>
            <div className="form-recovery-password">
              <FormLabel>Email</FormLabel>
              <TextField
                type="email"
                variant="outlined"
                placeholder="Email"
                value={email}
                onChange={(e) => prop.setEmail(e.target.value)}
              />
            </div>
            <div className="reset-password-button">
              <Button
                className="btn"
                variant="contained"
                size="large"
                color="secondary"
                onClick={async () => {
                  prop.setSend();
                  await resetPassword(prop.email);
                }}
              >
                Reset Password
              </Button>
            </div>
          </Paper>
          <ContactInfo />
        </Grid>
      </Grid>
    </div>
  );
}
