import { createContext, useState } from "react";

export const UserInstructionContext = createContext<[boolean | null, React.Dispatch<React.SetStateAction<boolean>>]>([null!, () => null!]);

export function UserInstructionContextProvider(props: { children: JSX.Element }) {
  const [openUserInstructions, setOpenUserInstructions] = useState(false);

  return (
    <UserInstructionContext.Provider value={[openUserInstructions, setOpenUserInstructions]}>
      {props.children}
    </UserInstructionContext.Provider>
  );
}
