import logo from "../pages/img/logo.png";
import info from "../pages/img/info_notification_icon.svg";

export function AfterSendEmail(prop: { email: string }) {
  const { email } = prop;
  return (
    <div className="email-message-content">
      <div style={{ paddingTop: 30 }}>
        <img src={logo} alt="" className="after-send-email-logo" />
      </div>
      <div style={{ paddingTop: 30 }}>
        <img src={info} alt="" />
      </div>
      <p className="send-email-text">
        An email with instructions on how to reset your password was sent to
      </p>
      <p className="send-email-text">
        {email}
        .
      </p>
    </div>
  );
}
