import classes from "./incident-header.module.css";

export function IncidentHeader(prop: {
  title: string | JSX.Element
  dataSize?: number
  customLabels?: JSX.Element
}) {
  const { title, dataSize, customLabels } = prop;

  return (
    <div className={`${classes.incident_header}`}>
      {typeof title === "string"
        ? <p className={`${classes.incident_title}`}>{title}</p>
        : <div style={{ padding: 15, textTransform: "capitalize" }}>{title}</div>}
      {customLabels ? customLabels : (dataSize ?? 0) >= 0 ? <span className={`${classes.incident_label}`}>{dataSize}</span>
        : <></>}
    </div>
  );
}
