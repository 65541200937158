
import { OrientationNames } from "@models/cad";
import { Color } from "@models/color";

export function MapOrientation(props: { color: Color, text: string }) {
    const { color } = props;
    let textKey: OrientationNames | string = Object.entries(OrientationNames).find(([key]) => props.text.toLocaleLowerCase().includes(key.toLocaleLowerCase()))?.[1] ?? OrientationNames.Unknown

    return (
        <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15" r="14" fill={color.background} stroke={color.text} strokeWidth="2" />
            <text
                x="15"
                y="16.5"
                fontSize="16"
                fontWeight="bold"
                fontFamily="Arial, sans-serif"
                fill={color.text}
                textAnchor="middle"
                alignmentBaseline="middle"
            >
                {textKey}
            </text>
        </svg>

    )
}