/* eslint-disable camelcase */

import { Agency } from "./agencies";

export enum UnitKindTypes {
  Person = "person",
  Vehicle = "vehicle",
  FixedWing = "fixed-wing",
  Helicopter = "helicopter",
  Vessel = "vessel"
}

export interface BattalionUnit {
  _id: string
  active: boolean
  api_battalion_id: string
  agencyId?: string
  battalion_name: string
  battalion_uuid: string
  departmentId: string
  friendly_id?: string
  id: string
  isMandatory: boolean
  modified_date: string
  modified_unix_date: number
  name?: string
  personnel?: string
  position?: number
  userId: string
  uuid: string
  kindType: UnitKindTypes
}

export interface BulkEditBattalion {
  name: string
  personnel: string
  agencyId: string
  kindType: UnitKindTypes | string
}

export interface Battalion {
  _id: string
  active: boolean
  agencyId?: Agency
  departmentId: string
  id: string
  isMandatory: boolean
  isQuickUnits: boolean
  modified_date: string
  modified_unix_date: number
  name?: string
  position?: number
  units?: BattalionUnit[]
  userId: string
  uuid: string
}

export interface ExportBattalion {
  battalion: Battalion
  unit: BattalionUnit
}

export interface DebugUnit {
  _id: string
  active: boolean
  isMandatory: boolean
  position: number
  radioName: string
}

export interface DebugBattalion {
  _id: string
  active: boolean
  isMandatory: boolean
  modified: string
  name: string
  position: number
  units: DebugUnit[]
}

export interface DebugBattalionPackage {
  agency: string
  email: string
  hasMandatory: boolean
  items: DebugBattalion[]
}
