/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/no-array-index-key */
import { withStyles, createStyles, makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IncidentHeader } from "./incident-header/incident-header";
import { ReactNode, useCallback } from "react";

type RowProps = {
  headerColor?: string;
  headerCellTextColor?: string;
}

const StyledTableCell = withStyles(() => createStyles({
  head: {
    backgroundColor: (prop: RowProps) => (prop.headerColor ? prop.headerColor : "#F9F9F9"),
    color: (prop: RowProps) => (prop.headerCellTextColor ? prop.headerCellTextColor : "#676768"),
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(() => createStyles({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#EDEEED",
    },

    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
  countLabel: {
    padding: "2px 5px",
    borderRadius: "4px",
    marginRight: "13px",
    marginLeft: "7px",
    backgroundColor: "#F9F9F9",
    color: "black",
  },
});

function getRowStyle<T>(el: {
  name: string | JSX.Element,
  prop: string,
  render?: ((item: T) => ReactNode)
  cellWidth?: number
  cellColors?: ((item: T) => { backgroundColor: string, color: string })
}, item: T): React.CSSProperties {
  const defaultStyles: React.CSSProperties = {};
  const cellColorOverwrite = el.cellColors ? el.cellColors(item) : null;
  if (el.cellWidth) {
    defaultStyles.width = el.cellWidth;
  }
  if (cellColorOverwrite?.backgroundColor) {
    defaultStyles.backgroundColor = cellColorOverwrite.backgroundColor;
  }
  if (cellColorOverwrite?.color) {
    defaultStyles.color = cellColorOverwrite.color;
  }

  return defaultStyles;
}

function Row<T>(props: {
  item: T
  index: number
  headerColor?: string;
  headerCellTextColor?: string;
  header: CustomMaterialProps["header"]
  cellClassName?: string;
  cellAlign?: "left" | "right" | "inherit" | "center" | "justify"
}) {
  const {
    index, headerColor, headerCellTextColor, header, cellAlign, cellClassName, item,
  } = props;
  return (
    <StyledTableRow key={index}>
      {header.map((el) => (
        <StyledTableCell
          headerColor={headerColor}
          headerCellTextColor={headerCellTextColor}
          size="small"
          key={el.name as string}
          align={cellAlign ? cellAlign : "left"}
          className={`material-table-custom-cell ${cellClassName}`}
          style={getRowStyle(el, props.item)}
        >
          {el.render ? el.render(props.item) : String(item[el.prop as keyof typeof item])}
        </StyledTableCell>
      ))}
    </StyledTableRow>
  );
}

type CustomMaterialProps<T = any> = {
  data: T[]
  header: {
    name: string | JSX.Element,
    prop: string,
    render?: ((item: T) => ReactNode)
    cellWidth?: number
    cellColors?: ((item: T) => { backgroundColor: string, color: string })
  }[],
  groups?: {
    title: string
    note: string
    unitsCount: number
    personnelCount: number
    units: T[]
  }[]
  title?: string | JSX.Element
  cellClassName?: string;
  headerColor?: string
  customHeader?: JSX.Element
  headerCellTextColor?: string,
  cellAlign?: "left" | "right" | "inherit" | "center" | "justify" | undefined
  noHeader?: boolean
  hideContent?: boolean
}

export function CustomMaterialTable<T>(prop: CustomMaterialProps<T>) {
  const classes = useStyles();

  const GroupedRow = useCallback((props: {
    item: T
    index: number
    name: string
    note: string
    personnelCount: number
    unitsCount: number
  }) => {
    const {
      index, name, note, unitsCount, personnelCount, item,
    } = props;
    return (
      <>
        {index === 0 ? (
          <TableRow style={{ backgroundColor: "#919191" }}>
            <StyledTableCell
              colSpan={8}
            >
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ paddingRight: 20, minWidth: 110 }}>{name}</div>
                  <div style={{ paddingRight: 20 }}>{note}</div>
                </div>
                <div>
                  <span>
                    <span>Units</span>
                    <span className={classes.countLabel}>{unitsCount}</span>
                  </span>
                  <span>
                    <span>Personnel</span>
                    <span className={classes.countLabel}>{personnelCount}</span>
                  </span>
                </div>
              </div>
            </StyledTableCell>
          </TableRow>
        ) : <></>}
        <StyledTableRow>
          {prop.header.map((el) => (
            <StyledTableCell
              size="small"
              key={el.name as string}
              align={prop.cellAlign ? prop.cellAlign : "left"}
              className={`material-table-custom-cell ${prop.cellClassName}`}
            >
              {el.render ? el.render(props.item) : String(item[el.prop as keyof typeof item])}
            </StyledTableCell>
          ))}
        </StyledTableRow>
      </>
    );
  }, [prop.data, prop.groups]);

  const {
    title,
    data,
    cellAlign,
    header,
    noHeader,
    groups,
    customHeader,
    hideContent,
  } = prop;

  return (
    <>
      {title
        ? <IncidentHeader title={title} dataSize={data ? data.length : 0} customLabels={customHeader} />
        : <></>}
      <div style={{ marginBottom: 20, width: "100%" }} className="custom-mt">
        <TableContainer component={Paper} style={{ borderRadius: 0, boxShadow: "none" }}>
          {!hideContent && (
            <Table className={classes.table} aria-label="customized table">
              {noHeader ? <></>
                : (
                  <TableHead>
                    <TableRow>
                      <StyledTableCell
                        align={cellAlign ? cellAlign : "left"}
                        size="small"
                        style={{ width: "fitContent" }}
                      >
                        {header[0].name}
                      </StyledTableCell>
                      {header
                        .filter((el) => el.name !== prop.header[0].name)
                        .map((el) => (
                          <StyledTableCell
                            size="small"
                            key={el.name as string}
                            align={prop.cellAlign ? prop.cellAlign : "left"}
                          >
                            {el.name}
                          </StyledTableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                )}
              <TableBody>
                {groups ? groups.map((el) => (
                  el.units.map((row, i) => (
                    <GroupedRow
                      item={row}
                      index={i}
                      key={i}
                      name={el.title}
                      note={el.note}
                      unitsCount={el.unitsCount}
                      personnelCount={el.personnelCount}
                    />
                  ))))
                  : data?.map((row, i) => (
                    <Row item={row} index={i} key={i} header={prop.header} cellAlign={prop.cellAlign} cellClassName={prop.cellClassName} />
                  ))}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </div>
    </>
  );
}
