import Extent from "@arcgis/core/geometry/Extent";
import Point from "@arcgis/core/geometry/Point";
import MapView from "@arcgis/core/views/MapView";
import { ArcGISMapMapContext } from "@contexts/arcgis-map-context";
import { GraphicOptions } from "@models/arcgis";
import { UserMapSettings } from "@models/user-web-map";
import { getValidCoordinate } from "@utils/utilsFunctions";
import {
  useContext, useEffect, useMemo,
} from "react";

export function useExtendedView(view: MapView, avlUnitsGraphics: GraphicOptions[], noStoreData?: boolean) {
  const [mapSettings] = useContext(ArcGISMapMapContext);

  const markerFit = useMemo(() => {
    const fitBounds = {
      xmin: Number.MAX_SAFE_INTEGER,
      ymin: Number.MAX_SAFE_INTEGER,
      xmax: Number.MIN_SAFE_INTEGER,
      ymax: Number.MIN_SAFE_INTEGER,
    };
    avlUnitsGraphics.forEach(
      (geom) => {
        if (!geom.id.includes("label")) {
          fitBounds.xmin = Math.min(fitBounds.xmin, geom.geometry.longitude);
          fitBounds.ymin = Math.min(fitBounds.ymin, geom.geometry.latitude);
          fitBounds.xmax = Math.max(fitBounds.xmax, geom.geometry.longitude);
          fitBounds.ymax = Math.max(fitBounds.ymax, geom.geometry.latitude);
        }
      },
    );
    return fitBounds;
  }, [avlUnitsGraphics]);

  useEffect(() => {
    if (mapSettings.isMapOnExtendedView) {
      const xCoordinates = getValidCoordinate(markerFit.ymin, markerFit.xmin);
      const yCoordinates = getValidCoordinate(markerFit.ymax, markerFit.xmax);
      const pointMarker = new Point({
        longitude: markerFit.xmin,
        latitude: markerFit.ymin,
      });
      const fit = new Extent(markerFit);
      if (!mapSettings.isMapDragged && (xCoordinates && yCoordinates)) {
        if (markerFit.ymin === markerFit.ymax && markerFit.xmin === markerFit.xmax) {
          view.set("zoom", 18);
          view.set("center", pointMarker);
        } else {
          view.set("extent", fit.expand(1.5));
        }
      }
      return () => {
        pointMarker.destroy();
        fit.destroy();
      };
    }

    if (view.zoom <= 0 && !noStoreData) {
      let userSettings: UserMapSettings | null = null;
      const items = localStorage.getItem("userMapSettings");
      if (items) {
        userSettings = JSON.parse(items);
      }
      if (userSettings !== null && userSettings.center.latitude !== 0 && userSettings.center.longitude !== 0 && userSettings.zoom !== 0) {
        const pointMarker = new Point({
          longitude: userSettings.center.longitude,
          latitude: userSettings.center.latitude,
        });
        view.set("zoom", userSettings.zoom);
        view.set("center", pointMarker);
        return () => {
          pointMarker.destroy();
        };
      }
      const xCoordinates = getValidCoordinate(markerFit.ymin, markerFit.xmin);
      const yCoordinates = getValidCoordinate(markerFit.ymax, markerFit.xmax);
      const fit = new Extent(markerFit);
      if (xCoordinates && yCoordinates) {
        view.set("extent", fit.expand(1));
      }
      return () => {
        fit.destroy();
      };
    }

    return () => { };
  }, [view, markerFit, noStoreData]);
}
