import React from "react";
import {
  InputBase, InputBaseProps, Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { IconButton, IconButtonProps } from "rsuite";
import styles from "./search-box.module.css";
import { TimedOutTextField, TimedOutTextFieldType } from "../TimedOutTextField";

export function SearchBox(props: { containerStyle?: React.CSSProperties, iconButtonProps?: IconButtonProps, open?: boolean, inputProps?: InputBaseProps, timeoutInputProps?: TimedOutTextFieldType }) {
  const {
    inputProps, containerStyle, iconButtonProps, timeoutInputProps, open = true,
  } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Paper style={{ ...containerStyle }} component="form" onSubmit={(e) => e.preventDefault()} className="search-bar-paper-wrapper">
      <div className={styles.search_box_wrapper}>
        {open
          && (
            timeoutInputProps
              ? (
                <TimedOutTextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...timeoutInputProps}
                  timeout={500}
                  placeholder={timeoutInputProps.placeholder ?? "Filter"}
                  className={styles.search_box_input}
                  style={{ padding: 0 }}
                  inputProps={{
                    "aria-label": "filter",
                    autoCorrect: "off",
                    spellCheck: false,
                    "data-testid": "search-input-field",
                  }}
                />
              )
              : (
                <InputBase
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...inputProps}
                  autoFocus
                  placeholder={inputProps?.placeholder ?? "Filter"}
                  className={styles.search_box_input}
                  inputProps={{
                    "data-testid": "search-input-field",
                    "aria-label": "filter",
                    autoCorrect: "off",
                    spellCheck: false,
                  }}
                />
              )

          )}
        <IconButton
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...iconButtonProps}
          data-testid="search-filter-toggle"
          className={`${styles.search_box_button} ${open ? styles.search_box_button_half_radius : styles.search_box_button_full_radius}`}
          aria-label="search-icon-button"
        >
          <SearchIcon />
        </IconButton>
      </div>
    </Paper>
  );
}
