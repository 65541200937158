import moment from "moment";
import { ReactNode, useMemo } from "react";
import classes from "./cad.module.css";
import flashIcon from "../../img/flash_grey_icon.svg";
import { Paper, Button } from "@mui/material";

import { canStopShare } from "@components/incident-details-shared-to";
import IncidentShareCode from "@components/IncidentShareCode";

import {
  ActionChangeShareIncident,
  CADIncident,
  ReportNumber,
  SharedSource,
  SharedToItem,
} from "@models/cad";
import { IncidentFieldPermissions } from "@models/static-data";
import { changeShareIncident, ignoreIncident } from "@services/cad";
import { updateChannels } from "@utils/utilsFunctions";

const nonEmptyStringFiled: FieldType<string> = {
  format: (s) => s,
  validator: (s): s is string => !!s && s !== "",
};

const dateFiled: FieldType<number> = {
  format: (n) => moment((n ?? 0) * 1000).format(
    "HH:mm:ss, MMMM DD YYYY",
  ),
  validator: (n): n is number => !!n && n > 0,
};

type FieldType<T> = {
  format: (value: T) => string,
  validator: (value: T | undefined) => value is T,
}

export function ShareToButton({
  incidentId,
  isAdmin,
  item,
  onChange,
}: {
  incidentId: string | null | undefined,
  isAdmin: boolean,
  item: SharedToItem,
  onChange: () => void,
}): JSX.Element {
  const {
    departmentId,
    expireAt,
  } = item;
  const willStopShare = canStopShare(new Date(), expireAt);
  const actionTitle = willStopShare ? "Stop" : "Restore";
  const backgroundColor = willStopShare ? "#e6b7b1" : "#81C784";
  const disabled = !isAdmin || !incidentId || incidentId === "";
  const action: ActionChangeShareIncident = willStopShare ? ActionChangeShareIncident.Stop : ActionChangeShareIncident.Restore;
  return (
    <div style={{
      grid: "container",
    }}
    >
      <div style={{
        paddingLeft: "20px",
      }}
      >
        <Button
          style={{
            backgroundColor,
          }}
          disabled={disabled}
          onClick={async () => {
            await changeShareIncident({
              action,
              departmentId,
              incidentId,
            });
            await onChange();
          }}
        >
          {actionTitle}
        </Button>
      </div>
    </div>
  );
}

export function SharedSourceField({ sharedSource }: { sharedSource?: SharedSource }) {
  if (!sharedSource || !sharedSource.isExternal) {
    return null;
  }

  return (
    <div className={`${classes.incident_content}`} style={{ backgroundColor: "#ffe6ff" }}>
      <span className={`${classes.text_label}`}>Shared by: </span>
      <span className={`${classes.text_label2}`}>
        {sharedSource?.name}
      </span>
    </div>
  );
}

export function IncidentField<T>({ fieldValue, label, type }: { fieldValue: T | undefined, label: ReactNode, type: FieldType<T> }) {
  if (!type.validator(fieldValue)) {
    return null;
  }

  return (
    <div className={`${classes.incident_content}`}>
      <span className={`${classes.text_label}`}>
        {label}
      </span>
      <span className={`${classes.text_label2}`}>
        {type.format(fieldValue)}
      </span>
    </div>
  );
}

export function IgnoreIncidentButton({
  age,
  allowedIgnoreAge,
  ignored,
  incidentId,
  isAdmin,
  onChange,
}: {
  age: number,
  allowedIgnoreAge: number,
  ignored?: boolean,
  incidentId: string,
  isAdmin: boolean,
  onChange: () => void;
}): JSX.Element | null {
  if (!isAdmin) {
    return null;
  }

  const isVisible = age > allowedIgnoreAge; // days
  if (!isVisible) {
    return null;
  }

  let actionTitle = "Ignore Incident";
  if (ignored) {
    actionTitle = "Restore Incident";
  }
  return (
    <div
      style={{ paddingBottom: 10 }}
      className={`${classes.incident_content}`}
    >
      <Button
        style={{
          backgroundColor: "#e6b7b1",
        }}
        onClick={async () => {
          await ignoreIncident(incidentId);
          await onChange();
        }}
      >
        {actionTitle}
      </Button>
    </div>
  );
}

export function ReportNumbersSection({
  items,
}: {
  items?: ReportNumber[],
}): JSX.Element | null {
  if (!items || !(items instanceof Array) || items.length === 0) {
    return null;
  }

  return (
    <div className={`${classes.incident_content}`}>
      <span className={`${classes.text_label}`}>Report Number: </span>
      <div className={`${classes.channels_text_label}`}>
        {items.map((el) => (
          <span key={el.name + el.number} className={`${classes.text_label2}`}>
            {el.name}
            {": "}
            {el.number}
          </span>
        ))}
      </div>
    </div>
  );
}

function incidentAge(fromDate: string | null | undefined, toDate: Date, intervalType: "years" | "months" | "weeks" | "days" | "hours" | "minutes" | "seconds"): number {
  if (!fromDate || fromDate === "") {
    return 0;
  }

  const startDateTime = moment(fromDate);
  if (!startDateTime.isValid()) {
    return 0;
  }

  return moment(toDate).diff(startDateTime, intervalType);
}

export function Fields({
  allowedIgnoreAge,
  cadIncident,
  permissions,
  onChange,
}: {
  allowedIgnoreAge: number,
  cadIncident: CADIncident,
  permissions: IncidentFieldPermissions,
  onChange: () => void,
}) {
  const channels = useMemo(() => cadIncident && updateChannels(cadIncident), [cadIncident]);
  const age = useMemo<number>(() => incidentAge(cadIncident?.EntryDateTime, new Date(), "days"), [cadIncident]);
  const {
    isAdmin,
    canCreateShareIncidentCode,
    shareIncidentEnabled,
  } = permissions;

  return (
    <Paper className={`${classes.paper_content}`}>
      <div className={`${classes.incident_data}`}>
        <IncidentField
          type={nonEmptyStringFiled}
          label="Incident Number: "
          fieldValue={cadIncident.IncidentNumber ?? "-"}
        />
        <ReportNumbersSection
          items={cadIncident.ReportNumber}
        />
        <IncidentField
          type={nonEmptyStringFiled}
          label="Call Type: "
          fieldValue={cadIncident.AgencyIncidentCallTypeDescription}
        />
        <IncidentField
          type={nonEmptyStringFiled}
          label="Incident Name: "
          fieldValue={cadIncident.CommandName}
        />
        <IncidentField
          type={nonEmptyStringFiled}
          label="Common Place Name: "
          fieldValue={cadIncident.CommonPlaceName}
        />
        <IncidentField
          label="Location Comment: "
          type={nonEmptyStringFiled}
          fieldValue={cadIncident?.LocationComment ? cadIncident?.LocationComment : cadIncident?.Comment?.find((el) => el.Comment?.includes("Location Comment"))?.Comment.split(":")[1]}
        />
        <IncidentField
          type={nonEmptyStringFiled}
          label="Agency: "
          fieldValue={cadIncident.AgencyID}
        />
        <IncidentField
          type={nonEmptyStringFiled}
          label="Map Pages: "
          fieldValue={cadIncident.MapPages}
        />
        <IncidentField
          type={nonEmptyStringFiled}
          label="Fire Map: "
          fieldValue={cadIncident.FireMap}
        />
        <IncidentField
          type={dateFiled}
          label="Start Time: "
          fieldValue={cadIncident.start_unix_date}
        />
        <IncidentField
          type={dateFiled}
          label="End Time: "
          fieldValue={cadIncident.closed_unix_date}
        />
        <IncidentField
          type={dateFiled}
          label="Modified Time: "
          fieldValue={cadIncident.modified_unix_date}
        />
        <IncidentField
          type={nonEmptyStringFiled}
          label="Address: "
          fieldValue={cadIncident.full_address}
        />
        <IncidentField
          type={nonEmptyStringFiled}
          label="Cross Streets: "
          fieldValue={cadIncident.cross_streets}
        />
        <IncidentField
          type={nonEmptyStringFiled}
          label="Caller Number: "
          fieldValue={cadIncident.CallerNumber}
        />

        {cadIncident.radioChannels?.length ? (
          <div className={`${classes.incident_content}`}>
            <span className={`${classes.text_label}`}>Radio Channels: </span>
            <div className={`${classes.channels_text_label}`}>
              {cadIncident.radioChannels?.map((el) => (
                <span key={el.name + el.channel} className={`${classes.text_label2}`}>
                  {el.name}
                  :
                  {" "}
                  {el.channel}
                </span>
              ))}
            </div>
          </div>
        ) : channels.length ? (
          <div className={`${classes.incident_content}`}>
            <span className={`${classes.text_label}`}>Channels: </span>
            {channels.map((el) => (
              <span key={el.name + el.channel} className={`${classes.text_label2}`}>
                {el.name}
                :
                {el.channel}
              </span>
            ))}
          </div>
        ) : <></>}

        {/* <IncidentShareCode
          incidentNumber={cadIncident.IncidentNumber ?? ""}
          departmentId={cadIncident.departmentId ?? ""}
          canGenerateCode={canCreateShareIncidentCode}
          shareIncidentEnabled={shareIncidentEnabled}
        /> */}

        <div
          style={{ paddingBottom: 10 }}
          className={`${classes.incident_content}`}
        >
          {(cadIncident.notificationType ?? []).filter((el) => el.name !== "Any").length ? (
            <img
              src={flashIcon}
              alt=""
              style={{ width: 10, marginRight: 8 }}
            />
          ) : <></>}
          {(cadIncident.notificationType ?? []).map((item) => (
            item.name !== "Any" ? <span key={item.value + item.name} style={{ color: "#7a7d7d", paddingRight: 5 }}>{item.name}</span> : <div key={item.name + item.value} />
          ))}
        </div>

        <IgnoreIncidentButton
          age={age}
          allowedIgnoreAge={allowedIgnoreAge}
          ignored={cadIncident.ignored}
          isAdmin={isAdmin}
          incidentId={cadIncident.id}
          onChange={onChange}
        />
      </div>
    </Paper>
  );
}
