/* eslint-disable react/no-array-index-key */
import { IconButton, TextField } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useContext, useEffect, useState } from "react";
import styles from "./cad-simulator.module.css";
import { useLoading } from "@hooks/useLoading";
import { CADComment } from "@models/cad";
import moment from "moment";
import { LoginContext } from "@contexts/login-context";
import { getWordPriorityColor } from "@utils/utilsFunctions";
import { useDataWithoutLoading } from "@hooks/useDataWithoutLoading";
import { getDepartmentById } from "@services/department";

export function CadSimulatorComments(props: {
  comments: CADComment[],
  isDataCleared: boolean,
  submitForm: (() => Promise<void>) & (() => Promise<any>)
  disableInput?: boolean,
}) {
  const [user] = useContext(LoginContext);
  const [commentSource, setCommentSource] = useState<string>("SC");
  const [commentText, setCommentText] = useState<string>("");
  const { data: departmentData } = useDataWithoutLoading((as) => getDepartmentById(user?.departmentId, as));

  const { withLoading } = useLoading();
  const {
    comments, isDataCleared, submitForm, disableInput,
  } = props;

  useEffect(() => {
    comments?.splice(0, comments?.length);
  }, [isDataCleared]);

  useEffect(() => {
    const source = user?.email?.split("@")[0] ?? "";
    if (source !== "") {
      setCommentSource(source);
    }
  }, [user]);

  const submitData = withLoading("Loading ...", async (): Promise<void> => {
    if (commentText.trim() === "") {
      return;
    }

    comments.push({
      Comment: commentText,
      CommentSource: commentSource,
      CommentDateTime: new Date().toISOString(),
    });
    setCommentText("");
    submitForm();
  });

  if (!departmentData) {
    return <div />;
  }

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.section_header}>
        <p className={styles.section_name}>
          Comments
        </p>
        <span className={styles.data_length}>
          {comments?.length}
        </span>
      </div>
      {!disableInput && (
        <div className={styles.comments_section}>
          <div className={styles.button_container}>
            <IconButton
              style={{ padding: 0, color: "white" }}
              onClick={submitData}
              type="submit"
            >
              <AddCircleIcon />
            </IconButton>
          </div>
          <TextField
            variant="outlined"
            className={styles.textField}
            style={{ marginLeft: 13 }}
            value={commentText}
            onChange={(el) => setCommentText(el.target.value)}
          />
        </div>
      )}
      <div className={styles.comments_wrapper}>
        {comments.sort((a, b) => (moment(b.CommentDateTime).unix() - moment(a.CommentDateTime).unix())).map((el, index) => el.Comment !== "" && (
          <li key={index} className={styles.history_child} style={{ color: getWordPriorityColor(departmentData.safetyPriorityKeywords, el.Comment).text, backgroundColor: getWordPriorityColor(departmentData.safetyPriorityKeywords, el.Comment).background }}>
            <span className={styles.history_date_source}>
              {`${moment(el.CommentDateTime).format("MM/DD/YYYY - HH:mm:ss")} [${el.CommentSource}]`}
            </span>
            <span style={{ fontSize: 14 }}>{el.Comment}</span>
          </li>
        ))}
      </div>
    </div>
  );
}
