import { Button } from "@mui/material";
import { Lock } from "@mui/icons-material";
import { CADCannedSimulation, CADIncident } from "@models/cad";
import { postSimulatorIncident, updateCannedSimulation } from "@services/cad";

export function CloseCADSimulatedIncidentButton(prop: {
  incident: CADIncident,
  reload: () => Promise<void>
}) {
  const {
    incident,
    reload,
  } = prop;

  const nextButtonColor: string = "#CC0033";
  const nextIcon = (
    <Lock style={{ height: 20 }} />
  );

  return (
    <Button
      style={{
        backgroundColor: nextButtonColor,
        color: "white",
        borderRadius: "3px",
        boxShadow: "none",
        padding: "4px 10px",
      }}
      className="btn"
      variant="contained"
      size="small"
      onClick={async () => {
        await postSimulatorIncident({
          IncidentNumber: incident.IncidentNumber,
          AgencyID: incident.AgencyID,
          ClosedDateTime: new Date().toISOString(),
          simulation: incident.simulation,
          rts: incident.rts,
        });
        await reload();
      }}
    >
      {nextIcon}
      Close Incident
    </Button>
  );
}

export function CloseCADCannedSimulatedIncidentButton(prop: {
  incident: CADCannedSimulation,
  disabled: boolean,
  reload: () => Promise<void>
}) {
  const {
    incident,
    reload,
    disabled,
  } = prop;

  const nextButtonColor: string = "#CC0033";
  const nextIcon = (
    <Lock style={{ height: 20 }} />
  );

  return (
    <Button
      style={{
        backgroundColor: disabled ? "grey" : nextButtonColor,
        color: "white",
        borderRadius: "3px",
        boxShadow: "none",
        padding: "4px 10px",
      }}
      disabled={disabled}
      className="btn"
      variant="contained"
      size="small"
      onClick={async () => {
        if (incident._id) {
          await updateCannedSimulation(incident._id, { active: false });
          await reload();
        }
      }}
    >
      {nextIcon}
      Delete Simulation
    </Button>
  );
}
