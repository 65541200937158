/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { NavLink } from "react-router-dom";

function MenuIem(p: { label: string, hidden?: boolean, icon: React.ComponentType<{}>, icon2?: React.ComponentType<{}>, onClick?: () => void }) {
  const {
    icon, icon2, label, onClick, hidden,
  } = p;
  const Icon = icon;
  const Icon2 = icon2;
  return (
    <div style={{ cursor: "pointer", visibility: hidden ? "hidden" : "visible" }} role="button" tabIndex={0} className="side-menu-items" onClick={onClick}>
      <div><Icon /></div>
      <p className="info">{label}</p>
      {!Icon2 ? <></> : <div className="collapseIcon"><Icon2 /></div>}
    </div>
  );
}

export function MenuLink(p: { to?: string, hidden?: boolean, label: string, icon: React.ComponentType<{}>, icon2?: React.ComponentType<{}>, onClick?: () => void, alternateRoutes?: string[], currentRoute?: string }) {
  const {
    to, label, icon, icon2, onClick, hidden, alternateRoutes, currentRoute,
  } = p;

  if (to) {
    return (
      <NavLink
        to={to}
        style={{ textDecoration: "none", overflow: "hidden", visibility: hidden ? "hidden" : "visible" }}
        className={({ isActive, isPending }) => [
          isPending ? "pending" : "",
          (isActive || (alternateRoutes?.length && alternateRoutes?.includes(currentRoute ?? ""))) ? "active" : "",
        ].join(" ")}
      >
        <MenuIem label={label} icon={p.icon} icon2={p.icon2} onClick={p.onClick} hidden={p.hidden} />
      </NavLink>
    );
  }
  return (
    <div style={{ textDecoration: "none", overflow: "hidden", visibility: hidden ? "hidden" : "visible" }}>
      <MenuIem label={label} icon={icon} icon2={icon2} onClick={onClick} hidden={hidden} />
    </div>
  );
}
