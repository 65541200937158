import { CADVehicle } from "@models/cad-vehicles";
import { appFetch } from "./config";

export const getCADVehicles = async (signal?: AbortSignal): Promise<CADVehicle[]> => {
  const response: Response = await appFetch("api/v2/cad-vehicle/?", { signal });
  return response.json();
};

export const toggleCADVehicleMapHidden = async (id: string, status: boolean) => {
  const response: Response = await appFetch(`api/a1/admin/toggle_map_status_cad/${id}/${status}`, {
    method: "POST",
  });
  return response.json();
};

export const toggleCADVehicleSendLocationToCAD = async (id: string, status: boolean) => {
  const response: Response = await appFetch(`api/a1/admin/vehicle-location-to-cad/${id}/${status}`, {
    method: "POST",
  });
  return response.json();
};

export const getCADVehiclesSuperAdmin = async (departmentId: string, backupRequested: boolean, signal: AbortSignal): Promise<CADVehicle[]> => {
  const response: Response = await appFetch(`api/a1/admin/cad-vehicles/${departmentId}?backupRequested=${backupRequested}`, { signal });

  return response.json();
};

export const clearCADVehiclesSuperAdmin = async (departmentId: string, restore: boolean): Promise<void> => {
  const response: Response = await appFetch(`api/a1/admin/cad-vehicles/clear/${departmentId}?restore=${restore}`, {
    method: "POST",
  });
  return response.json();
};
