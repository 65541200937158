import React from "react";
import { Typography } from "@mui/material";
import NearMeIcon from "@mui/icons-material/NearMe";
import HomeIcon from "@mui/icons-material/Home";
import FormatListBulletedRounded from "@mui/icons-material/FormatListBulletedRounded";
import classes from "./cad-status.module.css";

interface StatusInfoProps {
  detail?: string;
  iconType?: "nearMe" | "home" | "list";
}

function StatusInfo({ detail, iconType }: StatusInfoProps) {
  const renderIcon = () => {
    switch (iconType) {
      case "home":
        return <HomeIcon className={`${classes.status_group_icon}`} />;
      case "list":
        return <FormatListBulletedRounded className={`${classes.status_group_icon}`} />;
      case "nearMe":
        return <NearMeIcon className={`${classes.status_group_icon}`} />;
      default:
        return null;
    }
  };

  return (
    <div className={`${classes.status_group}`}>
      {renderIcon()}
      <Typography className={`${classes.status_group_text}`}>
        {detail}
      </Typography>
    </div>
  );
}

export default StatusInfo;
