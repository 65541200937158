/* eslint-disable camelcase */
import { CustomMaterialTable } from "@components/custom-material-table";
import { Hazard, TCIncident } from "@models/tc-incident";
import moment from "moment";
import { useState } from "react";
import IncidentTableHeaderWithToggle from "../incident-table-header-with-toggle";

export function TCIncidentHazardsTable({
  incident,
}: {
  incident: TCIncident
}) {
  const [expand, setExpand] = useState(true);
  return (
    <CustomMaterialTable<Hazard>
      data={incident?.hazards ?? []}
      hideContent={!expand}
      title={<IncidentTableHeaderWithToggle title="Hazards" expand={expand} setExpand={setExpand} />}
      header={[
        {
          name: "Hazard",
          prop: "name",
          render: (item) => item.name + (item.note ? `|${item.note}` : ""),
        },
        {
          name: "Added",
          prop: "time",
          render: (item) => {
            const { time } = item;
            return (time
              ? (
                <div>
                  <div>{moment(time).format("HH:mm:ss")}</div>
                  <div style={{ fontSize: 10 }}>{moment(time).format("MM/DD/YYYY")}</div>
                </div>
              ) : ""
            );
          },
        },
        {
          name: "Location On Scene",
          prop: "location_on_scene",
          render: (item) => {
            const { location_on_scene } = item;
            return (
              <a href="https://maps.google.com/?q={item.location_on_scene}">
                {location_on_scene}
              </a>
            );
          },
        },
        {
          name: "Removed",
          prop: "",
        },
      ]}
    />
  );
}
