import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import AccountAudioConfig from "../pages/account-audio-config";
import MainPage from "../pages/layout/superadmin/main";
import { route, urls } from "@utils/routing";
import { componentLoader } from "@utils/utilsFunctions";
import AccountPushNotificationsConfig from "../pages/account-push-notifications-config";

const AccountAdd = lazy(() => componentLoader(() => import("../pages/add-account/account-add"), 5));
const AccountCustomButtons = lazy(() => componentLoader(() => import("../pages/account-custom-buttons"), 5));
const AccountDetails = lazy(() => componentLoader(() => import("../pages/account-details/account-details"), 5));
const Accounts = lazy(() => componentLoader(() => import("../pages/accounts/accounts"), 5));
const AccountsLicensing = lazy(() => componentLoader(() => import("../pages/account-licensing/accounts-licensing"), 5));
const AccountUsersList = lazy(() => componentLoader(() => import("../pages/account-users/account-users"), 5));
const AccountUsersSearch = lazy(() => componentLoader(() => import("../pages/account-user-search/account-users-search"), 5));
const ActionLog = lazy(() => componentLoader(() => import("../pages/action-log"), 5));
const AddNewUser = lazy(() => componentLoader(() => import("../pages/add-user/add-new-user"), 5));
const AdminMessages = lazy(() => componentLoader(() => import("../pages/admin/messages/messages"), 5));
const Agencies = lazy(() => componentLoader(() => import("../pages/admin/settings/settings-tabs/agencies/agencies"), 5));
const ArcGISGroups = lazy(() => componentLoader(() => import("../pages/arcgis-groups/arcgis-groups"), 5));
const ChangeUserPassword = lazy(() => componentLoader(() => import("../pages/user-change-password"), 5));
const ChecklistDebugging = lazy(() => componentLoader(() => import("../pages/checklist-debugging"), 5));
const CSVImportTool = lazy(() => componentLoader(() => import("../pages/csv-import/csv-import-tool"), 5));
const Dashboard = lazy(() => componentLoader(() => import("../pages/dashboard/dashboard"), 5));
const DeviceStats = lazy(() => componentLoader(() => import("../pages/device-stats/device-stats"), 5));
const DeviceStatsNext = lazy(() => componentLoader(() => import("../pages/device-stats-2/device-stats"), 5));
const EditUserAccount = lazy(() => componentLoader(() => import("../pages/edit-user/edit-user-account"), 5));
const IncidentsChart = lazy(() => componentLoader(() => import("../pages/charts/incidents-chart"), 5));
const IncidentsLeftOpen = lazy(() => componentLoader(() => import("../pages/incidents-left-open/incidents-left-open"), 5));
const MailGunLog = lazy(() => componentLoader(() => import("../pages/mailgun-log"), 5));
const ManagedIncidentsChart = lazy(() => componentLoader(() => import("../pages/charts/managed-incidents-chart"), 5));
const MapsDebugging = lazy(() => componentLoader(() => import("../pages/maps-debugging/maps-debugging"), 5));
const Messages = lazy(() => componentLoader(() => import("../pages/admin/messages/messages"), 5));
const MonitorEvents = lazy(() => componentLoader(() => import("../pages/monitor-events"), 5));
const ResourceDebugging = lazy(() => componentLoader(() => import("../pages/resource-debugging/resource-debugging"), 5));
const TaskDebugging = lazy(() => componentLoader(() => import("../pages/task-debugging"), 5));
const UnmappedDevices = lazy(() => componentLoader(() => import("../pages/unmapped-devices/unmapped-devices"), 5));
const UserDeviceLogs = lazy(() => componentLoader(() => import("../pages/admin/user-device-logs"), 5));
const UsersChart = lazy(() => componentLoader(() => import("../pages/charts/users-chart"), 5));
const ValidationReport = lazy(() => componentLoader(() => import("../pages/validation-report"), 5));
const UserSignUpForm = lazy(() => componentLoader(() => import("../pages/admin/user-signup/user-signup-form"), 5));

export default function AuthenticatedSuperUser() {
  return (
    <Routes>
      <Route path="/" element={<MainPage />}>
        <Route path={route(urls.dashboard)} element={<Dashboard />} />
        <Route path={route(urls.addNewUserAccount, ["departmentId"])} element={<AddNewUser />} />
        <Route path={route(urls.userChangePassword, ["userId"])} element={<ChangeUserPassword />} />
        <Route
          path={route(urls.editUserAccount, ["userId"])}
          element={<EditUserAccount />}
        />
        <Route path={route(urls.accountsUsers, ["departmentId"])} element={<AccountUsersList />} />
        <Route path={route(urls.accountsAgencies, ["departmentId"])} element={<Agencies />} />
        <Route path={route(urls.accountsCustomButtons, ["departmentId"])} element={<AccountCustomButtons />} />
        <Route path={route(urls.accountsAudioConfig, ["departmentId"])} element={<AccountAudioConfig />} />
        <Route path={route(urls.accountsPushNotificationsConfig, ["departmentId"])} element={<AccountPushNotificationsConfig />} />
        <Route path={route(urls.accountsAdd)} element={<AccountAdd />} />
        <Route path={route(urls.accountUsersSearch)} element={<AccountUsersSearch />} />
        <Route
          path={route(urls.accountDetails, ["departmentId"])}
          element={<AccountDetails />}
        />
        <Route path={route(urls.accountsList)} element={<Accounts />} />
        <Route path={route(urls.accountsLicensing)} element={<AccountsLicensing />} />
        <Route path={route(urls.unMappedDevicesList)} element={<UnmappedDevices />} />
        <Route path={route(urls.resourceDebuggingList)} element={<ResourceDebugging />} />
        <Route path={route(urls.checklistDebuggingList)} element={<ChecklistDebugging />} />
        <Route path={route(urls.taskDebuggingList)} element={<TaskDebugging />} />
        <Route path={route(urls.actionLogList)} element={<ActionLog />} />
        <Route path={route(urls.mailgunLogList)} element={<MailGunLog />} />
        <Route path={route(urls.monitorEventsList)} element={<MonitorEvents />} />
        <Route path={route(urls.mapsDebuggingList)} element={<MapsDebugging />} />
        <Route path={route(urls.messages)} element={<Messages />} />
        <Route path={route(urls.arcGISGroupsList)} element={<ArcGISGroups />} />
        <Route path={route(urls.csvImportTool)} element={<CSVImportTool />} />
        <Route path={route(urls.messages)} element={<AdminMessages />} />
        <Route path={route(urls.adminRemoteLogs)} element={<UserDeviceLogs />} />
        <Route path={route(urls.incidentsLeftOpenList)} element={<IncidentsLeftOpen />} />
        <Route path={route(urls.validationReportList)} element={<ValidationReport />} />
        <Route path={route(urls.devicesStatsLegacy)} element={<DeviceStats />} />
        <Route path={route(urls.devicesStatsNext)} element={<DeviceStatsNext />} />
        <Route path={route(urls.incidentsChart)} element={<IncidentsChart />} />
        <Route path={route(urls.managedIncidentsChart)} element={<ManagedIncidentsChart />} />
        <Route path={route(urls.usersChart)} element={<UsersChart />} />
        <Route
          path={route(urls.addNewQuickUserAccount, ["signupKey", "departmentId"])}
          element={<UserSignUpForm />}
        />
      </Route>
    </Routes>
  );
}
