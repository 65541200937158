// cspell: words clsx

import { useState, useEffect } from "react";
import {
  IconButton, Typography, Toolbar, Tooltip, Menu, MenuItem, Checkbox,
} from "@mui/material";
import { MTableToolbar } from "material-table/dist/components/m-table-toolbar";

import clsx from "clsx";
import { useHistoryState } from "@hooks/useHistoryState";
import { SearchBox } from "@components/search-box/search-box";

interface MTableToolbarProps {
  search: boolean;
  onSearchChanged: (value: string) => void,
  dataManager: any;
}

function TableSearch(props: MTableToolbarProps) {
  const [searchTable, setSearchTable] = useHistoryState("material-table-search", "");
  const { search } = props;

  useEffect(() => {
    props.dataManager.changeSearchText(searchTable);
  }, []);

  const tableSearchHandle = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchTable(e.target.value);
    props.dataManager.changeSearchText(e.target.value);
    props.onSearchChanged(e.target.value);
  };
  const tableSearchHandleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (e.key === "Enter") {
      props.dataManager.changeSearchText(searchTable);
      props.onSearchChanged(searchTable);
    }
  };

  if (search) {
    return (
      <SearchBox
        containerStyle={{ marginRight: 10 }}
        inputProps={{
          value: searchTable,
          fullWidth: true,
          onChange: (e) => tableSearchHandle(e),
          onKeyDown: (e) => tableSearchHandleKeyPress(e),
        }}
        iconButtonProps={{
          onClick: () => props.onSearchChanged(searchTable),
        }}
      />
    );
  }
  return null;
}

export function TableToolBarRender(props: any) {
  const {
    classes, searchText, showTextRowsSelected, selectedRows, title, localization, showTitle, toolbarButtonAlignment, searchFieldAlignment,
  } = props;
  type TableToolBarState = {
    columnsButtonAnchorEl: EventTarget & HTMLButtonElement,
    exportButtonAnchorEl: EventTarget & HTMLButtonElement,
    searchText: string,
  }
  const [state, setState] = useState<TableToolBarState>({
    columnsButtonAnchorEl: null!,
    exportButtonAnchorEl: null!,
    searchText,
  });
  const localizationCombined = {
    ...MTableToolbar.defaultProps.localization,
    ...localization,
  };
  function renderToolbarTitle(title: string | JSX.Element) {
    const toolBarTitle = typeof title === "string" ? (
      <Typography
        variant="h6"
        style={{
          color: "white",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {title}
      </Typography>
    ) : (
      title
    );

    return <div className={clsx(classes.title)}>{toolBarTitle}</div>;
  }
  function renderSelectedActions() {
    return (
      <props.components.Actions
        actions={props.actions.filter(
          (a: { position: string; }) => a.position === "toolbarOnSelect",
        )}
        data={props.selectedRows}
        components={props.components}
      />
    );
  }
  function renderDefaultActions() {
    const localization = {
      ...MTableToolbar.defaultProps.localization,
      ...props.localization,
    };
    const { classes } = props;

    return (
      <div>
        {props.columnsButton && (
          <span>
            <Tooltip title={localization.showColumnsTitle}>
              <IconButton
                color="inherit"
                onClick={(event) => setState({
                  ...state,
                  columnsButtonAnchorEl: event.currentTarget,
                })}
                aria-label={localization.showColumnsAriaLabel}
              >
                <props.icons.ViewColumn />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={state.columnsButtonAnchorEl}
              open={Boolean(state.columnsButtonAnchorEl)}
              onClose={() => setState({ ...state, columnsButtonAnchorEl: null! })}
            >
              <MenuItem
                key="text"
                disabled
                style={{
                  opacity: 1,
                  fontWeight: 600,
                  fontSize: 12,
                }}
              >
                {localization.addRemoveColumns}
              </MenuItem>
              {props.columns.map((col: any) => {
                if (!col.hidden || col.hiddenByColumnsButton) {
                  return (
                    <li key={col.tableData.id}>
                      <MenuItem
                        className={classes.formControlLabel}
                        component="label"
                        htmlFor={`column-toggle-${col.tableData.id}`}
                        disabled={col.removable === false}
                      >
                        <Checkbox
                          checked={!col.hidden}
                          id={`column-toggle-${col.tableData.id}`}
                          onChange={() => props.onColumnsChanged(col, !col.hidden)}
                          color="secondary"
                        />
                        <span>{col.title}</span>
                      </MenuItem>
                    </li>
                  );
                }
                return null;
              })}
            </Menu>
          </span>
        )}
        {props.exportButton && (
          <span>
            <Tooltip title={localization.exportTitle}>
              <IconButton
                color="inherit"
                onClick={(event) => setState({
                  ...state,
                  exportButtonAnchorEl: event.currentTarget,
                })}
                aria-label={localization.exportAriaLabel}
              >
                <props.icons.Export />
              </IconButton>
            </Tooltip>
          </span>
        )}
        <span>
          <props.components.Actions
            actions={
              props.actions
              && props.actions.filter((a: { position: string; }) => a.position === "toolbar")
            }
            components={props.components}
          />
        </span>
      </div>
    );
  }
  function renderActions() {
    const { classes } = props;

    return (
      <div className={clsx(classes.actions, "material-table-actions-container")}>
        <div>
          {props.selectedRows && props.selectedRows.length > 0
            ? renderSelectedActions()
            : renderDefaultActions()}
        </div>
      </div>
    );
  }
  const tittle = showTextRowsSelected
    && selectedRows
    && selectedRows.length > 0
    ? typeof localizationCombined.nRowsSelected === "function"
      ? localizationCombined.nRowsSelected(selectedRows.length)
      : localizationCombined.nRowsSelected.replace(
        "{0}",
        selectedRows.length,
      )
    : showTitle
      ? title
      : null;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]:
          showTextRowsSelected
          && selectedRows
          && selectedRows.length > 0,
      }, "Header", classes.customToolbar ?? {})}
    >
      {tittle && renderToolbarTitle(tittle)}
      {searchFieldAlignment === "left" && TableSearch(props)}
      {toolbarButtonAlignment === "left" && renderActions()}
      {searchFieldAlignment === "right" && TableSearch(props)}
      {toolbarButtonAlignment === "right" && renderActions()}
    </Toolbar>
  );
}

Object.defineProperty(MTableToolbar.prototype, "renderSearch", {
  value(this: { props: MTableToolbarProps }) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <TableSearch {...this.props} />;
  },
});

Object.defineProperty(MTableToolbar.prototype, "render", {
  value(this: { props: MTableToolbarProps }) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <TableToolBarRender {...this.props} />;
  },
});
