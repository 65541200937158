import { createContext, useState } from "react";

export const DashboardSearchContext = createContext<[{ search: string, tab: string, openFilter: boolean } | null, React.Dispatch<{ search: string, tab: string, openFilter: boolean }>]>([null!, () => null!]);

export function DashboardSearchContextProvider(props: { children: JSX.Element }) {
  const [dashboardSearch, setDashboardSearch] = useState({
    search: "",
    tab: "active_incidents",
    openFilter: false,
  });

  return (
    <DashboardSearchContext.Provider value={[dashboardSearch, setDashboardSearch]}>
      {props.children}
    </DashboardSearchContext.Provider>
  );
}
