import timezone from "moment-timezone";
import { Color } from "@models/color";
import { Section } from "@models/dashboard";
import {
  Countries,
  HideLayerOption,
  IncidentEventTypeItem,
  IncidentHistoryTypeItem,
  MultiCheckboxItem,
  SelectableState,
  States,
} from "@models/static-data";
import { FireMapperHost } from "@models/department";

export const tabs: Section[] = [
  {
    label: "Active Incidents",
    value: "active_incidents",
  },
  {
    label: "Shared Active Incidents",
    value: "shared_active_incidents",
  },
  {
    label: "Units Committed",
    value: "units_committed",
  },
  {
    label: "Shared Managed Incidents",
    value: "shared_managed_incidents",
  },
  {
    label: "Managed Incidents",
    value: "managed_incidents",
  },
];

export const defaultDisclaimerMessage = "By logging in to Tablet Command/TC Mobile you acknowledge that you are accessing sensitive or confidential information. This information is protected by agency policy and you accept any liability related to sharing said information.";

/* cSpell:disable */
export const americaStates: States[] = [
  {
    name: "Alabama",
    abbreviation: "AL",
  }, {
    name: "Alaska",
    abbreviation: "AK",
  }, {
    name: "American Samoa",
    abbreviation: "AS",
  }, {
    name: "Arizona",
    abbreviation: "AZ",
  }, {
    name: "Arkansas",
    abbreviation: "AR",
  }, {
    name: "California",
    abbreviation: "CA",
  }, {
    name: "Colorado",
    abbreviation: "CO",
  }, {
    name: "Connecticut",
    abbreviation: "CT",
  }, {
    name: "Delaware",
    abbreviation: "DE",
  }, {
    name: "District Of Columbia",
    abbreviation: "DC",
  }, {
    name: "Federated States Of Micronesia",
    abbreviation: "FM",
  }, {
    name: "Florida",
    abbreviation: "FL",
  }, {
    name: "Georgia",
    abbreviation: "GA",
  }, {
    name: "Guam",
    abbreviation: "GU",
  }, {
    name: "Hawaii",
    abbreviation: "HI",
  }, {
    name: "Idaho",
    abbreviation: "ID",
  }, {
    name: "Illinois",
    abbreviation: "IL",
  }, {
    name: "Indiana",
    abbreviation: "IN",
  }, {
    name: "Iowa",
    abbreviation: "IA",
  }, {
    name: "Kansas",
    abbreviation: "KS",
  }, {
    name: "Kentucky",
    abbreviation: "KY",
  }, {
    name: "Louisiana",
    abbreviation: "LA",
  }, {
    name: "Maine",
    abbreviation: "ME",
  }, {
    name: "Marshall Islands",
    abbreviation: "MH",
  }, {
    name: "Maryland",
    abbreviation: "MD",
  }, {
    name: "Massachusetts",
    abbreviation: "MA",
  }, {
    name: "Michigan",
    abbreviation: "MI",
  }, {
    name: "Minnesota",
    abbreviation: "MN",
  }, {
    name: "Mississippi",
    abbreviation: "MS",
  }, {
    name: "Missouri",
    abbreviation: "MO",
  }, {
    name: "Montana",
    abbreviation: "MT",
  }, {
    name: "Nebraska",
    abbreviation: "NE",
  }, {
    name: "Nevada",
    abbreviation: "NV",
  }, {
    name: "New Hampshire",
    abbreviation: "NH",
  }, {
    name: "New Jersey",
    abbreviation: "NJ",
  }, {
    name: "New Mexico",
    abbreviation: "NM",
  }, {
    name: "New York",
    abbreviation: "NY",
  }, {
    name: "North Carolina",
    abbreviation: "NC",
  }, {
    name: "North Dakota",
    abbreviation: "ND",
  }, {
    name: "Northern Mariana Islands",
    abbreviation: "MP",
  }, {
    name: "Ohio",
    abbreviation: "OH",
  }, {
    name: "Oklahoma",
    abbreviation: "OK",
  }, {
    name: "Oregon",
    abbreviation: "OR",
  }, {
    name: "Palau",
    abbreviation: "PW",
  }, {
    name: "Pennsylvania",
    abbreviation: "PA",
  }, {
    name: "Puerto Rico",
    abbreviation: "PR",
  }, {
    name: "Rhode Island",
    abbreviation: "RI",
  }, {
    name: "South Carolina",
    abbreviation: "SC",
  }, {
    name: "South Dakota",
    abbreviation: "SD",
  }, {
    name: "Tennessee",
    abbreviation: "TN",
  }, {
    name: "Texas",
    abbreviation: "TX",
  }, {
    name: "Utah",
    abbreviation: "UT",
  }, {
    name: "Vermont",
    abbreviation: "VT",
  }, {
    name: "Virgin Islands",
    abbreviation: "VI",
  }, {
    name: "Virginia",
    abbreviation: "VA",
  }, {
    name: "Washington",
    abbreviation: "WA",
  }, {
    name: "West Virginia",
    abbreviation: "WV",
  }, {
    name: "Wisconsin",
    abbreviation: "WI",
  }, {
    name: "Wyoming",
    abbreviation: "WY",
  },
];

export const canadaStates: States[] = [
  {
    name: "Alberta",
    abbreviation: "AB",
  }, {
    name: "British Columbia",
    abbreviation: "BC",
  }, {
    name: "Manitoba",
    abbreviation: "MB",
  }, {
    name: "New Brunswick",
    abbreviation: "NB",
  }, {
    name: "Newfoundland",
    abbreviation: "NL",
  }, {
    name: "Northwest Territories",
    abbreviation: "NT",
  }, {
    name: "Nova Scotia",
    abbreviation: "NS",
  }, {
    name: "Nunavut",
    abbreviation: "NU",
  }, {
    name: "Ontario",
    abbreviation: "ON",
  }, {
    name: "Prince Edward Island",
    abbreviation: "PE",
  }, {
    name: "Quebec",
    abbreviation: "QC",
  }, {
    name: "Saskatchewan",
    abbreviation: "SK",
  }, {
    name: "Yukon Territory",
    abbreviation: "YT",
  },
];

export const countriesList: Countries[] = [
  {
    abbreviation: "BD",
    name: "Bangladesh",
  },
  {
    abbreviation: "BE",
    name: "Belgium",
  },
  {
    abbreviation: "BF",
    name: "Burkina Faso",
  },
  {
    abbreviation: "BG",
    name: "Bulgaria",
  },
  {
    abbreviation: "BA",
    name: "Bosnia and Herzegovina",
  },
  {
    abbreviation: "BB",
    name: "Barbados",
  },
  {
    abbreviation: "WF",
    name: "Wallis and Futuna",
  },
  {
    abbreviation: "BL",
    name: "Saint Barthelemy",
  },
  {
    abbreviation: "BM",
    name: "Bermuda",
  },
  {
    abbreviation: "BN",
    name: "Brunei",
  },
  {
    abbreviation: "BO",
    name: "Bolivia",
  },
  {
    abbreviation: "BH",
    name: "Bahrain",
  },
  {
    abbreviation: "BI",
    name: "Burundi",
  },
  {
    abbreviation: "BJ",
    name: "Benin",
  },
  {
    abbreviation: "BT",
    name: "Bhutan",
  },
  {
    abbreviation: "JM",
    name: "Jamaica",
  },
  {
    abbreviation: "BV",
    name: "Bouvet Island",
  },
  {
    abbreviation: "BW",
    name: "Botswana",
  },
  {
    abbreviation: "WS",
    name: "Samoa",
  },
  {
    abbreviation: "BQ",
    name: "Bonaire, Saint Eustatius and Saba ",
  },
  {
    abbreviation: "BR",
    name: "Brazil",
  },
  {
    abbreviation: "BS",
    name: "Bahamas",
  },
  {
    abbreviation: "JE",
    name: "Jersey",
  },
  {
    abbreviation: "BY",
    name: "Belarus",
  },
  {
    abbreviation: "BZ",
    name: "Belize",
  },
  {
    abbreviation: "RU",
    name: "Russia",
  },
  {
    abbreviation: "RW",
    name: "Rwanda",
  },
  {
    abbreviation: "RS",
    name: "Serbia",
  },
  {
    abbreviation: "TL",
    name: "East Timor",
  },
  {
    abbreviation: "RE",
    name: "Reunion",
  },
  {
    abbreviation: "TM",
    name: "Turkmenistan",
  },
  {
    abbreviation: "TJ",
    name: "Tajikistan",
  },
  {
    abbreviation: "RO",
    name: "Romania",
  },
  {
    abbreviation: "TK",
    name: "Tokelau",
  },
  {
    abbreviation: "GW",
    name: "Guinea-Bissau",
  },
  {
    abbreviation: "GU",
    name: "Guam",
  },
  {
    abbreviation: "GT",
    name: "Guatemala",
  },
  {
    abbreviation: "GS",
    name: "South Georgia and the South Sandwich Islands",
  },
  {
    abbreviation: "GR",
    name: "Greece",
  },
  {
    abbreviation: "GQ",
    name: "Equatorial Guinea",
  },
  {
    abbreviation: "GP",
    name: "Guadeloupe",
  },
  {
    abbreviation: "JP",
    name: "Japan",
  },
  {
    abbreviation: "GY",
    name: "Guyana",
  },
  {
    abbreviation: "GG",
    name: "Guernsey",
  },
  {
    abbreviation: "GF",
    name: "French Guiana",
  },
  {
    abbreviation: "GE",
    name: "Georgia",
  },
  {
    abbreviation: "GD",
    name: "Grenada",
  },
  {
    abbreviation: "GB",
    name: "United Kingdom",
  },
  {
    abbreviation: "GA",
    name: "Gabon",
  },
  {
    abbreviation: "SV",
    name: "El Salvador",
  },
  {
    abbreviation: "GN",
    name: "Guinea",
  },
  {
    abbreviation: "GM",
    name: "Gambia",
  },
  {
    abbreviation: "GL",
    name: "Greenland",
  },
  {
    abbreviation: "GI",
    name: "Gibraltar",
  },
  {
    abbreviation: "GH",
    name: "Ghana",
  },
  {
    abbreviation: "OM",
    name: "Oman",
  },
  {
    abbreviation: "TN",
    name: "Tunisia",
  },
  {
    abbreviation: "JO",
    name: "Jordan",
  },
  {
    abbreviation: "HR",
    name: "Croatia",
  },
  {
    abbreviation: "HT",
    name: "Haiti",
  },
  {
    abbreviation: "HU",
    name: "Hungary",
  },
  {
    abbreviation: "HK",
    name: "Hong Kong",
  },
  {
    abbreviation: "HN",
    name: "Honduras",
  },
  {
    abbreviation: "HM",
    name: "Heard Island and McDonald Islands",
  },
  {
    abbreviation: "VE",
    name: "Venezuela",
  },
  {
    abbreviation: "PR",
    name: "Puerto Rico",
  },
  {
    abbreviation: "PS",
    name: "Palestinian Territory",
  },
  {
    abbreviation: "PW",
    name: "Palau",
  },
  {
    abbreviation: "PT",
    name: "Portugal",
  },
  {
    abbreviation: "SJ",
    name: "Svalbard and Jan Mayen",
  },
  {
    abbreviation: "PY",
    name: "Paraguay",
  },
  {
    abbreviation: "IQ",
    name: "Iraq",
  },
  {
    abbreviation: "PA",
    name: "Panama",
  },
  {
    abbreviation: "PF",
    name: "French Polynesia",
  },
  {
    abbreviation: "PG",
    name: "Papua New Guinea",
  },
  {
    abbreviation: "PE",
    name: "Peru",
  },
  {
    abbreviation: "PK",
    name: "Pakistan",
  },
  {
    abbreviation: "PH",
    name: "Philippines",
  },
  {
    abbreviation: "PN",
    name: "Pitcairn",
  },
  {
    abbreviation: "PL",
    name: "Poland",
  },
  {
    abbreviation: "PM",
    name: "Saint Pierre and Miquelon",
  },
  {
    abbreviation: "ZM",
    name: "Zambia",
  },
  {
    abbreviation: "EH",
    name: "Western Sahara",
  },
  {
    abbreviation: "EE",
    name: "Estonia",
  },
  {
    abbreviation: "EG",
    name: "Egypt",
  },
  {
    abbreviation: "ZA",
    name: "South Africa",
  },
  {
    abbreviation: "EC",
    name: "Ecuador",
  },
  {
    abbreviation: "IT",
    name: "Italy",
  },
  {
    abbreviation: "VN",
    name: "Vietnam",
  },
  {
    abbreviation: "SB",
    name: "Solomon Islands",
  },
  {
    abbreviation: "ET",
    name: "Ethiopia",
  },
  {
    abbreviation: "SO",
    name: "Somalia",
  },
  {
    abbreviation: "ZW",
    name: "Zimbabwe",
  },
  {
    abbreviation: "SA",
    name: "Saudi Arabia",
  },
  {
    abbreviation: "ES",
    name: "Spain",
  },
  {
    abbreviation: "ER",
    name: "Eritrea",
  },
  {
    abbreviation: "ME",
    name: "Montenegro",
  },
  {
    abbreviation: "MD",
    name: "Moldova",
  },
  {
    abbreviation: "MG",
    name: "Madagascar",
  },
  {
    abbreviation: "MF",
    name: "Saint Martin",
  },
  {
    abbreviation: "MA",
    name: "Morocco",
  },
  {
    abbreviation: "MC",
    name: "Monaco",
  },
  {
    abbreviation: "UZ",
    name: "Uzbekistan",
  },
  {
    abbreviation: "MM",
    name: "Myanmar",
  },
  {
    abbreviation: "ML",
    name: "Mali",
  },
  {
    abbreviation: "MO",
    name: "Macao",
  },
  {
    abbreviation: "MN",
    name: "Mongolia",
  },
  {
    abbreviation: "MH",
    name: "Marshall Islands",
  },
  {
    abbreviation: "MK",
    name: "Macedonia",
  },
  {
    abbreviation: "MU",
    name: "Mauritius",
  },
  {
    abbreviation: "MT",
    name: "Malta",
  },
  {
    abbreviation: "MW",
    name: "Malawi",
  },
  {
    abbreviation: "MV",
    name: "Maldives",
  },
  {
    abbreviation: "MQ",
    name: "Martinique",
  },
  {
    abbreviation: "MP",
    name: "Northern Mariana Islands",
  },
  {
    abbreviation: "MS",
    name: "Montserrat",
  },
  {
    abbreviation: "MR",
    name: "Mauritania",
  },
  {
    abbreviation: "IM",
    name: "Isle of Man",
  },
  {
    abbreviation: "UG",
    name: "Uganda",
  },
  {
    abbreviation: "TZ",
    name: "Tanzania",
  },
  {
    abbreviation: "MY",
    name: "Malaysia",
  },
  {
    abbreviation: "MX",
    name: "Mexico",
  },
  {
    abbreviation: "IL",
    name: "Israel",
  },
  {
    abbreviation: "FR",
    name: "France",
  },
  {
    abbreviation: "IO",
    name: "British Indian Ocean Territory",
  },
  {
    abbreviation: "SH",
    name: "Saint Helena",
  },
  {
    abbreviation: "FI",
    name: "Finland",
  },
  {
    abbreviation: "FJ",
    name: "Fiji",
  },
  {
    abbreviation: "FK",
    name: "Falkland Islands",
  },
  {
    abbreviation: "FM",
    name: "Micronesia",
  },
  {
    abbreviation: "FO",
    name: "Faroe Islands",
  },
  {
    abbreviation: "NI",
    name: "Nicaragua",
  },
  {
    abbreviation: "NL",
    name: "Netherlands",
  },
  {
    abbreviation: "NO",
    name: "Norway",
  },
  {
    abbreviation: "NA",
    name: "Namibia",
  },
  {
    abbreviation: "VU",
    name: "Vanuatu",
  },
  {
    abbreviation: "NC",
    name: "New Caledonia",
  },
  {
    abbreviation: "NE",
    name: "Niger",
  },
  {
    abbreviation: "NF",
    name: "Norfolk Island",
  },
  {
    abbreviation: "NG",
    name: "Nigeria",
  },
  {
    abbreviation: "NZ",
    name: "New Zealand",
  },
  {
    abbreviation: "NP",
    name: "Nepal",
  },
  {
    abbreviation: "NR",
    name: "Nauru",
  },
  {
    abbreviation: "NU",
    name: "Niue",
  },
  {
    abbreviation: "CK",
    name: "Cook Islands",
  },
  {
    abbreviation: "XK",
    name: "Kosovo",
  },
  {
    abbreviation: "CI",
    name: "Ivory Coast",
  },
  {
    abbreviation: "CH",
    name: "Switzerland",
  },
  {
    abbreviation: "CO",
    name: "Colombia",
  },
  {
    abbreviation: "CN",
    name: "China",
  },
  {
    abbreviation: "CM",
    name: "Cameroon",
  },
  {
    abbreviation: "CL",
    name: "Chile",
  },
  {
    abbreviation: "CC",
    name: "Cocos Islands",
  },
  {
    abbreviation: "CA",
    name: "Canada",
  },
  {
    abbreviation: "CG",
    name: "Republic of the Congo",
  },
  {
    abbreviation: "CF",
    name: "Central African Republic",
  },
  {
    abbreviation: "CD",
    name: "Democratic Republic of the Congo",
  },
  {
    abbreviation: "CZ",
    name: "Czech Republic",
  },
  {
    abbreviation: "CY",
    name: "Cyprus",
  },
  {
    abbreviation: "CX",
    name: "Christmas Island",
  },
  {
    abbreviation: "CR",
    name: "Costa Rica",
  },
  {
    abbreviation: "CW",
    name: "Curacao",
  },
  {
    abbreviation: "CV",
    name: "Cape Verde",
  },
  {
    abbreviation: "CU",
    name: "Cuba",
  },
  {
    abbreviation: "SZ",
    name: "Swaziland",
  },
  {
    abbreviation: "SY",
    name: "Syria",
  },
  {
    abbreviation: "SX",
    name: "Sint Maarten",
  },
  {
    abbreviation: "KG",
    name: "Kyrgyzstan",
  },
  {
    abbreviation: "KE",
    name: "Kenya",
  },
  {
    abbreviation: "SS",
    name: "South Sudan",
  },
  {
    abbreviation: "SR",
    name: "Suriname",
  },
  {
    abbreviation: "KI",
    name: "Kiribati",
  },
  {
    abbreviation: "KH",
    name: "Cambodia",
  },
  {
    abbreviation: "KN",
    name: "Saint Kitts and Nevis",
  },
  {
    abbreviation: "KM",
    name: "Comoros",
  },
  {
    abbreviation: "ST",
    name: "Sao Tome and Principe",
  },
  {
    abbreviation: "SK",
    name: "Slovakia",
  },
  {
    abbreviation: "KR",
    name: "South Korea",
  },
  {
    abbreviation: "SI",
    name: "Slovenia",
  },
  {
    abbreviation: "KP",
    name: "North Korea",
  },
  {
    abbreviation: "KW",
    name: "Kuwait",
  },
  {
    abbreviation: "SN",
    name: "Senegal",
  },
  {
    abbreviation: "SM",
    name: "San Marino",
  },
  {
    abbreviation: "SL",
    name: "Sierra Leone",
  },
  {
    abbreviation: "SC",
    name: "Seychelles",
  },
  {
    abbreviation: "KZ",
    name: "Kazakhstan",
  },
  {
    abbreviation: "KY",
    name: "Cayman Islands",
  },
  {
    abbreviation: "SG",
    name: "Singapore",
  },
  {
    abbreviation: "SE",
    name: "Sweden",
  },
  {
    abbreviation: "SD",
    name: "Sudan",
  },
  {
    abbreviation: "DO",
    name: "Dominican Republic",
  },
  {
    abbreviation: "DM",
    name: "Dominica",
  },
  {
    abbreviation: "DJ",
    name: "Djibouti",
  },
  {
    abbreviation: "DK",
    name: "Denmark",
  },
  {
    abbreviation: "VG",
    name: "British Virgin Islands",
  },
  {
    abbreviation: "DE",
    name: "Germany",
  },
  {
    abbreviation: "YE",
    name: "Yemen",
  },
  {
    abbreviation: "DZ",
    name: "Algeria",
  },
  {
    abbreviation: "US",
    name: "United States",
  },
  {
    abbreviation: "UY",
    name: "Uruguay",
  },
  {
    abbreviation: "YT",
    name: "Mayotte",
  },
  {
    abbreviation: "UM",
    name: "United States Minor Outlying Islands",
  },
  {
    abbreviation: "LB",
    name: "Lebanon",
  },
  {
    abbreviation: "LC",
    name: "Saint Lucia",
  },
  {
    abbreviation: "LA",
    name: "Laos",
  },
  {
    abbreviation: "TV",
    name: "Tuvalu",
  },
  {
    abbreviation: "TW",
    name: "Taiwan",
  },
  {
    abbreviation: "TT",
    name: "Trinidad and Tobago",
  },
  {
    abbreviation: "TR",
    name: "Turkey",
  },
  {
    abbreviation: "LK",
    name: "Sri Lanka",
  },
  {
    abbreviation: "LI",
    name: "Liechtenstein",
  },
  {
    abbreviation: "LV",
    name: "Latvia",
  },
  {
    abbreviation: "TO",
    name: "Tonga",
  },
  {
    abbreviation: "LT",
    name: "Lithuania",
  },
  {
    abbreviation: "LU",
    name: "Luxembourg",
  },
  {
    abbreviation: "LR",
    name: "Liberia",
  },
  {
    abbreviation: "LS",
    name: "Lesotho",
  },
  {
    abbreviation: "TH",
    name: "Thailand",
  },
  {
    abbreviation: "TF",
    name: "French Southern Territories",
  },
  {
    abbreviation: "TG",
    name: "Togo",
  },
  {
    abbreviation: "TD",
    name: "Chad",
  },
  {
    abbreviation: "TC",
    name: "Turks and Caicos Islands",
  },
  {
    abbreviation: "LY",
    name: "Libya",
  },
  {
    abbreviation: "VA",
    name: "Vatican",
  },
  {
    abbreviation: "VC",
    name: "Saint Vincent and the Grenadines",
  },
  {
    abbreviation: "AE",
    name: "United Arab Emirates",
  },
  {
    abbreviation: "AD",
    name: "Andorra",
  },
  {
    abbreviation: "AG",
    name: "Antigua and Barbuda",
  },
  {
    abbreviation: "AF",
    name: "Afghanistan",
  },
  {
    abbreviation: "AI",
    name: "Anguilla",
  },
  {
    abbreviation: "VI",
    name: "U.S. Virgin Islands",
  },
  {
    abbreviation: "IS",
    name: "Iceland",
  },
  {
    abbreviation: "IR",
    name: "Iran",
  },
  {
    abbreviation: "AM",
    name: "Armenia",
  },
  {
    abbreviation: "AL",
    name: "Albania",
  },
  {
    abbreviation: "AO",
    name: "Angola",
  },
  {
    abbreviation: "AQ",
    name: "Antarctica",
  },
  {
    abbreviation: "AS",
    name: "American Samoa",
  },
  {
    abbreviation: "AR",
    name: "Argentina",
  },
  {
    abbreviation: "AU",
    name: "Australia",
  },
  {
    abbreviation: "AT",
    name: "Austria",
  },
  {
    abbreviation: "AW",
    name: "Aruba",
  },
  {
    abbreviation: "IN",
    name: "India",
  },
  {
    abbreviation: "AX",
    name: "Aland Islands",
  },
  {
    abbreviation: "AZ",
    name: "Azerbaijan",
  },
  {
    abbreviation: "IE",
    name: "Ireland",
  },
  {
    abbreviation: "ID",
    name: "Indonesia",
  },
  {
    abbreviation: "UA",
    name: "Ukraine",
  },
  {
    abbreviation: "QA",
    name: "Qatar",
  },
  {
    abbreviation: "MZ",
    name: "Mozambique",
  },
];
/* cSpell:enable */

export const colors: Color[] = [
  {
    background: "#006453",
    text: "#F8F9F9",
  },
  {
    background: "#00a388",
    text: "#F8F9F9",
  },
  {
    background: "#006c3f",
    text: "#F8F9F9",
  },
  {
    background: "#00b167",
    text: "#F8F9F9",
  },
  {
    background: "#005072",
    text: "#F8F9F9",
  },
  {
    background: "#0082ba",
    text: "#F8F9F9",
  },
  {
    background: "#572c5e",
    text: "#F8F9F9",
  },
  {
    background: "#9a40ab",
    text: "#F8F9F9",
  },
  {
    background: "#172631",
    text: "#F8F9F9",
  },
  {
    background: "#253f50",
    text: "#F8F9F9",
  },
  {
    background: "#836506",
    text: "#F8F9F9",
  },
  {
    background: "#ff9700",
    text: "#F8F9F9",
  },
  {
    background: "#823f07",
    text: "#F8F9F9",
  },
  {
    background: "#e84a00",
    text: "#F8F9F9",
  },
  {
    background: "#852116",
    text: "#F8F9F9",
  },
  {
    background: "#d42a15",
    text: "#F8F9F9",
  },
  {
    background: "#7a7d7d",
    text: "#F8F9F9",
  },
  {
    background: "#bbc3c7",
    text: "#F8F9F9",
  },
  {
    background: "#4a5656",
    text: "#F8F9F9",
  },
  {
    background: "#7b8c8d",
    text: "#F8F9F9",
  },
];

export const normalized = [
  { name: "assignedToPost" },
  { name: "atHospital" },
  { name: "atPatient" },
  { name: "availOnScene" },
  { name: "delayedAvailable" },
  { name: "dispatched" },
  { name: "dispatched2nd" },
  { name: "enrouteToPost" },
  { name: "inQuarters" },
  { name: "inService" },
  { name: "localArea" },
  { name: "multiAssign" },
  { name: "offDuty" },
  { name: "onScene" },
  { name: "onScene2nd" },
  { name: "outOfService" },
  { name: "responding" },
  { name: "responding2nd" },
  { name: "shiftPending" },
  { name: "staged" },
  { name: "transporting" },
];

export const closeInValues = [
  { value: 1, FormLabel: "1 minute" },
  { value: 2, FormLabel: "2 minutes" },
  { value: 3, FormLabel: "3 minutes" },
  { value: 4, FormLabel: "4 minutes" },
  { value: 5, FormLabel: "5 minutes" },
  { value: 10, FormLabel: "10 minutes" },
  { value: 60, FormLabel: "1 hour" },
  { value: 180, FormLabel: "3 hours" },
];

export const closeInValuesInSeconds = [
  { value: 60, FormLabel: "1 minute" },
  { value: 120, FormLabel: "2 minutes" },
  { value: 180, FormLabel: "3 minutes" },
  { value: 240, FormLabel: "4 minutes" },
  { value: 300, FormLabel: "5 minutes" },
  { value: 600, FormLabel: "10 minutes" },
  { value: 1200, FormLabel: "20 minutes" },
  { value: 1800, FormLabel: "30 minutes" },
  { value: 3600, FormLabel: "1 hour" },
  { value: 10800, FormLabel: "3 hours" },
];

export const unitsStatuses = [
  { objectProperty: "TimeDispatched", FormLabel: "DSP" },
  { objectProperty: "TimeEnroute", FormLabel: "RSP" },
  { objectProperty: "TimeStaged", FormLabel: "STG" },
  { objectProperty: "TimeArrived", FormLabel: "ONS" },
  { objectProperty: "TimeTransporting", FormLabel: "TR" },
  { objectProperty: "TimeAtHospital", FormLabel: "AH" },
  { objectProperty: "TimeCleared", FormLabel: "AVL" },
];

export const accountTypeOptions = [
  "production",
  "training",
  "development",
];

export const accountIndustryOptions = [
  "fire",
  "law",
  "university",
  "utility",
];

export enum AccountTypeOptionsEnum {
  "Production" = "production",
  "Training" = "training",
  "Development" = "development"
}

export const personnelImportTypeOptions = [
  "aladtec",
  "crewsense",
  "eso",
  "firstdue",
  "csv",
  "netduty",
  "telestaff",
];

export const URLProtocolOptions = [
  "sftp",
  "http",
  "https",
];

export const jumpPointOptions = [
  { name: "None", value: "" },
  { name: "Google", value: "google" },
];

export const timezones = timezone.tz.names();

export const americanAndCanadianStates: SelectableState[] = [
  ...americaStates.map((state) => ({ ...state, type: "US" })),
  ...canadaStates.map((state) => ({ ...state, type: "CA" })),
];

const defaultHideAVLLayerAtZoomLevelDefault: HideLayerOption = {
  option: "7 miles",
  value: 45000,
};

export const hideAVLLayerAtZoomLevel: HideLayerOption[] = [
  {
    option: "1 mile",
    value: 6500,
  },
  {
    option: "3 miles",
    value: 19000,
  },
  defaultHideAVLLayerAtZoomLevelDefault,
  {
    option: "10 miles",
    value: 65000,
  },
  {
    option: "15 miles",
    value: 97500,
  },
  {
    option: "20 miles",
    value: 130000,
  },
  {
    option: "40 miles",
    value: 260000,
  },
  {
    option: "100 miles",
    value: 650000,
  },
];

export function getSelectedHideAVLLayerAtZoomLevel(options: HideLayerOption[], current: number | undefined | null): HideLayerOption {
  if (!current || !Number.isInteger(current) || !Number.isFinite(current)) {
    return defaultHideAVLLayerAtZoomLevelDefault;
  }

  const sorted = options.sort((a, b) => a.value - b.value);
  let found = sorted[0];
  sorted.forEach((item) => {
    if (item.value <= current) {
      found = item;
    }
  });

  return found;
}

export const refreshLayerIntervalLabels = [
  {
    option: "Disabled",
    value: 0,
  },
  {
    option: "15 seconds",
    value: 15,
  },
  {
    option: "30 seconds",
    value: 30,
  },
  {
    option: "1 minute",
    value: 60,
  },
  {
    option: "5 minutes",
    value: 300,
  },
  {
    option: "10 minutes",
    value: 600,
  },
  {
    option: "1 hour",
    value: 3600,
  },
  {
    option: "3 hours",
    value: 10800,
  },
];

export const fireMapperHosts: { option: string, value: FireMapperHost }[] = [
  {
    option: "Production (AU)",
    value: "",
  },
  {
    option: "Production (US)",
    value: "us",
  },
  {
    option: "Staging",
    value: "staging",
  },
];

export const UserAuthenticationTypes: MultiCheckboxItem[] = [
  {
    label: "Password",
    value: "password",
  },
  {
    label: "Google",
    value: "o-google",
  },
  {
    label: "Enterprise",
    value: "saml",
  },
];

export const incidentHistoryTypeOptions: IncidentHistoryTypeItem[] = [
  { name: "Assignment", value: 1 },
  { name: "Battalion", value: 2 },
  { name: "Checklist", value: 3 },
  { name: "Checklist Item", value: 4 },
  { name: "Group/Division", value: 5 },
  { name: "Hazard", value: 6 },
  { name: "Incident", value: 7 },
  { name: "Incident Checklist", value: 8 },
  { name: "Incident Resource", value: 9 },
  { name: "Specialty", value: 10 },
  //
  { name: "Unit", value: 11 },
  { name: "Unit-Specialty", value: 12 },
  { name: "Note", value: 13 },
  { name: "CAD Comment", value: 14 },
  // { name: "Preview CAD Comment", value: 15 },
  { name: "Template", value: 16 },
  { name: "Takeover", value: 17 },
  { name: "User Comment", value: 18 },
  { name: "Incident Created", value: 19 },
  { name: "Stopwatch", value: 20 },
  //
  { name: "PAR Unit", value: 21 },
  { name: "PAR Complete", value: 22 },
  { name: "Unit Propagated", value: 23 },
  { name: "Columns Position Changed", value: 24 },
  { name: "Incident Extended", value: 25 },
];

export const incidentEventTypeOptions: IncidentEventTypeItem[] = [
  { name: "User ACK", value: "userackcomment" },
  { name: "User Comment", value: "usercomment" },
];
