import moment from "moment";

export default function TableDate(prop: { date?: string }) {
  const { date } = prop;

  return (
    <div className="dateFormat">
      <div className="longFormat">
        {moment(date).format("MM/DD/YY")}
      </div>
      <div className="hourFormat">
        {moment(date).format("HH:mm")}
      </div>

    </div>
  );
}
