import {
  CADIncident,
} from "@models/cad";
import {
  Page, Text, View, Document, StyleSheet, Image,
} from "@react-pdf/renderer";
import image from "../../../img/tcIconRed.png";
import moment from "moment";
import { getDiffInMinutes, parseLocation } from "@utils/utilsFunctions";
import { TCIncident, Units } from "@models/tc-incident";
import {
  PDFRenderChangesDetails,
  PDFRenderCommentRow,
  PDFRenderEventsDetails,
  PDFRenderFastForwardIcon,
  PDFRenderForwardArrowIcon,
  PDFRenderPersonIcon,
  PDFRenderTableDateField,
  PDFRenderTableHeader,
} from "./tc-incident-pdf-components";
import { SafetyPriorityKeyword } from "@models/department";
import { dateDelta } from "@utils/dateDelta";

const unitsTimesTableHeader = ["Unit", "DSP", "RSP", "STG", "ONS", "TR", "AH", "AVL", "Personnel"];
const unitsAssignmentsTableHeader = ["Unit", "Alarm Level", "Status", "Task", "Time", "Personnel"];
const hazardsTableHeader = ["Hazard", "Added", "Location On Scene", "Removed"];

const styles = StyleSheet.create({
  textBig: {
    fontSize: 16,
  },
  textNormal: {
    fontSize: 14,
  },
  textSmall: {
    fontSize: 10,
  },
  page: {
    padding: 10,
    paddingBottom: 20,
    width: "100%",
  },
  viewWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#EDEEED",
    padding: 5,
    width: "100%",
    rowGap: 5,
  },
  viewHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  unitsHeaderCell: {
    fontSize: 10,
    padding: "5px 2px",
    textAlign: "center",
    color: "white",
  },
  cellMinWidth: {
    minWidth: 45,
  },
  assignmentTableCellMinWidth: {
    minWidth: 60,
  },
  hazardsTableCellMinWidth: {
    minWidth: 150,
  },
  pageNumber: {
    textAlign: "right",
    fontSize: 10,
    bottom: 0,
    left: 0,
    width: "100%",
    padding: 5,
    position: "absolute",
  },
});

const formatDate = (date: number | undefined) => moment((date ?? 0) * 1000).format(
  "HH:mm:ss, MMMM DD YYYY",
);

export function PDFRenderInformationField({ name, value }: { name: string, value?: string | number }) {
  return (
    value ? (
      <View wrap={false} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <View style={{ minWidth: 150 }}>
          <Text style={styles.textSmall}>{name}</Text>
        </View>
        {" "}
        {typeof value === "number"
          ? <Text style={styles.textSmall}>{formatDate(value)}</Text>
          : <Text style={styles.textSmall}>{value}</Text>}
      </View>
    ) : null
  );
}

const personnelTotalNumber = (incident: TCIncident, units?: Units[]) => {
  let personnel = 0;

  if (units) {
    units?.forEach((unit) => {
      const unitPersonnel = unit.personnelOnScene ?? 0;
      personnel += unitPersonnel;
    });

    return personnel;
  }
  incident?.units?.forEach((unit) => {
    const unitPersonnel = unit.personnelOnScene ?? 0;
    personnel += unitPersonnel;
  });

  return personnel;
};

export function TCIncidentPDFDocument({
  incident, imageUrl, cadIncident, priorityKeywords,
}: {
  incident: TCIncident,
  imageUrl: string,
  priorityKeywords: SafetyPriorityKeyword[],
  cadIncident: CADIncident | undefined
}) {
  const [lat, long] = parseLocation(incident.location);
  const channelOwner = (incident.channel_owner ?? "")?.split("@");
  const assignmentsTableGroups = incident?.groups?.map((el) => ({
    title: el.name ?? "",
    note: el.note ?? "",
    unitsCount: el.units?.length ?? 0,
    personnelCount: personnelTotalNumber(incident, el.units),
    units: el.units ?? [],
  }));
  assignmentsTableGroups?.unshift({
    title: "Unassigned",
    note: "",
    unitsCount: incident.units?.filter((el) => el.column_position === 0).length ?? 0,
    personnelCount: personnelTotalNumber(incident, incident.units?.filter((el) => el.column_position === 0)),
    units: incident.units?.filter((el) => el.column_position === 0) ?? [],
  });

  const sortedIncidentHistory = () => {
    if (incident.history) {
      return incident.history.sort((a, b) => {
        const time1 = b.time ?? 0;
        const time2 = a.time ?? 0;
        return time1 - time2;
      });
    }
    return [];
  };

  return (
    <Document author="" pageLayout="oneColumn" title={incident.api_incident_number} style={{ width: "100%" }}>
      <Page style={styles.page} size="A4">
        <View style={styles.viewHeader}>
          <View style={{
            display: "flex", flexDirection: "row", columnGap: 5, fontSize: 16, alignItems: "center",
          }}
          >
            <Text>
              Incident:
            </Text>
            <Text style={{
              backgroundColor: "grey", color: "white", padding: 5, borderRadius: 5,
            }}
            >
              {incident.api_incident_number}
            </Text>
          </View>
          <View style={{
            display: "flex", flexDirection: "row", alignItems: "center", columnGap: 5,
          }}
          >
            <Image source={image} style={{ width: 40, height: 40 }} />
            <Text style={{ fontSize: 20 }}>Tablet Command</Text>
          </View>
        </View>
        <View style={styles.viewWrapper}>
          <PDFRenderInformationField name="Incident Number:" value={incident.api_incident_number} />
          <PDFRenderInformationField name="Managed by:" value={channelOwner[0]} />
          {incident.ReportNumber.length ? (
            <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <View style={{ minWidth: 150 }}>
                <Text style={styles.textSmall}>Report Number:</Text>
              </View>
              {" "}
              <View style={{
                display: "flex", flexDirection: "row", flexWrap: "wrap", columnGap: 10,
              }}
              >
                {incident.ReportNumber.map((el) => (
                  <Text
                    key={el.name + el.number}
                    style={{
                      ...styles.textSmall, marginLeft: 0, display: "flex",
                    }}
                  >
                    {el.name}
                    {": "}
                    {el.number}
                  </Text>
                ))}
              </View>
            </View>
          ) : null}

          <PDFRenderInformationField name="Call Type:" value={cadIncident?.AgencyIncidentCallTypeDescription} />
          <PDFRenderInformationField name="Incident Name:" value={cadIncident?.CommandName} />
          <PDFRenderInformationField name="Common Name:" value={cadIncident?.CommonPlaceName} />
          <PDFRenderInformationField name="Location Comment:" value={incident?.history?.find((el) => el.message.includes("Location Comment"))?.message?.split(":")[1].trim()} />
          <PDFRenderInformationField name="Agency" value={cadIncident?.AgencyID} />
          <PDFRenderInformationField name="Map Pages" value={incident.MapPages} />
          <PDFRenderInformationField name="Fire Map" value={incident.FireMap} />
          <PDFRenderInformationField name="Start Time:" value={incident.start_time} />
          <PDFRenderInformationField name="End Time:" value={incident.end_unix_time} />
          <PDFRenderInformationField name="Modified Time:" value={incident.modified_unix_date} />
          <PDFRenderInformationField name="PAR:" value={incident.par_unix_time} />
          <PDFRenderInformationField name="Address:" value={incident.address} />
          <PDFRenderInformationField name="Cross Streets:" value={incident.cross_streets} />
          <PDFRenderInformationField name="Caller Number:" value={cadIncident?.CallerNumber} />
          <View wrap={false}>
            <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <View style={{ minWidth: 150 }}>
                <Text style={styles.textSmall}>Radio Channels:</Text>
              </View>
              <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                {incident.CommandChannel && (
                  <Text style={styles.textSmall}>
                    {`Command: ${incident.CommandChannel}  `}
                  </Text>
                )}
                {incident.TacticalChannel && (
                  <Text style={styles.textSmall}>
                    {`Tactical: ${incident.TacticalChannel}`}
                  </Text>
                )}
                {incident.radioChannels?.length
                  ? incident.radioChannels.map((channel) => (
                    <Text style={styles.textSmall} key={channel.channel + channel.name}>
                      {`${channel.channel}: ${channel.name}`}
                    </Text>
                  ))
                  : null}
              </View>
            </View>
          </View>
          {incident.extended && <Text style={{ ...styles.textSmall, color: "red", margin: "5px 0px" }}>Long running incident</Text>}
        </View>
        <View style={{ padding: "20px 35px" }}>
          {imageUrl && <Image source={imageUrl} style={{ width: 505, height: 400 }} />}
          <View style={{ textAlign: "center", backgroundColor: "#3da1ca", padding: 2 }}><Text style={{ ...styles.textNormal, color: "white" }}>{`${lat},${long} `}</Text></View>
        </View>
        <Text style={styles.pageNumber} fixed render={({ pageNumber, totalPages }) => (`Page ${pageNumber}/${totalPages}`)} />
      </Page>
      <Page id="document-assignment-units" wrap style={styles.page} size="A4">
        <View fixed style={{ ...styles.viewHeader, paddingLeft: 5, paddingRight: 5 }}>
          <Text style={{ ...styles.textSmall, color: "grey" }}>
            {incident.api_incident_number}
          </Text>
          <Image source={image} style={{ width: 20, height: 20 }} />
        </View>
        <View>
          <View style={{
            display: "flex", justifyContent: "space-between", flexDirection: "row", width: "100%", marginBottom: 5, backgroundColor: "lightgrey", padding: 4,
          }}
          >
            <Text style={styles.textNormal}>Units Assignment</Text>
            <View style={{ display: "flex", flexDirection: "row", columnGap: 5 }}>
              <Text style={{
                padding: 4, backgroundColor: "grey", color: "white", borderRadius: 3, fontSize: 10,
              }}
              >
                Units
                {" "}
                {incident.units?.length}
              </Text>
              <Text style={{
                padding: 4, backgroundColor: "grey", color: "white", borderRadius: 3, fontSize: 10,
              }}
              >
                Personnel
                {" "}
                {personnelTotalNumber(incident)}
              </Text>
            </View>
          </View>
          <View
            fixed
            style={{
              display: "flex", flexDirection: "row", backgroundColor: "lightgrey", columnGap: 2,
            }}
          >
            {unitsAssignmentsTableHeader.map((headerText) => (
              <View
                key={headerText}
                style={{ ...styles.unitsHeaderCell, ...styles.assignmentTableCellMinWidth }}
              >
                <Text>{headerText}</Text>
              </View>
            ))}
          </View>
          <View style={{ display: "flex", flexDirection: "column" }}>
            {assignmentsTableGroups?.map((group) => (
              (group.units.length && (
                <View key={group.title + group.note + group.personnelCount + group.unitsCount}>
                  <View
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "grey",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: 3,
                    }}
                  >
                    <Text style={{ ...styles.textSmall, color: "white" }}>{group.title}</Text>
                    <View style={{ display: "flex", flexDirection: "row", columnGap: 5 }}>
                      <Text style={{
                        padding: 3, backgroundColor: "white", borderRadius: 3, fontSize: 10,
                      }}
                      >
                        Units
                        {" "}
                        {group.unitsCount}
                      </Text>
                      <Text style={{
                        padding: 3, backgroundColor: "white", borderRadius: 3, fontSize: 10,
                      }}
                      >
                        Personnel
                        {" "}
                        {group.personnelCount}
                      </Text>
                    </View>
                  </View>
                  {
                    group.units.map((unit) => (
                      <View
                        key={`${unit.UnitID} + ${unit.uuid}`}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          padding: 5,
                          borderBottom: "1px solid lightgrey",
                        }}
                      >
                        <View style={styles.assignmentTableCellMinWidth}>
                          {!!unit.UnitID && (
                            <Text style={styles.textSmall}>{unit.UnitID}</Text>
                          )}
                        </View>
                        <View style={{ ...styles.assignmentTableCellMinWidth, textAlign: "center" }}>
                          {!!unit.AlarmAtDispatch && (
                            <Text style={styles.textSmall}>{unit.AlarmAtDispatch}</Text>
                          )}
                        </View>
                        <View style={styles.assignmentTableCellMinWidth}>
                          {!!unit.status && (
                            <Text style={styles.textSmall}>{unit.status}</Text>
                          )}
                        </View>
                        <View style={styles.assignmentTableCellMinWidth}>
                          {!!unit.assignment?.name && (
                            <Text style={styles.textSmall}>{unit.assignment?.name}</Text>
                          )}
                        </View>
                        <View style={{ ...styles.assignmentTableCellMinWidth, ...styles.textSmall }}>
                          {!!unit.air_time && (
                            <View style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                              <Text>
                                {moment(unit.air_time).format("HH:mm:ss")}
                              </Text>
                              <Text style={{
                                height: 10,
                                width: 10,
                                backgroundColor: (getDiffInMinutes(unit.air_time) >= 0 && getDiffInMinutes(unit.air_time) <= 10
                                  ? "#009900" : getDiffInMinutes(unit.air_time) > 10 && getDiffInMinutes(unit.air_time) <= 15
                                    ? "#fbe52e" : "red"),
                                borderRadius: "30px",
                              }}
                              />
                            </View>
                          )}
                        </View>
                        <View style={{
                          display: "flex", flexDirection: "row", alignItems: "center", columnGap: 10,
                        }}
                        >
                          <Text style={styles.textSmall}>{unit.Personnel?.length}</Text>
                          <View style={{ display: "flex", flexDirection: "column" }}>
                            {unit.Personnel?.map((personnel) => (
                              <View key={personnel.PersonnelID} style={{ fontSize: 10, margin: "2px 0px" }}>
                                <Text>
                                  {personnel.PersonnelRank && personnel.PersonnelRank !== null && personnel.PersonnelRank !== "" ? `${personnel.PersonnelRank} - ${personnel.PersonnelName}` : personnel.PersonnelName}
                                </Text>
                              </View>
                            ))}
                          </View>
                        </View>
                      </View>
                    ))
                  }
                </View>
              ))
            ))}
          </View>
        </View>
        <Text style={styles.pageNumber} fixed render={({ pageNumber, totalPages }) => (`Page ${pageNumber}/${totalPages}`)} />
      </Page>
      <Page id="document-time-units" wrap style={styles.page} size="A4">
        <View fixed style={{ ...styles.viewHeader, paddingLeft: 5, paddingRight: 5 }}>
          <Text style={{ ...styles.textSmall, color: "grey" }}>
            {incident.api_incident_number}
          </Text>
          <Image source={image} style={{ width: 20, height: 20 }} />
        </View>
        <View>
          <PDFRenderTableHeader name="Units" value={incident.units?.length ?? 0} />
          <View
            fixed
            style={{
              display: "flex", flexDirection: "row", backgroundColor: "lightgrey", columnGap: 2,
            }}
          >
            {unitsTimesTableHeader.map((headerText) => (
              <View
                key={headerText}
                style={{ ...styles.unitsHeaderCell, ...styles.cellMinWidth }}
              >
                <Text>{headerText}</Text>
              </View>
            ))}
          </View>
          <View style={{ display: "flex", flexDirection: "column" }}>
            {cadIncident?.units?.map((unit) => (
              <View
                wrap={false}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderBottom: "1px solid lightgrey",
                  padding: "2px 0",
                  columnGap: 2,
                }}
                key={unit.UnitID ?? "" + unit.uuid ?? ""}
              >
                <View style={{ ...styles.cellMinWidth, justifyContent: "center" }}>
                  <Text
                    style={{
                      padding: 2,
                      fontSize: 10,
                      textAlign: "center",
                    }}
                  >
                    {unit.UnitID}
                  </Text>
                </View>
                <View style={styles.cellMinWidth}>
                  {!!unit.TimeDispatched && (
                    <PDFRenderTableDateField value={unit.TimeDispatched} />
                  )}
                </View>
                <View style={styles.cellMinWidth}>
                  {!!unit.TimeEnroute && (
                    <PDFRenderTableDateField value={unit.TimeEnroute} />
                  )}
                </View>
                <View style={styles.cellMinWidth}>
                  {!!unit.TimeStaged && (
                    <PDFRenderTableDateField value={unit.TimeStaged} />
                  )}
                </View>
                <View style={styles.cellMinWidth}>
                  {!!unit.TimeArrived && (
                    <PDFRenderTableDateField value={unit.TimeArrived} />
                  )}
                </View>
                <View style={styles.cellMinWidth}>
                  {!!unit.TimeTransporting && (
                    <PDFRenderTableDateField value={unit.TimeTransporting} />
                  )}
                </View>
                <View style={styles.cellMinWidth}>
                  {!!unit.TimeAtHospital && (
                    <PDFRenderTableDateField value={unit.TimeAtHospital} />
                  )}
                </View>
                <View style={styles.cellMinWidth}>
                  {!!unit.TimeCleared && (
                    <PDFRenderTableDateField value={unit.TimeCleared} />
                  )}
                </View>
                <View style={{ display: "flex", flexDirection: "column" }}>
                  {unit.Personnel?.map((personnel) => (
                    <View key={personnel.PersonnelID} style={{ fontSize: 10, margin: "2px 0px" }}>
                      {!!personnel.PersonnelRank && <Text>{`${personnel.PersonnelRank} -`}</Text>}
                      <Text style={{ marginLeft: 10 }}>{personnel.PersonnelName}</Text>
                    </View>
                  ))}
                </View>
              </View>
            ))}
          </View>
        </View>
        <Text style={styles.pageNumber} fixed render={({ pageNumber, totalPages }) => (`Page ${pageNumber}/${totalPages}`)} />
      </Page>
      <Page style={styles.page} size="A4">
        <View fixed style={{ ...styles.viewHeader, paddingLeft: 5, paddingRight: 5 }}>
          <Text style={{ ...styles.textSmall, color: "grey" }}>
            {`Incident: ${incident.api_incident_number}`}
          </Text>
          <Image source={image} style={{ width: 20, height: 20 }} />
        </View>
        <View>
          <PDFRenderTableHeader name="Hazards" value={incident.hazards?.length} />
          <View
            fixed
            style={{
              display: "flex", flexDirection: "row", backgroundColor: "lightgrey", columnGap: 2,
            }}
          >
            {hazardsTableHeader.map((headerText) => (
              <View
                key={headerText}
                style={{ ...styles.unitsHeaderCell, ...styles.hazardsTableCellMinWidth }}
              >
                <Text>{headerText}</Text>
              </View>
            ))}
          </View>
          <View style={{ display: "flex", flexDirection: "column" }}>
            {incident?.hazards?.map((hazard) => (
              <View
                wrap={false}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderBottom: "1px solid lightgrey",
                  padding: "2px 0",
                  columnGap: 2,
                }}
                key={hazard.name ?? "" + hazard.time + hazard.local_id}
              >
                <View style={{ ...styles.hazardsTableCellMinWidth, justifyContent: "center" }}>
                  <Text
                    style={{
                      padding: 2,
                      fontSize: 10,
                      textAlign: "center",
                    }}
                  >
                    {hazard.name}
                  </Text>
                </View>
                <View style={styles.hazardsTableCellMinWidth}>
                  {!!hazard.time && (
                    <View style={styles.textSmall}>
                      <Text>
                        {moment(hazard.time).format("MM/DD/YY")}
                      </Text>
                      <Text>
                        {moment(hazard.time).format("HH:mm:ss")}
                      </Text>
                    </View>
                  )}
                </View>
                <View style={{ ...styles.hazardsTableCellMinWidth, justifyContent: "center" }}>
                  <Text
                    style={{
                      padding: 2,
                      fontSize: 10,
                      textAlign: "center",
                    }}
                  >
                    {hazard.location_on_scene}
                  </Text>
                </View>
              </View>
            ))}
          </View>

        </View>
        <View style={{ marginTop: 20 }}>
          <PDFRenderTableHeader name="Checklists" value={incident.checklists?.length} />
          <View style={{ display: "flex", flexDirection: "column", rowGap: 10 }}>
            {incident.checklists?.map((checklist) => (
              <View key={checklist.name}>
                <Text style={{ ...styles.textNormal, marginBottom: 5 }}>{checklist.name}</Text>
                <View style={{
                  display: "flex", flexDirection: "column", rowGap: 5, marginLeft: 10,
                }}
                >
                  {checklist.items?.map((item) => <Text style={styles.textSmall} key={`${item.name} ${item.description}`}>{item.name}</Text>)}
                </View>
              </View>
            ))}
          </View>
        </View>
        <View style={{ marginTop: 20 }}>
          <PDFRenderTableHeader name="Incident Notes" value={incident.notes?.length} />
          <View
            style={{
              ...styles.textNormal,
              display: "flex",
              flexDirection: "column",
              rowGap: 10,
            }}
          >
            {incident.notes?.map((note) => (
              <View key={note.entity_id + note.message} style={{ display: "flex", flexDirection: "row", columnGap: 20 }}>
                <Text>
                  {moment(note.time * 1000).format("MMMM DD YYYY")}
                </Text>
                <Text>{note.message}</Text>
              </View>
            ))}
          </View>
        </View>
        <View id="document-comments-wrapper" style={{ marginTop: 20 }}>
          <PDFRenderTableHeader name="Incident History" value={incident?.history?.length ?? 0} />
          <View>
            {(incident?.history ?? []).length ? (
              <View>
                {sortedIncidentHistory().map((item) => (
                  <PDFRenderCommentRow key={item.message + item.time + item.time} item={item} priorityKeywords={priorityKeywords} />
                ))}
              </View>
            ) : null}
          </View>
        </View>
        {
          cadIncident && (
            <View id="document-changes-wrapper" style={{ marginTop: 20 }}>
              <PDFRenderTableHeader name="Changes" value={cadIncident?.changes?.length ?? 0} />
              <PDFRenderChangesDetails incident={cadIncident} />
            </View>
          )
        }
        {
          cadIncident && (
            <View id="document-events-wrapper" style={{ marginTop: 20 }}>
              <PDFRenderTableHeader name="Events" value={cadIncident?.events?.length ?? 0} />
              <PDFRenderEventsDetails incident={cadIncident} />
            </View>
          )
        }
        <View style={{ marginTop: 20 }}>
          <PDFRenderTableHeader name="Transfers" />
          <View>
            {incident?.transfers?.length && (
              <View>
                {incident?.transfers?.map((item) => (
                  <View
                    key={`${item.id} ${item.uuid} ${item.owner}`}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      rowGap: 5,
                      padding: "0 0 5px 10px",
                      borderBottom: "1px solid lightgrey",
                    }}
                  >
                    <View style={{
                      display: "flex", flexDirection: "row", columnGap: 5, flexWrap: "wrap",
                    }}
                    >
                      <Text style={styles.textNormal}>
                        {item.old_owner}
                      </Text>
                      <PDFRenderForwardArrowIcon />
                      <Text style={styles.textNormal}>
                        {item.new_owner}
                      </Text>
                    </View>
                    <View style={{
                      display: "flex", flexDirection: "row", columnGap: 5, flexWrap: "wrap",
                    }}
                    >
                      <PDFRenderPersonIcon />
                      <Text style={styles.textNormal}>
                        {item.owner}
                      </Text>
                      <Text
                        style={styles.textNormal}
                      >
                        {item.status}
                      </Text>
                      <Text style={styles.textNormal}>
                        {item.request_time ? moment(item.request_time * 1000).format("HH:mm:ss") : ""}
                      </Text>
                      <PDFRenderFastForwardIcon />
                      <Text style={styles.textNormal}>
                        {item.response_time ? moment(item.response_time * 1000).format("HH:mm:ss") : ""}
                      </Text>
                    </View>
                  </View>
                ))}
              </View>
            )}
          </View>
        </View>
        <View style={{ marginTop: 20 }}>
          <PDFRenderTableHeader name="Debug" />
          <View style={{ display: "flex", flexDirection: "column", rowGap: 5 }}>
            <Text style={styles.textNormal}>
              {`Server Time: ${moment(incident?.serverTime).format("MMMM DD YYYY, h:mm:ss")}`}
            </Text>

            <Text style={styles.textNormal}>
              {`Device Time: ${moment(incident?.deviceTime).format("MMMM DD YYYY, h:mm:ss")}`}
            </Text>

            <Text style={styles.textNormal}>
              {`Delta: ${dateDelta(incident?.serverTime, incident?.deviceTime)}`}
            </Text>
          </View>
        </View>
        <Text style={styles.pageNumber} fixed render={({ pageNumber, totalPages }) => (`Page ${pageNumber}/${totalPages}`)} />
      </Page>
    </Document>
  );
}
