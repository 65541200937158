export type RouteParamDepartmentId = {
  departmentId: string,
}

export type RouteParamUserId = {
  userId: string,
}

export type RouteParamMessageId = {
  messageId: string,
}

export type RouteParamTemplate = {
  templateId: string,
}

export enum StatusTab {
  Statuses = "statuses",
  Vehicles = "vehicles",
  VehicleStatuses = "vehicles-status",
}

export type RouteParamVehicleStatusDetails = {
  vehicleId: string,
}

export type RouteParamStatus = {
  tab: StatusTab;
}

// CAD Incident

export enum CADIncidentsTab {
  Active = "active-incidents",
  Inactive = "inactive-incidents",
  Search = "incidents-search",
}

export enum IncidentsTab {
  Active = "active-incidents",
  Inactive = "inactive-incidents",
  Search = "incidents-search",
}

export enum CADSimulatorTabs {
  Normal = "normal",
  Canned = "canned"
}

export type RouteParamIncidentsList = {
  tab: IncidentsTab;
}

export type RouteParamCADIncidentsList = {
  tab: CADIncidentsTab;
}

export type RouteParamCADSimulators = {
  tab: CADSimulatorTabs;
}

export type RouteParamCADIncidentDetails = {
  // Shouldn't be mandatory, however some of the code converts incident to optional...
  incidentNumber?: string,
}

// Managed Incident

export type RouteParamManagedIncidentDetails = {
  incidentNumber: string,
}

export enum ManagedIncidentsTab {
  Active = "active-incidents",
  Inactive = "inactive-incidents",
}

export type RouteParamManagedIncidentsList = {
  tab: ManagedIncidentsTab;
}

// oAuth

export type LoginParamError = {
  key: LoginErrorKey;
}

export enum LoginErrorKey {
  InvalidToken = "invalid-token",
  InvalidEmail = "invalid-email", // Not an email?
  EmailNotFound = "email-not-found",
  NoSAMLConfiguration = "no-saml-configuration",
  NoSAMLEmail = "no-saml-user-email",
}

export type OAuthParamCode = {
  code: string,
  authType: string,
}
