// import moment from "moment";

import {
  EventOptionsBeansAI,
  EventOptionsFireMapper,
  Events,
} from "@models/cad";
import classes from "../pages/admin/tc-incident-details/managed-incidents-list.module.css";
import { useState } from "react";
import IncidentTableHeaderWithToggle from "../pages/admin/incident-table-header-with-toggle";
import { CustomMaterialTable } from "./custom-material-table";
import { Typography } from "@mui/material";
import { strDateFormat } from "./incident-details-shared-to";

function decodeEventMessage(item: Events): JSX.Element | string {
  let type = item.type;
  let msg: JSX.Element | string = item.message;

  switch (item.type) {
    case "cadoptlayersfiremapper": // fallthrough
    case "useroptlayersfiremapper": {
      type = "FireMapper Layers";
      const opts = item.opts as EventOptionsFireMapper;
      const layerNames = opts.items.map((l) => l.name).sort().join(", ");
      const layersUUID = opts.items[0] ? opts.items[0].uuid : "";
      msg = (
        <>
          <div>
            {layerNames}
          </div>
          <div>
            {layersUUID}
          </div>
        </>
      );
      break;
    }
    case "cadoptroutesbeansai": {
      type = "Beans.AI";
      const opts = item.opts as EventOptionsBeansAI;
      msg = `${item.message} routes: ${opts.routes.length}`;
      let routesMessage = "No routes found";
      if (opts.routes.length > 0) {
        routesMessage = `${opts.routes.length} routes found`;
      }
      msg = (
        <>
          <div>
            {item.message}
          </div>
          <div>
            {routesMessage}
          </div>
        </>
      );
      break;
    }
    case "systemaddressdidresolve": {
      type = "Address => Lat/Long";
      msg = `${item.message} => ${item.location.latitude},${item.location.longitude}`;
      break;
    }
    case "usercomment": {
      type = "CAD Comment";
      msg = item.message;
      break;
    }
    case "usercommentnottoCad": {
      type = "User Note";
      msg = item.message;
      break;
    }
    case "userUnitPersonnelDidChange": {
      type = "Personnel Change";
      msg = item.message;
      break;
    }
    case "userdeleteevent":
      type = "Delete Event";
      msg = item.message;
      break;
    case "usercolumnsdidchange":
      type = "Columns Did Change";
      msg = item.message;
      break;
    default:
      break;
  }

  return (
    <>
      <div>
        {type}
      </div>
      <div>
        {msg}
      </div>
    </>
  );
}

export default function IncidentEvents({ events }: {
  events: Events[] | undefined
}) {
  if (!events || events.length === 0) {
    return null;
  }
  const [expand, setExpand] = useState(false);
  return (
    <div className={`${classes.changes}`}>
      <CustomMaterialTable<Events>
        cellClassName={classes.cellWidth}
        hideContent={!expand}
        data={events ?? []}
        title={<IncidentTableHeaderWithToggle title="Events" expand={expand} setExpand={setExpand} />}
        cellAlign="left"
        header={
          [{
            name: "Modified",
            prop: "",
            render: (item) => (
              <div>
                <Typography variant="body2">{strDateFormat(item.modified)}</Typography>
                <Typography variant="body2">{` [${item.user.email !== "" ? item.user.email : "system"}]`}</Typography>

              </div>
            ),
          },
          {
            name: "Message",
            prop: "",
            cellWidth: 150,
            render: (item) => (decodeEventMessage(item)),
          },
          {
            name: "UUID",
            prop: "uuid",
          },
          ]
        }
      />
    </div>
  );
}
