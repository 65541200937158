import Portal from "@arcgis/core/portal/Portal";
import MapView from "@arcgis/core/views/MapView";
import WebMap from "@arcgis/core/WebMap";
import { ILoadViewOptions } from "@models/arcgis";

export function loadItem(itemId: string, portal: Portal | undefined, options: ILoadViewOptions = {}) {
  const { view } = options;
  const map = new WebMap({
    portalItem: {
      id: itemId,
      portal,
    },
  });

  return new MapView({
    ...view,
    id: Math.random(),
    popup: {
      dockOptions: {
        position: "bottom-left",
        breakpoint: { width: 700, height: 700 },
      },
    },
    map,
  });
}

export function loadView(itemId: string, portal: Portal | undefined, options?: ILoadViewOptions) {
  return loadItem(itemId, portal, options);
}

export function destroyView(view: MapView) {
  if (!view) {
    return;
  }
  view.allLayerViews.removeAll();
  view.graphics.removeAll();
  view.graphics.destroy();
  view.allLayerViews.destroy();
  view.destroy();
}
