import { OnlineUser } from "@models/dashboard";
import {
  AccountSession,
  ChangePasswordVerify,
  Credentials,
  LoginConfigureItem,
  OAuthCredentials,
  SAMLCredentials,
  SignupVerify,
  User,
  UserConfiguration,
  UserLoginError,
  UserLoginSuccess,
} from "@models/user";

import { apiUrl, appFetch } from "./config";
import { UserDepartment } from "@models/department";

export const userLogin = async (info: Credentials | OAuthCredentials | SAMLCredentials): Promise<UserLoginSuccess | UserLoginError> => {
  const response: Response = await appFetch("auth/login", {
    method: "POST",
    body: JSON.stringify(info),
  });
  return response.json();
};

export const userLogout = async (): Promise<void> => {
  const response: Response = await appFetch("auth/logout", {
    method: "POST",
    body: JSON.stringify({}),
  });
  return response.json();
};

export const userLoginConfigure = async (email: string): Promise<LoginConfigureItem[]> => {
  const response: Response = await appFetch("auth/login/configure", {
    method: "POST",
    body: JSON.stringify({ email }),
  });
  return response.json();
};

export const checkSession = async (token: string): Promise<AccountSession> => {
  const response = await appFetch("api/v7/account/session?checkSession", {
    headers: {
      cookie: `seneca-login=${token}`,
    },
  });
  if (response.status === 401) {
    throw new Error(response.statusText);
  }
  return response.json();
};

export const accountSession = async (signal?: AbortSignal): Promise<AccountSession> => {
  const response: Response = await appFetch("api/v7/account/session?accountSession", { signal });
  return response.json();
};

export const accountSessionSignupKey = async (signupKey?: string): Promise<AccountSession> => {
  const response: Response = await appFetch(`api/v7/account/session?signupKey=${signupKey}`);
  return response.json();
};

export const closeUserSession = async (token: string) => {
  const response: Response = await appFetch(`api/v7/auth/clear/${token}`, {
    method: "POST",
  });
  return response.json();
};

export const getActiveSessionsForUser = async (userId: string): Promise<OnlineUser[]> => {
  const response: Response = await appFetch(`api/a1/online/request-logs/${userId}`);
  return response.json();
};

export const verifySignupKey = async (key?: string): Promise<SignupVerify> => {
  const response: Response = await appFetch(`account/signup-verify/${key}`);
  return response.json();
};

export const verifyChangePasswordToken = async (token: string): Promise<ChangePasswordVerify> => {
  const response: Response = await appFetch(`account/change-password-verify/${token}`);
  return response.json();
};

export const getUserInfo = async (token: string): Promise<User> => {
  const response: Response = await appFetch(`api/v7/account/user/${token}`);
  return response.json();
};

export const signInWithGoogleURL = () => {
  const url = apiUrl("/api/v7/oauth/google");
  return url.toString();
};

export const usersBulkEdit = async (userEditPayload: { userId: string, userPayload: Partial<UserDepartment> }[]) => {
  const response: Response = await appFetch("api/a1/admin/user-bulk-edit", {
    method: "POST",
    body: JSON.stringify(userEditPayload),
  });
  return response.json();
};

export const usersBulkRemove = async (userRemovePayload: { userId: string }[]) => {
  const response: Response = await appFetch("api/a1/admin/user-bulk-remove", {
    method: "POST",
    body: JSON.stringify(userRemovePayload),
  });
  return response.json();
};

export const clearLocalStorage = () => {
  localStorage.removeItem("hideAutoRefresh");
  localStorage.removeItem("mapLayerName");
  localStorage.removeItem("user");
  localStorage.removeItem("userMapSettings");
  localStorage.removeItem("username");
  localStorage.removeItem("unitsFilter");
  localStorage.removeItem("mapLayers");
};

export const SAMLStartURLPrefix = "/api/v7/oauth/saml/start";
export const getSAMLStart = (selector: string): string => apiUrl(`${SAMLStartURLPrefix}/${selector}`).toString();
export const getSignInWithGoogle = (urlPath: string): string => apiUrl(urlPath).toString();

export const userConfiguration = async (id: string, signal?: AbortSignal): Promise<UserConfiguration> => {
  const response: Response = await appFetch(`api/v7/account/configuration/${id}`, { signal });
  return response.json();
};
