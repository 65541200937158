import {
  Checkbox, TableRow,
} from "@mui/material";
import { createStyles, withStyles } from "@mui/styles";

export const StyledTableRow = withStyles(() => createStyles({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#EDEEED",
    },

    "&:nth-of-type(even)": {
      backgroundColor: "#F9F9F9",
    },
  },
}))(TableRow);

export function Row<T>(props: {
  item: T,
  index: number
  header: {
    name: string | JSX.Element,
    prop: string,
    render?: ((item: T) => string | JSX.Element | JSX.Element[] | undefined) | undefined
  }[]
  StyledTableCell: React.ComponentType<any>
  cellAlign?: "left" | "right" | "inherit" | "center" | "justify" | undefined
  cellClassName?: string;
}) {
  const { index, StyledTableCell } = props;
  return (
    <StyledTableRow key={index}>
      {props.header.map((el) => (
        <StyledTableCell size="small" key={el.name as string} align={props.cellAlign ? props.cellAlign : "left"} className={`material-table-custom-cell ${props.cellClassName}`}>
          {props.item[el.prop as keyof T] === null ? <Checkbox /> : (el.render ? el.render(props.item) : props.item[el.prop as keyof T])}
        </StyledTableCell>
      ))}
    </StyledTableRow>
  );
}
