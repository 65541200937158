import MaterialTable, { Column } from "material-table";
import moment from "moment";
import {
  createRef, RefObject, useCallback, useMemo, useState,
} from "react";
import { NavLink, useNavigate } from "react-router-dom";
import AddRowButton from "@components/add-new-row-button";
import CADLabel from "@components/cad-labels";
import TableDate from "@components/table-date";
import { getAllActiveCADIncidents } from "@services/cad";
import { CADIncident } from "@models/cad";
import { useDataWithoutLoading } from "@hooks/useDataWithoutLoading";
import { useHeight } from "@hooks/useHeight";
import { useInterval } from "@hooks/useInterval";
import { getURL, urls } from "@utils/routing";
import { getCustomFilterAndSearch } from "@utils/utilsFunctions";
import { CloseCADSimulatedIncidentButton } from "./cad-simulator-close-buttons";

export function CadSimulatorIncidentsList() {
  const { data: activeIncidents, reload } = useDataWithoutLoading((as) => getAllActiveCADIncidents(as), undefined, { historyStateKey: "session-data-key" });
  const height = useHeight();
  const navigate = useNavigate();
  const addActionRef: RefObject<HTMLDivElement> = createRef();
  const [columns, setColumns] = useState<Array<Column<CADIncident>>>([
    {
      title: "Incident Date Time",
      field: "EntryDateTime",
      render: (item) => (
        <NavLink
          style={{ textDecoration: "none", color: "black" }}
          to={item.simulationId ? `${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADSimulatorCannedEdit, { incidentId: item.id ?? "" })}`
            : `${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADSimulatorEdit, { incidentId: item?.id ?? "" })}`}
        >
          <TableDate date={item.EntryDateTime} />
        </NavLink>
      ),
      sorting: true,
      customSort: (a, b) => (moment(b.EntryDateTime).unix() ?? 0) - (moment(a.EntryDateTime).unix() ?? 0),
      cellStyle: { width: "16%" },
    },
    {
      title: "Incident",
      field: "AgencyIncidentCallTypeDescription",
      render: (item) => (
        <NavLink
          style={{ textDecoration: "none", color: "black" }}
          to={item.simulationId ? `${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADSimulatorCannedEdit, { incidentId: item.id ?? "" })}`
            : `${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADSimulatorEdit, { incidentId: item?.id ?? "" })}`}
        >
          <CADLabel
            incidentCallTypeDescription={item.AgencyIncidentCallTypeDescription ?? ""}
            incidentNumber={item.IncidentNumber ?? ""}
            incidentNumberDisplay={item.IncidentNumberDisplay ?? ""}
            address={item.full_address ?? ""}
            units={item.units ?? []}
            to={item.simulationId ? `${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADSimulatorCannedEdit, { incidentId: item.id ?? "" })}`
              : `${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADSimulatorEdit, { incidentId: item?.id ?? "" })}`}
            rts={item.rts}
            extended={false}
            simulation={item.simulation}
          />
        </NavLink>
      ),
      ...getCustomFilterAndSearch<CADIncident>((item) => (item.units ?? []).map((unit) => unit.UnitID).toString()
        + (item.IncidentNumber ?? "").toString()
        + (item.full_address ?? "").toString()
        + (item.AgencyIncidentCallTypeDescription ?? "").toString()),
    },
    {
      title: "Last Updated",
      field: "modified_unix_date",
      render: (item) => (
        <>
          <p style={{ margin: "8px 0px 10px 0px" }}>
            {item.modified_unix_date ? moment(item.modified_unix_date * 1000).fromNow() : ""}
          </p>
          <CloseCADSimulatedIncidentButton
            incident={item}
            reload={reload}
          />
        </>
      ),
      ...getCustomFilterAndSearch<CADIncident>((item) => moment((item.modified_unix_date ?? 0) * 1000).fromNow()),
      sorting: true,
      customSort: (a, b) => (b.modified_unix_date ?? 0) - (a.modified_unix_date ?? 0),
    },
  ]);

  const onOrderChanged = useCallback((colIndex: number, order?: "asc" | "desc") => {
    setColumns((c) => c.map((c, i) => (colIndex === i
      ? { ...c, defaultSort: order }
      : { ...c, defaultSort: undefined })));
  }, [setColumns]);

  useInterval(async () => {
    await reload();
  }, [], { reloadInterval: 15000 });

  const sortedActiveCadSimulatedIncidents = useMemo(() => {
    if (activeIncidents) {
      // We look for simulations
      // Canned simulations (they have simulationId)
      // CADSimulator ?
      return activeIncidents.filter((el) => ((el.simulation) || (el.CADSimulator && el.CADSimulator !== "") || el.simulationId) && !el.sharedSource.isExternal).sort((a, b) => (moment(b.EntryDateTime).unix() ?? 0) - (moment(a.EntryDateTime).unix() ?? 0));
    }
    return [];
  }, [activeIncidents]);

  return (
    <MaterialTable<CADIncident>
      title={(
        <div>
          <AddRowButton addActionRef={addActionRef} onClick={() => navigate(`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADSimulatorNew)}`)} actionName="Add Simulation" disabled={false} />
        </div>
      )}
      data={sortedActiveCadSimulatedIncidents}
      onOrderChange={onOrderChanged}
      options={{
        paging: false,
        sorting: true,
        maxBodyHeight: height + 50,
        headerStyle: {
          position: "sticky",
          top: "0",
        },
      }}
      columns={columns.map((c) => ({
        ...c,
      }))}
    />
  );
}
