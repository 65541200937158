/* eslint-disable default-param-last */
import { useEffect } from "react";

export function useInterval(
  handler: () => Promise<void> | void,
  deps: unknown[] = [],
  { reloadInterval = 5000, enabled = true }: { reloadInterval?: number, enabled?: boolean } = {},
  cleanupHandler?:() => void,
) {
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined;
    if (enabled) {
      interval = setInterval(async () => {
        await handler();
      }, reloadInterval);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }

      if (cleanupHandler) {
        cleanupHandler();
      }
    };
  }, [...deps, enabled]);
}
