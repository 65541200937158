import classes from "./DebugGrid.module.css";

export default function DebugGrid() {
  const isVisible = import.meta.env.NODE_ENV === "development" && false;
  if (!isVisible) {
    return null;
  }

  return (
    <div title="Debug Grid Layout" className={classes.debugGrid}>
      <div className={`${classes.debugGridXS} ${classes.debugGridItem}`}>XS</div>
      <div className={`${classes.debugGridSM} ${classes.debugGridItem}`}>SM</div>
      <div className={`${classes.debugGridMD} ${classes.debugGridItem}`}>MD</div>
      <div className={`${classes.debugGridLG} ${classes.debugGridItem}`}>LG</div>
      <div className={`${classes.debugGridXL} ${classes.debugGridItem}`}>XL</div>
    </div>
  );
}
