import { getNameFontSize, getRectColor } from "@utils/utilsFunctions";

export function IncidentTruckIconWithCount(prop: {
  unitsNumber: number
  translateX?: number,
  translateY?: number,
  scale?: number
}) {
  const { unitsNumber, scale = 0.5, translateX = 10, translateY = 10 } = prop;

  return (
    <svg >
      <g transform="translate(10, 10)">
        <g transform="scale(0.5)">
          <rect id="Rectangle_539" data-name="Rectangle 539" width="12.266" height="1.553" transform="translate(0 0.026)" fill="white" />
          <path id="Path_517" data-name="Path 517" d="M3401.863,752.129v-1.346h-1.552v1.346h-3.379v1.785h-16.811v10.247h2.01a3.865,3.865,0,0,1,7.7,0h5.067a3.865,3.865,0,0,1,7.7,0h1.583v-6.624h-3.628v-2.872h3.628v-2.536Z" transform="translate(-3379.923 -750.783)" fill="white" />
          <path id="Path_518" data-name="Path 518" d="M3385.092,759.295a2.334,2.334,0,1,0,2.334,2.334A2.334,2.334,0,0,0,3385.092,759.295Z" transform="translate(-3379.031 -747.963)" fill="white" />
          <path id="Path_519" data-name="Path 519" d="M3394.634,759.295a2.334,2.334,0,1,0,2.334,2.334A2.334,2.334,0,0,0,3394.634,759.295Z" transform="translate(-3375.803 -747.963)" fill="white" />
        </g>
        <g transform="scale(0.8)">
          <text
            x="18"
            y="7"
            dominantBaseline="middle"
            textAnchor="start"
            style={{
              isolation: "isolate",
              textAlign: "center",
              fontSize: 14,
              fill: "#fff",
              fontFamily: "Arial-BoldMT, Arial",
            }}
          >
            {unitsNumber}
          </text>
        </g>
      </g>
    </svg>
  );
}



