import { createContext, useState } from "react";

type ArcGISMapSettings = {
  isMapDragged: boolean
  isMapOnExtendedView: boolean
  isFullscreen: boolean
  isSideMenuOpen: boolean
}

export const ArcGISMapMapContext = createContext<[ArcGISMapSettings, React.Dispatch<ArcGISMapSettings>]>([null!, () => null!]);

// used to check if the location map is dragged by user, in order to keep user view, not extended view
// used to check if we need extended view for location map, if there are selected rows on table, or filter is active
// used to check if map was centered to department center
export function ArcGISMapMapContextProvider(props: { children: JSX.Element }) {
  const [arcgisMapSettings, setArcgisMapSettings] = useState({
    isMapDragged: false,
    isMapOnExtendedView: false,
    isFullscreen: false,
    isSideMenuOpen: true,
  });

  return (
    <ArcGISMapMapContext.Provider value={[arcgisMapSettings, setArcgisMapSettings]}>
      {props.children}
    </ArcGISMapMapContext.Provider>
  );
}
