import { CustomMaterialTable } from "@components/custom-material-table";
import { PriorIncident } from "@models/cad";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import { useMemo, useState } from "react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { createStyles, makeStyles } from "@mui/styles";
import { ListItemButton, Theme } from "@mui/material";
import styles from "./cad.module.css";
import IncidentTableHeaderWithToggle from "../incident-table-header-with-toggle";
import { strDateFormat } from "@components/incident-details-shared-to";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
    paddingTop: 0,
    paddingBottom: 0,
  },
  incidentWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
}));

function ExpandableRow({ priorIncident }: { priorIncident: PriorIncident }) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const memoizedListElement: JSX.Element = useMemo(() => (
    <List style={{ padding: 0 }}>
      <ListItemButton onClick={handleClick} style={{ padding: 5, borderRadius: 10 }}>
        <ListItemText primary={(
          <div className={classes.incidentWrapper}>
            <span style={{ fontSize: 14, fontFamily: "Roboto Condensed, sans-serif" }}>
              {`${priorIncident.Problem}`}
            </span>
            <span className={styles.cad_incident_label}>
              {priorIncident.Comment?.length}
            </span>
          </div>
        )}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {priorIncident.Comment?.map((el) => (
            <ListItem key={el.Comment} button className={classes.nested}>
              <span style={{
                fontSize: 14, paddingTop: 5, paddingBottom: 5, fontFamily: "Roboto Condensed, sans-serif",
              }}
              >
                {el.Comment}
              </span>
            </ListItem>
          ))}
        </List>
      </Collapse>
    </List>
  ), [open]);

  return (
    <>
      {memoizedListElement}
    </>
  );
}

export default function PriorIncidentsTable({ priorIncidents }: { priorIncidents?: PriorIncident[] }) {
  if (!priorIncidents) {
    return null;
  }
  const [expand, setExpand] = useState(true);
  return (
    <div style={{ marginTop: 25 }}>
      <CustomMaterialTable<PriorIncident>
        data={priorIncidents}
        hideContent={!expand}
        title={<IncidentTableHeaderWithToggle title="Prior Incidents" expand={expand} setExpand={setExpand} />}
        header={[
          {
            name: "Modified",
            prop: "",
            render: (item) => strDateFormat(item.IncidentDateTime),
          },
          {
            name: "Type",
            prop: "",
            render: (item) => <ExpandableRow priorIncident={item} />,
          },
        ]}
      />
    </div>
  );
}
