import { useState } from "react";
import { AfterSendEmail } from "@components/after-send-email";
import ResetPassword from "@components/reset-password";
import { useTitle } from "@hooks/useTitle";

export default function PasswordRecovery() {
  useTitle("Reset Password");
  const [email, setEmail] = useState("");
  const [send, setSend] = useState(false);

  if (send) {
    return (
      <AfterSendEmail
        email={email}
      />
    );
  }

  return (
    <ResetPassword
      email={email}
      setEmail={setEmail}
      setSend={() => setSend(true)}
    />
  );
}
