import { createContext, useState } from "react";

export const ScrollContext = createContext<[{ top: number, left: number, tabName: string } | null, React.Dispatch<{ top: number, left: number, tabName: string }>]>([null!, () => null!]);

export function ScrollContextProvider(props: { children: JSX.Element }) {
  const [scroll, setScroll] = useState({
    top: 0,
    left: 0,
    tabName: "all",
  });

  return (
    <ScrollContext.Provider value={[scroll, setScroll]}>
      {props.children}
    </ScrollContext.Provider>
  );
}
