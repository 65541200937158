import {
  AccountBalance,
  SupervisorAccount,
} from "@mui/icons-material";
import { MenuLink } from "@components/menu-links";
import { getURL, urls } from "@utils/routing";

export default function MenuSuperAdminReadOnly(prop: {
  handleCollapse: () => void
}) {
  const { handleCollapse } = prop;

  return (
    <>
      <MenuLink
        to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.accountsList)}`}
        label="Accounts"
        icon={SupervisorAccount}
        onClick={handleCollapse}
      />
      <MenuLink
        to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.accountsLicensing)}`}
        label="Licensing"
        icon={AccountBalance}
        onClick={handleCollapse}
      />
    </>
  );
}
