import "./index.css";
import { datadogRum } from "@datadog/browser-rum";
import { BrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";

import p from "../package.json";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createTheme, ThemeProvider } from "@mui/material";
import { createRoot } from "react-dom/client";

const { version } = p;

// TODO: Move this to another file and import it from there
if (import.meta.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://0925ee6a3a3547b29a7ca907eb27301c@o114662.ingest.sentry.io/252380",
    release: `frontend@${version}`,
    integrations: [
      Sentry.browserTracingIntegration(),
    ],
    ignoreErrors: [
      "Failed to fetch",
      "Fetch is aborted",
      "Load failed",
      "NetworkError when attempting to fetch resource.",
      "Preflight response is not successful",
      "The network connection was lost.",
      "The request timed out.",
      "The user aborted a request",
      "ChunkLoadError",
      /Loading chunk.*failed/,
    ],
    // debug: true,
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 0.75,
    sampleRate: 0.75,
    tracePropagationTargets: [
      "localhost",
      // Include / in URL, to filter out when domain is part of query
      /tabletcommand\.com\//,
      /tabletcommand\.ca\//,
      /^\//,
    ],
  });

  datadogRum.init({
    applicationId: "1f1b99b9-bc07-4afc-ab31-fe1450361e9d",
    clientToken: "pub9866a55613a359152ade84b97190a057",
    site: "datadoghq.com",
    service: "portal.tabletcommand.com",
    env: process.env.NODE_ENV,
    version,
    sessionSampleRate: 25,
    sessionReplaySampleRate: 0,
    trackUserInteractions: true,
  });
}

const theme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#d39a9c",
            color: "#530f1e",
            opacity: 1,
            fontWeight: 700,
          },
        },
      },
    },
  },
});
const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <BrowserRouter basename={window.env.PUBLIC_URL}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
