import { useRef } from "react";

function getDeviceType(deviceType: string, svgColor: string) {

    switch (deviceType) {
        case "android":
            return (
                <path
                    xmlns="http://www.w3.org/2000/svg"
                    id="android"
                    d={`M10.893,11.856a.509.509,0,0,1-.511-.505.5.5,0,0,1,.511-.5h0a.5.5,0,0,1,.511.5.509.509,0,0,1-.511.505m-5.6,0a.509.509,0,0,1-.511-.505.5.5,0,0,1,.511-.5h0a.5.5,0,0,1,.505.5.5.5,0,0,1-.505.505m5.782-3.049,1.016-1.753a.214.214,0,0,0-.079-.286.188.188,0,0,0-.274.079L10.7,8.617a6.178,6.178,0,0,0-2.611-.56,6.291,6.291,0,0,0-2.6.554L4.459,6.84a.2.2,0,0,0-.286-.079.208.208,0,0,0-.079.286L5.1,8.8A6.007,6.007,0,0,0,2,13.59H14.173a5.971,5.971,0,0,0-3.1-4.784Z`}
                    transform="translate(3.347 2.749)"
                    fill={svgColor}
                />
            )

        case "cad":
            return (
                <path
                    xmlns="http://www.w3.org/2000/svg"
                    id="link"
                    d="M3.218,10.207A1.99,1.99,0,0,1,5.207,8.218H7.772V7H5.207a3.207,3.207,0,0,0,0,6.413H7.772V12.195H5.207a1.99,1.99,0,0,1-1.988-1.988m2.629.641h5.13V9.565H5.848v1.283M11.62,7H9.054V8.218H11.62a1.988,1.988,0,0,1,0,3.976H9.054v1.218H11.62A3.207,3.207,0,0,0,11.62,7Z"
                    transform="translate(3.461 3.41)"
                    fill={svgColor}
                />
            )

        case "truck":
            return (
                <path
                    xmlns="http://www.w3.org/2000/svg"
                    id="truck"
                    d="M10.489,12.093a.837.837,0,1,1,.837-.837.837.837,0,0,1-.837.837m.837-5.023,1.094,1.4H9.931V7.07m-6.14,5.023a.837.837,0,1,1,.837-.837.837.837,0,0,1-.837.837m7.814-5.861H9.931V4H2.116A1.112,1.112,0,0,0,1,5.116v6.14H2.116a1.674,1.674,0,0,0,3.349,0H8.814a1.674,1.674,0,0,0,3.349,0H13.28V8.465Z"
                    transform="translate(4 5.06)"
                    fill={svgColor}
                />
            )

        case "iphone":
            return (
                <path
                    xmlns="http://www.w3.org/2000/svg"
                    id="apple_1_"
                    data-name="apple (1)"
                    d={`M15.081,15.222c-.627.937-1.292,1.851-2.3,1.866s-1.337-.6-2.486-.6-1.511.582-2.471.62c-.99.038-1.738-1-2.372-1.911C4.156,13.333,3.166,9.9,4.5,7.583a3.69,3.69,0,0,1,3.113-1.9c.967-.015,1.889.657,2.486.657s1.708-.808,2.879-.688a3.508,3.508,0,0,1,2.75,1.5A3.442,3.442,0,0,0,14.1,10.031a3.334,3.334,0,0,0,2.025,3.052,8.351,8.351,0,0,1-1.043,2.138M10.767,3.133A3.435,3.435,0,0,1,12.988,2,3.306,3.306,0,0,1,12.2,4.41,2.758,2.758,0,0,1,9.973,5.483,3.229,3.229,0,0,1,10.767,3.133Z`}
                    transform="translate(1.136 2.88)"
                    fill={svgColor}
                />
            )

        case "phone":
            return (
                <path
                    xmlns="http://www.w3.org/2000/svg"
                    id="apple_1_"
                    data-name="apple (1)"
                    d={`M15.081,15.222c-.627.937-1.292,1.851-2.3,1.866s-1.337-.6-2.486-.6-1.511.582-2.471.62c-.99.038-1.738-1-2.372-1.911C4.156,13.333,3.166,9.9,4.5,7.583a3.69,3.69,0,0,1,3.113-1.9c.967-.015,1.889.657,2.486.657s1.708-.808,2.879-.688a3.508,3.508,0,0,1,2.75,1.5A3.442,3.442,0,0,0,14.1,10.031a3.334,3.334,0,0,0,2.025,3.052,8.351,8.351,0,0,1-1.043,2.138M10.767,3.133A3.435,3.435,0,0,1,12.988,2,3.306,3.306,0,0,1,12.2,4.41,2.758,2.758,0,0,1,9.973,5.483,3.229,3.229,0,0,1,10.767,3.133Z`}
                    transform="translate(1.136 2.88)"
                    fill={svgColor}
                />
            )

        case "ipad":
            return (
                <path
                    xmlns="http://www.w3.org/2000/svg"
                    id="tablet_mac_black_24dp" d="M12.56,0H3.6A1.6,1.6,0,0,0,2,1.6V13.76a1.6,1.6,0,0,0,1.6,1.6h8.96a1.6,1.6,0,0,0,1.6-1.6V1.6A1.6,1.6,0,0,0,12.56,0ZM8.08,14.72a.96.96,0,1,1,.96-.96A.959.959,0,0,1,8.08,14.72Zm4.8-2.56H3.28V1.92h9.6Z"
                    transform="translate(4 5.88)"
                    fill={svgColor}
                />
            )
        default:
            return (
                <path
                    id="Path_879"
                    data-name="Path 879"
                    d="M17.6,4.11H9.1a1.752,1.752,0,0,0-1.7,1.7v15.4a1.752,1.752,0,0,0,1.7,1.7h8.6a1.752,1.752,0,0,0,1.7-1.7V5.81A1.71,1.71,0,0,0,17.6,4.11Zm0,15.4H9.1v-12h8.6v12ZM13.4,9.01a2.946,2.946,0,0,0-3,3h1.5a1.5,1.5,0,0,1,3,0c0,1.5-2.3,1.3-2.3,3.8h1.5c0-1.7,2.2-1.9,2.2-3.8A2.865,2.865,0,0,0,13.4,9.01Zm-.9,7.9h1.7v1.7H12.5Z"
                    transform="translate(-0.4 -0.021)"
                    fill={svgColor}
                />
            )
    }
}

export function DeviceMarker(prop: { info: string, text: string, background: string, deviceType: string }) {
    const { info, text, background, deviceType } = prop;
    return (
        <svg  xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" width={40 + info.length * 8} height="28">
            <filter id="darker" color-interpolation-filters="sRGB">
                <feComponentTransfer>
                    <feFuncR type="linear" slope="0.55" />
                    <feFuncG type="linear" slope="0.55" />
                    <feFuncB type="linear" slope="0.55" />
                </feComponentTransfer>
            </filter>
            <rect
                y="-0.00632"
                width={40 + info.length * 8}
                height="27" rx="3.18074"
                filter="url(#darker)"
                style={{
                    fill: background,
                }}
            />
            <rect x="2.36055" y="2.38424" width={36 + info.length * 8} height="22.21889" rx="3.0007" style={{ fill: background }} />
            <text
                id="svg-text"
                transform="translate(24.09966 18.75131)"
                style={{
                    isolation: "isolate", fontSize: 14, fill: text, fontFamily: "Arial-BoldMT, Arial", fontWeight: 700, paddingRight: 10,
                }}
            >
                {info}
            </text>
            {getDeviceType(deviceType, text)}
        </svg>
    )
}

export function AVLMarker(prop: { info: string, text: string, background: string }) {
    const { info, text, background } = prop;
    // used in useLocations.tsx
    const rectangleWidth = info.length < 4 ? 25 : (25 + ((info.length - 3) * 5.25));
    const rectangleHeight = 15;
    const triangleHeight = 15;

    return (
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width={rectangleWidth} height={rectangleHeight + triangleHeight}>
            <path d={"M3,0 L" + (rectangleWidth - 3) + ",0 A5,5 0 0 1" + (rectangleWidth) + ",3 L" + rectangleWidth + ",12 A5,5 0 0 1 " + (rectangleWidth - 3) + ",15 L3,15 L0,30 L0,3 A5,5 0 0 1 3,0"}
                fill={background}
                stroke="black"
                strokeWidth="0.5"
                strokeLinejoin="round" />
        </svg>
    )
}

export function SharedAVLMarker(prop: { info: string, agency: string, text?: string, background?: string, opacity: number }) {
    const { info, agency, background, opacity } = prop;
    // used in useLocations.tsx
    const iconLongestText = Math.max(info.length, agency.length)
    const rectangleWidth = iconLongestText < 4 ? 25 : (25 + ((iconLongestText - 3) * 5.25));
    const rectangleHeight = 24;
    const triangleHeight = 15;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={rectangleWidth} height={rectangleHeight + triangleHeight}>
            <rect width={rectangleWidth} height={rectangleHeight} rx="3" ry="3" fill="black" opacity={opacity} />
            <polygon points="0,10 5,10 0,40" fill="black" opacity={opacity} />
            <rect x="1" y="10" width={rectangleWidth - 2} height={12} rx="3" ry="3" fill={background ? background : "grey"} opacity={opacity} />
        </svg>
    )
}