import {
  Button,
  Checkbox, FormLabel, Grid, MenuItem, Select,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import moment from "moment";
import {
  ChangeEvent, useEffect, useMemo, useState,
} from "react";
import { SimulatorIncident } from "@models/cad";
import { closeInValues } from "@utils/static-data";
import { generateSecretKey, KeyIsValue } from "@utils/utilsFunctions";
import styles from "./cad-simulator.module.css";
import { CadSimulatorField } from "./cad-simulator-field";
import { CadSimulatorMap } from "./cad-simulator-map";
import { getURL, urls } from "@utils/routing";
import { useLocation, useNavigate } from "react-router-dom";

export function CadSimulatorIncidentDetails(props: {
  isSubmitting: boolean
  closeDate: string
  dirty: boolean
  values: SimulatorIncident
  keys: KeyIsValue<SimulatorIncident>
  formSubmitCount: number
  handleChange: {
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void;
    <T = string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>>(field: T): T extends ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ? void : (e: string | ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  }
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  handleResetForm: () => void
  disableUpdate?: boolean
}) {
  const {
    values, keys, handleChange, setFieldValue, isSubmitting, handleResetForm, dirty, formSubmitCount, disableUpdate,
  } = props;
  let { closeDate } = props;
  const [submitCount, setSubmitCount] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (values.IncidentNumber === "") {
      setFieldValue(keys.IncidentNumber, `TEST-${generateSecretKey(6)}`);
    }
  }, [isSubmitting, values]);

  useEffect(() => {
    if (formSubmitCount !== 0) {
      setSubmitCount(formSubmitCount);
    }
  }, [formSubmitCount]);

  const getIncidentNumber = useMemo(() => values.IncidentNumber, [values.IncidentNumber, submitCount === 0]);

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.section_header}>
        <p className={styles.section_name}>
          Incident Details
        </p>
      </div>
      <div className={styles.incident_details_section_wrapper}>
        <div style={{ minWidth: 120, textAlign: "right", marginRight: 10 }}>
          Incident Number:
        </div>
        <div>
          {getIncidentNumber}
        </div>
      </div>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={6}>
          <div className={styles.search_widget_container}>
            <div id="searchWidget" />
          </div>
          <CadSimulatorField handleChange={handleChange} fieldKey={keys.AgencyIncidentCallTypeDescription} value={values.AgencyIncidentCallTypeDescription} label="Call Type" />
          <CadSimulatorField handleChange={handleChange} fieldKey={keys.StreetName} value={values.StreetName} label="Street" />
          <CadSimulatorField handleChange={handleChange} fieldKey={keys.Suite} value={values.Suite} label="Apt/Suite" />
          <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
            width: "100%",

          }}
          >
            <Grid item xs={12} sm={7} lg={7}>
              <CadSimulatorField handleChange={handleChange} fieldKey={keys.CityOrLocality} value={values.CityOrLocality} label="City" />
            </Grid>
            <Grid item xs={12} sm={5} lg={5}>
              <CadSimulatorField handleChange={handleChange} fieldKey={keys.StateOrProvince} value={values.StateOrProvince} label="State" />
            </Grid>
          </div>
          <CadSimulatorField handleChange={handleChange} fieldKey={keys.CommonPlaceName} value={values.CommonPlaceName} label="Common Name" />
          <CadSimulatorField handleChange={handleChange} fieldKey={keys.cross_streets} value={values.cross_streets} label="Cross Streets" />
          <CadSimulatorField handleChange={handleChange} fieldKey={keys.CallerNumber} value={values.CallerNumber} label="Caller Number" />
          <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
          >
            <Grid item xs={12} sm={6} lg={6}>
              <CadSimulatorField handleChange={handleChange} fieldKey={keys.MapPages} value={values.MapPages} label="TB Map" />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <CadSimulatorField handleChange={handleChange} fieldKey={keys.FireMap} value={values.FireMap} label="Fire Map" />
            </Grid>
          </div>
          <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
          }}
          >
            <Grid item xs={12} sm={6} lg={6}>
              <CadSimulatorField handleChange={handleChange} fieldKey={keys.TacticalChannel} value={values.TacticalChannel} label="TAC" />
            </Grid>
            <Grid item xs={12} sm={6} lg={6}>
              <CadSimulatorField handleChange={handleChange} fieldKey={keys.CommandChannel} value={values.CommandChannel} label="CMD" />
            </Grid>
          </div>
          <div style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            verticalAlign: "middle",
            marginBottom: 15,
          }}
          >
            <div
              style={{
                minWidth: 135, textAlign: "right", marginRight: 10,
              }}
            >
              <FormLabel>Close In</FormLabel>
            </div>
            <Select
              variant="outlined"
              className={`${styles.textField} ${styles.closeIn}`}
              MenuProps={{ classes: { paper: styles.closeInMenu }, anchorOrigin: { horizontal: "center", vertical: "center" }, transformOrigin: { horizontal: "center", vertical: "center" } }}
              value={values.closeIn}
              id={keys.closeIn}
              onChange={(e) => {
                setFieldValue(keys.closeIn, e.target.value);
                values.expiration_date = moment(values.EntryDateTime)
                  .add(e.target.value, "m")
                  .format();
                closeDate = values.expiration_date;
                setFieldValue(keys.expiration_date, closeDate);
              }}
            >
              {closeInValues.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.FormLabel}
                </MenuItem>
              ))}
            </Select>
          </div>
          <Grid item sm={10} xs={12} style={{ marginLeft: 45 }}>
            <Checkbox
              id={keys.simulation}
              onChange={handleChange}
              checked={values.simulation}
              name="simulation"
              color="secondary"
            />
            <FormLabel>Simulation</FormLabel>
            <Checkbox
              id={keys.rts}
              onChange={handleChange}
              checked={values.rts}
              name="rts"
              color="secondary"
            />
            <FormLabel>RTS</FormLabel>
            <Checkbox
              id={keys.notify}
              onChange={handleChange}
              checked={values.notify}
              name="notify"
              color="secondary"
            />
            <FormLabel>Notify</FormLabel>
          </Grid>
          <Grid item xs={12} container spacing={1} style={{ marginLeft: 52, marginTop: 15 }}>
            <Grid item>
              <Button
                variant="contained"
                endIcon={<CloseIcon />}
                disabled={disableUpdate}
                className="cancel-button"
                onClick={() => {
                  handleResetForm();
                  setSubmitCount(0);
                  if (location.pathname.match(getURL(urls.adminCADSimulatorEdit, { incidentId: "" }))) {
                    navigate(`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADSimulatorNew)}`);
                  }
                }}
              >
                End
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                className="save-button"
                type="submit"
                endIcon={<CheckIcon />}
                color="secondary"
                disabled={!dirty || disableUpdate}
              >
                {submitCount > 0 ? "Update" : "Create"}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={6}>
          <CadSimulatorMap
            values={{
              AgencyIncidentCallTypeDescription: values.AgencyIncidentCallTypeDescription ?? "",
              IncidentNumber: values.IncidentNumber ?? "",
              Latitude: values.Latitude ?? "",
              Longitude: values.Longitude ?? "",
              callers: values.callers ?? [],
            }}
            setFieldValue={setFieldValue}
          />
        </Grid>
      </Grid>
    </div>
  );
}
