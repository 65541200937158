import {
  useState,
  useMemo,
  useContext,
  useCallback,
  useEffect,
} from "react";
import { MapLayersContext } from "@contexts/map-layers-context";
import { ColoredUnits, DefaultUnitColor, UnitLocation } from "@models/location";
import { getSharedAVLLocationAdmin } from "@services/location";
import { useQuery } from "@tanstack/react-query";
import { MapDetails, MapUnit } from "@models/arcgis";

export function useSharedAVLUnits(page: string, mapDetails: MapDetails, departmentFadeZoomLevel?: number) {
  const [mapLayersState] = useContext(MapLayersContext);

  const computedDiagonal = useMemo(() => {
    const departmentDiagonal = departmentFadeZoomLevel ? departmentFadeZoomLevel : 12784;
    return mapDetails.diagonal === 0 ? departmentDiagonal : mapDetails.diagonal > departmentDiagonal ? 0 : mapDetails.diagonal;
  }, [departmentFadeZoomLevel, mapDetails.diagonal]);

  const queryFn = useCallback((signal: AbortSignal) => getSharedAVLLocationAdmin(
    mapDetails.latitude,
    mapDetails.longitude,
    computedDiagonal,
    page,
    signal,
  ), [mapDetails, computedDiagonal, page]);

  const {
    data: sharedAVLUnits, isError,
  } = useQuery({
    queryKey: ["sharedAVL", mapDetails.latitude, mapDetails.longitude, mapDetails.diagonal, mapDetails.zoom],
    retry: false,
    queryFn: ({ signal }) => queryFn(signal),
    enabled: mapLayersState?.sharedAVL,
    refetchInterval: 30000,
    placeholderData: (prev) => prev,
  });
  const [units, setUnits] = useState<UnitLocation[]>([]);

  // INFO: Reference to keep old data in case of error;
  useEffect(() => {
    if (!isError) {
      setUnits(sharedAVLUnits ?? []);
    }
  }, [sharedAVLUnits, isError]);

  const sharedAVLUnitsLocations: MapUnit[] = useMemo(() => {
    const avlMarkers: ColoredUnits[] = (units ?? [])?.map((el) => {
      let color = DefaultUnitColor;
      // If no color is set or empty text/background, use the default unit color
      if (el.color && el.color?.text !== "" && el.color?.background !== "") {
        color = el.color;
      }
      return {
        ...el,
        color,
      };
    });
    return avlMarkers.map((el) => ({
      agencyCode: el.agencyCode,
      agencyName: el.agencyName,
      altitude: el.altitude,
      color: el.color,
      device_type: el.device_type,
      id: el._id,
      kindType: el.kindType,
      location: el.location,
      modified: el.modified,
      opAreaCode: el.opAreaCode,
      source: el.source,
      speed: el.speed,
      state: el.state,
      username: el.username,
    }));
  }, [units]);

  return {
    sharedAVLLocations: sharedAVLUnitsLocations,
    isSharedAVLEnabled: mapLayersState?.sharedAVL,
  };
}
