import {
  matchPath,
  NavigateFunction,
  useNavigate,
} from "react-router-dom";

import {
  CADIncidentsTab,
  CADSimulatorTabs,
  LoginParamError,
  ManagedIncidentsTab,
  OAuthParamCode,
  RouteParamCADIncidentDetails,
  RouteParamCADIncidentsList,
  RouteParamCADSimulators,
  RouteParamDepartmentId,
  RouteParamManagedIncidentDetails,
  RouteParamManagedIncidentsList,
  RouteParamStatus,
  RouteParamTemplate,
  RouteParamUserId,
  RouteParamVehicleStatusDetails,
  StatusTab,
} from "@models/routing";

export const urls = {
  appPrefix: () => "/app/*",
  adminAppPrefix: () => "/admin/app/*",
  routingAppPrefix: () => "/admin/app",
  routingSuperadminAppPrefix: () => "/app",
  login: () => "/login",
  // oAuth routes
  loginWithOAuth: (p: OAuthParamCode) => `/login/${p.authType}/${p.code}`,
  loginError: (p: LoginParamError) => `/login-error/${p.key}`,

  // Public routes, checked using `matchPath` in `isPublicRoute`
  signupForm: (p: { key: string }) => `/account/signup/${p.key}`,
  resetPasswordForm: (p: { token: string }) => `/account/change-password/${p.token}`,
  recoveryPassword: () => "/account/reset-password",
  oAuthJump: () => "/google",
  incidentJoin: (p: { code: string }) => `/incident/join/${p.code}`,

  dashboardFullPath: () => "/app/dashboard",
  accountsListFullPath: () => "/app/accounts",
  accountsAdd: () => "accounts/add",
  accountsUsers: (p: RouteParamDepartmentId) => `accounts/user/${p.departmentId}`,
  accountsAgencies: (p: RouteParamDepartmentId) => `accounts/agencies/${p.departmentId}`,
  accountsCustomButtons: (p: RouteParamDepartmentId) => `accounts/buttons/${p.departmentId}`,
  accountsAudioConfig: (p: RouteParamDepartmentId) => `accounts/audio-config/${p.departmentId}`,
  accountsPushNotificationsConfig: (p: RouteParamDepartmentId) => `accounts/push-notifications-config/${p.departmentId}`,
  editUserAccount: (p: RouteParamUserId) => `accounts/user/edit/${p.userId}`,
  addNewUserAccount: (p: RouteParamDepartmentId) => `accounts/user/new/${p.departmentId}`,
  addNewQuickUserAccount: (p: { signupKey: string, departmentId: string }) => `accounts/user/signup/${p.signupKey}/${p.departmentId}`,
  accountUsersSearch: () => "accounts/user-search",
  accountsList: () => "accounts",
  accountsLicensing: () => "accounts-licensing",
  userChangePassword: (p: RouteParamUserId) => `accounts/user/change-password/${p.userId}`,
  unMappedDevicesList: () => "unmapped-devices",
  resourceDebuggingList: () => "resource-debugging",
  checklistDebuggingList: () => "checklist-debugging",
  taskDebuggingList: () => "task-debugging",
  actionLogList: () => "action-log",
  mailgunLogList: () => "mailgun-log",
  monitorEventsList: () => "monitor",
  mapsDebuggingList: () => "maps-debugging",
  arcGISGroupsList: () => "arcgis-groups",
  csvImportTool: () => "csv-import-tool",
  messages: () => "messages", // view only messages view
  incidentsLeftOpenList: () => "incidents-left-open",
  validationReportList: () => "validation-report",
  devicesStatsLegacy: () => "devices-stats",
  devicesStatsNext: () => "devices-stats-next",

  incidentsChart: () => "chart/incidents-chart",
  managedIncidentsChart: () => "chart/managed-incidents",
  usersChart: () => "chart/users-chart",

  accountDetails: (p: RouteParamDepartmentId) => `accounts/details/${p.departmentId}`,

  adminIncidentsLeftOpen: () => "admin-features/incidents-left-open",

  adminResources: () => "resources",
  adminPersonnel: () => "personnel",
  adminResourcesUnit: (p: { id: string }) => `resources/units/${p.id}`,
  adminChecklists: () => "checklists",
  adminChecklistItem: (p: { id: string }) => `checklist/items/${p.id}`,
  adminTasks: () => "tasks",
  adminTemplate: () => "admin-features/template",
  adminTemplateAdd: () => "admin-features/template/add",
  adminTemplateEdit: (p: RouteParamTemplate) => `admin-features/template/edit/${p.templateId}`,
  adminUnitStaffing: () => "unit-staffing",

  adminDeviceMapping: () => "admin-features/device-mapping",
  adminAgencies: () => "admin-features/agencies",
  adminMaps: () => "admin-features/maps",
  dashboard: () => "dashboard",
  adminDashboardFullPath: () => "/admin/app/dashboard",
  adminMapOverview: () => "map-overview",

  releaseNotes: () => "release-notes",

  // CAD Incidents
  adminCADIncidents: (p: RouteParamCADIncidentsList) => `cad/${p.tab}`,
  adminCADIncidentDetails: (p: RouteParamCADIncidentDetails) => `cad/details/${p.incidentNumber}`,

  adminIncidentsList: (p: { tab: string }) => `incidents/${p.tab}`,
  adminIncidentDetails: (p: { incidentNumber: string, incidentId: string }) => `incidents/details/${p.incidentNumber}`,

  // Managed incidents
  adminActiveTcIncidents: () => "tc-incidents/active-incidents",
  adminInactiveTcIncidents: () => "tc-incidents/inactive-incidents",

  adminManagedIncidentsList: (p: RouteParamManagedIncidentsList) => `tc-incidents/${p.tab}`,
  adminManagedIncidentDetails: (p: RouteParamManagedIncidentDetails) => `tc-incidents/details/${p.incidentNumber}`,
  adminManagedIncidentLargeScreenView: (p: RouteParamManagedIncidentDetails) => `cad/manged-incidents/${p.incidentNumber}`,

  // CAD Simulator
  adminCADSimulator: () => "admin-features/cad-simulator",
  adminCADSimulatorNew: () => "admin-features/cad-simulator-new",
  adminCADSimulatorCannedNew: () => "admin-features/cad-simulator-canned-new",
  adminCADSimulatorCannedEditTemplate: (p: { incidentId: string }) => `admin-features/cad-simulator-canned-edit/${p.incidentId}`,
  adminCADSimulatorTabs: (p: RouteParamCADSimulators) => `admin-features/cad-simulator/${p.tab}`,
  adminCADSimulatorEdit: (p: { incidentId: string }) => `admin-features/cad-simulator/edit/${p.incidentId}`,
  adminCADSimulatorCannedEdit: (p: { incidentId: string }) => `admin-features/cad-simulator-canned/edit/${p.incidentId}`,
  adminCADSimulatorCannedEditNew: (p: { incidentId: string }) => `admin-features/cad-simulator-canned-new/edit/${p.incidentId}`,

  adminSettings: () => "admin-features/settings",
  adminLocation: () => "admin-features/location",
  adminUsers: () => "admin-features/users",
  adminEditUsers: (p: RouteParamUserId) => `admin-features/users/edit/${p.userId}`,
  adminUsersSignup: (p: { signupKey: string, departmentId: string }) => `admin-features/users/signup/${p.signupKey}/${p.departmentId}`,

  // Messages
  adminMessages: () => "admin-features/messages",
  adminMessagesAdd: (p: { id: string }) => `admin-features/messages/add/${p.id}`,
  adminMessageEdit: (p: { departmentId: string, messageId: string }) => `admin-features/messages/edit/${p.departmentId}/${p.messageId}`,

  adminRemoteLogs: () => "admin-features/device-logs",
  adminActionLog: () => "admin-features/action-log",
  adminLastLoggedIn: () => "admin-features/last-logged-in",
  adminCADStatus: (p: RouteParamStatus) => `admin-features/cad-status/${p.tab}`,
  adminCADVehicleStatusDetails: (p: RouteParamVehicleStatusDetails) => `admin-features/cad-vehicle-status-details/${p.vehicleId}`,
  // Charts
  adminChartIncidents: () => "admin-features/chart/incidents-chart",
  adminChartManagedIncidents: () => "admin-features/chart/managed-incidents",
  adminChartUsers: () => "admin-features/chart/users-chart",

  adminOnlineUsers: () => "admin-features/online-users",
  adminArcGISMap: () => "dev/arcgis-map",

  // Basic user admin restricted pages
  userVehiclesStatus: () => "user/vehicle-status",
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function routeTo<T>(p: NavigateFunction, fn: () => string): void
// eslint-disable-next-line no-redeclare
export function routeTo<T>(p: NavigateFunction, fn: (p: T) => string, params: T): void
// eslint-disable-next-line no-redeclare
export function routeTo<T>(p: NavigateFunction, fn: (p?: T) => string, params?: T) {
  p(fn(params));
}
export function useRouting() {
  const navigate = useNavigate();

  function goBack() {
    navigate(-1);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function routeTo<T>(fn: () => string): void
  // eslint-disable-next-line no-redeclare
  function routeTo<T>(fn: (p: T) => string, params: T): void
  // eslint-disable-next-line no-redeclare
  function routeTo<T>(fn: (p?: T) => string, params?: T) {
    navigate(fn(params));
  }
  return {
    routeTo, goBack,
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function route(fn: () => string): string
// eslint-disable-next-line no-redeclare
export function route<T>(fn: (p: T) => string, params: Array<keyof T>): string
// eslint-disable-next-line no-redeclare
export function route<T>(fn: (p: T) => string, params: Array<keyof T> = []) {
  const parameter: Record<string, string> = {};
  params.forEach((p) => {
    parameter[p as string] = `:${p as string}`;
  });
  return fn(parameter as unknown as T);
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function getURL<T>(fn: () => string): string
// eslint-disable-next-line no-redeclare
export function getURL<T>(fn: (p: T) => string, params: T): string
// eslint-disable-next-line no-redeclare
export function getURL<T>(fn: (p?: T) => string, params?: T) {
  return fn(params);
}

export function getValidStatusTab(maybeTab: StatusTab | null | undefined, defaultValue: StatusTab): StatusTab {
  let nextTabValue = defaultValue;
  switch (maybeTab) {
    case StatusTab.Statuses: // Validate selected tab
    case StatusTab.Vehicles:
    case StatusTab.VehicleStatuses:
      nextTabValue = maybeTab;
      break;

    default:
      break;
  }
  return nextTabValue;
}

export function getValidCADIncidentsTab(maybeTab: CADIncidentsTab | null | undefined, defaultValue: CADIncidentsTab): CADIncidentsTab {
  let nextTabValue = defaultValue;
  switch (maybeTab) {
    case CADIncidentsTab.Active: // Validate selected tab
    case CADIncidentsTab.Inactive:
    case CADIncidentsTab.Search:
      nextTabValue = maybeTab;
      break;

    default:
      break;
  }
  return nextTabValue;
}

export function getValidCADSimulatorTab(maybeTab: CADSimulatorTabs | null | undefined, defaultValue: CADSimulatorTabs): CADSimulatorTabs {
  let nextTabValue = defaultValue;
  switch (maybeTab) {
    case CADSimulatorTabs.Normal: // Validate selected tab
    case CADSimulatorTabs.Canned:
      nextTabValue = maybeTab;
      break;

    default:
      break;
  }
  return nextTabValue;
}

export function getValidManagedIncidentsTab(maybeTab: ManagedIncidentsTab | null | undefined, defaultValue: ManagedIncidentsTab): ManagedIncidentsTab {
  let nextTabValue = defaultValue;
  switch (maybeTab) {
    case ManagedIncidentsTab.Active: // Validate selected tab
    case ManagedIncidentsTab.Inactive:
      nextTabValue = maybeTab;
      break;

    default:
      break;
  }
  return nextTabValue;
}

export function normalizedPathName(realPathName: string, publicUrl = window.env.PUBLIC_URL): string {
  if (!publicUrl || publicUrl === "" || publicUrl === "/") {
    return realPathName;
  }

  // If VITE_PUBLIC_URL is set (e.g. review-xxx)
  // replace review-xxx with /
  // so isPublicRoute would match
  return realPathName.replace(publicUrl, "");
}

export function isPublicRoute(currentPath: string): boolean {
  // Public routes excluding /login
  const publicRoutes: string[] = [
    urls.recoveryPassword(),
    route(urls.signupForm, ["key"]), // /account/signup/:key123
    route(urls.resetPasswordForm, ["token"]), // /account/change-password/:token123
    // oAuth routes,
    route(urls.loginWithOAuth, ["authType", "code"]),
    route(urls.loginError, ["key"]),
    urls.oAuthJump(), // temporary entry point for login with Google
    route(urls.incidentJoin, ["code"]),
  ];

  let result = false;
  for (const r of publicRoutes) {
    if (matchPath(r, currentPath)) {
      result = true;
      break;
    }
  }

  return result;
}

export function isLoginErrorRoute(currentPath: string): boolean {
  return matchPath(route(urls.loginError, ["key"]), normalizedPathName(currentPath)) !== null;
}

export function isChecklistRoute(currentPath: string): boolean {
  const routes: string[] = [
    // For authenticated URLS, include appPrefix
    `${getURL(urls.routingAppPrefix)}/${getURL(urls.adminChecklists)}`,
    `${getURL(urls.routingAppPrefix)}/${route(urls.adminChecklistItem, ["id"])}`,
  ];

  let result = false;
  for (const r of routes) {
    if (matchPath(r, currentPath)) {
      result = true;
      break;
    }
  }

  return result;
}
