export class ServerError<T = any> extends Error {
  constructor(
        public status: number,
        public errorPayload: T,
  ) {
    super(`Server returned ${status} with payload ${JSON.stringify(errorPayload)}`);
  }

  static async throwIfNot200(response: Response) {
    if (response.status !== 200) {
      throw this.fromResponse(response);
    }
  }

  static async fromResponse(response: Response) {
    let payload;
    try {
      payload = await response.json();
    } catch {
      payload = await response.text();
    }
    return new ServerError(response.status, payload);
  }
}
