/* eslint-disable camelcase */
import { AddUpdatePersonnel, Personnel, PersonnelKnown } from "@models/active_personnel";
import { appFetch } from "./config";

export const getActivePersonnelParsed = async (signal?: AbortSignal): Promise<Personnel[]> => {
  const response: Response = await appFetch("api/a1/personnel/active", { signal });
  const results = await response.json() as Personnel[];
  // De-normalize data for unit staffing view
  const finalResults: Personnel[] = [];
  for (const person of results) {
    const multipleUnits = person.radioNames.length > 1;
    const agencyName = person.agencyName;
    for (const radioName of person.radioNames) {
      const temp = JSON.parse(JSON.stringify(person)) as Personnel;
      temp.multipleUnits = multipleUnits;
      temp.radioName = radioName;
      temp.radioNames = [radioName];
      temp.groupedData = `${radioName} - ${agencyName}`;
      finalResults.push(temp);
    }
  }
  return finalResults;
};

export const updateActivePersonnel = async (item: AddUpdatePersonnel[]) => {
  const response: Response = await appFetch("api/a1/personnel", {
    method: "POST",
    body: JSON.stringify(item),
  });
  return response.json();
};

export const getKnownPersonnel = async (): Promise<PersonnelKnown[]> => {
  const response: Response = await appFetch("api/a1/personnel-known");
  return response.json();
};

export const updateKnownPersonnel = async (itemId: string, item: PersonnelKnown) => {
  const response: Response = await appFetch(`api/a1/personnel-known/update/${itemId}`, {
    method: "POST",
    body: JSON.stringify(item),
  });
  return response.json();
};

export const createKnownPersonnel = async (item: PersonnelKnown, departmentId: string) => {
  const response: Response = await appFetch(`api/a1/personnel-known/add/${departmentId}`, {
    method: "POST",
    body: JSON.stringify(item),
  });
  return response.json();
};
