import { Department, IncidentType } from "@models/department";
import { useTitle } from "@hooks/useTitle";
import classes from "../settings.module.css";
import { IconButton, Typography } from "@mui/material";
import MaterialTable from "material-table";
import { useState } from "react";
import { ExpandLess, ExpandMore, GetApp } from "@mui/icons-material";
import { CSVLink } from "react-csv";
import { TabletButton } from "@components/tablet-button/custom-button";
import { useHeight } from "@hooks/useHeight";

interface ParsedIncidentItem {
  name: string,
  callTypeDescription: string,
  callType: string[]
}

export function TableChildrenCell(props: { title: string, listElements: string[], allowPartialMatch: boolean }) {
  const [open, setOpen] = useState(false);
  const { title, listElements, allowPartialMatch } = props;
  const renderTitle = `${title} ${allowPartialMatch ? "contains" : "is"}`;
  if (!listElements.length) {
    return <Typography className={classes.red_text}>{`No ${title} configuration present.`}</Typography>;
  }
  return (
    <>
      <div style={{ display: "flex" }}>
        <Typography>{renderTitle}</Typography>
        <IconButton className={classes.push_notification_row_button} onClick={() => setOpen(!open)}>{open ? <ExpandLess /> : <ExpandMore />}</IconButton>
      </div>
      <ul style={{ display: open ? "block" : "none", margin: "10px 0" }}>
        {listElements.map((element, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <li key={`${element} ${index}`}>
            <Typography>{element}</Typography>
          </li>
        ))}
      </ul>
    </>
  );
}

export default function PushNotifications(prop: { department: Department }) {
  useTitle("Push Notifications Settings");
  const height = useHeight();
  const { department } = prop;

  if (!department) {
    return <div />;
  }
  if (!department.incidentTypes.length) {
    return <div className={`${classes.note_incident} `}>Not configured</div>;
  }

  const headers = [
    { label: "CAD Call Type Description", key: "callTypeDescription" },
    { label: "TC Incident Category", key: "name" },
    { label: "Call Type", key: "callType" },
  ];

  const parseDataForCSV = (data: IncidentType[]) => {
    const parsedData = data.flatMap((incident) => {
      const parsedIncident: ParsedIncidentItem[] = [];
      incident.callTypeDescription.map((description) => parsedIncident.push({ name: incident.name, callTypeDescription: description, callType: incident.callType }));
      return parsedIncident;
    });

    return parsedData;
  };

  return (
    <div style={{ padding: 20 }}>
      <MaterialTable
        data={department.incidentTypes}
        title={(
          <div className="rsuite-table-title-wrapper">
            <Typography style={{
              color: "white", fontSize: 20, fontWeight: "500", marginRight: 10,
            }}
            >
              Push Notifications
            </Typography>
            <TabletButton
              variant="contained"
              color="primary"
              startIcon={<GetApp />}
              style={{ marginLeft: 10 }}
            >
              <CSVLink
                data={parseDataForCSV(department.incidentTypes)}
                headers={headers}
                filename="push_notifications.csv"
                style={{
                  textDecoration: "none",
                  color: "white",
                }}
              >
                Export Push Notifications
              </CSVLink>
            </TabletButton>
          </div>
        )}
        options={{
          paging: false,
          header: true,
          defaultExpanded: true,
          maxBodyHeight: height - 40,
          headerStyle: {
            position: "sticky",
            top: "0",
          },
        }}
        columns={[{
          title: "Name",
          field: "name",
          render: (data) => {
            const { name, value } = data;
            return <Typography>{`${name} (${value})`}</Typography>;
          },

        }, {
          render: (data) => {
            const { callTypeDescription, allowPartialMatch } = data;
            return <TableChildrenCell title="Call Type Description" allowPartialMatch={allowPartialMatch} listElements={callTypeDescription} />;
          },
        },
        {
          render: (data) => {
            const { callType, allowPartialMatch } = data;
            return <TableChildrenCell title="Call Type" allowPartialMatch={allowPartialMatch} listElements={callType} />;
          },
        }]}
      />
    </div>
  );
}
