import { useState } from "react";
import { Collapse } from "react-collapse";

import {
  AccountBalance,
  CloudUpload,
  BarChart,
  ExpandMore,
  Fireplace,
  Public as PublicIcon,
  SupervisorAccount,
  VerifiedUserOutlined,
} from "@mui/icons-material";

import ActionLog from "../img/menu-icons/Action-Log.svg?react";
import ChecklistDebugging from "../img/menu-icons/Checklist-Debugging.svg?react";
import Dashboard from "../img/menu-icons/Dashboard.svg?react";
import DevicesStats from "../img/menu-icons/Devices-Stats.svg?react";
import IncidentsLeftOpen from "../img/menu-icons/Incidents-Left-Open.svg?react";
import MailgunLog from "../img/menu-icons/Mailgun-Log.svg?react";
import MapsDebugging from "../img/menu-icons/Maps-Debugging.svg?react";
import Messages from "../img/menu-icons/messages_icon.svg?react";
import MonitorEvents from "../img/menu-icons/monitor_events_icon.svg?react";
import ResourceDebugging from "../img/menu-icons/Resource-Debugging.svg?react";
import TaskDebugging from "../img/menu-icons/Task-Debugging.svg?react";
import UnMappedDevices from "../img/menu-icons/Unmapped-Devices.svg?react";
import Tasks from "../img/menu-icons/Tasks.svg?react";

import { MenuLink } from "@components/menu-links";
import UserRegistration from "../user-registration";
import { getURL, urls } from "@utils/routing";

export default function MenuSuperAdmin(prop: {
  handleCollapse: () => void
}) {
  const [collapse, setCollapse] = useState(false);
  const { handleCollapse } = prop;

  return (
    <>
      <MenuLink
        to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.dashboard)}`}
        label="Dashboard"
        icon={Dashboard}
        onClick={handleCollapse}
      />
      <MenuLink
        to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.accountsList)}`}
        label="Accounts"
        icon={SupervisorAccount}
        onClick={handleCollapse}
      />
      <MenuLink
        to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.accountsLicensing)}`}
        label="Licensing"
        icon={AccountBalance}
        onClick={handleCollapse}
      />
      <MenuLink
        to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.unMappedDevicesList)}`}
        label="Unmapped Devices"
        icon={UnMappedDevices}
        onClick={handleCollapse}
      />
      <MenuLink
        to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.resourceDebuggingList)}`}
        label="Resource Debugging"
        icon={ResourceDebugging}
        onClick={handleCollapse}
      />
      <MenuLink
        to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.checklistDebuggingList)}`}
        label="Checklist Debugging"
        icon={ChecklistDebugging}
        onClick={handleCollapse}
      />
      <MenuLink
        to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.taskDebuggingList)}`}
        label="Task Debugging"
        icon={TaskDebugging}
        onClick={handleCollapse}
      />
      <MenuLink
        to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.devicesStatsLegacy)}`}
        label="Devices Stats"
        icon={DevicesStats}
        onClick={handleCollapse}
      />
      <MenuLink
        to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.devicesStatsNext)}`}
        label="Devices Stats Next"
        icon={DevicesStats}
        onClick={handleCollapse}
      />
      <MenuLink
        to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.mapsDebuggingList)}`}
        label="Maps Debugging"
        icon={MapsDebugging}
        onClick={handleCollapse}
      />
      <MenuLink
        to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.arcGISGroupsList)}`}
        label="ArcGIS Groups"
        icon={PublicIcon}
        onClick={handleCollapse}
      />
      <MenuLink
        to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.csvImportTool)}`}
        label="CSV Import Tool"
        icon={CloudUpload}
        onClick={handleCollapse}
      />
      <UserRegistration />
      <MenuLink
        to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.incidentsLeftOpenList)}`}
        label="Incidents Left Open"
        icon={IncidentsLeftOpen}
        onClick={handleCollapse}
      />
      <MenuLink
        to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.validationReportList)}`}
        label="Validation Report"
        icon={VerifiedUserOutlined}
        onClick={handleCollapse}
      />
      <MenuLink
        to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.actionLogList)}`}
        label="Action Log"
        icon={ActionLog}
        onClick={handleCollapse}
      />
      <MenuLink
        to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.messages)}`}
        label="Messages"
        icon={Messages}
        onClick={handleCollapse}
      />
      <MenuLink
        to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.releaseNotes)}`}
        label="Release Notes"
        icon={Tasks}
        onClick={handleCollapse}
      />
      <MenuLink
        to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.adminRemoteLogs)}`}
        label="Remote Logs"
        icon={Fireplace}
        onClick={handleCollapse}
      />
      <MenuLink
        to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.mailgunLogList)}`}
        label="Mailgun Log"
        icon={MailgunLog}
        onClick={handleCollapse}
      />
      <MenuLink
        to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.monitorEventsList)}`}
        label="Monitor Events"
        icon={MonitorEvents}
        onClick={handleCollapse}
      />
      <MenuLink
        label="Chart"
        icon={BarChart}
        icon2={ExpandMore}
        onClick={() => setCollapse(!collapse)}
      />
      <Collapse isOpened={collapse}>
        <MenuLink
          to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.incidentsChart)}`}
          label="Incidents Chart"
          icon={BarChart}
          onClick={handleCollapse}
        />
        <MenuLink
          to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.managedIncidentsChart)}`}
          label="Managed Incidents"
          icon={BarChart}
          onClick={handleCollapse}
        />
        <MenuLink
          to={`${getURL(urls.routingSuperadminAppPrefix)}/${getURL(urls.usersChart)}`}
          label="Users Chart"
          icon={BarChart}
          onClick={handleCollapse}
        />
      </Collapse>
    </>
  );
}
