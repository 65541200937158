import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide, { SlideProps } from "@mui/material/Slide";
import {
  forwardRef, useContext,
} from "react";
import { LoginPopupContext } from "@contexts/login-popup-context";
import { LoginPopupStatusContext } from "@contexts/login-popup-status-context";
import { TabletButton } from "./tablet-button/custom-button";

const Transition = forwardRef((
  props: SlideProps,
  ref: React.Ref<unknown>,
  // eslint-disable-next-line react/jsx-props-no-spreading
) => <Slide direction="up" ref={ref} {...props} />);

export function LoginMessagePopUp() {
  const [data, setData] = useContext(LoginPopupContext);
  const [, setResponse] = useContext(LoginPopupStatusContext);

  const handleClose = (popupStatus: boolean) => {
    setResponse(popupStatus);
    setData({
      open: false,
      message: data!.message,
    });
  };

  return (
    <div>
      <Dialog
        open={data!.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        className="login-popup"
      >
        <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: "center" }}>Login Message</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {data!.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <TabletButton className="formButton" onClick={() => handleClose(true)}>
            Accept
          </TabletButton>
          <TabletButton className="formButton" onClick={() => handleClose(false)}>
            Deny
          </TabletButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
