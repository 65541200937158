import { RefObject } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { TabletButton } from "./tablet-button/custom-button";

export default function AddRowButton(prop: {
  actionName: string,
  addActionRef: RefObject<HTMLDivElement>,
  disabled: boolean,
  title?: string,
  onClick?: () => void
}) {
  const {
    actionName,
    addActionRef,
    disabled,
    title,
    onClick,
  } = prop;

  let titleJSX: JSX.Element | null = null;
  if (title && title !== "") {
    titleJSX = (
      <span
        style={{
          color: "white",
          fontSize: 20,
          marginBottom: 0,
          marginTop: 0,
          marginLeft: 10,
        }}
      >
        {title}
      </span>
    );
  }

  return (
    <div style={{
      marginTop: "auto",
      marginBottom: "auto",
    }}
    >
      <TabletButton
        className="material-table-add-row-button"
        disabled={disabled}
        size="small"
        onClick={() => (onClick ? onClick() : addActionRef.current?.click())}
        startIcon={<AddCircleIcon />}
      >
        {actionName}
      </TabletButton>
      {titleJSX}
    </div>
  );
}
