import {
  useState, useEffect, Suspense, useContext,
  useMemo,
} from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  Navigate,
  Route, Routes,
} from "react-router-dom";

import "./App.css";
import "./overrides/m-table-toolbar-search-fix";
import "./overrides/m-table-default-props-mod";

import { LogoutPopUp } from "./components/pop-up-logout";
import DebugGrid from "./components/debug-grid/DebugGrid";
import Version from "./components/version";

import { accountSession, checkSession, clearLocalStorage } from "./services/user";

import {
  LoginContext,
  Role,
  SavedUser,
  LoginContextProvider,
} from "./contexts/login-context";

import { LoadingProvider } from "./hooks/useLoading";
import { TitleContextProvider } from "./hooks/useTitle";
import { useEffectAsync } from "./hooks/utils";
import {
  UIStateContextProvider,
} from "./contexts/ui-state-context";

import Login from "./pages/login/login-page";
import PasswordRecovery from "./pages/password-recovery";
import IncidentJoin from "./pages/incident-join";

import {
  getURL, isPublicRoute, normalizedPathName, route, urls, useRouting,
} from "./utils/routing";

import SignUpForm from "./pages/signup/signup-page";
import ResetPasswordForm from "./pages/change-password";
import OAuthJump from "./pages/OAuth/OAuthJump";

import { LoginMessagePopUp } from "./components/login-disclaimer-message-popup";
import FreshDeskHelp from "./components/freshdesk-help-button";
import { UserInstructionContextProvider } from "./contexts/user-instructions-context";
import { DepartmentContextProvider } from "./contexts/department-context";
import { ScrollContextProvider } from "./contexts/scroll-context";
import { DashboardSearchContextProvider } from "./contexts/dashboard-search-context";
import { LoginPopupContextProvider } from "./contexts/login-popup-context";
import { TablesPageContextProvider } from "./contexts/tables-page-context";
import { LoginPopupStatusContext, LoginPopupStatusContextProvider } from "./contexts/login-popup-status-context";
import AuthenticatedSuperUser from "./routes/superuser-routes";
import AuthenticatedAdmin from "./routes/admin-routes";
import { ApplyContext } from "./utils/utils";
import { MessagesContextProvider } from "./contexts/message-context";
import { ArcGISMapMapContextProvider } from "./contexts/arcgis-map-context";
import { MapLayersContextProvider } from "./contexts/map-layers-context";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

export default function App() {
  const [user, setUser] = useContext(LoginContext);
  const { routeTo } = useRouting();
  const [isUserChecked, setIsUserChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [, setPopupStatus] = useContext(LoginPopupStatusContext);

  const queryClient = new QueryClient();

  useEffect(() => {
    const interval = setInterval(async () => {
      const noSessionRequired = normalizedPathName(window.location.pathname) === urls.login() || isPublicRoute(normalizedPathName(window.location.pathname));
      if (noSessionRequired) {
        return;
      }

      const user = await accountSession();
      setOpen(false);
      setPopupStatus(false);
      if (!user.session.active && user.session.why === "A new web login was detected.") {
        setOpen(true);
        clearLocalStorage();
        routeTo(urls.login);
      }
      if (!user.session.active) {
        clearLocalStorage();
        routeTo(urls.login);
      }
    }, 30000);

    return () => clearInterval(interval);
  }, []);

  const navigationUrl = useMemo(() => {
    if (user) {
      if (user.role === Role.SuperUserReadOnly) {
        return getURL(urls.accountsList);
      }
      if (user.role === Role.SuperAdmin) {
        return getURL(urls.dashboardFullPath);
      }
      return getURL(urls.adminDashboardFullPath);
    }
    return getURL(urls.login);
  }, [user]);

  useEffectAsync(async () => {
    // Entry point for https://site/, if logged in, redirect to "/home", otherwise, to /login
    const userInStorage = localStorage.getItem("user");
    if (userInStorage) {
      const savedUser: SavedUser = JSON.parse(userInStorage);
      try {
        setUser(savedUser);
        const data = await checkSession(savedUser.token);
        if (data?.session?.active !== true) {
          throw new Error("Login not active!");
        }

        if (normalizedPathName(window.location.pathname) === "/") {
          // Send user to homepage
          let destRoute = urls.adminDashboardFullPath;
          if (savedUser.role === Role.SuperUserReadOnly) {
            destRoute = urls.accountsList;
          }
          if (savedUser.role === Role.SuperAdmin) {
            destRoute = urls.dashboardFullPath;
          }
          routeTo(destRoute);
        }
      } catch (e) {
        clearLocalStorage();
        routeTo(urls.login);
      }
    } else if (isPublicRoute(normalizedPathName(window.location.pathname))) {
      // do nothing
    } else {
      routeTo(urls.login);
    }
    setIsUserChecked(true);
    // eslint-disable-next-line
  }, []);

  if (!isUserChecked) {
    return <div />;
  }

  return (
    <DndProvider backend={HTML5Backend}>
      <Suspense fallback={<div />}>
        <QueryClientProvider client={queryClient}>
          <ApplyContext contexts={[
            LoadingProvider,
            TitleContextProvider,
            DashboardSearchContextProvider,
            DepartmentContextProvider,
            LoginContextProvider,
            MapLayersContextProvider,
            LoginPopupContextProvider,
            LoginPopupStatusContextProvider,
            MessagesContextProvider,
            ScrollContextProvider,
            TablesPageContextProvider,
            UIStateContextProvider,
            UserInstructionContextProvider,
            ArcGISMapMapContextProvider,
          ]}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <StyledEngineProvider injectFirst>
                <div className="App">
                  <Routes>
                    {/* Allowed Login routes:
                  - /login (normal login)
                  - /login/:authType/:code (e.g. /login/o-google/12code34)
                */}
                    <Route path={route(urls.login)} element={<Login />}>
                      <Route path={route(urls.loginWithOAuth, ["code", "authType"])} element={<Login />} />
                    </Route>
                    <Route path={route(urls.loginError, ["key"])} element={<Login />} />
                    <Route path={route(urls.appPrefix)} element={<AuthenticatedSuperUser />} />
                    <Route path={route(urls.adminAppPrefix)} element={<AuthenticatedAdmin />} />
                    <Route
                      path="*"
                      element={(
                        <Navigate
                          to={navigationUrl}
                          replace
                        />
                      )}
                    />
                    <Route path={route(urls.recoveryPassword)} element={<PasswordRecovery />} />
                    <Route path={route(urls.signupForm, ["key"])} element={<SignUpForm />} />
                    <Route path={route(urls.resetPasswordForm, ["token"])} element={<ResetPasswordForm />} />
                    <Route path={route(urls.oAuthJump)} element={<OAuthJump />} />
                    <Route path={route(urls.incidentJoin, ["code"])} element={<IncidentJoin />} />
                  </Routes>
                </div>
                <LogoutPopUp open={open} />
                <LoginMessagePopUp />
                <DebugGrid />
                <Version />
                <FreshDeskHelp />
              </StyledEngineProvider>
            </LocalizationProvider>
          </ApplyContext>
        </QueryClientProvider>
      </Suspense>
    </DndProvider>
  );
}
