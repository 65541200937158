import moment from "moment";
import {
  useCallback,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import CADLabel from "@components/cad-labels";
import TableDate from "@components/table-date";
import { CADIncident } from "@models/cad";
import { useHeight } from "@hooks/useHeight";
import { getURL, urls } from "@utils/routing";
import {
  getCADIncidentCustomFilterAndSearchValue,
  getCustomFilterAndSearch,
} from "@utils/utilsFunctions";
import MaterialTable, { Column } from "material-table";
import classes from "./cad-incidents.module.css";
import { CADIncidentsTab } from "@models/routing";
import { Tooltip, Typography } from "@mui/material";

interface CADIncidentsListProps {
  CADIncidents: CADIncident[]
  tableTitle: CADIncidentsTab
  tableNoDataMessage?: string
  isLoading?: boolean
  displayTooltipsScore?: boolean
}

export function CADIncidentsTable({
  CADIncidents, tableTitle, tableNoDataMessage, isLoading, displayTooltipsScore = false,
}: CADIncidentsListProps) {
  const navigate = useNavigate();
  const height = useHeight();
  const [columns, setColumns] = useState<Array<Column<CADIncident>>>([
    {
      title: "Incident Date Time",
      field: "EntryDateTime",
      render: ({ EntryDateTime }) => <TableDate date={EntryDateTime} />,
      sorting: true,
      customSort: (a, b) => (moment(b.EntryDateTime).unix() ?? 0) - (moment(a.EntryDateTime).unix() ?? 0),
      cellStyle: { width: "16%" },
    },
    {
      title: "Incident",
      field: "AgencyIncidentCallTypeDescription",
      render: (item) => (
        <Tooltip title={displayTooltipsScore ? item.scoreDetails?.value : ""} placement="left">
          <div>
            <CADLabel
              incidentCallTypeDescription={item.AgencyIncidentCallTypeDescription ?? ""}
              incidentNumber={item.IncidentNumber ?? ""}
              incidentNumberDisplay={item.IncidentNumberDisplay ?? ""}
              address={item.full_address ?? ""}
              units={item.units ?? []}
              rts={item.rts}
              extended={false}
              simulation={item.simulation}
              sharedTo={item.sharedTo}
              sharedSource={item.sharedSource}
            />
          </div>
        </Tooltip>
      ),
      ...getCustomFilterAndSearch<CADIncident>((item) => getCADIncidentCustomFilterAndSearchValue(item)),
    },
    {
      title: "Last Updated",
      field: "modified_unix_date",
      render: (item) => moment((item.modified_unix_date ?? 0) * 1000).fromNow(),
      ...getCustomFilterAndSearch<CADIncident>((item) => moment((item.modified_unix_date ?? 0) * 1000).fromNow()),
      sorting: true,
      customSort: (a, b) => (b.modified_unix_date ?? 0) - (a.modified_unix_date ?? 0),
    },
    {
      title: "Incident Status",
      field: "ClosedDateTime",
      hidden: tableTitle !== CADIncidentsTab.Search,
      render: (item) => (
        <Typography className={classes.cad_incidents_status_label} style={{ backgroundColor: item.ClosedDateTime ? "#d39a9c" : "#72918d" }}>
          {item.ClosedDateTime
            ? "Inactive"
            : "Active"}
        </Typography>
      ),
      sorting: true,
      customSort: (a, b) => {
        const aClosedDateTime = a.ClosedDateTime ? moment(a.ClosedDateTime).unix() : moment().unix();
        const bClosedDateTime = b.ClosedDateTime ? moment(b.ClosedDateTime).unix() : moment().unix();
        return bClosedDateTime - aClosedDateTime;
      },
    },
  ]);

  const onOrderChanged = useCallback((colIndex: number, order?: "asc" | "desc") => {
    setColumns((c) => c.map((c, i) => (colIndex === i
      ? { ...c, defaultSort: order }
      : { ...c, defaultSort: undefined })));
  }, [setColumns]);

  const tableRenderTitle = () => {
    switch (tableTitle) {
      case CADIncidentsTab.Active:
        return "Active Incidents";
      case CADIncidentsTab.Inactive:
        return "Inactive Incidents";
      case CADIncidentsTab.Search:
      default:
        return "Incidents";
    }
  };

  return (
    <>
      <MaterialTable<CADIncident>
        // eslint-disable-next-line react/jsx-props-no-spreading
        title={tableRenderTitle()}
        data={CADIncidents}
        localization={{
          body: {
            emptyDataSourceMessage: tableNoDataMessage || "No incidents to display",
          },
        }}
        isLoading={isLoading}
        onOrderChange={onOrderChanged}
        options={{
          paging: false,
          sorting: true,
          maxBodyHeight: height - 90,
          headerStyle: {
            position: "sticky",
            top: "0",
          },
          rowStyle: (data: CADIncident) => ({
            backgroundColor: (data.sharedSource && data.sharedSource.isExternal) ? "#fadea6" : data.simulation ? "#b8e4fc" : "",
          }),
        }}
        columns={columns.map((c) => ({
          ...c,
        }))}
        onRowClick={(event, rowData) => navigate(`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADIncidentDetails, { incidentNumber: rowData?.IncidentNumber })}`)}
      />
    </>
  );
}
