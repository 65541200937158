import { useContext, useEffect } from "react";
import "./menu.css";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import logo from "../img/logo.png";
import { LoginContext, Role } from "@contexts/login-context";
import { UIStateContext } from "@contexts/ui-state-context";

import { MenuAdmin } from "./admin-menu";
import { MenuAgencyAdmin } from "./agencyadmin-menu"; // cspell:disable-line
import MenuSuperAdmin from "./superadmin-menu";
import { useFullScreen } from "@hooks/useFullScreen";
import MenuSuperAdminReadOnly from "./superadmin-menu-readonly";
import { useQuery } from "@tanstack/react-query";
import { getDepartmentById } from "@services/department";
import { CommonMenuItems } from "./common-menu";

export default function Menu(prop: {
  size: number | null
}) {
  const { size } = prop;
  const [state, setState] = useContext(UIStateContext);
  const [user] = useContext(LoginContext);
  const { isBrowserOnFullScreen } = useFullScreen();

  const {
    data: departmentData,
  } = useQuery({
    queryKey: ["user-department", user?.departmentId],
    retry: false,
    queryFn: ({ signal }) => getDepartmentById(user?.departmentId, signal),
    placeholderData: (prev) => prev,
  });

  useEffect(() => {
    if (size && size <= 600) {
      setState("collapsed");
    }

    if (isBrowserOnFullScreen) {
      setState("collapsed");
    } else {
      setState("expanded");
    }
  }, [size, isBrowserOnFullScreen]);

  const handleCollapse = () => {
    if (size && size <= 600) {
      setState("collapsed");
    }
  };

  // INFO: Superadmin do not have the common menu
  const commonMenuItems = (Role.SuperAdmin === user?.role || Role.SuperUserReadOnly === user?.role) ? null : <CommonMenuItems handleCollapse={handleCollapse} user={user} departmentData={departmentData} />;
  let extendedMenu = null;
  switch (user?.role) {
    case Role.SuperUserReadOnly:
      extendedMenu = <MenuSuperAdminReadOnly handleCollapse={handleCollapse} />;
      break;
    case Role.SuperAdmin:
      extendedMenu = <MenuSuperAdmin handleCollapse={handleCollapse} />;
      break;
    case Role.Admin:
      extendedMenu = <MenuAdmin handleCollapse={handleCollapse} departmentData={departmentData} />;
      break;
    case Role.AgencyAdmin:
      extendedMenu = <MenuAgencyAdmin handleCollapse={handleCollapse} user={user} departmentData={departmentData} />;
      break;
    default:
      break;
  }

  return (
    <>
      <div className={`fake-side-menu ${state}`} />
      <div className={`side-menu ${state}`}>
        <div className="logo">
          <img
            src={logo}
            className="image-logo"
            alt="logo"
          />
        </div>
        {commonMenuItems}
        {extendedMenu}
      </div>
      <div className={`bottom-menu ${state}`}>
        <div className={`upper-menu-component ${state}`} />
        <div className={`lower-menu-component ${state}`} />
      </div>
      <div
        className={`toggle ${state}`}
        role="button"
        aria-hidden="true"
        onClick={() => setState(state === "expanded" ? "collapsed" : "expanded")}
      >
        <ArrowBackIosIcon />
      </div>
    </>
  );
}
