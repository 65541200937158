import {
  Button,
  FormLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

import PermIdentityIcon from "@mui/icons-material/PermIdentity";

import { Form, Formik, FormikErrors } from "formik";
import {
  useCallback, useContext, useEffect, useState,
} from "react";
import { NavLink, useParams } from "react-router-dom";

import "./login-page.css";

import {
  getSAMLStart,
  getSignInWithGoogle,
  userLogin,
  userLoginConfigure,
} from "@services/user";

import {
  AuthType,
  Credentials,
  LoginConfigureItem,
  LoginConfigureItemSAML,
  LoginConfigureItemGoogle,
  UserLoginError,
  UserLoginSuccess,
} from "@models/user";
import ContactInfo from "@components/ContactInfo";
import {
  getUserRole,
  LoginContext,
  Role,
  SavedUser,
} from "@contexts/login-context";
import { LoginPopupContext } from "@contexts/login-popup-context";
import { LoginPopupStatusContext } from "@contexts/login-popup-status-context";
import { useTitle } from "@hooks/useTitle";
import { useEffectAsync } from "@hooks/utils";
import {
  useRouting, urls, route, isLoginErrorRoute,
} from "@utils/routing";
import logo from "../img/logo.png";
import { LoginErrorKey, LoginParamError, OAuthParamCode } from "@models/routing";
import { SelectRegion } from "./login-region-select";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const googleButtonId = "login-with-google";
const samlButtonId = "login-with-saml";

function decodeAuthType(maybe: string | undefined): AuthType {
  let result: AuthType;
  switch ((maybe ?? "").toLowerCase()) {
    case AuthType.Google:
      result = AuthType.Google;
      break;
    case AuthType.SAML:
      result = AuthType.SAML;
      break;
    default:
      result = AuthType.Password;
  }
  return result;
}

export default function Login() {
  useTitle("Login");

  const {
    authType,
    code,
  } = useParams<OAuthParamCode>();

  const {
    key,
  } = useParams<LoginParamError>();

  // code is provided URI encoded
  const authBy = decodeAuthType(authType);
  const authCode = (code && code !== "") ? decodeURIComponent(code) : "";
  const loginErrorRoute = isLoginErrorRoute(window.location.pathname);
  const { routeTo } = useRouting();
  const [error, setError] = useState<string>("");
  const [canEditEmail, setCanEditEmail] = useState<boolean>(true);
  const [showLoginWithPassword, setShowLoginWithPassword] = useState(true);
  const [showLoginWithSAML, setShowLoginWithSAML] = useState(false);
  const [showLoginWithGoogle, setShowLoginWithGoogle] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [loginConfigureItems, setLoginConfigureItems] = useState<LoginConfigureItem[]>([]);
  const [, setUser] = useContext(LoginContext);
  const [, setPopupData] = useContext(LoginPopupContext);
  const [popupResponse] = useContext(LoginPopupStatusContext);
  const [loginFormData, setLoginFormData] = useState<{
    userInfo: UserLoginSuccess
    role: Role,
    loggedIn: boolean,
  }>({
    userInfo: {
      user: {
        superuser: false,
      },
      settings: {
        webDisclaimer: {
          enabled: false,
          message: "",
        },
      },
    } as UserLoginSuccess,
    role: Role.SuperAdmin,
    loggedIn: false,
  });

  async function handleLoginResult(result: UserLoginSuccess | UserLoginError) {
    if (!result.ok) {
      const why = result.why;
      setError(why);

      return;
    }

    // Duplicate code, extract
    const role = getUserRole(result.user);
    const store: SavedUser = {
      // Session
      token: result.session.token,
      // User
      username: result.user.nick,
      email: result.user.email,
      userId: result.user.id,
      managedAgencies: result.user.managedAgencies ?? [],
      departmentId: result.user.departmentId ?? "",
      agencyId: result.user.agencyId ?? "",
      role,
      cadSimulatorAccess: result.user.cadSimulatorAccess,
      isPro: result.user.isPro,
      canCreateShareIncidentCode: result.user.canCreateShareIncidentCode ?? false,
    };

    // in case there are other tabs opened, cleanup their storage
    localStorage.removeItem("user");
    window.dispatchEvent(new Event("storage"));
    localStorage.setItem("user", JSON.stringify(store));
    setUser(store);
    setLoginFormData({
      userInfo: result,
      role,
      loggedIn: result.ok,
    });
  }

  useEffect(() => {
    if (!loginErrorRoute) {
      return;
    }

    if (!key) {
      return;
    }

    if (loginErrorRoute) {
      switch (key) {
        case LoginErrorKey.EmailNotFound:
        case LoginErrorKey.InvalidEmail: // fallthrough
          setError("No account found for this email.");
          break;

        case LoginErrorKey.NoSAMLEmail:
          setError("No email was provided by the identity provider.");
          break;

        case LoginErrorKey.InvalidToken:
          setError("Invalid auth token.");
          break;

        case LoginErrorKey.NoSAMLConfiguration:
          setError("Account not configured for enterprise login.");
          break;

        default:
          setError(`Unknown error: ${key}.`);
          break;
      }
    }
  }, [key, loginErrorRoute]);

  useEffect(() => {
    // in case that disclaimer message is activate
    if (loginFormData.userInfo.settings.webDisclaimer && loginFormData.userInfo.settings.webDisclaimer.enabled && loginFormData.role !== Role.SuperAdmin) {
      setPopupData({
        open: !(popupResponse === true),
        message: loginFormData.userInfo.settings.webDisclaimer.message,
      });

      if (popupResponse && loginFormData.userInfo.user.superUserReadOnly) {
        routeTo(urls.accountsListFullPath);
      } else if (popupResponse && loginFormData.userInfo.user.superuser) {
        routeTo(urls.dashboardFullPath);
      } else if (popupResponse && !loginFormData.userInfo.user.superuser) {
        routeTo(urls.adminDashboardFullPath);
      } else {
        // do nothing
      }
      // in case that disclaimer message is not activate and user types his credentials
      // TODO: Clean up this to only use on routeTo(super/normal)
    } else if (loginFormData.loggedIn) {
      if (loginFormData.userInfo.user.superUserReadOnly) {
        routeTo(urls.accountsListFullPath);
      } else if (loginFormData.userInfo.user.superuser) {
        routeTo(urls.dashboardFullPath);
      } else {
        routeTo(urls.adminDashboardFullPath);
      }
    }
  }, [popupResponse === true, loginFormData]);
  useEffectAsync(async () => {
    if (authCode === "" || authType !== AuthType.Google) {
      return;
    }

    const result = await userLogin({
      auth: authBy,
      code: authCode,
    });
    await handleLoginResult(result);
  }, [authBy === AuthType.Google, authCode]);

  useEffectAsync(async () => {
    if (authCode === "" || authType !== AuthType.SAML) {
      return;
    }

    const result = await userLogin({
      auth: AuthType.Google,
      saml: authCode,
    });
    await handleLoginResult(result);
  }, [authBy === AuthType.SAML, code]);

  const handleLoginNavigation = useCallback(async (username: string) => {
    const result = await userLoginConfigure(username);
    setLoginConfigureItems(result);
    setCanEditEmail(false);

    const canLoginWithPassword = result.filter((x) => x.type === "password").length > 0 || result.length === 0;
    const canLoginWithSAML = result.filter((x) => x.type === "saml").length > 0;
    const canLoginWithGoogle = result.filter((x) => x.type === "google").length > 0;

    setShowLoginWithPassword(canLoginWithPassword);
    setShowLoginWithSAML(canLoginWithSAML);
    setShowLoginWithGoogle(canLoginWithGoogle);
  }, [canEditEmail, showLoginWithGoogle, showLoginWithPassword, showLoginWithSAML]);

  let loginPanel: JSX.Element = (
    <Formik<Credentials>
      initialValues={{
        username: "",
        password: "",
      }}
      validate={(values) => {
        const errors: FormikErrors<Credentials> = {};
        if (!values.username) {
          errors.username = "Email is required";
        }
        if (!values.password && !canEditEmail) {
          errors.password = "Password is required";
        }
        return errors;
      }}
      onSubmit={async (data) => {
        const result = await userLogin(data);
        await handleLoginResult(result);
      }}
    >
      {({
        values, handleSubmit, isSubmitting, setFieldValue, errors, setErrors,
      }) => (
        <Form
          onSubmit={handleSubmit}
          onKeyDown={async (e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              if (!values.username.trim()) {
                setErrors({ ...errors, username: "Email is required" });
              } else if (canEditEmail) {
                handleLoginNavigation(values.username);
              } else if (showLoginWithGoogle) {
                const googleButton = document.querySelector(`#${googleButtonId}`) as HTMLButtonElement | undefined;
                googleButton?.click();
              } else if (showLoginWithSAML) {
                const samlButton = document.querySelector(`#${samlButtonId}`) as HTMLButtonElement | undefined;
                samlButton?.click();
              } else {
                handleSubmit();
              }
            }
          }}
        >
          <div className="head">
            <PermIdentityIcon fontSize="large" />
            <p className="title2">Login</p>
          </div>
          <div className="formLogin">
            <FormLabel>Email</FormLabel>
            <TextField
              variant="outlined"
              placeholder="Email"
              required
              value={values.username}
              id="username"
              InputProps={{
                style: { cursor: canEditEmail ? "text" : "default" },
                inputProps: {
                  style: { cursor: canEditEmail ? "text" : "default" },
                },
                endAdornment: !canEditEmail && (
                  <Button
                    disableRipple
                    style={{ minWidth: "fit-content", color: "#0000008a" }}
                    onClick={() => {
                      setCanEditEmail(true);
                      setShowLoginWithPassword(true);
                      setShowLoginWithSAML(false);
                      setShowLoginWithGoogle(false);
                      setShowPassword(false);
                      setFieldValue("password", "");
                      setError("");
                      setLoginConfigureItems([]);
                      setErrors({});
                    }}
                  >
                    <Typography style={{ fontWeight: "bold", fontSize: 16 }}>Edit</Typography>
                  </Button>
                ),
              }}
              onChange={(e) => {
                if (canEditEmail) {
                  setFieldValue("username", e.target.value);
                }
              }}
            />
            {!!errors.username && (
              <div className="fieldErrorContainer">{errors.username}</div>
            )}
            {loginConfigureItems.filter((x) => x.type === "password").length ? (
              <>
                <FormLabel>Password</FormLabel>
                <TextField
                  variant="outlined"
                  autoFocus
                  name="password"
                  placeholder="Password"
                  value={values.password}
                  autoComplete="none"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    ),
                  }}
                  onChange={(e) => {
                    setFieldValue("password", e.target.value);
                  }}
                />
                {!!errors.password && (
                  <div className="fieldErrorContainer">{errors.password}</div>
                )}
              </>
            ) : null}
          </div>
          <div
            style={{
              margin: "10px 0",
              fontSize: "16px",
              fontWeight: "bold",
              color: "#ad0718",
            }}
          >
            <p style={{ fontSize: 18, fontWeight: "bold" }}>{error}</p>
          </div>
          {showLoginWithPassword ? (
            <div className="button">
              <Button
                className="btn"
                variant="contained"
                size="large"
                color="secondary"
                disabled={isSubmitting}
                type={canEditEmail ? "button" : "submit"}
                onClick={async () => {
                  if (!values.username.trim()) {
                    setErrors({ ...errors, username: "Email is required" });
                  } else if (!loginConfigureItems.filter((x) => x.type === "password").length) {
                    handleLoginNavigation(values.username);
                  }
                }}
              >
                {canEditEmail ? "Next" : "LOG IN"}
              </Button>
            </div>
          ) : null}

          {showLoginWithSAML && loginConfigureItems.filter((x) => x.type === "saml").map((x) => {
            const x2 = x as unknown as LoginConfigureItemSAML;
            return (
              <div className="button" key={x2.type + "-" + x2.selector}>
                <Button
                  id={samlButtonId}
                  className="btn"
                  variant="contained"
                  size="large"
                  color="secondary"
                  type="button"
                  href={getSAMLStart(x2.selector)}
                >
                  {x2.name}
                </Button>
              </div>
            );
          })}

          {showLoginWithGoogle && loginConfigureItems.filter((x) => x.type === "google").map((x) => {
            const x2 = x as unknown as LoginConfigureItemGoogle;
            return (
              <div className="button" key={x2.type + "-" + x2.urlPath}>
                <Button
                  id={googleButtonId}
                  className="btn"
                  variant="contained"
                  size="large"
                  color="secondary"
                  type="button"
                  href={getSignInWithGoogle(x2.urlPath)}
                >
                  {x2.name}
                </Button>
              </div>
            );
          })}

          <div className="fPass">
            <NavLink
              to={route(urls.recoveryPassword)}
              style={{
                textDecoration: "none",
                color: "white",
              }}
            >
              <p>Forgot Password?</p>
            </NavLink>
          </div>
          <SelectRegion />
        </Form>
      )}
    </Formik>
  );

  const isAuthBy = (authBy === AuthType.Google || authBy === AuthType.SAML) && authCode !== "";
  if (isAuthBy || loginErrorRoute) {
    let authSrc: string;
    switch (authBy) {
      case AuthType.Google:
        authSrc = "Google";
        break;
      case AuthType.SAML:
        authSrc = "SAML";
        break;
      default:
        authSrc = "unknown";
    }

    const loginWith = loginErrorRoute ? "Login Error" : `Logging in with ${authSrc}...`;
    loginPanel = (
      <>
        <div className="head">
          <PermIdentityIcon fontSize="large" />
          <p className="title2">{loginWith}</p>
        </div>
        <div
          style={{
            margin: "10px 0",
            fontSize: "16px",
            fontWeight: "bold",
            color: "#ad0718",
          }}
        >
          <p style={{ fontSize: 18, fontWeight: "bold" }}>{error}</p>
        </div>
      </>
    );
  }

  return (
    <div className="login-page container login-background">
      <Grid container>
        <Grid item xs={12} lg={7} className="left-side">
          <img src={logo} alt="" className="logo" />
        </Grid>
        <Grid item xs={12} lg={4} className="right-side">
          <Paper className="paperBody">
            {loginPanel}
          </Paper>
          <ContactInfo />
        </Grid>
      </Grid>
    </div>
  );
}
