/* eslint-disable react/no-array-index-key */
import { Checklist, TCIncident } from "@models/tc-incident";
import classes from "./managed-incidents-list.module.css";
import { useState } from "react";
import IncidentTableHeaderWithToggle from "../incident-table-header-with-toggle";
import { CustomMaterialTable } from "@components/custom-material-table";

export function TCIncidentChecklists({ incident }: { incident: TCIncident }) {
  const [expand, setExpand] = useState(true);
  return (
    <div className={classes.checklists}>
      <CustomMaterialTable<Checklist>
        data={incident.checklists ?? []}
        hideContent={!expand}
        cellAlign="left"
        title={<IncidentTableHeaderWithToggle title="Checklists" expand={expand} setExpand={setExpand} />}
        header={[
          {
            name: "Name",
            prop: "name",
          },
          {
            name: "Items",
            prop: "",
            render: (item) => (
              <ul style={{ padding: 0 }}>
                {(item.items ?? []).map((item, i) => (
                  <li
                    key={i}
                    className={classes.checklist_item2}
                  >
                    <span
                      className={classes.checklist_subitem_name}
                      style={{
                        textDecoration: item.checked ? "line-through" : "none",
                        wordBreak: "normal",
                      }}
                    >
                      {item.name}
                    </span>
                  </li>
                ))}
              </ul>
            ),
          },
        ]}
      />
    </div>
  );
}
