/* eslint-disable camelcase */

import { Color } from "./color";

export interface Events {
  location: Coordinate
  _id: string
  departmentId: string
  IncidentNumber: string
  message: string
  type: string
  user: DetailedUserInfo
  userTime: number
  archived: boolean
  modified_unix_date: number
  opts: EventOptions
  serverTime: number
  uuid: string
  ref_uuid?: string // temp unavailable until we update all the objects
  modified: string
}

type EventOptions = EventOptionsBeansAI | EventOptionsFireMapper | EventOptionsUnitPersonnelChange | undefined

export interface EventOptionsFireMapper {
  items: FireMapperLayer[]
}

export interface EventOptionsBeansAI {
  routes: Routes[]
}

export interface EventOptionsUnitPersonnelChange {
  friendlyId: string
  personnelOnScene: number
}

export interface FireMapperLayer {
  name: string
  url: string
  uuid: string
}

export interface Routes {
  origin: Coordinate
  destination: Coordinate
  fragments: Fragments[]
}

export interface Fragments {
  origin: Origin
  destination: Destination
  type: string
}

export interface DetailedUserInfo {
  username: string
  email: string
  radioName: string
  userId: string
}

export interface CADComment {
  Comment: string
  CommentDateTime: string
  CommentSource: string
  CommentUnixTime?: number
  CommentOpts?: {
    type?: string,
    item?: string,
  },
}

export interface NotificationType {
  name: string
  value: string
}

export interface IncidentUnit {
  active?: boolean;
  AlarmAtDispatch?: string;
  color?: Color;
  Personnel?: Personnel[];
  PersonnelCount?: number;
  status?: string;
  TimeArrived?: string;
  TimeAtHospital?: string;
  TimeCleared?: string;
  TimeDispatched?: string;
  TimeEnroute?: string;
  TimePatient?: string;
  TimeStaged?: string;
  TimeTransporting?: string;
  UnitDispatchNumber?: string;
  UnitID?: string;
  uuid?: string;
}

export interface NewUnit {
  AlarmAtDispatch: string
  Personnel: Personnel[]
  PersonnelCount: number
  TimeArrived: string
  TimeAtHospital: string
  TimeCleared: string
  TimeDispatched: string
  TimeEnroute: string
  TimePatient: string
  TimeStaged: string
  TimeTransporting: string
  UnitDispatchNumber: string
  UnitID: string
  active: boolean
  status: string
  color: Color
}

export interface Changes {
  message: string
  new: string
  old: string
  time: number
  type: string
  user: User
}

export interface User {
  username: string
}

export interface Personnel {
  PersonnelID: string
  PersonnelName: string
  PersonnelNote: string
  PersonnelRank: string
  PersonnelWorkCode: string
}

export interface RadioChannel {
  channel: string
  name: string
}

export interface ReportNumber {
  name: string
  number: string
}

export interface IncidentNotifiedLogItem {
  name: string
  type: string
  date: string
}

export interface IncidentNotifiedLog {
  updated: string
  sent: IncidentNotifiedLogItem[]
}

export interface Coordinate {
  longitude: number
  latitude: number
}

export interface LocationBound {
  xmin: number;
  ymin: number;
  xmax: number;
  ymax: number;
}

export interface Origin {
  latitude: number
  longitude: number
  type: string
  text: string
}

export interface Destination {
  latitude: number
  longitude: number
  type: string
  text: string
}

export interface Caller {
  CallerId: string,
  Latitude: number,
  Longitude: number,

  Address?: string,
  Altitude?: number,
  Application?: number,
  LocationUpdatedAt?: string,
  Source?: string,
  UncertaintyRadius?: number,
}

export interface CADIncident {
  AgencyID?: string;
  AgencyIncidentCallTypeDescription?: string;
  CADSimulator: string;
  CallerNumber?: string;
  callers?: Caller[];
  changes?: Changes[];
  CityOrLocality?: string;
  closed_unix_date?: number;
  ClosedDateTime?: string;
  closeIn?: number;
  CommandChannel?: string;
  CommandName?: string;
  Comment?: CADComment[];
  CommonPlaceName?: string;
  cross_streets?: string;
  departmentId?: string;
  EntryDateTime?: string;
  events?: Events[];
  expiration_date?: string;
  FireMap?: string;
  full_address?: string;
  id: string;
  ignored?: boolean;
  IncidentNumber?: string;
  IncidentNumberDisplay?: string;
  Latitude?: string | number;
  LocationComment?: string
  Longitude?: string | number;
  managedIncidentId?: string;
  MapPages?: string;
  modified_date?: string;
  modified_unix_date?: number;
  notificationType?: NotificationType[];
  notify?: boolean;
  PriorIncident?: PriorIncident[]
  radioChannels?: RadioChannel[];
  ReportNumber?: ReportNumber[];
  rts: boolean;
  scoreDetails?: { value: number } // Used for search incidents feature, should delete after it's consistent
  sharedSource: SharedSource;
  sharedTo?: SharedToItem[];
  simulation: boolean;
  simulationId?: string;
  start_unix_date: number;
  StateOrProvince?: string;
  StreetName?: string;
  StreetNumber?: string;
  Suite?: string;
  TacticalAltChannel?: string;
  TacticalChannel?: string;
  TransactionID?: number;
  units?: IncidentUnit[];
  uuid?: string;
}

export interface PriorIncident {
  Address: string
  Comment: PriorIncidentComment[]
  IncidentDateTime: string
  IncidentNumber: string
  Jurisdiction: string
  Problem: string
  Suite: string
}

export interface PriorIncidentComment {
  Comment: string
  CommentDateTime: string
  CommentSource: string
}

export interface SimulatorIncident extends Pick<CADIncident,
  "AgencyID" |
  "AgencyIncidentCallTypeDescription" |
  "CityOrLocality" |
  "ClosedDateTime" |
  "Comment" |
  "CallerNumber" |
  "CommonPlaceName" |
  "CommandChannel" |
  "cross_streets" |
  "EntryDateTime" |
  "expiration_date" |
  "FireMap" |
  "IncidentNumber" |
  "Latitude" |
  "Longitude" |
  "MapPages" |
  "notify" |
  "StateOrProvince" |
  "StreetName" |
  "StreetNumber" |
  "Suite" |
  "TacticalChannel" |
  "TransactionID" |
  "units"
> {
  closeIn?: number;
  CADSimulator?: string;
  rts: boolean;
  simulation: boolean;
  simulationId?: string;
  simulationSequences?: number[];
  callers?: Caller[]
}

export interface SimulatorIncidentPayload extends Omit<SimulatorIncident, "units"> {
  // The API /api/v1/incident uses .Unit
  Unit?: IncidentUnit[];
}

export interface IncidentMap {
  IncidentNumber: string
  IncidentNumberDisplay: string
  address: string
  comments: CADComment[]
  id: string
  latitude: number
  longitude: number
  name: string
  start_time: string
  units: string
  uuid: string
  sharedSource: SharedSource
}

export interface UnitInfo {
  active: boolean,
  AlarmAtDispatch: string,
  Personnel: Personnel[] | undefined,
  PersonnelCount: number,
  status: string,
  TimeArrived: string,
  TimeAtHospital: string,
  TimeCleared: string,
  TimeDispatched: string,
  TimeEnroute: string,
  TimePatient: string,
  TimeStaged: string,
  TimeTransporting: string,
  UnitDispatchNumber: string,
  UnitID: string,
}

export interface IncidentQuery {
  active?: boolean;
  limit?: number;
  skip?: number;
  date?: string
  direction?: string
}

// Copied from backend server-admin
export type SharedToItemReason = {
  name: string,
  date: string,
};

export interface SharedToItem extends Record<string, unknown> {
  active: boolean,
  departmentId: string,
  expireAt: string,
  startAt: string,
  name: string,
  reasons: SharedToItemReason[],
}

export type SharedSource = {
  name: string,
  isExternal: boolean,
  reasons: SharedToItemReason[],
  expireAt?: string,
  startAt?: string,
};

export enum CadSimulatorNavigationTabs {
  Normal = "normal",
  Canned = "canned"
}

export interface SequenceComment {
  source: string
  comment?: string
}

export interface Sequence {
  alarm: string,
  title: string,
  sequenceId: number,
  comments: SequenceComment[],
  unitsConfig: { alarmLevelAtDispatch: string, units: string[] },
  _id?: string,
}

export interface CADCannedSimulationSlim {
  departmentId: string
  title: string,
  firemap: string,
  simulation: boolean,
  notify: boolean,
  rts: boolean,
  notes: string,
  streetName: string,
  locationComment: string,
  suite: string,
  CrossStreet1: string,
  city: string,
  state: string,
  lat: number,
  lon: number,
  mapPages: string,
  tacticalChannel: string,
  commandChannel: string,
  sequences: Sequence[],
  closeDelay: number,
  CallerNumber: string,
}

export interface CADCannedSimulation {
  active: boolean,
  _id?: string,
  uuid?: string,
  AgencyID: string,
  IncidentNumber: string,
  departmentId: string,
  modifiedDate: number,
  friendlyId: string,
  title: string,
  notes: string,
  simulation: boolean,
  notify: boolean,
  rts: boolean,
  tags: string[],
  incidentType: string,
  streetName: string,
  locationComment: string,
  suite: string,
  CrossStreet1: string,
  city: string,
  state: string,
  lat: number,
  lon: number,
  firemap: string,
  mapPages: string,
  tacticalChannel: string,
  commandChannel: string,
  closeDelay: number,
  randomPriorIncidents: boolean,
  randomStaffing: boolean,
  sortId: number,
  sendStatus: boolean,
  modified: string,
  priorIncidents: PriorIncident[],
  radioChannels: RadioChannel[],
  sequences: Sequence[],
  closeIn?: string;
  simulationId: string;
  EntryDateTime: string,
  CADSimulator: string;
  simulationSequences?: number[];
  CallerNumber: string,
}

export enum ActionChangeShareIncident {
  Stop = "stop", // Default, when not set
  Restore = "restore",
}

export enum OrientationNames {
  Alpha = "A",
  Bravo = "B",
  Charlie = "C",
  Delta = "D",
  Echo = "E",
  One = "1",
  Two = "2",
  Three = "3",
  Four = "4",
  Five = "5",
  Unknown = "?",
}

export type IncidentShareCodeNotFound = {
  ok: false,
};

export type IncidentShareCodeFound = {
  ok: true,
  IncidentNumber: string,
  AgencyIncidentCallTypeDescription: string,
  joinCode: string,
  createdAt: string,
  expireAt: string,
  reasons: SharedToItemReason[],
};

export type IncidentShareCode = IncidentShareCodeFound | IncidentShareCodeNotFound;
