/* eslint-disable react/jsx-no-bind */

import logo from "../pages/img/logo.png";
import info from "../pages/img/info_notification_icon.svg";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { TabletButton } from "./tablet-button/custom-button";
import { useNavigate } from "react-router-dom";
import { getURL, urls } from "@utils/routing";

export default function PasswordResetOverview(prop: {
  mobileAccess: boolean,
  validToken: boolean
  tabletAccess: boolean,
  webAccess: boolean,
}) {
  const {
    mobileAccess,
    validToken,
    tabletAccess,
    webAccess,
  } = prop;
  const determineGrid = (mobileAccess && tabletAccess && !webAccess || mobileAccess && webAccess && !tabletAccess) ? 12 : 5;
  const navigate = useNavigate();

  function handleRequestNewPassword() {
    navigate(getURL(urls.recoveryPassword));
  }

  return (
    <div
      className="email-message-content"
      style={{
        paddingBottom: 25,
      }}
    >
      {validToken ? (
        <div>
          <div style={{ paddingTop: 30 }}>
            <img
              src={logo}
              alt=""
              className="after-send-email-logo"
            />
          </div>
          <Box style={{
            backgroundColor: "none",
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 25,
            maxWidth: 880,
            paddingBottom: 25,
            paddingTop: 25,
          }}
          >
            <div style={{ paddingTop: 30 }}>
              <img src={info} className="info-email-text" alt="" />
            </div>
            <p className="send-email-text-change-password">
              Password Updated Successfully
            </p>
            <p className="send-email-text-change-password-subtext">
              Please use the following to access Tablet Command from your device:
            </p>
            <Grid
              container
              spacing={1}
              justifyContent="center"
              style={{
                minHeight: "25vh",
                paddingLeft: "5%",
                paddingRight: "5%",
              }}
            >
              {mobileAccess ? (
                <Grid item sm={12} xs={12} md={determineGrid} lg={determineGrid} className="access-method-grid">
                  <a href="https://apps.apple.com/us/app/tc-mobile-tablet-command/id1161577043" target="_blank" rel="noreferrer" className="cardLinkRef">
                    <Card className="access-method-card">
                      <CardContent>
                        <Typography style={{ fontSize: 14 }} gutterBottom>
                          TC Mobile - iPhone
                        </Typography>
                        <img src={`${window.env.PUBLIC_URL.endsWith("/") ? window.env.PUBLIC_URL : `${window.env.PUBLIC_URL}/`}img/apple_appstore.svg`} style={{ paddingTop: 10, height: 40 }} alt="TCMobileIphone" title="TCMobileIphone" />
                      </CardContent>
                    </Card>
                  </a>
                </Grid>
              )
                : null}
              {tabletAccess ? (
                <Grid item sm={12} xs={12} md={determineGrid} lg={determineGrid} className="access-method-grid">
                  <a href="https://apps.apple.com/us/app/tablet-command/id584903857" target="_blank" rel="noreferrer" className="cardLinkRef">
                    <Card className="access-method-card">
                      <CardContent>
                        <Typography style={{ fontSize: 14 }} gutterBottom>
                          Tablet Command - iPad
                        </Typography>
                        <img src={`${window.env.PUBLIC_URL.endsWith("/") ? window.env.PUBLIC_URL : `${window.env.PUBLIC_URL}/`}img/apple_appstore.svg`} style={{ paddingTop: 10, height: 40 }} alt="TabletCommandIpad" title="TabletCommandIpad" />
                      </CardContent>
                    </Card>
                  </a>
                </Grid>
              )
                : null}
              {mobileAccess ? (
                <Grid item sm={12} xs={12} md={determineGrid} lg={determineGrid} className="access-method-grid">
                  <a href="https://play.google.com/store/apps/details?id=com.tabletcommand.tcmobile&amp;hl=en_US&amp;gl=US" target="_blank" rel="noreferrer" className="cardLinkRef">
                    <Card className="access-method-card">
                      <CardContent>
                        <Typography style={{ fontSize: 14 }} gutterBottom>
                          TC Mobile - Android
                        </Typography>
                        <img src={`${window.env.PUBLIC_URL.endsWith("/") ? window.env.PUBLIC_URL : `${window.env.PUBLIC_URL}/`}img/google-play-badge.png`} style={{ height: 60 }} alt="TCMobileAndroid" title="TCMobileAndroid" />
                      </CardContent>
                    </Card>
                  </a>
                </Grid>
              )
                : null}
              {webAccess ? (
                <Grid item sm={12} xs={12} md={determineGrid} lg={determineGrid} className="access-method-grid">
                  <a href="https://portal.tabletcommand.com/login" target="_blank" rel="noreferrer" className="cardLinkRef">
                    <Card className="access-method-card">
                      <CardContent>
                        <Typography style={{ fontSize: 14 }} gutterBottom>
                          TC Web Portal
                        </Typography>
                        <img src={`${window.env.PUBLIC_URL.endsWith("/") ? window.env.PUBLIC_URL : `${window.env.PUBLIC_URL}/`}img/tablet_command_logo.png`} style={{ paddingTop: 10, height: 45 }} alt="TCWebPortal" title="TCWebPortal" />
                      </CardContent>
                    </Card>
                  </a>
                </Grid>
              )
                : null}
            </Grid>
          </Box>
        </div>
      ) : (
        <div>
          <div style={{ paddingTop: 30 }}>
            <img
              src={logo}
              alt="Tablet Command Logo"
              title="Tablet Command Logo"
              className="after-send-email-logo"
            />
          </div>
          <p className="send-email-text">
            Unfortunately the link you&apos;ve clicked has expired after 21 days or was already used to change the password.
            Please request another link using the reset password form.
          </p>
          <TabletButton onClick={handleRequestNewPassword}>Request new password</TabletButton>
        </div>
      )}
    </div>
  );
}
