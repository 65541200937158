import { Agency } from "./agencies";
import { Coordinate } from "./cad";
import { Color } from "./color";

/* eslint-disable camelcase */
export interface Incident {
  enabled: boolean;
  staleMinutes: number;
  heartbeatMinutes: number;
}

export interface Location {
  enabled: boolean;
  staleMinutes: number;
  heartbeatMinutes: number;
}

export interface Status {
  enabled: boolean;
  staleMinutes: number;
  heartbeatMinutes: number;
  ackUnitsCount: number;
}

export interface Connectivity {
  incident: Incident;
  location: Location;
  status: Status;
}

export interface AddressDetail {
  address: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
}

export interface Contact {
  email: string;
  name: string;
  department: string;
  phone: string;
}

export interface Encrypted {
  iv: string;
  encryptedData: string;
}

export interface FireMapperAuth {
  username: string;
  encrypted: Encrypted;
}

export interface SafetyPriorityKeyword {
  keywords: string[];
  priority: number;
  hexColor: string;
}

export interface ShareIncidentType {
  enabled: boolean;
  rules: ShareIncidentRuleType[];
}

export interface MapLayer {
  itemId: string;
  layerId: string;
  layerType: string;
  opacity: number;
  title: string;
  url: string;
  visibility: boolean;
  access: string;
  owner: string;
}

export interface BaseMap {
  title: string;
  baseMapLayers: MapLayer[];
}

export interface EsriMap {
  access: string;
  baseMap: BaseMap;
  id: string;
  mapLayers: MapLayer[];
  modified: number
  owner: string;
  spatialReference?: unknown
  tags: string[];
  title: string;
  type: string;
  url?: unknown;
}

export interface IncidentType {
  allowPartialMatch: boolean;
  callType: string[];
  callTypeDescription: string[];
  name: string;
  value: string;
}

export interface Licensing {
  fireMapperPro: number;
  tcMobile: number;
  tcPro1Way: number;
  tcPro2Way: number;
  tcWeb: number;
  sendToCAD: number;
  tcStreams: number;
}

export interface LicensingRecord {
  licenseType: string;
  current: number;
  allocated: number;
  difference: number;
  className: string;
  deptKey: string;
}

export interface LicensingListRecord {
  id: string;
  name: string;
  recordType: string;
  tcProAlloc: number;
  tcStatusAlloc: number;
  tcMobileAlloc: number;
  tcWebAlloc: number;
  tcIncidentManagerAlloc: number;
  fireMapperProAlloc: number;
  locationToCADAlloc: number;
  tcProStat: number;
  tcStatusStat: number;
  tcMobileStat: number;
  tcWebStat: number;
  tcIncidentManagerStat: number;
  fireMapperProStat: number;
  locationToCADStat: number;
  tcProDiff: number;
  tcStatusDiff: number;
  tcMobileDiff: number;
  tcWebDiff: number;
  tcIncidentManagerDiff: number;
  fireMapperProDiff: number;
  locationToCADDiff: number;
  tcStreamsAlloc: number,
  tcStreamsStat: number,
  users: number;
  accountAdmins: number;
  agencyAdmins: number;
  fireMapperEnabled: boolean;
  cad2WayEnabled: boolean;
  locationToCADEnabled: boolean;
  personnelIntegration: boolean;
  parentId: string;
}

export type FireMapperLayer = {
  name: string; // eg. FireMapper - Symbols
  pathname: string; // eg /api/rest/services/features/FeatureServer/0 (including 0)
}

export type FireMapperOutline = {
  uuid: string;
  layer: FireMapperLayer[];
};

export type FireMapperHostDefault = "";
export type FireMapperHostStaging = "staging";
export type FireMapperHostUS = "us";
export type FireMapperHost = FireMapperHostDefault | FireMapperHostStaging | FireMapperHostUS;
export type FireMapperSettings = {
  enabled: boolean;
  layerRefreshInterval: number;
  proLicenseCount: number;
  host: FireMapperHost;
  layer: FireMapperLayer[];
  staticLayer: FireMapperOutline[]
}

export type ManagedIncidentModePriorityItem = {
  title: string;
  color: Color;
  position: number;
};

export type ManagedIncidentMode = {
  label: string;
  items: ManagedIncidentModePriorityItem[];
};

export type ManagedIncidentPriority = {
  label: string;
  items: ManagedIncidentModePriorityItem[];
};

export type AccountCallerLocationResolver = {
  type: string;
  opts?: Record<string, string | number>;
};

export type AccountCallerLocation = {
  enabled: boolean;
  resolvers: AccountCallerLocationResolver[];
};

export interface Department {
  _id: string;
  accountType: string
  ackDelimiter: string;
  active: boolean;
  activeUserCount: number;
  addressDetails: AddressDetail;
  addUserInstructions: string;
  agencyIds: Agency[];
  apikey?: string;
  arcGISMapsEnabled: boolean;
  audioConfiguration?: AudioConfig[];
  authSource?: string[]; // Optional
  beaconEnabled: boolean;
  beansAIEnabled: boolean;
  cadAllowedIPAddresses: string[];
  cadAllowIgnoreAfterDays?: number;
  cadBidirectionalEnabled: boolean;
  cadEmailUsername: string;
  cadGetLocationEnabled: boolean;
  cadIncidentEventType: string[];
  cadIncidentHistoryType: number[];
  cadMonitorEnabled: boolean;
  cadMonitorMinutes: number;
  cadOneWayVehiclesEnabled: boolean;
  callerLocation: AccountCallerLocation;
  callTypeBlock: string[];
  connectivity: Connectivity;
  contact: Contact;
  customButtons?: CustomButton[];
  customWebName: string;
  customWebUrl: string;
  defaultMapPosition: Coordinate;
  department_contact: string;
  department: string;
  esriGeoJSONFilename: string;
  esriMaps: EsriMap[];
  externalNotificationsEnabled: boolean;
  fdid: string;
  fireMapPDFReader: string;
  fireMapper: FireMapperSettings;
  fireMapperAuth: FireMapperAuth;
  firstArriving: { token: string };
  firstArrivingEnabled: boolean;
  heartbeatEnabled: boolean;
  heartbeatMinutes: number;
  id: string;
  incidentTypes: IncidentType[];
  incidentVehicleStatus: { enabled: boolean };
  intterra: Intterra;
  isMandatory: boolean;
  licensing: Licensing;
  locationStaleMinutes: number;
  logOffEnabled?: boolean
  managedIncidentMode: ManagedIncidentMode;
  managedIncidentPriority: ManagedIncidentPriority;
  minPasswordLength: number;
  modified_date: string;
  modified_unix_date: number;
  mowsEnabled: boolean;
  notificationEmails: string[];
  orientationMarkerColor: Color;
  pager_number: string;
  partialApiKey?: string;
  personnelStaffingEnabled: boolean;
  pushEnabled: boolean;
  reportNumberEnabled: boolean;
  restrictedComments: RestrictedComments;
  rosteringEnabled: boolean;
  rosteringShiftDefault: number;
  rtsAuthKey: string;
  rtsChannelPrefix: string;
  rtsEnabled: boolean;
  safetyPriorityKeywords: SafetyPriorityKeyword[];
  selfAssignmentEnabled: boolean;
  shareAVL?: {
    fadeZoomLevel: number;
    enabled: boolean;
  }
  shareIncident?: ShareIncidentType
  shareLocationPhones: boolean;
  shareLocationTablets: boolean;
  signupDomains: string[];
  signupKey: string;
  simpleSense: { token: string };
  simpleSenseEnabled: boolean;
  speedReportingEnabled: boolean;
  timeZone: string;
  userContributionEnabled: boolean;
  userId: string;
  uuid: string;
  vehicleRadioNameIsStable: boolean;
  vehicleSwapEnabled: boolean;
  webDisclaimer: WebDisclaimer;
  zonehaven: Zonehaven;
  reportOdometer?: string,
  industry: AccountIndustry,
}

export type ShareIncidentRuleType = ShareIncidentRuleTypeAssignedUnit | ShareIncidentRuleTypeIncidentType;

export type ShareIncidentRuleTypeAssignedUnit = {
  ruleType: "assignedUnit",
  departmentId: string,
  departmentName: string,
  configuration: {
    radioNames: string[],
  }
}

export type ShareIncidentRuleTypeIncidentType = {
  ruleType: "incidentType",
  departmentId: string,
  departmentName: string,
  configuration: {
    callTypeDescription: string[],
  }
}

// TODO: check why the above interface doesn't have ShareAVL
export interface DepartmentWithAvl extends Department { shareAVL: ShareAVL }

export interface ShareAVL {
  enabled: boolean,
  fadeZoomLevel: number,
  opAreaCode: string,
  opAreaName: string,
}

export interface DepartmentInvite {
  department: Department
  ok: boolean
  user: UserDepartment
}

export interface WebDisclaimer {
  message: string;
  enabled: boolean;
}

export interface Zonehaven {
  enabled: boolean;
  layerUrl: string;
  fadeZoomLevel: number;
  visible: boolean;
}

export interface RestrictedComments {
  enabled: boolean;
  callTypesAllowed: string[];
  statusesAllowed: string[];
  restrictedFields: string[];
  restrictedMessage: string;
}

export interface CustomButton {
  name: string;
  url: string;

  order: number;
  defaultExternal: boolean;
  allowExternal: boolean;
  allowFloating: boolean;
  color?: Color;
}

export interface AudioConfig {
  group: string;
  streams?: AudioConfigStream[];
  order: number;
}

export interface AudioConfigStream {
  channel: string;
  description: string;
  url: string;
  order: number;
}

export type IAccountDetails = Omit<Department & {
  shareAVL: {
    enabled: boolean,
    fadeZoomLevel: number,
    opAreaCode: string,
    opAreaName: string,
  }
  intterra: Intterra,
  accountType?: string;
  timeZone?: string;
  logOffEnabled?: boolean;
  callerLocation: AccountCallerLocation;
}, "customButtons">

export interface ArcGISAuth {
  username?: string;
}

export interface PubNubAuth {
  expireAt: string;
  runAt: string;
  token: string;
}

export interface UserDepartment {
  _id: string;
  active: boolean;
  admin: boolean;
  agencyId?: Agency;
  arcGISAuth?: ArcGISAuth;
  auth?: string[];
  cadBidirectional: boolean;
  cadSimulatorAccess: boolean;
  cadStatus?: boolean
  canAddRemoveVehicle: boolean;
  canCreateShareIncidentCode: boolean;
  confirmPassword?: string
  count?: number
  department?: string
  departmentData?: Department;
  departmentId?: string;
  email: string;
  fireMapperProEnabled: boolean,
  id: string;
  isIncidentManager: boolean;
  isPro: boolean;
  locationToCAD: boolean;
  logOffEnabled: boolean;
  managedAgencies: string[];
  mapHidden: boolean;
  mapHiddenNoShare?: boolean;
  mapId: string;
  mobileAccess: boolean;
  modified_date: string;
  name: string;
  nick: string;
  offDutyEnabled: boolean;
  offlineMapsEnabled: boolean;
  pubNubV2?: PubNubAuth;
  pubNubV3?: PubNubAuth;
  remoteLoggingEnabled: boolean;
  restrictedCommentsEnabled: boolean;
  rtsAuthKey: string;
  sessionCountiPad: number;
  sessionCountiPhone: number;
  shareLocationPhone: boolean;
  shareLocationTablet: boolean;
  stealthStatus?: string;
  superuser: boolean;
  syncLoggingExpireDate?: string;
  token: string;
  tokenExpireAt?: string;
  userContributionEnabled: boolean;
  uuid: string;
  vehicle?: UserVehicle;
  webAccess: boolean;
  when: string;
}

export interface AddUser {
  active: boolean;
  admin: boolean;
  agencyId?: Agency;
  auth: string[];
  confirmPassword: string;
  // For superuser, department requires have _id and signupDomains
  // agencyIDs, department and shareLocationPhones are leaked in the UI
  department?: Pick<Department, "_id" | "signupDomains" | "logOffEnabled" | "agencyIds" | "department" | "shareLocationPhones" | "fireMapper">;
  email: string;
  fireMapperProEnabled: boolean;
  isPro: boolean;
  isIncidentManager: boolean;
  logOffEnabled: boolean;
  managedAgencies: Agency[];
  mapHidden: boolean;
  mapId: string;
  mobileAccess: boolean;
  modified_date: Date;
  name: string;
  nick: string;
  offDutyEnabled: boolean;
  offlineMapsEnabled: boolean;
  password: string;
  remoteLoggingEnabled: boolean;
  shareLocationPhone: boolean;
  shareLocationTablet: boolean;
  superuser: boolean;
  userContributionEnabled: boolean;
  webAccess: boolean;
  when: Date;
  cadSimulatorAccess: boolean;
}

export interface UserVehicle {
  radioName: string
  vehicleId: string
}

export interface FilterSession {
  android: boolean,
  androidBeta: boolean,
  browser: boolean,
  iPad: boolean,
  iPadBeta: boolean,
  iPhone: boolean,
  iPhoneBeta: boolean,
}

export interface SelectSession {
  name: string
  value: string
}

export interface NewUser {
  active: string,
  agencyCode: string,
  agencyName: string,
  cadStatus: string,
  created: string,
  email: string,
  modified: string,
  name: string,
  proUser: string,
  incidentManager: string,
  username: string,
}

export interface Vehicle {
  radioName: string
  vehicleId: string
}

export interface SwapVehicle {
  email: string
  hasEmail: boolean
  hasStatus: boolean
  inUse: boolean
  radioName: string
  status: string
  statusCode: string
  vehicleId: string
  locationToCAD: boolean
}

export enum SwapVehicleAction {
  Select = "select",
  Remove = "remove",
}

export type SwapVehicleSelect = {
  action: SwapVehicleAction.Select,
  radioName: string,
  vehicleId: string,
}

export type SwapVehicleRemove = {
  action: SwapVehicleAction.Remove,
}

export type SwapVehicleBody = (SwapVehicleSelect | SwapVehicleRemove) & {
  departmentId: string,
  userId: string,
};

export interface VerifiedUser {
  locked: boolean
  ok: boolean
}

export interface Account {
  accountName: string,
  accountType: string,
  FDID: string,
  address: string,
  city: string,
  state: string,
  zipCode: string,
  country: string,
  timeZone: string,
  accountContact: string,
  contactPhone: string,
  contactEmail: string,
  contactDepartment: string,
  cadUsername: string,
  name: string,
  email: string,
  password: string,
  confirmPassword: string
  latitude: string,
  longitude: string,
  industry: AccountIndustry,
}

export interface SignUp {
  email: string
  name: string
  offDutyEnabled: boolean
  agencyId?: string
}

export interface PasswordReset {
  email: string
  password: string
  token: string
}

export interface ChangeUserPassword {
  email: string
  password: string
}

export interface SearchUserItem {
  email: string,
  name: string,
  nick: string,
  vehicle?: Vehicle,
  mapId: string,
  active: boolean,
  _id: string,
  agencyCode: string,
  agencyName: string,
  department: string,
  departmentId: string,
}

export interface SearchUserResult {
  items?: SearchUserItem[],
  complete?: boolean,
}

export interface DepartmentTableDefinition {
  _id: string,
  id: string,
  activeUserCount: number,
  accountName: string,
  cadBidirectionalEnabled: boolean,
  personnelStaffingEnabled: boolean,
  cadGetLocationEnabled: boolean,
  fireMapperEnabled: boolean,
  shareAVL: ShareAVL,
  accountType: string,
  partialApiKey?: string,
  city: string,
  state: string,
  agencyNames: string[],
}

export enum AccountsTableFiltersEnum {
  cadBidirectionalEnabled = "cadBidirectionalEnabled",
  cadGetLocationEnabled = "cadGetLocationEnabled",
  personnelStaffingEnabled = "personnelStaffingEnabled",
  fireMapperEnabled = "fireMapperEnabled",
  shareAVL = "shareAVL",
  accountType = "accountType"
}

export enum AccountsDepartmentTypesEnum {
  Production = "production",
  Development = "development",
  Training = "training",
}

export type AccountsCustomToggleFiltersValuesType = {
  cadBidirectionalEnabled: number,
  cadGetLocationEnabled: number,
  personnelStaffingEnabled: number,
  fireMapperEnabled: number,
  shareAVL: number,
  accountType: number,
};

export type AccountsTableFeaturesType = {
  cadBidirectionalEnabled?: boolean,
  cadGetLocationEnabled?: boolean,
  personnelStaffingEnabled?: boolean,
  fireMapperEnabled?: boolean,
  shareAVL?: ShareAVL,
  isProduction?: boolean,
  isDevelopment?: boolean,
  isTraining?: boolean,
}

export enum IncidentUnitsFilters {
  noUnits = "no_units",
}

export interface IntterraConnectionField {
  key: string,
  value: string,
  required: boolean,
  enabled: boolean,
  transformationRequired: boolean
}

export interface IntterraConnection {
  description: string,
  active: boolean,
  agency: string,
  authKey: string,
  authKeySecret: string,
  callTypes: string[],
  fields: IntterraConnectionField[]
}

export interface Intterra {
  enabled: boolean,
  connections: IntterraConnection[]
}

export enum DepartmentOdometerValues {
  disabled = "disabled",
  status = "status",
  comment = "comment",
  statusComment = "status-comment",
}

export enum AccountIndustry {
  Fire = "fire",
  Law = "law",
  University = "university",
  Utility = "utility",
}
