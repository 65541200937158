import moment from "moment";
import { useEffect, useState } from "react";
import {
  ArrowForwardIos,
} from "@mui/icons-material";
import { useEffectAsync } from "@hooks/utils";

import spinner from "../../pages/img/spinner.svg";

import { CADVehicleOption, CADVehicleStatusHistory, CADVehicleStatusHistoryOptionKeys } from "@models/cad-vehicles-status";
import { getVehicleStatusInfo } from "@services/cad-vehicles-status";
import classes from "../../pages/admin/cad-status/cad-status.module.css";
import CADVehicleStatusRequestedByIcon from "./CADVehicleStatusRequestedByIcon";
import { getLocaleDistanceFromMeters } from "@utils/utilsFunctions";

export default function CADVehicleStatusHistoryItems(prop: {
  vehicleId: string,
  setRefresh: (state: boolean) => void,
}): JSX.Element {
  const { vehicleId, setRefresh } = prop;
  const [items, setItems] = useState<CADVehicleStatusHistory[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setRefresh(false);
    return () => setRefresh(true);
  }, []);

  useEffectAsync(async () => {
    setIsLoading(true);
    const data = await getVehicleStatusInfo(vehicleId);
    setItems(data);
    setIsLoading(false);
  }, []);

  const getOptionFormatText = (option: CADVehicleOption) => {
    if (option.key === CADVehicleStatusHistoryOptionKeys.DistanceTraveled) {
      return `${option.key} - ${getLocaleDistanceFromMeters(option.value, false, true)} (${option.name})`;
    }
    return `${option.key} - ${option.value} (${option.name})`;
  };

  if (isLoading) {
    return (
      <div style={{ textAlign: "center" }}>
        <img src={spinner} alt="Loading" title="Loading" style={{ height: 60 }} />
      </div>
    );
  }

  if (vehicleId === "") {
    return <></>;
  }

  return (
    <>
      {items.map((vehicleStatusInfo: CADVehicleStatusHistory) => {
        const key = `${vehicleStatusInfo.requested}-${vehicleStatusInfo.statusCode}-${vehicleStatusInfo.requestedBy}-${vehicleStatusInfo.radioName}`;
        const requestedTime = `[${moment(vehicleStatusInfo.requested).format()}]`;
        let locationFromJSX: JSX.Element | null = null;
        if (vehicleStatusInfo.locationCurrent && vehicleStatusInfo.locationCurrent !== "") {
          locationFromJSX = (
            <span>{vehicleStatusInfo.locationCurrent}</span>
          );
        }

        let locationToJSX: JSX.Element | null = null;
        if (vehicleStatusInfo.locationDestination && vehicleStatusInfo.locationDestination !== "") {
          locationToJSX = (
            <span>{vehicleStatusInfo.locationDestination}</span>
          );
        }

        let locationMiddleJSX: JSX.Element | null = null;
        if (locationFromJSX !== null || locationToJSX !== null) {
          locationMiddleJSX = <ArrowForwardIos titleAccess="current going to" />;
        }

        return (
          <div key={key} className={`${classes.expand_content_wrapper}`}>
            <div className={`${classes.expand_content}`}>
              <p className={`${classes.expand_date_time}`}>{requestedTime}</p>
              <div className={`${classes.pin_icon}`}>
                <CADVehicleStatusRequestedByIcon
                  requestedBy={vehicleStatusInfo.requestedBy}
                  style={{ paddingBottom: 3 }}
                />
              </div>
              <p className={`${classes.vehicle_info}`}>
                {vehicleStatusInfo.radioName}
                {" "}
                -
                {" "}
                {vehicleStatusInfo.statusCode}
                {" "}
                (
                {vehicleStatusInfo.status}
                )
                {" "}
                {vehicleStatusInfo.incidentNumber}
                {"  "}
                {locationFromJSX}
                {locationMiddleJSX}
                {locationToJSX}
              </p>
            </div>
            {vehicleStatusInfo.options.length
              ? (
                <div className={classes.options}>
                  {vehicleStatusInfo.options.map((option) => (
                    <div key={option.key + option.value} className={`${classes.vehicle_info}`}>
                      {getOptionFormatText(option)}
                    </div>
                  ))}
                </div>
              ) : null}
          </div>
        );
      })}
    </>
  );
}
