import {
  Svg, Path, StyleSheet, Text, View,
} from "@react-pdf/renderer";
import moment from "moment";
import {
  CADComment, Events, EventOptionsFireMapper, EventOptionsBeansAI,
  CADIncident,
} from "@models/cad";
import { SafetyPriorityKeyword } from "@models/department";
import { History } from "@models/tc-incident";
import { getHistoryType } from "@utils/tc-incidents-helpers";
import { getWordPriorityColor, timeElapsed } from "@utils/utilsFunctions";

const styles = StyleSheet.create({
  textBig: {
    fontSize: 16,
  },
  textNormal: {
    fontSize: 14,
  },
});

const formatDate = (date: number | undefined) => moment((date ?? 0) * 1000).format(
  "HH:mm:ss, MMMM DD YYYY",
);

function publishedAfter(item: Events): string {
  if (item.serverTime >= item.userTime) {
    return "+" + timeElapsed(item.serverTime - item.userTime);
  }
  return "";
}

export function PDFRenderNotificationIcon() {
  return (
    <Svg
      style={{ width: 7, height: 14, marginRight: 5 }}
      viewBox="0 0 7 14"
    >
      <Path fill="#7a7d7d" d="M3061,446v7.7h2.1V460l4.9-8.4h-2.8l2.8-5.6Z" transform="translate(-3061 -446)" />
    </Svg>
  );
}

export function PDFRenderHelpCenterIcon() {
  return (
    <Svg style={{ width: 15, height: 15, marginRight: 5 }} viewBox="0 0 24 24">
      <Path
        fill="white"
        // eslint-disable-next-line max-len
        d="M13.25 16.74c0 .69-.53 1.26-1.25 1.26-.7 0-1.26-.56-1.26-1.26 0-.71.56-1.25 1.26-1.25.71 0 1.25.55 1.25 1.25M11.99 6c-1.77 0-2.98 1.15-3.43 2.49l1.64.69c.22-.67.74-1.48 1.8-1.48 1.62 0 1.94 1.52 1.37 2.33-.54.77-1.47 1.29-1.96 2.16-.39.69-.31 1.49-.31 1.98h1.82c0-.93.07-1.12.22-1.41.39-.72 1.11-1.06 1.87-2.17.68-1 .42-2.36-.02-3.08-.51-.84-1.52-1.51-3-1.51M19 5H5v14h14zm0-2c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2z"
      />
    </Svg>
  );
}

export function PDFRenderForwardArrowIcon() {
  return (
    <Svg style={{ width: 15, height: 15 }} viewBox="0 0 25 25">
      <Path
        fill="black"
        // eslint-disable-next-line max-len
        d="m12 4-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
      />
    </Svg>
  );
}

export function PDFRenderPersonIcon() {
  return (
    <Svg style={{ width: 15, height: 15 }} viewBox="0 0 25 25">
      <Path
        fill="black"
        // eslint-disable-next-line max-len
        d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
      />
    </Svg>
  );
}

export function PDFRenderFastForwardIcon() {
  return (
    <Svg style={{ width: 15, height: 15 }} viewBox="0 0 25 25">
      <Path
        fill="black"
        // eslint-disable-next-line max-len
        d="m4 18 8.5-6L4 6v12zm9-12v12l8.5-6L13 6z"
      />
    </Svg>
  );
}

export function PDFRenderInformationField({ name, value }: { name: string, value?: string | number }) {
  return (
    value ? (
      <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <View style={{ minWidth: 150 }}>
          <Text style={styles.textNormal}>{name}</Text>
        </View>
        {" "}
        {typeof value === "number"
          ? <Text style={styles.textBig}>{formatDate(value)}</Text>

          : <Text style={styles.textBig}>{value}</Text>}
      </View>
    ) : null
  );
}

export function PDFRenderTableDateField({ value }: { value: string }) {
  return (
    <View style={{ fontSize: 10, display: "flex", flexDirection: "column" }}>
      <Text>{moment(value).format("HH:mm:ss")}</Text>
      <Text>{moment(value).format("MM/DD/YY")}</Text>
    </View>
  );
}

export function PDFRenderTableHeader({ name, value }: { name: string, value?: number }) {
  return (
    <View style={{
      display: "flex", justifyContent: "space-between", flexDirection: "row", width: "100%", marginBottom: 5, backgroundColor: "lightgrey", padding: 4,
    }}
    >
      <Text style={styles.textNormal}>{name}</Text>
      {value
        && (
        <Text style={{
          padding: 4, backgroundColor: "grey", color: "white", borderRadius: 3, fontSize: 10,
        }}
        >
          {value}
        </Text>
        )}
    </View>
  );
}
export function PDFRenderCommentLines(text: string) {
  return text?.split(/\n/)?.map((line, index) => {
    if (index === 0) {
      return <Text key={line}>{line}</Text>;
    }
    return <Text key={line}>{line}</Text>;
  });
}

export function PDFCommentOptions(comment: CADComment | undefined) {
  if (!comment || !comment?.CommentOpts || !comment?.CommentOpts?.type || !comment?.CommentOpts?.item) {
    return null;
  }

  if (comment?.CommentOpts?.type === "ack" && comment?.CommentOpts?.item !== "") {
    const ackItem = comment?.CommentOpts?.item;
    return (
      <View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: "red",
            fontSize: 14,
            color: "white",
            marginLeft: 5,
            padding: "2px 5px",
          }}
        >
          <PDFRenderHelpCenterIcon />
          <Text>
            {ackItem}
          </Text>
        </View>
      </View>
    );
  }

  return null;
}

export function PDFRenderCommentRow({ item, priorityKeywords }: { item: History, priorityKeywords: SafetyPriorityKeyword[] }) {
  const commentColor = getWordPriorityColor(priorityKeywords, item.message);
  return (
    <View
      wrap={false}
      style={{
        backgroundColor: commentColor.background,
        color: commentColor.text,
        padding: 2,
        marginBottom: 5,
        display: "flex",
        flexDirection: "column",
        borderBottom: "1px solid lightgrey",
        fontSize: 14,
      }}
    >
      <View>
        <View style={{ fontSize: 14, backgroundColor: getWordPriorityColor(priorityKeywords, item.message).background, color: getWordPriorityColor(priorityKeywords, item.message).text }}>
          <Text>
            {item.time
              ? `${moment(item.time * 1000).format("MM/DD/YYYY - HH:mm:ss")}` : ""}
            <Text>{` [${getHistoryType(item)}]`}</Text>
          </Text>
          <Text style={{ fontSize: 14 }}>{item.message}</Text>
        </View>
      </View>
    </View>
  );
}

export function PDFDecodeEventMessage(item: Events): JSX.Element | string {
  let type = item.type;
  let msg: JSX.Element | string = item.message + "/?";

  switch (item.type) {
    case "cadoptlayersfiremapper": // fallthrough
    case "useroptlayersfiremapper": {
      type = "Fire Mapper Layers";
      const opts = item.opts as EventOptionsFireMapper;
      const layerNames = opts.items.map((l) => l.name).sort().join(", ");
      const layersUUID = opts.items[0] ? opts.items[0].uuid : "";
      msg = (
        <View>
          <Text>
            {layerNames}
          </Text>
          <Text>
            {layersUUID}
          </Text>
        </View>
      );
      break;
    }
    case "cadoptroutesbeansai": {
      type = "Beans.AI";
      const opts = item.opts as EventOptionsBeansAI;
      msg = `${item.message} routes: ${opts.routes.length}`;
      let routesMessage = "No routes found";
      if (opts.routes.length > 0) {
        routesMessage = `${opts.routes.length} routes found`;
      }
      msg = (
        <View>
          <Text>
            {item.message}
          </Text>
          <Text>
            {routesMessage}
          </Text>
        </View>
      );
      break;
    }
    case "systemaddressdidresolve": {
      type = "Address => Lat/Long";
      msg = `${item.message} => ${item.location.latitude},${item.location.longitude}`;
      break;
    }
    case "usercomment": {
      type = "CAD Comment";
      msg = item.message;
      break;
    }
    case "usercommentnottoCad": {
      type = "User Note";
      msg = item.message;
      break;
    }
    case "userUnitPersonnelDidChange": {
      type = "Personnel Change";
      msg = item.message;
      break;
    }
    case "userdeleteevent":
      type = "Delete Event";
      msg = item.message;
      break;
    case "usercolumnsdidchange":
      type = "Columns Did Change";
      msg = item.message;
      break;
    default:
      break;
  }

  return (
    <View>
      <Text>
        {type}
      </Text>
      <Text>
        {msg}
      </Text>
    </View>
  );
}

export function PDFRenderChangesDetails({ incident }: { incident: CADIncident }) {
  return (
    <View>
      {(incident.changes ?? []).length ? (
        <View style={{ marginTop: 0, fontSize: 14 }}>
          {(incident.changes ?? [])
            .sort((a, b) => b.time - a.time)
            .map((item) => (
              <Text
                wrap={false}
                key={item.user.username + item.message + item.type + item.time}
                style={{ marginBottom: 10, borderBottom: "1px solid lightgrey" }}
              >
                <Text style={styles.textBig}>
                  {moment(item.time * 1000).format("HH:mm:ss")}
                  {" "}
                  <Text>
                    [
                    {item.user.username}
                    ]
                  </Text>
                  {" "}
                </Text>
                <Text>
                  {" "}
                  TYPE:
                  {item.type}
                </Text>
                <Text>
                  {" "}
                  Message:
                  {item.message}
                </Text>
                <Text>
                  {" "}
                  NEW:
                  {item.new}
                </Text>
                <Text>
                  {" "}
                  OLD:
                  {item.old}
                </Text>
              </Text>
            ))}
        </View>
      ) : null}
    </View>
  );
}

export function PDFRenderEventsDetails({ incident }: { incident: CADIncident }) {
  return (
    <View>
      <View style={{
        marginTop: 0, paddingTop: 15, paddingLeft: 12, fontSize: 14,
      }}
      >
        {incident.events?.map((item) => (
          <View key={item.uuid + item.modified} style={{ marginBottom: 10, borderBottom: "1px solid lightgrey" }}>
            <View style={{ display: "flex", flexDirection: "column" }}>
              <Text style={styles.textBig}>
                {moment(item.modified).format("HH:mm:ss")}
                <Text>
                  {` [${item.user.email !== "" ? item.user.email : "system"}]`}
                </Text>
                <Text>
                  {` ${publishedAfter(item)}`}
                </Text>
              </Text>
              <Text>
                {PDFDecodeEventMessage(item)}
              </Text>
              <Text>
                UUID:
                {` ${item.uuid}`}
              </Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
}
