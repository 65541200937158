import { createContext, useState } from "react";

export const LoginPopupStatusContext = createContext<[boolean | null, React.Dispatch<boolean>]>([null!, () => null!]);

export function LoginPopupStatusContextProvider(props: { children: JSX.Element }) {
  const [popupStatus, setPopupStatus] = useState(false);

  return (
    <LoginPopupStatusContext.Provider value={[popupStatus, setPopupStatus]}>
      {props.children}
    </LoginPopupStatusContext.Provider>
  );
}
