import {
  Button, FormLabel, Grid, TextField, Paper,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/Info";

import { Form, Formik, FormikErrors } from "formik";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import styles from "./signup-page.module.css";

import ContactInfo from "@components/ContactInfo";

import { useTitle } from "@hooks/useTitle";
import { useData } from "@hooks/useData";
import { useEffectAsync } from "@hooks/utils";

import { getKeys } from "@utils/utilsFunctions";

import {
  checkEmailAvailableWithSignupKey,
  userSignupWithKey,
} from "@services/account";

import {
  accountSessionSignupKey,
  verifySignupKey,
} from "@services/user";
import {
  SignUp,
} from "@models/department";

import logo from "../img/logo.png";
import { SingleSelect } from "@components/SelectComponent";
import { Agency } from "@models/agencies";

export default function SignUpForm() {
  useTitle("Sign up");
  const { key } = useParams<{ key: string }>();
  const [submit, setSubmit] = useState<boolean>(false);
  const { data: infoSignUpKey } = useData(() => verifySignupKey(key));
  const { data: session } = useData(() => accountSessionSignupKey(key));
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const location = useLocation();
  const initialValues = {
    email: "",
    name: "",
    offDutyEnabled: true,
    agencyId: undefined,
  };

  useEffectAsync(async () => {
    if (email) {
      const ok = session?.department.signupDomains?.some((domain) => email.match(`${domain}`));
      if (!ok && (session?.department?.signupDomains ?? []).length > 0) {
        setEmailError("Not a valid email domain");
      } else {
        const result = await checkEmailAvailableWithSignupKey(email, key);
        if (!result.ok) {
          setEmailError("Email not available.");
        } else {
          setEmailError("");
        }
      }
    } else {
      setEmailError("Email is required");
    }
  }, [email]);

  const keys = getKeys<SignUp>({
    email: 1,
    name: 1,
    agencyId: 1,
    offDutyEnabled: 1,
  });

  if (infoSignUpKey && !infoSignUpKey.ok) {
    return (
      <div className={`${styles.invalid_key_error_message}`}>
        Invalid signup key
      </div>
    );
  }

  if (!session || !infoSignUpKey) {
    return <div />;
  }

  const urlParams = new URLSearchParams(location.search);
  const params = Object.fromEntries(urlParams);
  const agency = infoSignUpKey?.department.agencies.find((el) => el.code === params.code);

  const onSubmit = (obj: SignUp) => {
    if (agency && agency !== undefined) {
      setSubmit(true);
      userSignupWithKey({
        ...obj,
        agencyId: agency._id,
      }, key);
    } else {
      setSubmit(true);
      userSignupWithKey(obj, key);
    }
  };

  return (
    <div className="login-page container login-background">
      <Grid container>
        <Grid item xs={12} lg={7} className="left-side">
          <img src={logo} alt="" className="logo" />
        </Grid>
        <Grid item xs={12} lg={4} className="right-side">
          <Paper className="paperBody">
            <Formik<SignUp>
              initialValues={initialValues}
              onSubmit={onSubmit}
              validate={async (values) => {
                const errors: FormikErrors<SignUp> = {};
                if (!values.name) {
                  errors.name = "Required";
                }
                return errors;
              }}
            >
              {({
                values, errors, handleChange, setValues, dirty, isValid, setFieldValue,
              }) => (
                !submit
                  ? (
                    <Form>
                      <div className="head">
                        Join
                        {" "}
                        {infoSignUpKey?.department.department}
                      </div>
                      <div className="formLogin">
                        <FormLabel>Name</FormLabel>
                        <TextField
                          variant="outlined"
                          id={keys.name}
                          required
                          onChange={handleChange}
                        />
                        {!!errors.name && dirty && <div className={styles.errorContainer}>{errors.name}</div>}
                        <FormLabel>Email</FormLabel>
                        <TextField
                          variant="outlined"
                          id={keys.email}
                          type="email"
                          autoComplete="none"
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            const signupEmail = e.target.value;
                            setEmail(signupEmail);
                            setValues({
                              ...values,
                              email: signupEmail,
                            });
                          }}
                          required
                        />
                        {!!emailError && values.email.length > 0 && <div className={styles.errorContainer}>{emailError}</div>}
                        <FormLabel>Agency</FormLabel>
                        <SingleSelect
                          className={`${styles.textField} ${styles.closeIn}`}
                          onChange={(e) => {
                            setFieldValue(keys.agencyId, e);
                          }}
                          options={infoSignUpKey?.department.agencies!}
                          getOptionSelected={(e, v) => e.uuid === v.uuid}
                          renderOption={(el) => el?.name}
                          autocompleteInputPropsStyles={{
                            paddingRight: 0,
                          }}
                          inputPropsStyles={{
                            paddingTop: 10,
                            paddingBottom: 10,
                            borderRadius: 3,
                            paddingLeft: 7,
                          }}
                          value={agency && agency !== undefined ? agency._id as unknown as Agency : values.agencyId as unknown as Agency}
                        />
                      </div>

                      {session.department?.signupDomains && session.department?.signupDomains.length > 0
                        ? (
                          <div className={styles.infoContainer}>
                            <div className={styles.infoName}>
                              <InfoIcon className={styles.icon} />
                              <div style={{ fontSize: "13px" }}>
                                <span style={{ color: "#7a9a96" }}>Valid email domains: </span>
                              </div>
                            </div>
                            <div className={styles.domainsContainer}>
                              {session.department.signupDomains.map((item, index) => `${item}${session.department.signupDomains![index + 1] ? ", " : " "}`)}
                            </div>
                          </div>
                        ) : ""}

                      <div className="button">
                        <Button
                          disabled={!dirty || !isValid || !!emailError}
                          variant="contained"
                          endIcon={<CheckIcon />}
                          className="btn"
                          color="secondary"
                          type="submit"
                        >
                          Save
                        </Button>
                      </div>
                    </Form>
                  ) : (
                    <div style={{
                      textAlign: "center",
                      color: "white",
                      wordWrap: "break-word",
                      fontSize: 20,
                      paddingTop: 20,
                      paddingBottom: 25,
                    }}
                    >
                      Account created successfully. Please check your email,
                      {" "}
                      {values.email}
                      , to set your Tablet Command password.
                    </div>
                  )
              )}
            </Formik>
          </Paper>
          <ContactInfo />
        </Grid>
      </Grid>
    </div>
  );
}
