import { ReactNode, useMemo } from "react";

import {
  Paper,
} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import CloseManagedIncidentButton from "@components/close-managed-incident-button";
import IncidentShareCode from "@components/IncidentShareCode";
import {
  CADIncident,
  RadioChannel,
  ReportNumber,
} from "@models/cad";
import { IncidentFieldPermissions } from "@models/static-data";
import { TCIncident } from "@models/tc-incident";

import {
  dateField,
  FieldType,
  nonEmptyStringField,
  stringDateField,
  updateChannels,
} from "@utils/utilsFunctions";

import classes from "./managed-incidents-list.module.css";
import { RefetchOptions, QueryObserverResult } from "@tanstack/react-query";

export function TCIncidentField<T>({
  fieldValue, label, type, addressURL,
}: {
  fieldValue: T | undefined,
  label: ReactNode,
  type: FieldType<T>,
  addressURL?: string,
}) {
  if (!type.validator(fieldValue)) return <></>;
  return (
    <div className={classes.incident_content}>
      <span className={classes.incident_text}>
        {`${label}: `}
      </span>
      <span>
        {!addressURL ? (
          <span className={classes.agencyID}>
            {type.format(fieldValue)}
          </span>
        ) : (
          <a
            href={addressURL}
            target="_blank"
            style={{
              textDecoration: "none",
              color: "black",
              fontWeight: "bold",
              paddingBottom: 5,
            }}
            rel="noreferrer"
          >
            {type.format(fieldValue)}
          </a>
        )}
      </span>
    </div>
  );
}

export function RadioChannelsField({
  fieldValue, label, emptyDisplay,
}: {
  fieldValue: RadioChannel[] | undefined,
  label: ReactNode,
  emptyDisplay?: JSX.Element,
}) {
  if (!!fieldValue && fieldValue.length > 0) {
    return (
      <div className={`${classes.incident_content}`}>
        {label !== "" ? (
          <span className={`${classes.text_label}`}>
            {`${label}:`}
          </span>
        ) : <></>}
        {fieldValue?.map((el) => (
          <span key={el.name + el.channel} className={`${classes.text_label2}`}>
            {`${el.name}: ${el.channel}`}
          </span>
        ))}
      </div>
    );
  }
  return emptyDisplay ?? <></>;
}

function ReportNumbersSection({
  items,
}: {
  items?: ReportNumber[],
}): JSX.Element | null {
  if (!items || !(items instanceof Array) || items.length === 0) {
    return null;
  }

  return (
    <div className={`${classes.incident_content}`}>
      <span className={classes.incident_text}>Report Number: </span>
      <div className={classes.agencyID}>
        {items.map((el) => (
          <span key={el.name + el.number} className={`${classes.text_label2}`} style={{ marginLeft: 0, display: "flex", flexDirection: "column" }}>
            {el.name}
            {": "}
            {el.number}
          </span>
        ))}
      </div>
    </div>
  );
}

export function TCFields({
  incident,
  cadIncident,
  permissions,
  reload,
}: {
  incident: TCIncident,
  cadIncident?: CADIncident,
  permissions: IncidentFieldPermissions,
  reload: (options?: RefetchOptions) => Promise<QueryObserverResult<TCIncident, Error>>
}) {
  const addressURL = `https://www.google.com/maps/search/?api=1&query=${incident?.address}`;
  const oldChannels = useMemo(() => updateChannels(incident), [incident]);
  const channelOwner = (incident.channel_owner ?? "").split("@");
  const {
    isAdmin,
    canCreateShareIncidentCode,
    shareIncidentEnabled,
  } = permissions;

  return (
    <Paper className={classes.paper_content}>
      <div className={`${classes.incident}`}>
        <div className={classes.incident_content}>
          <span className={classes.incident_text}>
            Incident Number:
          </span>
          <span className={classes.incidentNumber}>
            {incident?.api_incident_number}
          </span>
          <div className={classes.closeIncidentWrapper}>
            <CloseManagedIncidentButton
              itemId={incident?._id}
              closed={(incident?.end_unix_time ?? 0) > 0}
              canClose={isAdmin && (incident?.sharedSource?.isExternal === false || cadIncident?.sharedSource?.isExternal === false)}
              reload={reload}
            />
          </div>
        </div>
        <div className={classes.incident_content}>
          <span className={classes.incident_text}>
            Managed by:
          </span>
          <div style={{
            marginTop: 2, display: "flex", flexDirection: "row", alignItems: "center", verticalAlign: "middle",
          }}
          >
            <CheckCircleOutlineIcon style={{ color: "#8b0000", fontSize: "1.25rem" }} />
            <span style={{ color: "#8b0000", paddingLeft: 5, fontSize: 16 }}>{channelOwner[0]}</span>
          </div>
        </div>
        <ReportNumbersSection
          items={incident?.ReportNumber}
        />
        <TCIncidentField
          label="Call Type"
          type={nonEmptyStringField}
          fieldValue={cadIncident?.AgencyIncidentCallTypeDescription}
        />
        <TCIncidentField
          type={nonEmptyStringField}
          label="Incident Name: "
          fieldValue={cadIncident?.CommandName}
        />
        <TCIncidentField
          label="Common Name"
          type={nonEmptyStringField}
          fieldValue={cadIncident?.CommonPlaceName}
        />
        <TCIncidentField
          label="Location Comment"
          type={nonEmptyStringField}
          fieldValue={incident?.history?.find((el) => el.message.includes("Location Comment"))?.message.split(":")[1]}
        />
        <TCIncidentField
          label="Agency"
          type={nonEmptyStringField}
          fieldValue={cadIncident?.AgencyID}
        />
        <TCIncidentField
          label="Map Pages"
          type={nonEmptyStringField}
          fieldValue={incident.MapPages}
        />
        <TCIncidentField
          label="Fire Map"
          type={nonEmptyStringField}
          fieldValue={incident.FireMap}
        />
        <TCIncidentField
          label="Start Time"
          type={stringDateField}
          fieldValue={incident.start_time}
        />
        <TCIncidentField
          label="End Time"
          type={dateField}
          fieldValue={incident.end_unix_time}
        />
        <TCIncidentField
          label="Modified"
          type={stringDateField}
          fieldValue={incident.modified_date}
        />
        <TCIncidentField
          label="PAR"
          type={dateField}
          fieldValue={incident.par_unix_time}
        />
        <TCIncidentField
          label="Address"
          type={nonEmptyStringField}
          fieldValue={incident.address}
          addressURL={addressURL}
        />
        <TCIncidentField
          label="Cross Streets"
          type={nonEmptyStringField}
          fieldValue={incident.cross_streets}
        />
        <TCIncidentField
          label="Caller Number"
          type={nonEmptyStringField}
          fieldValue={cadIncident?.CallerNumber}
        />
        <RadioChannelsField
          label="Radio Channels"
          fieldValue={incident.radioChannels}
          emptyDisplay={(
            <RadioChannelsField
              label="Channels"
              fieldValue={oldChannels}
            />
          )}
        />
        {!incident?.extended ? <></> : (
          <div className={classes.dangerMessage}>
            <div className="warning">
              <ErrorOutlineIcon
                style={{ color: "red", marginRight: 5 }}
              />
            </div>
            <span
              className="warning"
              style={{
                color: "red",
                paddingBottom: 15,
                paddingTop: 15,
              }}
            >
              Long running incident
            </span>
          </div>
        )}
        {/* <IncidentShareCode
          incidentNumber={incident?.api_incident_number ?? ""}
          departmentId={incident?.departmentId ?? ""}
          canGenerateCode={canCreateShareIncidentCode}
          shareIncidentEnabled={shareIncidentEnabled}
        /> */}
      </div>
    </Paper>
  );
}
