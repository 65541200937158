import {
  Paper,
} from "@mui/material";

import {
  AlternateEmail,
  Phone,
} from "@mui/icons-material";

import "../pages/login/login-page.css";

export default function ContactInfo() {
  return (
    <Paper className="paperBottom">
      <div className="bottom">
        <div className="iconbottom">

          <AlternateEmail className="iconn" />

        </div>
        <p className="info">support@tabletcommand.com</p>
      </div>

      <div className="bottom2">
        <div className="iconbottom">
          <Phone className="iconn" />
        </div>
        <p className="info">877-998-2639</p>
      </div>
    </Paper>
  );
}
