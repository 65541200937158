import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  useState,
  useEffect,
  createRef,
  RefObject,
  useContext,
} from "react";
import { AudioConfig, AudioConfigStream } from "@models/department";
import MaterialTable from "material-table";
import AddRowButton from "./add-new-row-button";
import { customAddAction } from "./material-table-custom-components";
import "../pages/menu/menu.css";
import { UIStateContext } from "@contexts/ui-state-context";

export enum ManageButtonAction {
  Add = "add",
  Update = "update",
  Delete = "delete",
  Cancel = "cancel",
  NestedUpdate = "nestedUpdated",
}

async function orderStreams(streams: AudioConfigStream[], action: ManageButtonAction, prev?: AudioConfigStream, next?: AudioConfigStream): Promise<AudioConfigStream[]> {
  // This clone works only on simple objects
  // - https://www.delftstack.com/howto/typescript/typescript-cloning-an-object/
  let cleanItems: AudioConfigStream[] = streams.map((item) => ({ ...item }));

  // - remove the item from the list (for edit, delete)
  if (action === ManageButtonAction.Delete || action === ManageButtonAction.Update || action === ManageButtonAction.NestedUpdate) {
    cleanItems = streams.map((item) => ({ ...item })).filter((item) => {
      if (!prev) {
        return false;
      }

      // Remove prev item when group is the same
      const sameItem = item.channel === prev.channel;
      return !sameItem;
    });
  }
  // - update positions, after the item was removed, starting with 1...length (last item)
  let orderIndex: number = 1;
  const reordered: AudioConfigStream[] = [];
  cleanItems.sort((a, b) => a.order - b.order).forEach((item) => {
    const withOrder = {
      ...item,
      order: orderIndex,
    };
    orderIndex = orderIndex + 1;
    reordered.push(withOrder);
  });

  // No next item (e.g. .Delete / .Update), we're done.
  if (!next) {
    return reordered;
  }

  const withNextItem: AudioConfigStream[] = [next, ...reordered];

  // - insert item at the correct position
  reordered.sort((a, b) => a.order - b.order).forEach((item) => {
    // - shift positions for the items after the inserted item s(+1)
    // Identity check is same name and same URL
    const sameItem = item.channel === next.channel && item.url === next.url;
    if (item.order >= next.order && !sameItem) {
      item.order = item.order + 1;
    }
  });

  const resultSorted = withNextItem.sort((a, b) => a.order - b.order);
  return resultSorted;
}

export default function AccountManageAudioConfig(prop: {
    isOpen: boolean,
    isUpdating: boolean,
    item?: AudioConfig,
    maxPosition: number,
    performAction: (action: ManageButtonAction, previous?: AudioConfig, next?: AudioConfig, shouldClose?: boolean) => Promise<void>,
  }) {
  const {
    isOpen,
    isUpdating,
    item,
    maxPosition,
    performAction,
  } = prop;

  const [manageAction, setManageAction] = useState(ManageButtonAction.Add);
  const [boxTitle, setBoxTitle] = useState("Add Audio Config Button");
  const [itemGroup, setItemGroupName] = useState("");
  const [itemStreams, setItemStreams] = useState<AudioConfigStream[]>([]);
  const [itemPosition, setItemPosition] = useState(maxPosition);
  const [streamPosition, setStreamPosition] = useState(1);
  const [state] = useContext(UIStateContext);

  useEffect(() => {
    setManageAction(item !== undefined ? ManageButtonAction.Update : ManageButtonAction.Add);
    setBoxTitle(item !== undefined ? "Edit Group Stream" : "Add Group Stream");
    setItemPosition(item?.order ?? maxPosition);
    setItemGroupName(item?.group ?? "");
    if (item?.streams) {
      setItemStreams(item.streams);
      setStreamPosition(item.streams.length + 1);
    } else {
      setItemStreams([]);
      setStreamPosition(1);
    }
  }, [isOpen, item, isUpdating]);

  const availablePositions = item === undefined ? maxPosition : maxPosition - 1;
  const positions = Array.from(Array(availablePositions).keys(), (n) => n + 1);

  const handleClose = async () => {
    await performAction(ManageButtonAction.Cancel, undefined, undefined, true);
  };

  const handleSave = async () => {
    const nextItem: AudioConfig = {
      group: itemGroup,
      streams: item?.streams,
      order: itemPosition,
    };
    await performAction(manageAction, item, nextItem, true);
  };

  const handleDelete = async () => {
    await performAction(ManageButtonAction.Delete, item, undefined, true);
  };

  const handleNewStream = async (newStream: AudioConfigStream) => {
    const orderedStreams = await orderStreams(itemStreams, ManageButtonAction.Add, undefined, newStream);
    await setItemStreams(orderedStreams);
    const nextItem: AudioConfig = {
      group: itemGroup,
      streams: orderedStreams,
      order: itemPosition,
    };
    if (manageAction === "add") {
      // maintain add action if it's a brand new object
      await performAction(manageAction, item, nextItem, false);
    } else {
      await performAction(ManageButtonAction.NestedUpdate, item, nextItem, false);
    }
  };

  const handleUpdateStream = async (updatedStream: AudioConfigStream, oldStream: AudioConfigStream) => {
    const orderedStreams = await orderStreams(itemStreams, ManageButtonAction.NestedUpdate, oldStream, updatedStream);
    await setItemStreams(orderedStreams);
    const nextItem: AudioConfig = {
      group: itemGroup,
      streams: orderedStreams,
      order: itemPosition,
    };
    await performAction(ManageButtonAction.NestedUpdate, item, nextItem);
  };

  const handleDeleteStream = async (stream: AudioConfigStream) => {
    const orderedStreams = await orderStreams(itemStreams, ManageButtonAction.Delete, stream, undefined);
    await setItemStreams(orderedStreams);
    const nextItem: AudioConfig = {
      group: itemGroup,
      streams: orderedStreams,
      order: itemPosition,
    };
    await performAction(ManageButtonAction.NestedUpdate, item, nextItem);
  };

  const tableRef = createRef();
  const addActionRef: RefObject<HTMLDivElement> = createRef();

  return (
    <div>
      <Dialog
        onClose={handleClose}
        open={isOpen}
        className={`audio-config-dialog-component ${state}`}
      >
        {
            (isUpdating ? (
              <div>
                <DialogTitle id="form-dialog-title">Updating...</DialogTitle>
              </div>
            ) : (
              <div>
                <DialogTitle id="form-dialog-title">{boxTitle}</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    fullWidth
                    id="group"
                    label="Group"
                    margin="dense"
                    type="text"
                    value={itemGroup}
                    onChange={(e) => {
                      setItemGroupName(e.target.value);
                    }}
                  />
                  <div style={{ margin: 10 }} />
                  <FormLabel style={{ fontSize: 12 }}>Order</FormLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="order"
                    value={itemPosition}
                    label="Order"
                    fullWidth
                  >
                    {positions.map((item) => (
                      <MenuItem
                        key={item}
                        value={item}
                        onClick={() => setItemPosition(item)}
                      >
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                  <Grid item xs={12} md={12} style={{ paddingTop: 10, paddingBottom: 25 }}>
                    <Typography variant="h6" component="div" style={{ paddingTop: 25, paddingBottom: 25 }}>
                      Audio Streams
                    </Typography>
                    <MaterialTable
                      data={itemStreams}
                      tableRef={tableRef}
                      title={<AddRowButton addActionRef={addActionRef} actionName="Add Stream" disabled={false} />}
                      options={{
                        paging: false,
                        search: true,
                        addRowPosition: "first",
                      }}
                      columns={[
                        {
                          title: "Order",
                          field: "order",
                          type: "numeric",
                          initialEditValue: streamPosition,
                          validate: (item) => (item.order > 0 && item.order <= streamPosition ? true : `Order must be in the range: 1-${streamPosition}`),
                        },
                        {
                          title: "Channel",
                          field: "channel",
                          validate: (item) => (item.channel ? true : "Channel cannot be empty"),
                        },
                        {
                          title: "Description",
                          field: "description",
                          validate: (item) => (item.description ? true : "Description cannot be empty"),
                        },
                        {
                          title: "Url",
                          field: "url",
                          validate: (item) => (item.url ? true : "Url cannot be empty"),
                          render: (rowData) => {
                            const { url } = rowData;
                            return (
                              <a href={url}>
                                { url }
                              </a>
                            );
                          },
                        },
                      ]}
                      editable={{
                        onRowAdd: async (stream) => {
                          await handleNewStream(stream);
                        },
                        onRowUpdate: async (newData, oldData) => {
                          const originalStream = JSON.parse(JSON.stringify(oldData));
                          await handleUpdateStream(newData, originalStream);
                        },
                        onRowDelete: async (stream) => {
                          await handleDeleteStream(stream);
                        },
                      }}
                      components={{
                        Action: (props) => customAddAction(props, addActionRef),
                      }}
                    />
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <div
                    style={{
                      width: "50%",
                    }}
                    hidden={manageAction !== ManageButtonAction.Update}
                  >
                    <Button
                      onClick={handleDelete}
                      color="primary"
                      style={{
                        backgroundColor: "red",
                        color: "white",
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                  <div
                    style={{
                      width: "50%",
                      textAlign: "right",
                    }}
                  >
                    <Button onClick={handleClose} color="secondary">
                      Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                      Save
                    </Button>
                  </div>
                </DialogActions>
              </div>
            ))
          }
      </Dialog>
    </div>
  );
}
