import { getDiffInMinutes, getNameFontSize } from "@utils/utilsFunctions";

export function UnitMap(prop: {
    name: string
    time: string
}) {
    const { name, time } = prop;

    return (
        <svg style={{ display: "block" }} xmlns="http://www.w3.org/2000/svg" width="70" height="45">
            <rect x="0" y="0" width="70" height="45" rx="8" ry="8" fill="#585858" />
            <text
                x="50%" y="10px" dominantBaseline="hanging" textAnchor="middle"
                style={{
                    isolation: "isolate",
                    textAlign: "center",
                    fontSize: getNameFontSize(name.length),
                    fill: "#fff",
                    fontFamily: "Arial, sans-serif",
                    fontWeight: "lighter"
                }}
            >
                {name}
            </text>
            <circle
                cx="9"
                cy={20 + getNameFontSize(name.length)}
                r="5"
                fill={time !== "" ? (getDiffInMinutes(time) >= 0 && getDiffInMinutes(time) <= 10 ? "#009900" : getDiffInMinutes(time) > 10 && getDiffInMinutes(time) <= 15 ? "#fbe52e" : "red") : "red"}
            />
        </svg>
    );
}