import {
  CADCannedSimulation,
  CADCannedSimulationSlim,
} from "@models/cad";
import { useTitle } from "@hooks/useTitle";
import {
  useContext,
  useEffect,
  useState,
} from "react";
import { LoginContext } from "@contexts/login-context";
import { TabletButton } from "@components/tablet-button/custom-button";
import { Save } from "@mui/icons-material";
import { getCannedSimulationById, updateCannedSimulation } from "@services/cad";
import { useNavigate, useParams } from "react-router-dom";
import { getURL, urls } from "@utils/routing";
import { CADSimulatorTabs } from "@models/routing";
import { useFormik } from "formik";
import classes from "./cad-simulator-canned.module.css";
import { SimulationInformationForm } from "./canned-simulator-simulation-info-form";
import { SimulatorSequencesForm } from "./canned-simulator-sequences-form";
import { useDataWithoutLoading } from "@hooks/useDataWithoutLoading";

const initialValuesOfSimulation: CADCannedSimulationSlim = {
  sequences: [{
    alarm: "0",
    title: "",
    sequenceId: 0,
    comments: [],
    unitsConfig: {
      alarmLevelAtDispatch: "0",
      units: [],
    },
  }],
  departmentId: "",
  title: "",
  simulation: true,
  notes: "",
  notify: false,
  firemap: "",
  rts: true,
  streetName: "",
  locationComment: "",
  suite: "",
  CrossStreet1: "",
  city: "",
  state: "",
  lat: 0,
  lon: 0,
  mapPages: "",
  tacticalChannel: "",
  commandChannel: "",
  closeDelay: 180,
  CallerNumber: "",
};

export function EditCADCannedSimulator() {
  useTitle("CAD Canned Simulator");
  const [user] = useContext(LoginContext);
  const { incidentId } = useParams<{ incidentId: "" }>();
  const { data } = useDataWithoutLoading((as) => getCannedSimulationById(incidentId!, as));
  const [cannedSimulationData, setCannedSimulationData] = useState<CADCannedSimulation | null>(null);

  useEffect(() => {
    if (data) {
      const closeTime = data.closeDelay ? Number(data.closeDelay) : 600;
      let closeInValue = 600;
      // Check if in between 1 minute and 3 hours
      if ((closeTime / 60) <= 180 && (closeTime / 60) >= 1) {
        closeInValue = closeTime;
      }
      const parsedData: CADCannedSimulation = { ...data, closeDelay: closeInValue };
      setCannedSimulationData(parsedData);
    }
  }, [data]);

  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const onSubmit = async (values: CADCannedSimulationSlim) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const simulationPayloadObject: Partial<CADCannedSimulation> = {
      ...values,
      departmentId: user?.departmentId,
    };

    await updateCannedSimulation(incidentId ?? "", simulationPayloadObject);
    navigate(`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADSimulatorTabs, { tab: CADSimulatorTabs.Canned })}`);
  };

  const {
    values, handleChange, handleSubmit, setFieldValue,
  } = useFormik<CADCannedSimulationSlim>({
    initialValues: cannedSimulationData ?? initialValuesOfSimulation,
    onSubmit,
    enableReinitialize: true,
  });

  if (!data) {
    return <div />;
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(e);
      }}
      style={{ position: "relative" }}
    >
      <div
        className={classes.add_cad_simulator_canned_header_container}
      >
        <div className={classes.add_cad_simulator_canned_header_buttons_wrapper}>
          <TabletButton style={{ backgroundColor: "#56a28d" }} type="submit" endIcon={<Save />}>Update Simulation</TabletButton>
        </div>
      </div>
      <div className={classes.add_cad_simulator_canned_form_container}>
        <SimulationInformationForm
          values={values}
          setFieldValue={setFieldValue}
          handleChange={handleChange}
        />
        <SimulatorSequencesForm
          userEmail={user?.email ?? ""}
          values={values}
          setFieldValue={setFieldValue}
        />
      </div>
    </form>
  );
}
