/* eslint-disable camelcase */
import moment from "moment";

import { getLocaleDistanceFromMeters, getMilesPerHour } from "@utils/utilsFunctions";
import { MapUnit } from "@models/arcgis";

export default function AVLPopUp(props: {
  avl: MapUnit,
}) {
  const {
    agencyName,
    altitude,
    device_type,
    kindType,
    location,
    modified,
    source,
    speed,
    username,
  } = props.avl;

  return (
    <>
      <p title={moment(modified).toISOString()}>
        {`${username} - ${moment(modified).fromNow()}`}
      </p>
      <p>
        {`Location: ${location.latitude}, ${location.longitude}`}
      </p>
      <p>
        {`Device Type: ${device_type}`}
      </p>
      {kindType ? (
        <p>
          {`Kind: ${kindType}`}
        </p>
      ) : null}
      {source ? (
        <p>
          {`Source: ${source}`}
        </p>
      ) : null}
      {agencyName ? (
        <p>
          {`Agency: ${agencyName}`}
        </p>
      ) : null}
      {speed >= 0 ? <p>{`Speed: ${getMilesPerHour(speed)} mph`}</p> : <div />}
      {altitude >= -10 ? <p>{`Altitude: ${getLocaleDistanceFromMeters(altitude, true)}`}</p> : <div />}
    </>
  );
}
