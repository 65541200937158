/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  useCallback, useContext, useEffect, useMemo, useRef, useState,
} from "react";

import { Signal, TCIncident, Units } from "@models/tc-incident";
import { useHeight } from "@hooks/useHeight";
import classes from "./managed-incidents-list.module.css";
import { parseLocation } from "@utils/utilsFunctions";
import target from "../../img/center_on_incident_icon.svg";
import { WebMap } from "@components/arcgis-map-with-hooks/map";
import { ArcGISMapMapContext } from "@contexts/arcgis-map-context";
import { MapUnit, MapDetails } from "@models/arcgis";
import { Color } from "@models/color";

export function TCIncidentMap({
  incident,
  unitsLocation,
  signal,
  sharedAVLMarkers,
  unassignedUnitsOnMap,
  sharedAVLButton,
  mapViewRef,
  refetchData,
  orientationSetting,
}: {
  incident: TCIncident,
  unitsLocation: MapUnit[]
  signal: Signal,
  sharedAVLMarkers: MapUnit[],
  unassignedUnitsOnMap: Units[],
  sharedAVLButton?: boolean,
  mapViewRef: React.MutableRefObject<string>,
  refetchData: (props: MapDetails) => void,
  orientationSetting: Color | {
    background: string;
    text: string;
  }
}) {
  const height = useHeight();
  const [userSelected, setUserSelected] = useState(false);
  const [mapSettings, setMapSettings] = useContext(ArcGISMapMapContext);
  const mapsURL = `https://www.google.com/maps/search/?api=1&query=${incident?.location}`;
  const [lat, long] = parseLocation(incident.location);
  const center = useRef<[number, number]>([lat, long]);

  const [latitude, longitude] = useMemo(() => [lat, long], [lat, long]);

  const centerOnIncident = useCallback(() => {
    setUserSelected(!userSelected);
  }, [userSelected]);

  const resetMapView = useCallback(() => { }, []);

  useEffect(() => {
    const [oldLat, oldLong] = center.current;
    if (oldLat !== lat || oldLong !== long) {
      setMapSettings({
        ...mapSettings,
        isMapDragged: false,
      });
    }
  }, [lat, long]);

  const hazards = useMemo(() => (incident?.hazards ?? []).filter((hazard) => hazard.location_on_scene && hazard.location_on_scene !== "").map((hazard, index) => ({
    id: index + (hazard.name ?? ""),
    name: hazard.name ?? "",
    note: hazard.note,
    latitude: parseLocation(hazard.location_on_scene)[0],
    longitude: parseLocation(hazard.location_on_scene)[1],
    color: orientationSetting,
  })), [incident?.hazards, orientationSetting]);

  // TODO: Is this correct?
  const callers = useMemo(() => (incident?.callers ?? []), [incident?.callers, orientationSetting]);

  const unassignedUnitsOnMapMarkers = useMemo(() => (unassignedUnitsOnMap.filter((unit) => unit.location_on_map && unit.location_on_map !== "").map((unit, index) => ({
    id: index + (unit.UnitID ?? ""),
    name: unit.UnitID ?? "",
    time: unit.air_time ?? "",
    latitude: parseLocation(unit.location_on_map)[0],
    longitude: parseLocation(unit.location_on_map)[1],
  }))), [unassignedUnitsOnMap]);

  const incidentGroupMarkers = useMemo(() => ((incident.groups ?? []).filter((group) => group.location_on_map && group.location_on_map !== "")).map((group, index) => ({
    name: group.name ?? "",
    units: group.units ?? [],
    id: index + (group.name ?? ""),
    signal,
    history: incident.history ?? [],
    latitude: parseLocation(group.location_on_map)[0],
    longitude: parseLocation(group.location_on_map)[1],
  })), [incident.groups]);

  return (
    <div style={{ height }} className={`${classes.incident_map}`}>
      <div className={`${classes.tc_incident_header}`}>
        <p
          className={`${classes.tc_incident_map_title_container}`}
          onClick={centerOnIncident}
          role="button"
          tabIndex={0}
        >
          <img src={target} alt="" style={{ marginRight: 8 }} />
          Center On Incident
        </p>
        {!(incident.location && incident.location !== "") ? (
          <p style={{ color: "red", fontWeight: "bold" }}>
            No Location From TC
          </p>
        )
          : <></>}
        <a
          href={mapsURL}
          style={{ textDecoration: "none" }}
          target="_blank"
          rel="noreferrer"
        >
          <span className={`${classes.tc_incident_map_label}`}>
            {latitude.toFixed(6)}
            ,
            {longitude.toFixed(6)}
          </span>
        </a>
      </div>
      <WebMap
        incidents={[{
          incidentCallTypeDescription: incident?.name ?? "",
          incidentNumberDisplay: incident?.api_incident_number ?? "",
          longitude,
          latitude,
          type: "Managed",
        }]}
        avlMarkers={unitsLocation}
        hazards={hazards}
        callers={callers}
        incidentGroupsMarkers={incidentGroupMarkers}
        unassignedUnitsOnMapMarkers={unassignedUnitsOnMapMarkers}
        zoomToIncident={false}
        incidentZoom={18}
        incidentCenter={[longitude, latitude]}
        resetMapView={resetMapView}
        height={height - 40}
        noStoreData
        sharedAVLMarkers={sharedAVLMarkers}
        sharedAVLButton={sharedAVLButton}
        refetchData={refetchData}
        centerOnIncident={!userSelected}
        mapViewRef={mapViewRef}
      />
    </div>
  );
}
