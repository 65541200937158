import MaterialTable, { Column } from "material-table";
import moment from "moment";
import {
  useCallback, useContext, useState,
} from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { CADCannedSimulation } from "@models/cad";
import { useDataWithoutLoading } from "@hooks/useDataWithoutLoading";
import { useHeight } from "@hooks/useHeight";
import { useInterval } from "@hooks/useInterval";
import { getURL, urls } from "@utils/routing";
import { IncidentTrainingLabel } from "@components/tc-incidents-label";
import { Edit, NotificationsOff } from "@mui/icons-material";
import classes from "../cad-incident-details/cad.module.css";
import icon from "../../img/rts-disabled-icon.png";
import TableDate from "@components/table-date";
import { getCannedSimulation } from "@services/cad";
import { getCustomFilterAndSearch } from "@utils/utilsFunctions";
import { TabletButton } from "@components/tablet-button/custom-button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { CloseCADCannedSimulatedIncidentButton } from "./cad-simulator-close-buttons";
import { IconButton } from "@mui/material";
import { LoginContext, Role } from "@contexts/login-context";

function CADSimulatorCannedSimulation({
  rts,
  address,
  incidentType,
  title,
  to,
  simulation,
  notify,
  units,
  id,
}: {
  id: string,
  units: string[],
  notify: boolean,
  rts: boolean,
  simulation: boolean,
  address: string,
  incidentType: string,
  title: string,
  to?: string
}) {
  return (
    <div className="cad-incidents-row">
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <NavLink
          to={to ?? `${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADSimulatorCannedEditNew, { incidentId: id })}`}
          style={{ textDecoration: "none", color: "black" }}
        >
          <span style={{ fontWeight: "bold", fontSize: 16 }}>
            {incidentType}
            {" "}
            {title}
          </span>
        </NavLink>
        <IncidentTrainingLabel simulation={simulation} />
        {!notify && <NotificationsOff />}
        {!rts ? (
          <div style={{ height: 20 }}>
            <img src={icon} alt="Icon unavailable" style={{ width: 20, height: 20, marginLeft: 5 }} />
          </div>
        ) : null}
      </div>
      {address ? <span>{address}</span> : <></>}
      <div>
        {units?.map((unit, index) => (
          <span
            // eslint-disable-next-line react/no-array-index-key
            key={unit + index}
            className={classes.cadUnitChild}
          >
            {unit}
          </span>
        ))}
      </div>
    </div>
  );
}

export function CadSimulatorCannedList() {
  const { data: cannedList, reload } = useDataWithoutLoading((as) => getCannedSimulation(as), undefined, { historyStateKey: "session-data-key" });
  const [user] = useContext(LoginContext);
  const height = useHeight();
  const navigate = useNavigate();
  const [columns, setColumns] = useState<Array<Column<CADCannedSimulation>>>([
    {
      title: "Last Updated",
      field: "modified",
      cellStyle: {
        width: "10%",
      },
      render: (item) => (
        <NavLink style={{ textDecoration: "none", color: "black" }} to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADSimulatorCannedEditNew, { incidentId: item?._id ?? "" })}`}>
          <TableDate date={item.modified} />
        </NavLink>
      ),
      sorting: true,
      customSort: (a, b) => (moment(b.modified).unix() ?? 0) - (moment(a.modified).unix() ?? 0),
    },
    {
      title: "",
      field: "",
      cellStyle: {
        width: "5%",
      },
      render: (item) => (
        <IconButton disabled={user?.role === Role.User} style={{ padding: 0 }} onClick={() => navigate(`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADSimulatorCannedEditTemplate, { incidentId: item._id ?? "" })}`)}><Edit /></IconButton>
      ),
    },
    {
      title: "Simulation",
      field: "title",
      cellStyle: {
        width: "50%",
      },
      render: (item) => {
        const incidentUnits = Array.from(new Set(item.sequences.flatMap((sequence) => (sequence.unitsConfig?.units.length ? sequence.unitsConfig.units : []))));
        return (
          <NavLink
            style={{ textDecoration: "none", color: "black" }}
            to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADSimulatorCannedEditNew, { incidentId: item?._id ?? "" })}`}
          >
            <CADSimulatorCannedSimulation
              id={item._id ?? ""}
              units={incidentUnits}
              notify={item.notify}
              rts={item.rts}
              simulation={item.simulation}
              address={`${item.streetName}, ${item.city}, ${item.state}`}
              incidentType={item.incidentType}
              title={item.title}
            />
          </NavLink>
        );
      },
    },
    {
      title: "Comment",
      field: "notes",
      cellStyle: {
        width: "20%",
      },
    },
    {
      title: "Created",
      field: "modified_unix_date",
      cellStyle: {
        width: "10%",
      },
      render: (item) => (
        <>
          <p style={{ margin: "8px 0px 10px 0px" }}>
            {item.modifiedDate ? moment(item.modifiedDate * 1000).fromNow() : ""}
          </p>
          <CloseCADCannedSimulatedIncidentButton
            disabled={user?.role === Role.User}
            incident={item}
            reload={reload}
          />
        </>
      ),
      ...getCustomFilterAndSearch<CADCannedSimulation>((item) => moment((item.modifiedDate ?? 0) * 1000).fromNow()),
      sorting: true,
      customSort: (a, b) => (b.modifiedDate ?? 0) - (a.modifiedDate ?? 0),
    },
  ]);

  const onOrderChanged = useCallback((colIndex: number, order?: "asc" | "desc") => {
    setColumns((c) => c.map((c, i) => (colIndex === i
      ? { ...c, defaultSort: order }
      : { ...c, defaultSort: undefined })));
  }, [setColumns]);

  useInterval(async () => {
    await reload();
  }, [], { reloadInterval: 15000 });

  return (
    <MaterialTable<CADCannedSimulation>
      data={cannedList?.filter((item) => item.active) ?? []}
      title={(
        <div>
          <TabletButton disabled={user?.role === Role.User} size="small" className="material-table-add-row-button" onClick={() => navigate(`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADSimulatorCannedNew)}`)} startIcon={<AddCircleIcon />}>Add Simulation</TabletButton>
        </div>
      )}
      onOrderChange={onOrderChanged}
      options={{
        paging: false,
        sorting: true,
        maxBodyHeight: height - 50,
        headerStyle: {
          position: "sticky",
          top: "0",
        },
      }}
      columns={columns.map((c) => ({
        ...c,
      }))}
    />
  );
}
