import "./simple-map-pin.module.css";

export function MapPin(props: { color: string }) {
    const { color } = props;
    
    return (
        <svg width="40" height="60" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="20px" y="0px" viewBox="0 0 16 16" xmlSpace="preserve" fillRule="evenodd">
            <path style={{ fill: color }} d="M4,5c0-2.2,1.8-4,4-4c2.2,0,4,1.8,4,4c0,2-1.5,3.7-3.5,4v8.5C8.5,18.8,8.3,19,8,19c-0.3,0-0.5-0.2-0.5-0.5V9  C5.5,8.7,4,7,4,5L4,5zM6,2.5 Q7,3.5 5.5,4.5 Q4.5,3.5 6,2.5z" />
        </svg>
    )
}