import {
  Button,
  FormLabel,
  Grid,
  Paper,
  TextField,
} from "@mui/material";
import {
  Check,
} from "@mui/icons-material";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import styles from "./signup/signup-page.module.css";
import ContactInfo from "@components/ContactInfo";
import PasswordResetOverview from "@components/password-reset-overview";
import { useData } from "@hooks/useData";
import { useTitle } from "@hooks/useTitle";
import { changePassword } from "@services/department";
import {
  accountSessionSignupKey,
  getUserInfo,
  verifyChangePasswordToken,
} from "@services/user";
import logo from "./img/logo.png";
import { getKeys } from "@utils/utilsFunctions";

type ResetPasswordFields = {
  password: string;
  confirmPassword: string;
}

export default function ResetPasswordForm() {
  useTitle("Change Password");
  const { token } = useParams<{ token: string }>();
  const [submit, setSubmit] = useState<boolean>(false);
  const { data: session } = useData(() => accountSessionSignupKey(token));
  const { data: changePasswordInfo } = useData(() => verifyChangePasswordToken(token!));
  const { data: userInfo } = useData(() => getUserInfo(token!));
  const [passwords, setPasswords] = useState<ResetPasswordFields>({
    password: "",
    confirmPassword: "",
  });
  const [passwordError, setPasswordError] = useState("");

  const initialValues: ResetPasswordFields = {
    password: "",
    confirmPassword: "",
  };
  const errors = {} as ResetPasswordFields;

  useEffect(() => {
    if (passwords) {
      if (passwords.password !== passwords.confirmPassword) {
        setPasswordError("Passwords don't match");
      } else {
        setPasswordError("");
      }
    } else {
      setPasswordError("Required");
    }
  }, [passwords]);

  const keys = getKeys<ResetPasswordFields>({
    password: 1,
    confirmPassword: 1,
  });

  const onSubmit = async (obj: ResetPasswordFields) => {
    const result = await changePassword({
      email: userInfo?.email ?? "",
      password: obj.password,
      token,
    });

    if (result?.error) {
      setPasswordError(result.error);
    }

    if (result?.ok) {
      setSubmit(true);
    }
  };

  if (!session || !changePasswordInfo || !userInfo) {
    return <div />;
  }

  return (
    submit || !changePasswordInfo.canChangePassword ? (
      <PasswordResetOverview
        mobileAccess={changePasswordInfo.mobileAccess}
        tabletAccess={changePasswordInfo.tabletAccess}
        validToken={changePasswordInfo.canChangePassword}
        webAccess={changePasswordInfo.webAccess}
      />
    ) : (
      <div className="login-page container login-background">
        <Grid container>
          <Grid item xs={12} lg={7} className="left-side">
            <img src={logo} alt="" className="logo" />
          </Grid>
          <Grid item xs={12} lg={4} className="right-side">
            <Paper className="paperBody">
              <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validate={async (values) => {
                  if (!values.password) {
                    errors.password = "Required";
                  }

                  if (!values.confirmPassword) {
                    errors.confirmPassword = "Required";
                  }

                  if (values.password.length < (changePasswordInfo?.minPasswordLength || 8)) {
                    errors.password = `Password must be ${changePasswordInfo?.minPasswordLength || 8} characters at minimum`;
                  }

                  return errors;
                }}
              >
                {({
                  values,
                  errors,
                  setValues,
                  dirty,
                  isValid,
                }) => (
                  <Form>
                    <div className="head">
                      Change Password
                    </div>
                    <div className="formLogin">
                      <FormLabel>Email</FormLabel>
                      <div style={{
                        marginRight: 100,
                        color: "white",
                      }}
                      >
                        {userInfo.email}
                      </div>
                      <FormLabel>Password</FormLabel>
                      <TextField
                        variant="outlined"
                        id={keys.password}
                        required
                        type="password"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const password = e.target.value;
                          setPasswords({ ...passwords, password });
                          setValues({
                            ...values,
                            password,
                          });
                        }}
                      />
                      {!!errors.password && dirty && <div className={styles.errorContainer}>{errors.password}</div>}
                      <FormLabel>Confirm Password</FormLabel>
                      <TextField
                        variant="outlined"
                        id={keys.confirmPassword}
                        type="password"
                        autoComplete="none"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const confirmPassword = e.target.value;
                          setPasswords({ ...passwords, confirmPassword });
                          setValues({
                            ...values,
                            confirmPassword,
                          });
                        }}
                        required
                      />

                      {!!passwordError && values.confirmPassword.length > 0 && <div className={styles.errorContainer}>{passwordError}</div>}
                    </div>
                    <div className="button">
                      <Button
                        disabled={!dirty || !isValid || !!passwordError}
                        variant="contained"
                        endIcon={<Check />}
                        className="btn"
                        color="secondary"
                        type="submit"
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Paper>
            <ContactInfo />
          </Grid>
        </Grid>
      </div>
    )
  );
}
