/* eslint-disable react/no-array-index-key */
import ReactDOMServer from "react-dom/server";
import { DefaultUnitColor } from "@models/location";
import CommandMarker from "../pages/img/managed-incidents/hazards/place_command@2x.png";
import FlamesMarker from "../pages/img/managed-incidents/hazards/place_flames@2x.png";
import HazardMarker from "../pages/img/managed-incidents/hazards/place_hazard@2x.png";
import SmokeMarker from "../pages/img/managed-incidents/hazards/place_smoke@2x.png";
import VictimMarker from "../pages/img/managed-incidents/hazards/place_victim@2x.png";
import QuestionMarker from "../pages/img/managed-incidents/question-mark-marker.png";
import { Buffer } from "buffer";
import { AVLMarker, SharedAVLMarker } from "../pages/img/SVG-components/device-marker";
import { GroupMarker } from "../pages/img/SVG-components/group-mapmarker";
import { MapPin } from "../pages/img/SVG-components/simple-map-pin/simple-map-pin";
import { ArcGIsIncidentGroup, ArcGIsUnassignedUnit, MapUnit } from "@models/arcgis";
import { UnitMap } from "../pages/img/SVG-components/unit";
import { personnelTotalNumber } from "./utilsFunctions";
import Language from "@mui/icons-material/Language";
import TCAndroid from "../pages/img/tc-android";
import TCiOS from "../pages/img/tc-ios";
import TCMobileAndroid from "../pages/img/tc-mobile-android";
import TCMobileIOS from "../pages/img/tc-mobile-ios";
import { UserSessionDeviceTypes } from "@models/user";
import { UnmappedDeviceMarker } from "../pages/img/SVG-components/green-marker-vehicle";
import { Color } from "@models/color";
import { MapOrientation } from "../pages/img/SVG-components/simple-map-orientation";
import { Monitor } from "@mui/icons-material";

const avlUnitsCacheLimit = 200;
const avlUnitsCacheClearCount = 50;

const sharedAVLUnitsCacheLimit = 1000;
const sharedAVLUnitsCacheClearCount = 250;

const hazardsMarkers: Record<string, {
  url: string
  width: number
  height: number
}> = {
  CommandPost: {
    url: CommandMarker,
    width: 45,
    height: 50,
  },
  RedPin: {
    url: "",
    width: 45,
    height: 50,
  },
  Smoke: {
    url: SmokeMarker,
    width: 90,
    height: 60,
  },
  Victim: {
    url: VictimMarker,
    width: 45,
    height: 50,
  },
  Hazard: {
    url: HazardMarker,
    width: 85,
    height: 80,
  },
  Flames: {
    url: FlamesMarker,
    width: 35,
    height: 50,
  },
  Unknown: {
    url: QuestionMarker,
    width: 55,
    height: 50,
  },
};

export function transformReactElementToBase64(node: React.ReactElement) {
  const svgToString = ReactDOMServer.renderToStaticMarkup(node);
  return `data:image/svg+xml;base64,${Buffer.from(svgToString).toString("base64")}`;
}

export function mapPinMarker(color: string) {
  return transformReactElementToBase64(<MapPin color={color} />);
}

export function mapOrientationMarker(color: Color, text: string) {
  return transformReactElementToBase64(<MapOrientation color={color} text={text} />);
}

export function hazardMarkerByType(type: string, orientationButton?: Color) {
  if (type.toLocaleLowerCase().includes("mappin")) {
    return {
      url: mapPinMarker(type.slice(6).toLocaleLowerCase()), width: 40, height: 60, yoffset: 30,
    };
  }
  if (type.toLocaleLowerCase().includes("orientation") && orientationButton) {
    return {
      url: mapOrientationMarker(orientationButton, type), width: 30, height: 30, yoffset: 15,
    };
  }
  return hazardsMarkers[type] ?? hazardsMarkers.Unknown;
}

function getMarkerCacheKey(item: MapUnit, color: Color = DefaultUnitColor) {
  return `${item.username}-${item.device_type}-${color.background}`;
}

function generateMarkerSVG(username: string, background: string, text: string) {
  return ReactDOMServer.renderToString(
    <AVLMarker info={username} text={text} background={background} />,
  );
}

export function getDeviceTypeMarkerUrl(
  item: MapUnit,
  cacheMap: Map<string, string>,
  color: Color = DefaultUnitColor,
): string {
  const key = getMarkerCacheKey(item, color);

  // Cache cleanup, remove oldest 50 entries
  if (!cacheMap.has(key)) {
    if (cacheMap.size >= avlUnitsCacheLimit) {
      const keysToDelete = Array.from(cacheMap.keys()).slice(0, avlUnitsCacheClearCount);
      keysToDelete.forEach((oldKey) => cacheMap.delete(oldKey));
    }

    const { background, text } = color;
    const svg = generateMarkerSVG(item.username, background, text);
    const base64 = Buffer.from(svg).toString("base64");
    const url = `data:image/svg+xml;base64,${base64}`;
    cacheMap.set(key, url);
  }

  return cacheMap.get(key)!;
}

export function sharedAVLDeviceTypeMarkerKey(item: MapUnit): string {
  const agencyLabel = item.agencyCode ?? item.opAreaCode;
  return `shared-avl-unit-type-${item.username}-${agencyLabel}`;
}

function generateSharedAVLMarkerSVG(item: MapUnit): string {
  const agencyName = item.agencyCode ?? item.opAreaCode;
  return ReactDOMServer.renderToString(
    <SharedAVLMarker info={item.username} opacity={item.opacity ?? 1} agency={agencyName} />,
  );
}

export function getSharedAvlMarkerUrl(
  item: MapUnit,
  cacheMap: Map<string, string>,
): string {
  const key = sharedAVLDeviceTypeMarkerKey(item);

  if (!cacheMap.has(key)) {
    // Cache cleanup, remove oldest 250 entries
    if (cacheMap.size >= sharedAVLUnitsCacheLimit) {
      const keysToDelete = Array.from(cacheMap.keys()).slice(0, sharedAVLUnitsCacheClearCount);
      keysToDelete.forEach((k) => cacheMap.delete(k));
    }

    const svgIcon = generateSharedAVLMarkerSVG(item);
    const base64 = Buffer.from(svgIcon).toString("base64");
    const url = `data:image/svg+xml;base64,${base64}`;
    cacheMap.set(key, url);
  }

  return cacheMap.get(key)!;
}

export function incidentGroupMarker(group: ArcGIsIncidentGroup) {
  const groupMarkerSvg = <GroupMarker name={group.name} unitsNumber={group.units.length} personnel={personnelTotalNumber(group)} />;
  return transformReactElementToBase64(groupMarkerSvg);
}

export function unmappedDeviceMarker() {
  const groupMarkerSvg = <UnmappedDeviceMarker />;
  return transformReactElementToBase64(groupMarkerSvg);
}

export function unassignedUnitsOnMapMarker(unit: ArcGIsUnassignedUnit) {
  const unassignedUnitsOnMapMarkerSvg = <UnitMap name={unit.name} time={unit.time} />;
  return transformReactElementToBase64(unassignedUnitsOnMapMarkerSvg);
}

export const getUserDeviceTypeIcon = (source: string): JSX.Element => {
  if (source.match(UserSessionDeviceTypes.tabletAndroid)) {
    return <TCAndroid />;
  }
  if (source.match(UserSessionDeviceTypes.android)) {
    return <TCMobileAndroid />;
  }
  if (source.match(UserSessionDeviceTypes.ipad)) {
    return <TCiOS />;
  }
  if (source.match(UserSessionDeviceTypes.iphone)) {
    return <TCMobileIOS />;
  }
  if (source.match(UserSessionDeviceTypes.appTabletcommand)) {
    return <Monitor />;
  }
  return <Language />;
};
