import { Collapse } from "react-collapse";
import { MenuLink } from "@components/menu-links";
import { getURL, urls } from "@utils/routing";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// TODO: Fix for svg to display correctly
import ActionLog from "../img/menu-icons/Action-Log.svg?react";
import AdminFeatures from "../img/menu-icons/Admin_Features.svg?react";
import CadStatus from "../img/menu-icons/CAD_status.svg?react";
import Chart from "../img/menu-icons/Chart.svg?react";
import DeviceMapping from "../img/menu-icons/device_mapping.svg?react";
import IncidentsLeftOpen from "../img/menu-icons/Incidents-Left-Open.svg?react";
import LastLoggedIn from "../img/menu-icons/last_logged_in.svg?react";
import Location from "../img/menu-icons/location.svg?react";
import Messages from "../img/menu-icons/messages_icon.svg?react";
import OnlineUsers from "../img/menu-icons/online_users.svg?react";
import Settings from "../img/menu-icons/settings.svg?react";
import Users from "../img/menu-icons/users.svg?react";
import { useState } from "react";
import {
  StatusTab,
} from "@models/routing";
import { useLocation } from "react-router-dom";
import { Department } from "@models/department";

export function MenuAdmin(prop: {
  departmentData: Department | undefined,
  handleCollapse: () => void
}) {
  const { handleCollapse, departmentData } = prop;
  const [collapse, setCollapse] = useState(false);
  const [chartCollapse, setChartCollapse] = useState(false);
  const router = useLocation();

  return (
    <>
      <MenuLink
        label="Admin Features"
        icon={AdminFeatures}
        icon2={ExpandMoreIcon}
        onClick={() => setCollapse(!collapse)}
      />
      <Collapse isOpened={collapse}>
        <MenuLink
          to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminUsers)}`}
          label="Users"
          icon={Users}
          onClick={handleCollapse}
        />
        {departmentData?.cadBidirectionalEnabled || departmentData?.cadOneWayVehiclesEnabled ? (
          <MenuLink
            to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADStatus, { tab: StatusTab.VehicleStatuses })}`}
            currentRoute={router.pathname}
            alternateRoutes={[
              `${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADStatus, { tab: StatusTab.Statuses })}`,
              `${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADStatus, { tab: StatusTab.Vehicles })}`,
            ]}
            label="CAD Status"
            icon={CadStatus}
            onClick={handleCollapse}
          />
        ) : <></>}
        <MenuLink
          to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminLocation)}`}
          label="Location"
          icon={Location}
          onClick={handleCollapse}
        />
        <MenuLink
          to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminDeviceMapping)}`}
          label="Device Mapping"
          icon={DeviceMapping}
          onClick={handleCollapse}
        />
        <MenuLink
          to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminSettings)}`}
          label="Settings"
          icon={Settings}
          onClick={handleCollapse}
        />
        <MenuLink
          to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminOnlineUsers)}`}
          label="Online Users"
          icon={OnlineUsers}
          onClick={handleCollapse}
        />
        <MenuLink
          to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminMessages)}`}
          label="Messages"
          icon={Messages}
          onClick={handleCollapse}
        />
        <MenuLink
          to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminIncidentsLeftOpen)}`}
          label="Incidents Left Open"
          icon={IncidentsLeftOpen}
          onClick={handleCollapse}
        />
        <MenuLink
          to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminActionLog)}`}
          label="Action Log"
          icon={ActionLog}
          onClick={handleCollapse}
        />
        <MenuLink
          to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminLastLoggedIn)}`}
          label="Last Logged In"
          icon={LastLoggedIn}
          onClick={handleCollapse}
        />
        <MenuLink
          label="Chart"
          icon={Chart}
          icon2={ExpandMoreIcon}
          onClick={() => setChartCollapse(!chartCollapse)}
        />
        <Collapse isOpened={chartCollapse}>
          <MenuLink
            to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminChartIncidents)}`}
            label="Incidents Chart"
            icon={Chart}
            onClick={handleCollapse}
          />
          <MenuLink
            to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminChartManagedIncidents)}`}
            label="Managed Incidents"
            icon={Chart}
            onClick={handleCollapse}
          />
          <MenuLink
            to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminChartUsers)}`}
            label="Users Chart"
            icon={Chart}
            onClick={handleCollapse}
          />
        </Collapse>
      </Collapse>
    </>
  );
}
