import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { LoginContext, Role } from "@contexts/login-context";
import { getDepartmentById } from "@services/department";
import { useDataWithoutLoading } from "./useDataWithoutLoading";

export const TitleContext = React.createContext<{
  setTitle:(value: string | undefined) => void,
  title: string | undefined
    }>(null!);

export function TitleContextProvider(p: React.PropsWithChildren<{}>) {
  const { children } = p;
  const oldTitle = useRef<string>();
  const [title, setTitle] = useState<string>();

  const [user] = useContext(LoginContext);

  const { data } = useDataWithoutLoading(() => {
    if (user && (user.role !== Role.SuperAdmin && user.role !== Role.SuperUserReadOnly)) {
      return getDepartmentById(user?.departmentId);
    }
    return Promise.resolve(null);
  }, [user]);

  useEffect(() => {
    if (!oldTitle.current) {
      oldTitle.current = document.title;
    }
    if (user && data && user.role === Role.Admin) {
      document.title = `${data?.department}`;
    } else {
      document.title = `${title}`;
    }

    return () => {
      document.title = oldTitle.current!;
    };
  }, [title, user, data]);
  const contextValue = useMemo(() => ({ setTitle, title }), [setTitle, title]);
  return <TitleContext.Provider value={contextValue}>{children}</TitleContext.Provider>;
}

export function useTitle(initialTitle: string) {
  const { title, setTitle } = useContext(TitleContext);
  const oldTitle = useRef(title);

  useEffect(() => {
    const oldTitleValue = oldTitle.current;
    setTitle(initialTitle);

    return () => setTitle(oldTitleValue);
  }, [initialTitle, setTitle]);
}
