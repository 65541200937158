import MaterialTable from "material-table";
import {
  useContext, useState,
} from "react";
import { useParams } from "react-router-dom";
import { LoginContext, Role } from "@contexts/login-context";
import { getDepartmentById, updateDepartmentById } from "@services/department";
import { useData } from "@hooks/useData";
import { useTitle } from "@hooks/useTitle";
import { TabletButton } from "@components/tablet-button/custom-button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  AudioConfig,
} from "@models/department";
import "./account-licensing/account-licensing.css";
import { useHeight } from "@hooks/useHeight";
import AccountManageAudioConfig, { ManageButtonAction } from "@components/account-manage-audio-config";
import { Typography } from "@mui/material";

function ClickableElement(props: {
  element: JSX.Element | string | number,
  item: AudioConfig,
  onClickItem: (item: AudioConfig) => void,
}): JSX.Element {
  const {
    element,
    item,
    onClickItem,
  } = props;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div onClick={() => onClickItem(item)}>
      {element}
    </div>
  );
}

export function AudioConfigsOutputForAction(audioGroups: AudioConfig[], action: ManageButtonAction, prev?: AudioConfig, next?: AudioConfig): {
  items: AudioConfig[],
  changed: boolean,
} {
  const processingActions: ManageButtonAction[] = [
    ManageButtonAction.Add,
    ManageButtonAction.Update,
    ManageButtonAction.Delete,
    ManageButtonAction.NestedUpdate,
  ];
  const canProcess = processingActions.indexOf(action) >= 0;
  if (!canProcess) {
    return {
      items: audioGroups,
      changed: false,
    };
  }

  // This clone works only on simple objects
  // - https://www.delftstack.com/howto/typescript/typescript-cloning-an-object/
  let cleanItems: AudioConfig[] = audioGroups.map((item) => ({ ...item }));

  // - remove the item from the list (for edit, delete)
  if (action === ManageButtonAction.Delete || action === ManageButtonAction.Update || action === ManageButtonAction.NestedUpdate) {
    cleanItems = audioGroups.map((item) => ({ ...item })).filter((item) => {
      if (!prev) {
        return false;
      }

      // Remove prev item when group is the same
      const sameItem = item.group === prev.group;
      return !sameItem;
    });
  }
  // - update positions, after the item was removed, starting with 1...length (last item)
  let orderIndex: number = 1;
  const reordered: AudioConfig[] = [];
  cleanItems.sort((a, b) => a.order - b.order).forEach((item) => {
    const withOrder = {
      ...item,
      order: orderIndex,
    };
    orderIndex = orderIndex + 1;
    reordered.push(withOrder);
  });

  // No next item (e.g. .Delete), we're done.
  if (!next) {
    return {
      items: reordered,
      changed: true,
    };
  }

  const withNextItem: AudioConfig[] = [next, ...reordered];
  // - insert item at the correct position
  reordered.sort((a, b) => a.order - b.order).forEach((item) => {
    // - shift positions for the items after the inserted item s(+1)
    // Identity check is same name and same URL
    const sameItem = item.group === next.group;
    if (item.order >= next.order && !sameItem) {
      item.order = item.order + 1;
    }
  });

  const resultSorted = withNextItem.sort((a, b) => a.order - b.order);
  return {
    items: resultSorted,
    changed: true,
  };
}

export default function AccountAudioConfig() {
  const [user] = useContext(LoginContext);
  useTitle("Manage Audio Config");

  // departmentId param is missing when loaded as a settings panel
  const maybeRoute = useParams<{ departmentId: string }>();
  const currentDepartmentId = ((user?.role === Role.SuperAdmin || user?.role === Role.SuperUserReadOnly) ? maybeRoute?.departmentId : user?.departmentId) ?? "";

  const { data: department, reload: reloadDepartment } = useData((as) => getDepartmentById(currentDepartmentId, as));

  const items = department?.audioConfiguration ?? [];

  const height = useHeight();
  const [editItem, setEditItem] = useState<AudioConfig | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const maxPosition = items.length + 1;
  const performCustomButtonAction = async (action: ManageButtonAction, previous?: AudioConfig, next?: AudioConfig, shouldClose?: boolean) => {
    if (!department) {
      return;
    }

    const result = AudioConfigsOutputForAction(items, action, previous, next);
    if (result.changed) {
      setIsUpdating(true);
      department.audioConfiguration = result.items;
      await updateDepartmentById(department._id, department);
      await reloadDepartment();
      setEditItem(next);
    }
    // do not close window on nested update
    if (shouldClose) {
      setIsOpen(false);
    }
    setIsUpdating(false);
  };

  const onAddAudioConfig = (): void => {
    setEditItem(undefined);
    setIsOpen(true);
  };

  const addButtonJSX = (
    (
      <div style={{
        marginTop: "auto",
        marginBottom: "auto",
      }}
      >
        {user?.role !== Role.SuperUserReadOnly ? (
          <TabletButton
            variant="contained"
            size="small"
            onClick={onAddAudioConfig}
            startIcon={<AddCircleIcon />}
          >
            Add Audio Config
          </TabletButton>
        ) : <Typography variant="h6" color="white">Audio Streams</Typography>}
        <span
          style={{
            color: "white",
            fontSize: 20,
            marginBottom: 0,
            marginTop: 0,
            marginLeft: 10,
          }}
        >
          {department?.department}
        </span>
      </div>
    )
  );

  const onClickItem = (item: AudioConfig) => {
    setEditItem(item);
    setIsOpen(true);
  };

  return (
    <>
      <AccountManageAudioConfig
        item={editItem}
        isOpen={isOpen}
        isUpdating={isUpdating}
        maxPosition={maxPosition}
        performAction={performCustomButtonAction}
      />
      <MaterialTable
        title={addButtonJSX}
        data={items}
        columns={[
          {
            title: "Order",
            cellStyle: {
              width: "10%",
            },
            render: (item) => (<ClickableElement element={Number(item.order) || 0} item={item} onClickItem={onClickItem} />),
          },
          {
            title: "Group",
            render: (item) => (<ClickableElement element={item.group} item={item} onClickItem={onClickItem} />),
          },
          {
            title: "# Streams",
            cellStyle: {
              width: "10%",
            },
            render: (item) => (<ClickableElement element={item && item.streams ? item.streams.length : "0"} item={item} onClickItem={onClickItem} />),
          },
          {
            title: "Streams",
            render: (rowData) => (
              <div>
                {rowData?.streams?.map((stream) => (
                  <div key={stream.channel + stream.url}>
                    <span style={{ fontWeight: "600" }}>{stream.channel || ""}</span>
                    {" "}
                    :
                    {" "}
                    <a href={stream.url}>{stream.url}</a>
                  </div>
                ))}
              </div>
            ),
          },
        ]}
        options={{
          paging: false,
          sorting: false,
          draggable: false,
          maxBodyHeight: height + 50,
          headerStyle: {
            position: "sticky",
            top: "0",
          },
        }}
      />
    </>
  );
}
