import * as React from "react";
const SVGComponent = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    width={26}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1,4c0-0.6,0.4-1,1-1h12c0.6,0,1,0.4,1,1v8c0,0.6-0.4,1-1,1H2c-0.6,0-1-0.4-1-1V4z M0,12c0,1.1,0.9,2,2,2h12
	c1.1,0,2-0.9,2-2V4c0-1.1-0.9-2-2-2H2C0.9,2,0,2.9,0,4V12z"
    />
    <path
      d="M5.2,5.6c0.1-0.1,0.3,0,0.4,0.1c0,0,0,0,0,0L6.2,7C6.8,6.8,7.4,6.7,8,6.7c0.6,0,1.2,0.1,1.8,0.3l0.7-1.3
	c0.1-0.1,0.2-0.2,0.4-0.1C10.9,5.7,11,5.8,10.9,6c0,0,0,0,0,0l-0.7,1.2c1.2,0.7,2,1.8,2,3.2H3.7c0-1.3,0.8-2.5,2-3.2L5.1,6
	C5,5.9,5.1,5.7,5.2,5.6L5.2,5.6z M6.4,9.3c0.3,0,0.5-0.2,0.5-0.5S6.7,8.3,6.4,8.3c-0.3,0-0.5,0.2-0.5,0.5S6.1,9.3,6.4,9.3z M9.6,9.3
	c0.3,0,0.5-0.2,0.5-0.5S9.9,8.3,9.6,8.3S9.1,8.5,9.1,8.8S9.3,9.3,9.6,9.3z"
    />
  </svg>
);
export default SVGComponent;
