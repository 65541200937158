import {
  Cached, Visibility, AssignmentInd, HourglassEmptyOutlined,
} from "@mui/icons-material";
import { Typography } from "@mui/material";

import { TabletButton } from "@components/tablet-button/custom-button";

import classes from "./cad-status.module.css";

export default function CADVehiclesStatusTableHeader(
  {
    restricted,
    filterAssignable,
    filterAwaitingConfirmation,
    startInterval,
    setStartInterval,
    setFilterAssignable,
    setFilterAwaitingConfirmation,
  }: {
    restricted: boolean,
    filterAssignable: boolean,
    filterAwaitingConfirmation: boolean,
    startInterval: boolean,
    setStartInterval: React.Dispatch<React.SetStateAction<boolean>>,
    setFilterAssignable: React.Dispatch<React.SetStateAction<boolean>>,
    setFilterAwaitingConfirmation: React.Dispatch<React.SetStateAction<boolean>>,
  },
) {
  return (
    <div className={`${classes.page_header}`}>
      <Typography className={`${classes.page_title}`} variant="h6">CAD Vehicle Status</Typography>
      {!restricted && (
        <div className={`${classes.header_right_content}`}>
          <TabletButton
            className="formButton"
            startIcon={<Cached />}
            onClick={() => {
              setStartInterval(!startInterval);
              localStorage.setItem("hideAutoRefresh", JSON.stringify(!startInterval));
            }}
          >
            {startInterval ? "Stop Refresh" : "Start Refresh"}
          </TabletButton>
          <TabletButton
            className="formButton"
            startIcon={filterAssignable ? <Visibility /> : <AssignmentInd />}
            onClick={() => {
              setFilterAssignable(!filterAssignable);
            }}
          >
            {" "}
            {filterAssignable ? "Show All" : "User Assigned"}
          </TabletButton>

          <TabletButton
            className="formButton"
            startIcon={filterAwaitingConfirmation ? <Visibility /> : <HourglassEmptyOutlined />}
            onClick={() => {
              setFilterAwaitingConfirmation(!filterAwaitingConfirmation);
            }}
          >
            {" "}
            {!filterAwaitingConfirmation ? "Show Awaiting Confirmation" : "Show All"}
          </TabletButton>
        </div>
      )}
    </div>
  );
}
