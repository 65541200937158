import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";

export default function IncidentTableHeaderWithToggle({
  expand,
  setExpand,
  title,
}: {
  expand: boolean,
  setExpand: React.Dispatch<React.SetStateAction<boolean>>,
  title: string,

}) {
  return (
    <div style={{ display: "flex", columnGap: 10 }}>
      <IconButton onClick={() => setExpand(!expand)} style={{ borderRadius: 5 }}>
        {expand ? (
          <>
            <KeyboardArrowDown />
          </>
        ) : (
          <>
            <KeyboardArrowRight />
          </>
        )}
      </IconButton>
      <Typography style={{ fontWeight: "bold", fontSize: 16 }}>{title}</Typography>
    </div>
  );
}
