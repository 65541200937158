import { useNavigate, useParams } from "react-router-dom";
import { getCADIncidentById, getCannedSimulationById, postSimulatorIncident } from "../../../services/cad";
import { useDataWithoutLoading } from "@hooks/useDataWithoutLoading";
import styles from "./cad-simulator.module.css";
import {
  FormikHelpers, useFormik,
} from "formik";
import {
  Button, Checkbox, FormLabel, Grid,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import {
  CADComment, IncidentUnit, Sequence,
  SimulatorIncident,
} from "@models/cad";
import { useTitle } from "@hooks/useTitle";
import {
  checkUTF8Characters, detectDuplicateUnits, generateSecretKey, getKeys, getWordPriorityColor,
} from "@utils/utilsFunctions";
import {
  useCallback,
  useContext, useEffect, useState,
} from "react";
import { TabletButton } from "@components/tablet-button/custom-button";
import { CadSimulatorMap } from "./cad-simulator-map";
import { CadSimulatorField } from "./cad-simulator-field";
import CheckIcon from "@mui/icons-material/Check";
import { getURL, urls } from "@utils/routing";
import { closeInValuesInSeconds } from "@utils/static-data";
import { LoginContext, SavedUser } from "@contexts/login-context";
import { getDepartmentById } from "@services/department";
import { Department } from "@models/department";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import classes from "../cad-incident-details/cad.module.css";
import { useLoading } from "@hooks/useLoading";
import moment from "moment";
import { CadSimulatorComments } from "./cad-simulator-comments";
import { CadSimulatorUnits } from "./cad-simulator-units";
import { CADSimulatorTabs } from "@models/routing";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";

// INFO: This duplicate of (edit-cad-simulator-canned) should be removed after dedicated endpoints are created

export function CadCannedSimulatorComments(
  {
    sequence,
    departmentData,
    user,
    currentSequence,
    setNewComments,
  }
    : {
      sequence: Sequence,
      departmentData: Department | undefined,
      user: SavedUser | null,
      currentSequence: number,
      setNewComments: (comment: CADComment) => void
    },
) {
  const [commentSource, setCommentSource] = useState<string>("SC");
  const [commentText, setCommentText] = useState<string>("");

  useEffect(() => {
    const source = user?.email?.split("@")[0] ?? "";
    if (source !== "") {
      setCommentSource(source);
    }
  }, [user]);

  if (!departmentData) {
    return <div />;
  }

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.section_header} style={{ border: "none", color: "white" }}>
        <p className={styles.section_name}>
          Comments
        </p>
        <span className={styles.data_length}>
          {sequence.comments.length}
        </span>
      </div>
      <div className={styles.comments_section}>
        <div className={styles.button_container}>
          <IconButton
            style={{ padding: 0, color: "white" }}
            onClick={() => {
              setNewComments({
                CommentSource: commentSource,
                Comment: commentText,
                CommentDateTime: moment(new Date()).format(),
              });
              setCommentText("");
            }}
            type="submit"
          >
            <AddCircleIcon />
          </IconButton>
        </div>
        <TextField
          variant="outlined"
          className={styles.textField}
          style={{ marginLeft: 15 }}
          value={commentText}
          onChange={(el) => setCommentText(el.target.value)}
        />
      </div>
      <div className={styles.comments_wrapper} style={{ maxHeight: 350 }}>
        <div
          key={sequence.sequenceId}
          style={{
            opacity: (sequence.sequenceId === currentSequence ? 1 : 0.5),
            padding: "10px 20px",
          }}
        >
          <Typography style={{ backgroundColor: "grey", color: "white", padding: "5px 10px" }}>{`${sequence.title}`}</Typography>
          <ul style={{ listStyleType: "none", margin: "10px 0px" }}>
            {[...sequence.comments].reverse().map((comment, index) => (
              <li
                // eslint-disable-next-line react/no-array-index-key
                key={comment.source + comment.comment + sequence.alarm + index}
                style={{
                  color: getWordPriorityColor(departmentData.safetyPriorityKeywords, comment.comment ?? "").text,
                  backgroundColor: getWordPriorityColor(departmentData.safetyPriorityKeywords, comment.comment ?? "").background,
                  marginBottom: 5,
                }}
              >
                {`[${comment.source}]: ${comment.comment ?? "Comment not available"}`}
              </li>
            ))}
          </ul>
        </div>
        {/* {sequence.map((comment) => <div>{comment.}</div>)} */}
      </div>
    </div>
  );
}

export function CadCannedSimulatorUnits(
  {
    sequence,
    currentSequence,
    setNewUnits,
    unitStatus,
    unitAlarm,
  }
    : {
      sequence: Sequence,
      currentSequence: number,
      setNewUnits: (newUnits: IncidentUnit[]) => void
      unitStatus: string,
      unitAlarm: string,
    },
) {
  const [unitName, setUnitName] = useState<string>("");
  const [error, setError] = useState(false);

  const handleSubmitNewUnits = () => {
    const unitNameSplit = Array.from(new Set(unitName.split(",").map((unit) => unit.trim())));
    const parsedUnits = unitNameSplit.map((unit) => (
      {
        UnitID: unit,
        UnitDispatchNumber: `${unit}-${moment().unix()}`,
        [unitStatus as string]: moment(new Date()).format(),
        AlarmAtDispatch: unitAlarm,
      }
    ));
    setNewUnits(parsedUnits);
    setUnitName("");
  };

  useEffect(() => {
    let error = false;
    if (unitName.length >= 1) {
      const splitUnits = unitName.split(",").map((value) => value.trim());
      error = detectDuplicateUnits(
        splitUnits.map((el) => el.toLocaleLowerCase()) as string[],
        sequence.unitsConfig.units.map((el) => el.toLocaleLowerCase()),
      ) || !!splitUnits.filter((unit) => !checkUTF8Characters(unit)).length;
    }
    setError(error);
  }, [unitName, sequence]);

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.section_header} style={{ border: "none", color: "white" }}>
        <p className={styles.section_name}>
          Units
        </p>
        <span className={styles.data_length}>
          {sequence.unitsConfig?.units.length}
        </span>
      </div>
      <div className={styles.comments_section} style={{ columnGap: 15 }}>
        <div className={styles.button_container} style={{ backgroundColor: error ? "grey" : "#bc7b7f" }}>
          <IconButton
            style={{ padding: 0, color: error ? "lightgrey" : "white" }}
            disabled={error}
            onClick={handleSubmitNewUnits}
            type="submit"
          >
            <AddCircleIcon />
          </IconButton>
        </div>
        <div style={{
          display: "flex", flexDirection: "column", rowGap: 5, width: "100%",
        }}
        >
          <TextField
            variant="outlined"
            className={styles.textField}
            value={unitName}
            error={error}
            onChange={(el) => setUnitName(el.target.value)}
          />
          {error && <Typography className="errorContainer" style={{ margin: 0 }}>Invalid unit</Typography>}
        </div>
      </div>
      <div className={styles.comments_wrapper} style={{ maxHeight: 350 }}>
        <div
          key={sequence.alarm}
          style={{
            opacity: (sequence.sequenceId === currentSequence ? 1 : 0.5),
            padding: "10px 20px",
            marginBottom: 30,
          }}
        >
          <Typography style={{ backgroundColor: "grey", color: "white", padding: "5px 10px" }}>{`${sequence.title} (Alarm Level ${sequence.alarm})`}</Typography>
          <div style={{ listStyleType: "none", margin: "10px 0px" }}>
            {sequence.unitsConfig && [...sequence.unitsConfig.units].map((unit, index) => (
              <span
                // eslint-disable-next-line react/no-array-index-key
                key={unit + index}
                className={classes.cadUnitChild}
              >
                {unit}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export function EditNewCadCannedSimulator() {
  useTitle("CAD Canned Simulator");
  const { incidentId } = useParams<{ incidentId: "" }>();
  const navigate = useNavigate();
  const [user] = useContext(LoginContext);
  const { data: cadSimulatedIncident } = useDataWithoutLoading((as) => getCannedSimulationById(incidentId!, as));
  const { data: departmentData } = useDataWithoutLoading((as) => getDepartmentById(user?.departmentId, as));
  const [currentSequence, setCurrentSequence] = useState<number>(0);
  const [displaySequence, setDisplaySequence] = useState<Sequence>();
  const { withLoading } = useLoading();
  const [units, setUnits] = useState<IncidentUnit[]>([]);
  const [comments, setComments] = useState<CADComment[]>([]);
  const [formOpen, setFormOpen] = useState<boolean>(true);
  const [unitAlarm, setUnitAlarm] = useState("");
  const [unitStatus, setUnitStatus] = useState("TimeDispatched");

  const syncUnitAlarm = (alarm: string) => {
    setUnitAlarm(alarm);
  };

  const syncUnitStatus = (status: string) => {
    setUnitStatus(status);
  };

  const [initialValues, setInitialValues] = useState<SimulatorIncident>({
    IncidentNumber: "",
    EntryDateTime: "",
    ClosedDateTime: "",
    expiration_date: "",
    AgencyID: "",
    CADSimulator: "",
    TransactionID: 0,
    closeIn: 600,
    StreetNumber: "",
    StreetName: "",
    CallerNumber: "",
    CommandChannel: "",
    Suite: "",
    CityOrLocality: "",
    StateOrProvince: "",
    CommonPlaceName: "",
    cross_streets: "",
    AgencyIncidentCallTypeDescription: "",
    Latitude: "",
    Longitude: "",
    FireMap: "",
    MapPages: "",
    TacticalChannel: "",
    units: [],
    Comment: [],
    notify: false,
    rts: true,
    simulation: true,
    callers: [],
  });

  const keys = getKeys<SimulatorIncident>({
    IncidentNumber: 1,
    EntryDateTime: 1,
    ClosedDateTime: 1,
    expiration_date: 1,
    CallerNumber: 1,
    AgencyID: 1,
    TransactionID: 1,
    CommonPlaceName: 1,
    CommandChannel: 1,
    FireMap: 1,
    CityOrLocality: 1,
    cross_streets: 1,
    MapPages: 1,
    AgencyIncidentCallTypeDescription: 1,
    CADSimulator: 1,
    Latitude: 1,
    Suite: 1,
    Longitude: 1,
    StateOrProvince: 1,
    StreetName: 1,
    StreetNumber: 1,
    TacticalChannel: 1,
    closeIn: 1,
    units: 1,
    Comment: 1,
    notify: 1,
    rts: 1,
    simulation: 1,
    simulationId: 1,
    callers: 1,
    simulationSequences: 0,
  });

  const setNewComments = useCallback((comment: CADComment) => {
    setComments([...comments, comment]);
  }, [initialValues, comments]);

  const setNewUnits = useCallback((newUnits: IncidentUnit[]) => {
    // Here we gather units from current display sequence and all unitsID from the side added units
    const unitsName = units.map((unit) => unit.UnitID);
    const mergedUnitsName = [...unitsName, ...(displaySequence?.unitsConfig.units ?? [])];
    const filteredUnits = newUnits.filter((unit) => !mergedUnitsName.includes(unit.UnitID));
    setUnits([...units, ...filteredUnits]);
  }, [initialValues, units, displaySequence]);

  useEffect(() => {
    const newDisplaySequence = cadSimulatedIncident?.sequences.find((sequence) => sequence.sequenceId === currentSequence);
    setDisplaySequence(newDisplaySequence);
  }, [currentSequence, cadSimulatedIncident]);

  useEffect(() => {
    if (cadSimulatedIncident) {
      const closeTime = cadSimulatedIncident.closeDelay ? Number(cadSimulatedIncident.closeDelay) : 600;
      let closeInValue = 600;
      // Check if in between 1 minute and 3 hours
      if ((closeTime / 60) <= 180 && (closeTime / 60) >= 1) {
        closeInValue = closeTime;
      }
      setInitialValues({
        ...initialValues,
        simulationId: cadSimulatedIncident._id ?? "",
        rts: cadSimulatedIncident.rts,
        simulation: cadSimulatedIncident.simulation,
        AgencyID: "WEBTEST",
        AgencyIncidentCallTypeDescription: cadSimulatedIncident.title,
        CADSimulator: cadSimulatedIncident.CADSimulator,
        CityOrLocality: cadSimulatedIncident.city,
        closeIn: closeInValue,
        CommandChannel: cadSimulatedIncident.commandChannel,
        cross_streets: cadSimulatedIncident.CrossStreet1,
        CommonPlaceName: cadSimulatedIncident.locationComment,
        EntryDateTime: cadSimulatedIncident.EntryDateTime,
        FireMap: cadSimulatedIncident.firemap,
        Latitude: cadSimulatedIncident.lat,
        Longitude: cadSimulatedIncident.lon,
        MapPages: cadSimulatedIncident.mapPages,
        notify: cadSimulatedIncident.notify,
        StateOrProvince: cadSimulatedIncident.state,
        StreetName: cadSimulatedIncident.streetName,
        Suite: cadSimulatedIncident.suite,
        TacticalChannel: cadSimulatedIncident.tacticalChannel,
        IncidentNumber: `TEST-${generateSecretKey(6)}`,
        expiration_date: moment(cadSimulatedIncident.EntryDateTime).add(cadSimulatedIncident.closeDelay / 60, "m").format(),
        CallerNumber: cadSimulatedIncident.CallerNumber,
      });
    }
  }, [cadSimulatedIncident]);

  useEffect(() => {
    if (!currentSequence.toString() && cadSimulatedIncident) {
      const activeSequence = cadSimulatedIncident?.sequences[0].sequenceId;
      setCurrentSequence(activeSequence ?? 0);
    }
  }, [cadSimulatedIncident]);

  const onSubmit = withLoading(
    "loading...",
    async (obj: SimulatorIncident, onSubmitProps: FormikHelpers<SimulatorIncident>) => {
      const displayUnits = displaySequence?.unitsConfig?.units.map(((unit) => {
        const unitForDisplay: IncidentUnit = {
          UnitID: unit,
          UnitDispatchNumber: unit,
          TimeDispatched: moment(new Date()).format(),
          active: true,
        };
        if (displaySequence.alarm) {
          unitForDisplay.AlarmAtDispatch = displaySequence.alarm;
        }
        return unitForDisplay;
      })) ?? [];
      const displayComments = displaySequence?.comments.map((comment) => ({
        Comment: comment.comment ?? "Comment not available",
        CommentSource: comment.source,
        CommentDateTime: moment(new Date()).format(),
      } as CADComment)) ?? [];
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { units: removedUnits, ...payload } = obj;
      const parsedValues = {
        ...payload,
        simulationSequences: [currentSequence],
        EntryDateTime: moment(new Date()).format(),
        Unit: [...displayUnits, ...units],
        Comment: [...displayComments, ...comments],
      };
      await postSimulatorIncident(parsedValues);
      // TODO: For now we do a double query to get back the ID so we can redirect, this will change in the future.
      const newIncidentId = await getCADIncidentById(obj.IncidentNumber ?? "");
      navigate(`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADSimulatorCannedEdit, { incidentId: newIncidentId.id })}`);
      onSubmitProps.setSubmitting(false);
    },
  );

  // TODO: This should take into account edited units.
  const handleQuickLaunch = withLoading(
    "loading...",
    async (obj: SimulatorIncident) => {
      // TODO: Make those with reducers
      const displayUnits = displaySequence?.unitsConfig?.units.map(((unit) => {
        const unitForDisplay: IncidentUnit = {
          UnitID: unit,
          UnitDispatchNumber: unit,
          TimeDispatched: moment(new Date()).format(),
          active: true,
        };
        if (displaySequence.alarm) {
          unitForDisplay.AlarmAtDispatch = displaySequence.alarm;
        }
        return unitForDisplay;
      })) ?? [];
      const displayComments = displaySequence?.comments.map((comment) => ({
        Comment: comment.comment ?? "Comment not available",
        CommentSource: comment.source,
        CommentDateTime: moment(new Date()).format(),
      } as CADComment)) ?? [];
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { units: removedUnits, ...payload } = obj;
      const parsedValues = {
        ...payload,
        simulationSequences: [currentSequence],
        EntryDateTime: moment(new Date()).format(),
        Unit: [...displayUnits, ...units],
        Comment: [...displayComments, ...comments],
      };
      await postSimulatorIncident(parsedValues);
      // TODO: For now we do a double query to get back the ID so we can redirect, this will change in the future.
      const newIncidentId = await getCADIncidentById(obj.IncidentNumber ?? "");
      navigate(`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADSimulatorCannedEdit, { incidentId: newIncidentId.id })}`);
    },
  );

  const {
    values, handleChange, setFieldValue, handleReset, handleSubmit, isSubmitting,
  } = useFormik<SimulatorIncident>({ initialValues, onSubmit, enableReinitialize: true });

  if (!cadSimulatedIncident) {
    return <div />;
  }

  return (
    <div style={{ position: "relative" }}>
      <div
        className={styles.canned_simulator_buttons_wrapper}
      >
        {cadSimulatedIncident.sequences.map((sequence) => (
          <TabletButton
            key={sequence.sequenceId}
            onClick={() => {
              if (currentSequence !== sequence.sequenceId) {
                setCurrentSequence(sequence.sequenceId);
              } else {
                handleQuickLaunch(values);
              }
            }}
            style={{
              backgroundColor: currentSequence === sequence.sequenceId ? "#56A28D" : "#d47376",
              width: "30%",
              minWidth: 250,
            }}
          >
            <Typography>
              {`${currentSequence === sequence.sequenceId ? "Launch" : ""} ${sequence.title ? sequence.title : ("Sequence " + sequence.sequenceId)}`}
            </Typography>
          </TabletButton>
        ))}
      </div>
      <div>
        <form onReset={handleReset} onSubmit={handleSubmit}>
          <Grid container spacing={3} style={{ width: "100%", margin: 0 }}>
            <div style={{ width: "100%" }}>
              <div className={styles.incident_details_section_wrapper}>
                <div style={{ minWidth: 120, textAlign: "right", marginRight: 10 }}>
                  <Typography>{`Incident Number: ${values.IncidentNumber}`}</Typography>
                </div>
                <IconButton onClick={() => setFormOpen(!formOpen)} style={{ padding: 0, color: "black" }}>{formOpen ? <KeyboardArrowDown /> : <KeyboardArrowRight />}</IconButton>
              </div>
              <Grid container spacing={1}>
                <Grid item xs={12} lg={6}>
                  {formOpen && (
                    <div>
                      <div className={styles.search_widget_container}>
                        <div id="searchWidget" />
                      </div>
                      <CadSimulatorField handleChange={handleChange} fieldKey={keys.AgencyIncidentCallTypeDescription} value={values.AgencyIncidentCallTypeDescription} label="Call Type" />
                      <CadSimulatorField handleChange={handleChange} fieldKey={keys.StreetName} value={values.StreetName} label="Street" />
                      <CadSimulatorField handleChange={handleChange} fieldKey={keys.Suite} value={values.Suite} label="Apt/Suite" />
                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexWrap: "wrap",
                        width: "100%",
                      }}
                      >
                        <Grid item xs={12} sm={7} lg={7}>
                          <CadSimulatorField handleChange={handleChange} fieldKey={keys.CityOrLocality} value={values.CityOrLocality} label="City" />
                        </Grid>
                        <Grid item xs={12} sm={5} lg={5}>
                          <CadSimulatorField handleChange={handleChange} fieldKey={keys.StateOrProvince} value={values.StateOrProvince} label="State" />
                        </Grid>
                      </div>
                      <CadSimulatorField handleChange={handleChange} fieldKey={keys.CommonPlaceName} value={values.CommonPlaceName} label="Common Name" />
                      <CadSimulatorField handleChange={handleChange} fieldKey={keys.cross_streets} value={values.cross_streets} label="Cross Streets" />
                      <CadSimulatorField handleChange={handleChange} fieldKey={keys.CallerNumber} value={values.CallerNumber} label="Caller Number" />
                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                      >
                        <Grid item xs={12} sm={6} lg={6}>
                          <CadSimulatorField handleChange={handleChange} fieldKey={keys.MapPages} value={values.MapPages} label="TB Map" />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <CadSimulatorField handleChange={handleChange} fieldKey={keys.FireMap} value={values.FireMap} label="Fire Map" />
                        </Grid>
                      </div>
                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                      >
                        <Grid item xs={12} sm={6} lg={6}>
                          <CadSimulatorField handleChange={handleChange} fieldKey={keys.TacticalChannel} value={values.TacticalChannel} label="TAC" />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={6}>
                          <CadSimulatorField handleChange={handleChange} fieldKey={keys.CommandChannel} value={values.CommandChannel} label="CMD" />
                        </Grid>
                      </div>
                      <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        verticalAlign: "middle",
                        marginBottom: 15,
                      }}
                      >
                        <div
                          style={{
                            minWidth: 135, textAlign: "right", marginRight: 10,
                          }}
                        >
                          <FormLabel>Close In</FormLabel>
                        </div>
                        <Select
                          variant="outlined"
                          className={`${styles.textField} ${styles.closeIn}`}
                          MenuProps={{ classes: { paper: styles.closeInMenu }, anchorOrigin: { horizontal: "center", vertical: "center" }, transformOrigin: { horizontal: "center", vertical: "center" } }}
                          value={values.closeIn}
                          id={keys.closeIn}
                          onChange={(e) => {
                            setFieldValue(keys.closeIn, (+e.target.value));
                            const closeDate = moment(values.EntryDateTime)
                              .add(e.target.value, "m")
                              .format();
                            setFieldValue(keys.expiration_date, closeDate);
                          }}
                        >
                          {closeInValuesInSeconds.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.FormLabel}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      <Grid item sm={10} xs={12} style={{ marginLeft: 45 }}>
                        <Checkbox
                          id={keys.simulation}
                          onChange={handleChange}
                          checked={values.simulation}
                          name="simulation"
                          color="secondary"
                        />
                        <FormLabel>Simulation</FormLabel>
                        <Checkbox
                          id={keys.rts}
                          onChange={handleChange}
                          checked={values.rts}
                          name="rts"
                          color="secondary"
                        />
                        <FormLabel>RTS</FormLabel>
                        <Checkbox
                          id={keys.notify}
                          onChange={handleChange}
                          checked={values.notify}
                          name="notify"
                          color="secondary"
                        />
                        <FormLabel>Notify</FormLabel>
                      </Grid>
                    </div>
                  )}
                  <Grid item xs={12} container spacing={1} style={{ marginLeft: 52, marginTop: 15 }}>
                    <Grid item>
                      <Button
                        variant="contained"
                        className="cancel-button"
                        color="secondary"
                        onClick={() => navigate(`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADSimulatorTabs, { tab: CADSimulatorTabs.Canned })}`)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        className="save-button"
                        type="submit"
                        endIcon={<CheckIcon />}
                        color="secondary"
                      >
                        Launch
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                {formOpen && (
                  <Grid item xs={12} lg={6}>
                    <CadSimulatorMap
                      values={{
                        AgencyIncidentCallTypeDescription: values.AgencyIncidentCallTypeDescription ?? "",
                        IncidentNumber: values.IncidentNumber ?? "",
                        Latitude: values.Latitude ?? 0,
                        Longitude: values.Longitude ?? 0,
                        callers: values.callers ?? [],
                      }}
                      setFieldValue={setFieldValue}
                    />
                  </Grid>
                )}
              </Grid>
            </div>
          </Grid>
        </form>
      </div>
      {/* // Merge the following components */}
      {
        departmentData && displaySequence
        && (
          <CadCannedSimulatorComments
            sequence={displaySequence}
            departmentData={departmentData}
            user={user}
            currentSequence={currentSequence}
            setNewComments={setNewComments}
          />
        )
      }
      <CadSimulatorComments comments={comments ?? []} isDataCleared={false} submitForm={() => new Promise(() => { })} disableInput />

      {
        displaySequence
        && (
          <CadCannedSimulatorUnits
            sequence={displaySequence}
            currentSequence={currentSequence}
            setNewUnits={setNewUnits}
            unitAlarm={unitAlarm}
            unitStatus={unitStatus}
          />
        )
      }
      <CadSimulatorUnits
        isSubmitting={isSubmitting}
        units={units}
        isDataCleared={false}
        submitForm={() => new Promise(() => { })}
        keys={keys}
        setFieldValue={setFieldValue}
        disableInput
        getAlarm={syncUnitAlarm}
        getStatus={syncUnitStatus}
      />

    </div>
  );
}
