import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { route, urls } from "@utils/routing";
import { componentLoader } from "@utils/utilsFunctions";
import { IncidentsList } from "../pages/admin/incidents/incidents";
import { IncidentDetails } from "../pages/admin/incidents/incident-details";
import AdminMainPage from "../pages/layout/admin/adminMain";
import { EditCadSimulator } from "../pages/admin/cad-simulator/edit-cad-simulator";
import { CadSimulator } from "../pages/admin/cad-simulator/cad-simulator";
import CadSimulatorsList from "../pages/admin/cad-simulator/cad-simulator-tabs";
import { EditCadCannedSimulator } from "../pages/admin/cad-simulator/edit-cad-simulator-canned";
import { EditNewCadCannedSimulator } from "../pages/admin/cad-simulator/edit-new-cad-simulator-canned";
import CADVehiclesStatus from "../pages/admin/cad-status/cad-vehicles-status";
import { AddNewCadCannedSimulator } from "../pages/admin/cad-simulator/add-cad-simulator-canned-form.tsx/add-cad-simulator-canned";
import { EditCADCannedSimulator } from "../pages/admin/cad-simulator/add-cad-simulator-canned-form.tsx/edit-cad-simulator-canned";

const ActionLog = lazy(() => componentLoader(() => import("../pages/admin/action-log"), 5));
const AdminDeviceMapping = lazy(() => componentLoader(() => import("../pages/admin/device-mapping/device-mapping"), 5));
const AdminMaps = lazy(() => componentLoader(() => import("../pages/admin/settings/settings-tabs/maps/maps"), 5));
const Agencies = lazy(() => componentLoader(() => import("../pages/admin/settings/settings-tabs/agencies/agencies"), 5));
const CADIncidentDetails = lazy(() => componentLoader(() => import("../pages/admin/cad-incident-details/cad-incident-details"), 5));
const CADIncidents = lazy(() => componentLoader(() => import("../pages/admin/cad-incidents/cad-incidents"), 5));
const CADSimulator = lazy(() => componentLoader(() => import("../pages/admin/cad-simulator"), 5));
const CADStatus = lazy(() => componentLoader(() => import("../pages/admin/cad-status/cad-status"), 5));
const CADVehicleStatusDetails = lazy(() => componentLoader(() => import("../pages/admin/cad-status/cad-vehicle-status-details"), 5));
const ChartIncidents = lazy(() => componentLoader(() => import("../pages/admin/charts/incidents-chart"), 5));
const ChartManagedIncidents = lazy(() => componentLoader(() => import("../pages/admin/charts/managed-incidents-chart"), 5));
const ChartUsers = lazy(() => componentLoader(() => import("../pages/admin/charts/users-chart"), 5));
const ChecklistItems = lazy(() => componentLoader(() => import("../pages/admin/checklists-items"), 5));
const Checklists = lazy(() => componentLoader(() => import("../pages/admin/checklists"), 5));
const Dashboard = lazy(() => componentLoader(() => import("../pages/admin/dashboard/dashboard"), 5));
const EditMessage = lazy(() => componentLoader(() => import("../pages/admin/messages/messages-edit"), 5));
const IncidentsLeftOpen = lazy(() => componentLoader(() => import("../pages/admin/incidents-left-open/incidents-left-open"), 5));
const LastLoggedIn = lazy(() => componentLoader(() => import("../pages/admin/last-logged-in"), 5));
const Location = lazy(() => componentLoader(() => import("../pages/admin/location/location"), 5));
const ManagedIncidentDetails = lazy(() => componentLoader(() => import("../pages/admin/tc-incident-details/tc-incident-details"), 5));
const ManagedIncidentLargeScreenView = lazy(() => componentLoader(() => import("../pages/admin/managed-incidents/managed-incidents-large-screen-view"), 5));
const ManagedIncidentList = lazy(() => componentLoader(() => import("../pages/admin/managed-incidents-list"), 5));
const MapOverview = lazy(() => componentLoader(() => import("../pages/admin/map-overview/map-overview"), 5));
const Messages = lazy(() => componentLoader(() => import("../pages/admin/messages/messages"), 5));
const MessagesAdd = lazy(() => componentLoader(() => import("../pages/admin/messages/messages-add"), 5));
const OnlineUsers = lazy(() => componentLoader(() => import("../pages/admin/online-users"), 5));
const Resources = lazy(() => componentLoader(() => import("../pages/admin/resources"), 5));
const Personnel = lazy(() => componentLoader(() => import("../pages/admin/personnel"), 5));
const ResourceUnits = lazy(() => componentLoader(() => import("../pages/admin/resource-item/resource-units"), 5));
const Settings = lazy(() => componentLoader(() => import("../pages/admin/settings/settings"), 5));
const Tasks = lazy(() => componentLoader(() => import("../pages/admin/tasks"), 5));
const TemplateForm = lazy(() => componentLoader(() => import("../pages/admin/templates/template-form"), 5));
const Templates = lazy(() => componentLoader(() => import("../pages/admin/templates/templates"), 5));
const UnitStaffing = lazy(() => componentLoader(() => import("../pages/admin/unit-staffing/unit-staffing"), 5));
const UserDeviceLogs = lazy(() => componentLoader(() => import("../pages/admin/user-device-logs"), 5));
const Users = lazy(() => componentLoader(() => import("../pages/admin/users/users"), 5));
const ReleaseNotes = lazy(() => componentLoader(() => import("../pages/release-notes/release-notes"), 5));
const UserSignUpForm = lazy(() => componentLoader(() => import("../pages/admin/user-signup/user-signup-form"), 5));
const EditUserAccount = lazy(() => componentLoader(() => import("../pages/edit-user/edit-user-account"), 5));

export default function AuthenticatedAdmin() {
  return (
    <Routes>
      {/*
                    Order of these is important...
                    if the routes use the same path,
                    place the shortest one last.
                    */}
      <Route path="/" element={<AdminMainPage />}>
        <Route path={route(urls.adminCADSimulatorEdit, ["incidentId"])} element={<EditCadSimulator />} />
        <Route path={route(urls.adminCADSimulatorCannedEdit, ["incidentId"])} element={<EditCadCannedSimulator />} />
        <Route path={route(urls.adminCADSimulatorCannedEditNew, ["incidentId"])} element={<EditNewCadCannedSimulator />} />
        <Route path={route(urls.adminCADSimulatorTabs, ["tab"])} element={<CadSimulatorsList />} />
        <Route path={route(urls.adminIncidentDetails, ["incidentNumber"])} element={<IncidentDetails />} />
        <Route path={route(urls.adminIncidentsList, ["tab"])} element={<IncidentsList />} />
        <Route path={route(urls.adminMessageEdit, ["departmentId", "messageId"])} element={<EditMessage />} />
        <Route path={route(urls.adminManagedIncidentDetails, ["incidentNumber"])} element={<ManagedIncidentDetails />} />
        <Route path={route(urls.adminManagedIncidentLargeScreenView, ["incidentNumber"])} element={<ManagedIncidentLargeScreenView />} />
        <Route path={route(urls.adminManagedIncidentsList, ["tab"])} element={<ManagedIncidentList />} />
        <Route path={route(urls.adminCADIncidentDetails, ["incidentNumber"])} element={<CADIncidentDetails />} />
        <Route path={route(urls.adminCADIncidents, ["tab"])} element={<CADIncidents />} />
        <Route path={route(urls.adminCADSimulator)} element={<CADSimulator />} />
        <Route path={route(urls.adminCADSimulatorNew)} element={<CadSimulator />} />
        <Route path={route(urls.adminCADSimulatorCannedNew)} element={<AddNewCadCannedSimulator />} />
        <Route path={route(urls.adminCADSimulatorCannedEditTemplate, ["incidentId"])} element={<EditCADCannedSimulator />} />
        <Route path={route(urls.adminChecklistItem, ["id"])} element={<ChecklistItems />} />
        <Route path={route(urls.adminChecklists)} element={<Checklists />} />
        <Route path={route(urls.adminResourcesUnit, ["id"])} element={<ResourceUnits />} />
        <Route path={route(urls.adminResources)} element={<Resources />} />
        <Route path={route(urls.adminPersonnel)} element={<Personnel />} />
        <Route path={route(urls.adminTasks)} element={<Tasks />} />
        <Route
          path={route(urls.adminTemplateAdd)}
          element={<TemplateForm />}
        />
        <Route
          path={route(urls.adminTemplateEdit, ["templateId"])}
          element={<TemplateForm />}
        />
        <Route path={route(urls.adminTemplate)} element={<Templates />} />
        <Route path={route(urls.adminIncidentsLeftOpen)} element={<IncidentsLeftOpen />} />
        <Route path={route(urls.adminLastLoggedIn)} element={<LastLoggedIn />} />
        <Route path={route(urls.adminActionLog)} element={<ActionLog />} />
        <Route path={route(urls.adminDeviceMapping)} element={<AdminDeviceMapping />} />
        <Route path={route(urls.adminAgencies)} element={<Agencies />} />
        <Route path={route(urls.adminMaps)} element={<AdminMaps />} />
        <Route path={route(urls.adminSettings)} element={<Settings />} />
        <Route
          path={route(urls.adminUsersSignup, ["signupKey", "departmentId"])}
          element={<UserSignUpForm />}
        />
        <Route
          path={route(urls.adminUnitStaffing)}
          element={<UnitStaffing />}
        />
        <Route path={route(urls.adminLocation)} element={<Location />} />
        <Route path={route(urls.adminCADStatus, ["tab"])} element={<CADStatus />} />
        <Route path={route(urls.adminCADVehicleStatusDetails, ["vehicleId"])} element={<CADVehicleStatusDetails />} />
        <Route path={route(urls.adminUsers)} element={<Users />} />
        <Route
          path={route(urls.adminEditUsers, ["userId"])}
          element={<EditUserAccount />}
        />
        <Route path={route(urls.adminMapOverview)} element={<MapOverview />} />
        <Route path={route(urls.adminMessagesAdd, ["id"])} element={<MessagesAdd />} />
        <Route path={route(urls.adminMessages)} element={<Messages />} />
        <Route path={route(urls.adminRemoteLogs)} element={<UserDeviceLogs />} />
        <Route path={route(urls.dashboard)} element={<Dashboard />} />
        <Route path={route(urls.adminOnlineUsers)} element={<OnlineUsers />} />
        <Route path={route(urls.adminChartIncidents)} element={<ChartIncidents />} />
        <Route path={route(urls.adminChartManagedIncidents)} element={<ChartManagedIncidents />} />
        <Route path={route(urls.adminChartUsers)} element={<ChartUsers />} />
        <Route path={route(urls.userVehiclesStatus)} element={<CADVehiclesStatus restricted />} />

        <Route path={route(urls.releaseNotes)} element={<ReleaseNotes />} />
      </Route>
    </Routes>
  );
}
