import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";

import { useLocations } from "@hooks/arcgis/useLocations";
import { useGraphics } from "@hooks/arcgis/useGraphics";
import { ArcGISMapMapContext } from "@contexts/arcgis-map-context";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils.js";
import {
  ArcGISHazard,
  ArcGIsIncidentGroup,
  ArcGIsUnassignedUnit,
  ILoadViewOptions,
  IncidentLocation,
  MapDetails,
  MapUnit,
} from "@models/arcgis";
import { UserMapSettings } from "@models/user-web-map";
import { DeviceMapping } from "@models/device-mapping";

import spinner from "../../pages/img/spinner.svg";
import { mapUtils } from "./mapUtils";
import { useEffectAsync } from "@hooks/utils";
import { useExtendedView } from "@hooks/arcgis/useExtendedView";
import { useView } from "@hooks/arcgis/useView";
import Point from "@arcgis/core/geometry/Point";
import { debounce } from "@utils/utilsFunctions";
import { Caller } from "@models/cad";

const mapLoaderId = "arcgis-map-loader-id";

interface WebMapInterface {
  incidents: IncidentLocation[],
  avlMarkers: MapUnit[],
  incidentCenter: [number, number],
  height: number,
  incidentZoom: number,
  zoomToIncident: boolean,
  unassignedUnitsOnMapMarkers?: ArcGIsUnassignedUnit[],
  resetMapView: () => void,
  refetchData?: (props: MapDetails) => void,
  sharedAVLMarkers?: MapUnit[],
  unmappedDevices?: DeviceMapping[],
  hazards?: ArcGISHazard[],
  callers?: Caller[],
  incidentGroupsMarkers?: ArcGIsIncidentGroup[],
  resetButton?: boolean,
  noStoreData?: boolean,
  centerOnIncident?: boolean,
  startStoreOnDrag?: boolean,
  sharedAVLButton?: boolean,
  hasFullscreenButton?: boolean,
  hasSideMenuButton?: boolean,
  onMarkerClickHandlers?: ((result: __esri.ViewHit) => void)[],
  mapViewRef?: React.MutableRefObject<string>
  getSearchResults?: (resultsFeatures: __esri.Graphic) => void,
}

export const WebMap = memo(({
  // Incident red point markers
  incidents,
  // Unit markers
  avlMarkers,
  // Unit markers shared across agencies
  sharedAVLMarkers = [],
  //
  unmappedDevices = [],
  // Markers for potentially dangerous areas
  hazards = [],
  // Incident Callers
  callers = [],
  // Markers for a groups of units
  incidentGroupsMarkers = [],
  // Unit markers at an incident that were not assigned
  unassignedUnitsOnMapMarkers = [],
  // Center of an incident
  incidentCenter,
  // Height of the map inside the DOM
  height,
  // The zoom at which the maps is loaded
  incidentZoom,
  //
  zoomToIncident,
  // Button to reset the view
  resetButton,
  // Data is not stored in local storage
  noStoreData,
  // Center on incident coordinates
  centerOnIncident,
  // Store map position for re initialization
  startStoreOnDrag,
  // Button to enable shared AVL units query
  sharedAVLButton,
  // Button to enable fullscreen view
  hasFullscreenButton,
  // Button to enable side map container
  hasSideMenuButton,
  // Handlers for on click events
  onMarkerClickHandlers,
  // Function for the reset view handler
  resetMapView,
  // Function to handle search widget results
  getSearchResults,
  // Function to handle map movement for on change queries
  refetchData,
  // Container for map screenshot as soon as it loads
  mapViewRef,
}: WebMapInterface) => {
  const [noStoreDataBeforeDrag, setNoStoreDataBeforeDrag] = useState(startStoreOnDrag);
  const [mapSettings, setMapSettings] = useContext(ArcGISMapMapContext);
  const [mapCenter, setMapCenter] = useState(centerOnIncident);

  const options: ILoadViewOptions = useMemo(() => {
    const defaultViewReturns = {
      center: incidentCenter.map((position) => ((typeof position === "number") && !Number.isNaN(position) ? position : 0)),
      zoom: incidentZoom,
      userDragMap: mapSettings.isMapDragged,
      centerOnIncident: mapCenter,
      zoomToIncident,
      resetButton,
      sharedAVLButton,
      hasFullscreenButton,
      hasSideMenuButton,
      noStoreData,
      resetMapView,
      getSearchResults,
      refetchData,
    };
    if (!(zoomToIncident || noStoreData || noStoreDataBeforeDrag)) {
      const userInfo = localStorage.getItem("userMapSettings");
      if (userInfo !== null) {
        const userSettings: UserMapSettings = JSON.parse(userInfo);
        return {
          view: {
            ...defaultViewReturns,
            center: Object.keys(userSettings.center).length > 0
              ? [
                ((typeof userSettings.center.longitude === "number") && !Number.isNaN(userSettings.center.longitude) ? userSettings.center.longitude : 0),
                ((typeof userSettings.center.latitude === "number") && !Number.isNaN(userSettings.center.latitude) ? userSettings.center.latitude : 0),
              ] : [0, 0],
            zoom: userSettings.zoom,
          },
        };
      }
    }
    return {
      view: defaultViewReturns,
    };
  }, [
    incidentCenter,
    incidentZoom,
    mapSettings.isMapDragged,
    mapCenter,
    zoomToIncident,
    resetButton,
    sharedAVLButton,
    noStoreDataBeforeDrag,
    hasFullscreenButton,
    hasSideMenuButton,
    noStoreData,
  ]);

  const [ref, view] = useView(options);

  const {
    incidentsGraphics,
    avlUnitsGraphics,
    sharedAVLGraphics,
    hazardGraphics,
    callersGraphics,
    incidentGroupsGraphics,
    unassignedUnitsGraphics,
    unmappedDeviceGraphics,
  } = useLocations(
    incidents,
    avlMarkers,
    sharedAVLMarkers,
    hazards,
    callers,
    unmappedDevices,
    incidentGroupsMarkers,
    unassignedUnitsOnMapMarkers,
  );

  useGraphics(
    view,
    incidentsGraphics,
    avlUnitsGraphics,
    sharedAVLGraphics,
    hazardGraphics,
    callersGraphics,
    incidentGroupsGraphics,
    unassignedUnitsGraphics,
    unmappedDeviceGraphics,
  );

  useExtendedView(view, [...avlUnitsGraphics, ...incidentsGraphics], noStoreData);

  useEffect(() => {
    setMapCenter(true);
    setMapSettings({
      ...mapSettings,
      isMapDragged: false,
    });
  }, [centerOnIncident]);

  useEffectAsync(async () => {
    // Take screenshot only if map is ready and no data is present in ref
    if (view.ready && mapViewRef?.current.length === 0) {
      setTimeout(async () => {
        mapViewRef.current = (await view.takeScreenshot({ width: 505, height: 400 })).dataUrl;
      }, 1000);
    }
  }, [view.ready, mapViewRef]);

  useEffect(() => {
    if (zoomToIncident) {
      setMapSettings({
        ...mapSettings,
        isMapDragged: false,
      });
    }
  }, [zoomToIncident]);

  // INFO: Map event handlers start here
  useEffect(() => {
    const dragWatchHandler = view.on("drag", () => {
      if (!mapSettings.isMapDragged && !zoomToIncident) {
        setMapSettings({
          ...mapSettings,
          isMapDragged: true,
        });
        setMapCenter(false);
      }
      if (noStoreDataBeforeDrag) {
        setNoStoreDataBeforeDrag(false);
      }
    });
    return () => dragWatchHandler.remove();
  }, [zoomToIncident, mapSettings, view]);

  const handleRefetchOfUnits = useCallback(() => {
    if (!!refetchData && view.extent) {
      const boxMaxPoints = new Point({
        x: view.extent.xmax,
        y: view.extent.ymax,
        spatialReference: view.extent.spatialReference,
      });
      const diagonalDistance = geometryEngine.distance(view.extent.center, boxMaxPoints);
      if (diagonalDistance && view.center?.latitude && view.center?.longitude) {
        refetchData({
          zoom: view?.zoom,
          latitude: Number(view?.center?.latitude?.toFixed(6) ?? "0"),
          longitude: Number(view?.center?.longitude?.toFixed(6) ?? "0"),
          diagonal: Math.round(diagonalDistance),
        });
      }
      return boxMaxPoints;
    }
    return null;
  }, [view.center?.latitude, view.center?.longitude, refetchData, view.extent]);

  useEffect(() => {
    const dragWatchHandler = reactiveUtils.on(() => view, "drag", debounce(() => {
      const resultingPoint = handleRefetchOfUnits();
      if (resultingPoint) {
        resultingPoint.destroy();
      }
    }));
    return () => { dragWatchHandler.remove(); };
  }, [view, refetchData]);

  useEffect(() => {
    const zoomWatchHandler = reactiveUtils.watch(() => view.zoom, debounce(() => {
      if (Number.isInteger(view.zoom)) {
        const resultingPoint = handleRefetchOfUnits();
        if (resultingPoint) {
          resultingPoint.destroy();
        }
      }
    }));
    return () => { zoomWatchHandler.remove(); };
  }, [view, refetchData]);

  useEffect(() => {
    const zoomWatchHandler = reactiveUtils.watch(() => view.zoom, () => {
      if (!mapSettings.isMapDragged && !zoomToIncident) {
        setMapSettings({
          ...mapSettings,
          isMapDragged: true,
        });
        setMapCenter(false);
      }
      if (noStoreDataBeforeDrag) {
        setNoStoreDataBeforeDrag(false);
      }
    });
    return () => { zoomWatchHandler.remove(); };
  }, [zoomToIncident, mapSettings, view]);

  useEffect(() => {
    const clickWatchHandler = view.on("click", (event) => {
      if (onMarkerClickHandlers?.length) {
        view.hitTest(event)
          .then((hitTestResult) => {
            if (hitTestResult.results.length) {
              // We get the first result and apply the handler on it.
              onMarkerClickHandlers.forEach((clickHandler) => clickHandler(hitTestResult.results[0]));
            }
          });
      }
    });
    return () => clickWatchHandler.remove();
  }, [view, unassignedUnitsOnMapMarkers]);

  useEffect(() => {
    const readyHandler = reactiveUtils.watch(() => view.ready, (event) => {
      if (event) {
        document.getElementById(mapLoaderId)?.remove();
      }
    });
    return () => readyHandler.remove();
  }, [view]);

  return (
    <div style={{ height, position: "relative" }} ref={ref}>
      {/* INFO: Loading spinner for map -> ready when all initial requests are completed */}
      <img
        src={spinner}
        id={mapLoaderId}
        alt="Loading"
        className="arcgis_map_loader_spinner"
        title="Loading"
      />
    </div>
  );
}, (prev, next) => (
  JSON.stringify(prev.incidentCenter) === JSON.stringify(next.incidentCenter)
  && prev.height === next.height
  && prev.centerOnIncident === next.centerOnIncident
  && prev.startStoreOnDrag === next.startStoreOnDrag
  && prev.onMarkerClickHandlers === next.onMarkerClickHandlers
  && prev.resetMapView === next.resetMapView
  && prev.getSearchResults === next.getSearchResults
  && prev.refetchData === next.refetchData
  && prev.zoomToIncident === next.zoomToIncident
  && prev.resetButton === next.resetButton
  && prev.sharedAVLButton === next.sharedAVLButton
  && prev.hasFullscreenButton === next.hasFullscreenButton
  && prev.hasSideMenuButton === next.hasSideMenuButton
  && prev.incidentZoom === next.incidentZoom
  && prev.noStoreData === next.noStoreData
  && mapUtils.checkIncidentsDifferences(prev.incidents, next.incidents)
  && mapUtils.checkAVLMarkersDifferences(prev.avlMarkers, next.avlMarkers)
  && mapUtils.checkAVLMarkersDifferences(prev.sharedAVLMarkers ?? [], next.sharedAVLMarkers ?? [])
  && mapUtils.checkHazardsDifferences(prev.hazards ?? [], next.hazards ?? [])
  && mapUtils.checkCallersDifferences(prev.callers ?? [], next.callers ?? [])
  && mapUtils.checkUnmappedDevicesDifferences(prev.unmappedDevices ?? [], next.unmappedDevices ?? [])
  && mapUtils.checkIncidentGroupsDifferences(prev.incidentGroupsMarkers ?? [], next.incidentGroupsMarkers ?? [])
  && mapUtils.checkUnassignedUnitsDifferences(prev.unassignedUnitsOnMapMarkers ?? [], next.unassignedUnitsOnMapMarkers ?? [])
));
