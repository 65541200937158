import { useState } from "react";
import { MenuLink } from "@components/menu-links";
import { Role, SavedUser } from "@contexts/login-context";
import { Department } from "@models/department";
import {
  CADIncidentsTab, IncidentsTab, CADSimulatorTabs, ManagedIncidentsTab,
} from "@models/routing";
import { getURL, urls } from "@utils/routing";
import { useLocation } from "react-router-dom";
import { Collapse } from "react-collapse";
import Cad from "../img/menu-icons/CAD.svg?react";
import ReactLogo from "../img/menu-icons/TC_Incidents.svg?react";
import Dashboard from "../img/menu-icons/Dashboard.svg?react";
import MapOverview from "../img/menu-icons/Map_Overview.svg?react";
import UnitStaffing from "../img/menu-icons/Unit_Staffing.svg?react";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import CadStatus from "../img/menu-icons/CAD_status.svg?react";
import Checklists from "../img/menu-icons/Checklists.svg?react";
import Resources from "../img/menu-icons/Resources.svg?react";
import Tasks from "../img/menu-icons/Tasks.svg?react";
import Template from "../img/menu-icons/template.svg?react";
import TuneIcon from "@mui/icons-material/Tune";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// INFO: Superadmin menu is separate due to not having most of these items + different route prefix
export function CommonMenuItems(prop: {
  departmentData: Department | undefined,
  user: SavedUser | null,
  handleCollapse: () => void
}) {
  const { handleCollapse, user, departmentData } = prop;
  const router = useLocation();
  const [configCollapse, setConfigCollapse] = useState(false);
  return (
    <>
      <MenuLink
        to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.dashboard)}`}
        label="Dashboard"
        icon={Dashboard}
        onClick={handleCollapse}
      />
      <MenuLink
        to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminMapOverview)}`}
        label="Map Overview"
        icon={MapOverview}
        onClick={handleCollapse}
      />
      <MenuLink
        to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADIncidents, { tab: CADIncidentsTab.Active })}`}
        currentRoute={router.pathname}
        alternateRoutes={[
          `${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADIncidents, { tab: CADIncidentsTab.Inactive })}`,
          `${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADIncidents, { tab: CADIncidentsTab.Search })}`,
        ]}
        label="CAD Incidents"
        icon={Cad}
        onClick={handleCollapse}
      />
      <MenuLink
        to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminManagedIncidentsList, { tab: ManagedIncidentsTab.Active })}`}
        currentRoute={router.pathname}
        alternateRoutes={[
          `${getURL(urls.routingAppPrefix)}/${getURL(urls.adminManagedIncidentsList, { tab: ManagedIncidentsTab.Inactive })}`,
        ]}
        label="TC Incidents"
        icon={ReactLogo}
        onClick={handleCollapse}
      />
      <MenuLink
        to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminIncidentsList, { tab: IncidentsTab.Active })}`}
        currentRoute={router.pathname}
        alternateRoutes={[
          `${getURL(urls.routingAppPrefix)}/${getURL(urls.adminIncidentsList, { tab: IncidentsTab.Inactive })}`,
          `${getURL(urls.routingAppPrefix)}/${getURL(urls.adminIncidentsList, { tab: IncidentsTab.Search })}`,
        ]}
        label="Incidents"
        icon={ReactLogo}
        onClick={handleCollapse}
      />
      {(user?.cadSimulatorAccess || user?.role === Role.Admin) && (
        <MenuLink
          to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADSimulatorTabs, { tab: CADSimulatorTabs.Normal })}`}
          label="CAD Simulator"
          icon={PlayCircleOutlineIcon}
          onClick={handleCollapse}
        />
      )}
      {(departmentData?.cadBidirectionalEnabled && user?.isPro) && (
        <MenuLink
          to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.userVehiclesStatus)}`}
          label="Vehicles Status"
          icon={CadStatus}
          onClick={handleCollapse}
        />
      )}
      <MenuLink
        to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminUnitStaffing)}`}
        label="Unit Staffing"
        icon={UnitStaffing}
        onClick={handleCollapse}
      />
      <MenuLink
        label="Configuration"
        icon={TuneIcon}
        onClick={() => setConfigCollapse(!configCollapse)}
        icon2={ExpandMoreIcon}
      />
      <Collapse isOpened={configCollapse}>
        <MenuLink
          to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminResources)}`}
          label="Resources"
          icon={Resources}
          onClick={handleCollapse}
        />
        {user?.role !== Role.User && departmentData?.rosteringEnabled && (
          <MenuLink
            to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminPersonnel)}`}
            label="Personnel"
            icon={UnitStaffing}
            onClick={handleCollapse}
          />
        )}
        <MenuLink
          to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminChecklists)}`}
          label="Checklists"
          icon={Checklists}
          onClick={handleCollapse}
        />
        <MenuLink
          to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminTasks)}`}
          label="Tasks"
          icon={Tasks}
          onClick={handleCollapse}
        />
        {user?.role !== Role.User && (
          <MenuLink
            to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminTemplate)}`}
            label="Template"
            icon={Template}
            onClick={handleCollapse}
          />
        )}
        <MenuLink
          to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.releaseNotes)}`}
          label="Release Notes"
          icon={Tasks}
          onClick={handleCollapse}
        />
      </Collapse>
    </>
  );
}
