
export function IncidentPersonnelIconWithCount(prop: {
  personnel: number
  translateX?: number,
  translateY?: number,
  scale?: number
}) {
  const { personnel, scale = 0.8, translateX = 55, translateY = 9 } = prop;
  const personnelLength = personnel.toString().length
  const personnelLongNumberOffset = personnelLength > 1 ? personnelLength * 3 : 0
  return (
    <svg>
      <g transform={`translate(${translateX - personnelLongNumberOffset}, ${translateY})`}>
        <g transform="scale(0.6)">
          <path id="Path_773" data-name="Path 773" d="M3265.26,909.289a4.144,4.144,0,0,0,0-8.289h0a4.144,4.144,0,1,0,0,8.289Z" transform="translate(-3256.97 -901)" fill="white" />
          <path id="Path_774" data-name="Path 774" d="M3266.4,908c-2.767,0-8.289,1.322-8.289,3.947v2.96h16.577v-2.96C3274.692,909.322,3269.169,908,3266.4,908Z" transform="translate(-3258.115 -898.907)" fill="white" />
        </g>
        <g transform={`scale(${scale})`}>
          <text
            x="15"
            y="8"
            dominantBaseline="middle"
            textAnchor="start"
            style={{
              isolation: "isolate",
              textAlign: "center",
              fontSize: 14,
              fill: "#fff",
              fontFamily: "Arial-BoldMT, Arial",
            }}
          >
            {personnel}
          </text>
        </g>
      </g>
    </svg>
  );
}



