import { useEffect, useState } from "react";

export function useHeight() {
  const [height, setHeight] = useState(window.innerHeight - 178);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newHeight = window.innerHeight - 178;
      setHeight(newHeight);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return height;
}
