import { TabletButton } from "@components/tablet-button/custom-button";
import {
  Checkbox, FormLabel, MenuItem, Select, Typography,
} from "@mui/material";
import { CadSimulatorField } from "../cad-simulator-field";
import { CadSimulatorMap } from "../cad-simulator-map";
import styles from "../cad-simulator.module.css";
import { CADCannedSimulationSlim } from "@models/cad";
import classes from "./cad-simulator-canned.module.css";
import { FormikErrors } from "formik";
import { useCallback } from "react";
import { closeInValuesInSeconds } from "@utils/static-data";

export function SimulationInformationForm(
  {
    values,
    setFieldValue,
    handleChange,
  }: {
    values: CADCannedSimulationSlim,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void> | Promise<FormikErrors<CADCannedSimulationSlim>>,
    handleChange: {
      (e: React.ChangeEvent<any>): void;
      <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any> ? void : (e: string | React.ChangeEvent<any>) => void;
    }
  },
) {
  const handleReset = useCallback(() => {
    setFieldValue("streetName", "");
    setFieldValue("title", "");
    setFieldValue("firemap", "");
    setFieldValue("city", "");
    setFieldValue("state", "");
    setFieldValue("suite", "");
    setFieldValue("locationComment", "");
    setFieldValue("notes", "");
    setFieldValue("CrossStreet1", "");
    setFieldValue("tacticalChannel", "");
    setFieldValue("commandChannel", "");
    setFieldValue("mapPages", "");
    setFieldValue("lat", 36.06199804030064);
    setFieldValue("lon", -115.03954633447312);
    setFieldValue("simulation", true);
    setFieldValue("notify", false);
    setFieldValue("rts", true);
    setFieldValue("CallerNumber", "");
  }, [setFieldValue]);

  return (
    <div style={{ padding: "0px 20px", scrollMarginTop: 100 }}>
      <div className={classes.add_cad_simulator_canned_form_section_header}>
        <Typography
          variant="h6"
        >
          Simulation Information
        </Typography>
      </div>
      <div style={{ backgroundColor: "lightgrey" }}>
        <div className={classes.add_cad_simulator_canned_form_section_content}>
          <div style={{
            padding: "15px 5px", flexGrow: 1,
          }}
          >
            <div className={styles.search_widget_container}>
              <div id="searchWidget" />
            </div>
            <CadSimulatorField handleChange={handleChange} fieldKey="streetName" value={values.streetName} label="Address" width="100%" required />
            <CadSimulatorField handleChange={handleChange} fieldKey="title" value={values.title} label="Call Type" width="100%" required />
            <CadSimulatorField handleChange={handleChange} fieldKey="city" value={values.city} label="City" width="100%" required />
            <CadSimulatorField handleChange={handleChange} fieldKey="state" value={values.state} label="State" width="100%" required />
            <CadSimulatorField handleChange={handleChange} fieldKey="suite" value={values.suite} label="Ap/Suite" width="100%" />
            <CadSimulatorField handleChange={handleChange} fieldKey="firemap" value={values.firemap} label="Fire Map" width="100%" />
            <CadSimulatorField handleChange={handleChange} fieldKey="locationComment" value={values.locationComment} label="Common Name" width="100%" />
            <CadSimulatorField handleChange={handleChange} fieldKey="CrossStreet1" value={values.CrossStreet1} label="Cross Streets" width="100%" />
            <CadSimulatorField handleChange={handleChange} fieldKey="CallerNumber" value={values.CallerNumber} label="Caller Number" width="100%" />
            <CadSimulatorField handleChange={handleChange} fieldKey="tacticalChannel" value={values.tacticalChannel} label="Tactical Ch" width="100%" />
            <CadSimulatorField handleChange={handleChange} fieldKey="commandChannel" value={values.commandChannel} label="Command Ch" width="100%" />
            <CadSimulatorField handleChange={handleChange} fieldKey="mapPages" value={values.mapPages} label="Map Page" width="100%" />
            <CadSimulatorField handleChange={handleChange} fieldKey="notes" value={values.notes} label="Notes" width="100%" />
            <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
              <FormLabel style={{ minWidth: 135, textAlign: "right", marginRight: 10 }}>Close In</FormLabel>
              <Select
                variant="outlined"
                className={`${styles.textField} ${styles.closeIn}`}
                MenuProps={{ classes: { paper: styles.closeInMenu }, anchorOrigin: { horizontal: "center", vertical: "center" }, transformOrigin: { horizontal: "center", vertical: "center" } }}
                value={values.closeDelay}
                id="closeDelay"
                onChange={(e) => {
                  setFieldValue("closeDelay", (+e.target.value));
                }}
              >
                {closeInValuesInSeconds.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.FormLabel}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div style={{
              paddingLeft: 45, alignItems: "center", display: "flex", marginTop: 10,
            }}
            >
              <Checkbox
                id="simulation"
                onChange={handleChange}
                checked={values.simulation}
                name="simulation"
                color="secondary"
              />
              <FormLabel>Simulation</FormLabel>
              <Checkbox
                id="rts"
                onChange={handleChange}
                checked={values.rts}
                name="rts"
                color="secondary"
              />
              <FormLabel>RTS</FormLabel>
              <Checkbox
                id="notify"
                onChange={handleChange}
                checked={values.notify}
                name="notify"
                color="secondary"
              />
              <FormLabel>Notifications</FormLabel>
            </div>
            <div className={classes.add_cad_simulator_canned_form_section_actions}>
              <TabletButton onClick={() => handleReset()}>Reset</TabletButton>
            </div>
          </div>
          <CadSimulatorMap
            values={{
              AgencyIncidentCallTypeDescription: values.title ?? "",
              IncidentNumber: "",
              Latitude: values.lat ?? 0,
              Longitude: values.lon ?? 0,
            }}
            setFieldValue={setFieldValue}
            isCannedSimulator
          />
        </div>
      </div>
    </div>
  );
}
