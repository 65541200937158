import { Typography, TextField } from "@mui/material";
import { AdvancedSearchProps } from "@models/incident";

enum IncidentsTableAdvancedSearchKeys {
  IncidentNumber = "incidentNumber",
  CallType = "callType",
  UnitId = "unitId",
  Address = "address",
}

interface IncidentsTableAdvancedSearchInputProps {
  value: string
  fieldKey: keyof AdvancedSearchProps
  fieldSetAdvancedSearch: (fieldKey: keyof AdvancedSearchProps, value: string) => void
}

const getFieldLabel = (fieldKey: string) => {
  switch (fieldKey) {
    case IncidentsTableAdvancedSearchKeys.CallType:
      return "Call Type";
    case IncidentsTableAdvancedSearchKeys.Address:
      return "Address";
    case IncidentsTableAdvancedSearchKeys.UnitId:
      return "Unit Id";
    case IncidentsTableAdvancedSearchKeys.IncidentNumber:
    default:
      return "Incident Number";
  }
};

export function IncidentsTableAdvancedSearchInput({ value, fieldKey, fieldSetAdvancedSearch }: IncidentsTableAdvancedSearchInputProps) {
  return (
    <div>
      <Typography>
        {getFieldLabel(fieldKey)}
      </Typography>
      <TextField size="small" value={value} onChange={(event) => fieldSetAdvancedSearch(fieldKey, event.currentTarget.value)} />
    </div>
  );
}
