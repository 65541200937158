import MaterialTable, { Column } from "material-table";
import moment from "moment";
import { useContext, useState } from "react";
import CADLabel from "@components/cad-labels";
import CloseManagedIncidentButton from "@components/close-managed-incident-button";
import TableDate from "@components/table-date";
import { IncidentLabel } from "@components/tc-incidents-label";
import { LoginContext, roleIsAdmin } from "@contexts/login-context";
import { IncidentUnit } from "@models/cad";
import { Incident } from "@models/incident";
import { useHeight } from "@hooks/useHeight";
import { getURL, urls } from "@utils/routing";
import { getCustomFilterAndSearch, getIncidentCustomFilterAndSearchValue } from "@utils/utilsFunctions";

interface IncidentsTableProps {
  incidents: Incident[]
  tableTitle: string
  isLoading?: boolean
  reload?: () => Promise<void>
}

export function IncidentsTable({
  incidents, tableTitle, isLoading, reload,
}: IncidentsTableProps) {
  const [user] = useContext(LoginContext);
  const isAdmin = roleIsAdmin(user?.role);
  const height = useHeight();
  const [columns] = useState<Array<Column<Incident>>>([
    {
      title: "Incident Date Time",
      field: "startTime",
      render: (item) => <TableDate date={item.startTime} />,
      sorting: true,
      customSort: (a, b) => (moment(b.startTime).unix() ?? 0) - (moment(a.startTime).unix() ?? 0),
      cellStyle: { width: "16%" },
    },
    {
      title: "Incident",
      field: "incidentCallTypeDescription",
      render: (item) => (item.type === "CAD" ? (
        <CADLabel
          incidentCallTypeDescription={item.incidentCallTypeDescription ?? ""}
          incidentNumber={item.incidentNumber ?? ""}
          incidentNumberDisplay={item.incidentNumberDisplay ?? ""}
          address={item.address ?? ""}
          units={item.units ?? []}
          rts={item.rts}
          simulation={item.simulation}
          extended={item.extended}
          to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminIncidentDetails, { incidentNumber: item?.incidentNumber ?? "", incidentId: item?.id ?? "" })}`}
          sharedSource={item.sharedSource}
          sharedTo={item.sharedTo}
        />
      ) : (
        <IncidentLabel
          uniqueKey={item.uuid}
          channelOwner={item.incidentOwner ?? ""}
          id={item.id}
          incidentCallTypeDescription={item.incidentCallTypeDescription}
          incidentNumber={item.incidentNumber}
          address={item.address}
          groups={item.groups ?? []}
          units={item.units as IncidentUnit[]}
          rts={item.rts}
          simulation={item.simulation}
          extended={item.extended}
          to={`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminIncidentDetails, { incidentNumber: item?.incidentNumber ?? "", incidentId: item?.id ?? "" })}`}
          sharedSource={item.sharedSource}
          sharedTo={item.sharedTo}
        />
      )),
      ...getCustomFilterAndSearch<Incident>((item) => getIncidentCustomFilterAndSearchValue(item)),
    },
    {
      title: "Last Updated",
      field: "modified_unix_date",
      render: (item) => (item.type === "CAD" ? moment((item.modified_unix_date ?? 0) * 1000).fromNow()
        : (
          <>
            <p style={{ margin: "8px 0px 10px 0px" }}>
              {item.modified_unix_date ? moment(item.modified_unix_date * 1000).fromNow() : ""}
            </p>
            <CloseManagedIncidentButton
              itemId={item.id}
              closed={item.isClosed ?? false}
              canClose={isAdmin && item.sharedSource?.isExternal === false}
              reload={reload ?? (() => { })}
            />
          </>
        )),
      sorting: true,
      customSort: (a, b) => (b.modified_unix_date ?? 0) - (a.modified_unix_date ?? 0),
    },
  ]);

  return (
    <MaterialTable
      title={tableTitle}
      data={incidents}
      isLoading={isLoading}
      options={{
        paging: false,
        sorting: true,
        maxBodyHeight: height - 90,
        headerStyle: {
          position: "sticky",
          top: "0",
        },
        rowStyle: (data: Incident) => ({
          backgroundColor: (data.sharedSource && data.sharedSource.isExternal) ? "#fadea6" : data.simulation ? "#b8e4fc" : "",
        }),
      }}
      columns={columns.map((c) => ({
        ...c,
      }))}
    />
  );
}
