import * as React from "react";

const SVGComponent = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg
    viewBox="0 0 16 16"
    width={26}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m11,1c0.6,0 1,0.4 1,1l0,12c0,0.6 -0.4,1 -1,1l-6,0c-0.6,0 -1,-0.4 -1,-1l0,-12c0,-0.6 0.4,-1 1,-1l6,0zm-6,-1c-1.1,0 -2,0.9 -2,2l0,12c0,1.1 0.9,2 2,2l6,0c1.1,0 2,-0.9 2,-2l0,-12c0,-1.1 -0.9,-2 -2,-2l-6,0z"
    />
    <path
      d="m9.59467,3.91716c0,0 -0.6,0 -1.2,0.6c-0.5,0.6 -0.4,1.2 -0.4,1.3c0,0 0.8,0 1.3,-0.6s0.3,-1.3 0.3,-1.3zm1.7,5.9c0,0 -1.2,-0.6 -1.1,-1.7c0.1,-1.1 0.8,-1.4 0.9,-1.4s-0.3,-0.4 -0.6,-0.6c-0.2,-0.1 -0.5,-0.2 -0.8,-0.2c-0.1,0 -0.2,0 -0.6,0.1c-0.3,0 -0.9,0.2 -1.1,0.2c-0.2,0 -0.6,-0.3 -1.1,-0.3c-0.4,-0.1 -0.7,0 -0.9,0.1c-0.3,0.1 -0.8,0.4 -1.1,1.2c-0.3,0.7 -0.2,1.9 0,2.3c0.1,0.3 0.3,0.9 0.6,1.4c0.3,0.5 0.7,0.8 0.8,1c0.2,0.1 0.6,0.2 0.9,0c0.3,-0.2 0.7,-0.2 0.9,-0.2c0.2,0 0.5,0.1 0.9,0.3c0.3,0.1 0.6,0.1 0.8,-0.1c0.3,-0.1 0.7,-0.5 1.1,-1.4c0.3,-0.4 0.4,-0.7 0.4,-0.7z"
    />
    <path
      d="m9.59467,3.91716c0,0 -0.6,0 -1.2,0.6c-0.5,0.6 -0.4,1.2 -0.4,1.3c0,0 0.8,0 1.3,-0.6s0.3,-1.3 0.3,-1.3zm1.7,5.9c0,0 -1.2,-0.6 -1.1,-1.7c0.1,-1.1 0.8,-1.4 0.9,-1.4s-0.3,-0.4 -0.6,-0.6c-0.2,-0.1 -0.5,-0.2 -0.8,-0.2c-0.1,0 -0.2,0 -0.6,0.1c-0.3,0 -0.9,0.2 -1.1,0.2c-0.2,0 -0.6,-0.3 -1.1,-0.3c-0.4,-0.1 -0.7,0 -0.9,0.1c-0.3,0.1 -0.8,0.4 -1.1,1.2c-0.3,0.7 -0.2,1.9 0,2.3c0.1,0.3 0.3,0.9 0.6,1.4c0.3,0.5 0.7,0.8 0.8,1c0.2,0.1 0.6,0.2 0.9,0c0.3,-0.2 0.7,-0.2 0.9,-0.2c0.2,0 0.5,0.1 0.9,0.3c0.3,0.1 0.6,0.1 0.8,-0.1c0.3,-0.1 0.7,-0.5 1.1,-1.4c0.3,-0.4 0.4,-0.7 0.4,-0.7z"
    />
  </svg>
);
export default SVGComponent;
