import {
  ArcGISHazard, ArcGIsIncidentGroup, ArcGIsUnassignedUnit, IncidentLocation, MapUnit,
} from "@models/arcgis";
import { Caller } from "@models/cad";
import { DeviceMapping } from "@models/device-mapping";

// TODO: Review those comparisons we might save some renders by optimizing them

function checkAVLMarkersDifferences(prev: MapUnit[], next: MapUnit[]): boolean {
  if (prev.length !== next.length) {
    return false;
  }

  const response = prev.every((element, index) => {
    const secondElement = next[index];

    if (element.altitude === secondElement.altitude
      && element.color.background === secondElement.color.background
      && element.color.text === secondElement.color.text
      && element.device_type === secondElement.device_type
      && element.location.latitude === secondElement.location.latitude
      && element.location.longitude === secondElement.location.longitude
      && element.modified === secondElement.modified
      && element.speed === secondElement.speed
      && element.username === secondElement.username
    ) {
      return true;
    }
    return false;
  });

  return response;
}

function checkHazardsDifferences(prev: ArcGISHazard[], next: ArcGISHazard[]): boolean {
  if (prev.length !== next.length) {
    return false;
  }

  const response = prev.every((element, index) => {
    const secondElement = next[index];

    if (element.id === secondElement.id
      && element.latitude === secondElement.latitude
      && element.longitude === secondElement.longitude
      && element.name === secondElement.name
      && element.note === secondElement.note
    ) {
      return true;
    }
    return false;
  });

  return response;
}

function checkCallersDifferences(prev: Caller[], next: Caller[]): boolean {
  if (prev.length !== next.length) {
    return false;
  }

  const response = prev.every((element, index) => {
    const secondElement = next[index];

    if (element.CallerId === secondElement.CallerId
      && element.Latitude === secondElement.Latitude
      && element.Longitude === secondElement.Longitude
      && element.Address === secondElement.Address
      && element.Altitude === secondElement.Altitude
      && element.UncertaintyRadius === secondElement.UncertaintyRadius
    ) {
      return true;
    }
    return false;
  });

  return response;
}

function checkUnmappedDevicesDifferences(prev: DeviceMapping[], next: DeviceMapping[]): boolean {
  if (prev.length !== next.length) {
    return false;
  }

  const response = prev.every((element, index) => {
    const secondElement = next[index];

    if (element._id === secondElement._id
      && element.active === secondElement.active
      && element.departmentId === secondElement.departmentId
      && element.deviceId === secondElement.deviceId
      && element.deviceType === secondElement.deviceType
      && element.location === secondElement.location
      && element.mapHidden === secondElement.mapHidden
      && element.modified_unix_date === secondElement.modified_unix_date
      && element.modified === secondElement.modified
    ) {
      return true;
    }
    return false;
  });

  return response;
}

function checkIncidentGroupsDifferences(prev: ArcGIsIncidentGroup[], next: ArcGIsIncidentGroup[]): boolean {
  if (prev.length !== next.length) {
    return false;
  }

  const response = prev.every((element, index) => {
    const secondElement = next[index];

    if (element.id === secondElement.id
      && element.latitude === secondElement.latitude
      && element.longitude === secondElement.longitude
      && element.history === secondElement.history
      && element.name === secondElement.name
      && element.signal === secondElement.signal
      && element.units === secondElement.units

    ) {
      return true;
    }
    return false;
  });

  return response;
}

function checkUnassignedUnitsDifferences(prev: ArcGIsUnassignedUnit[], next: ArcGIsUnassignedUnit[]): boolean {
  if (prev.length !== next.length) {
    return false;
  }

  const response = prev.every((element, index) => {
    const secondElement = next[index];

    if (element.id === secondElement.id
      && element.latitude === secondElement.latitude
      && element.longitude === secondElement.longitude
      && element.time === secondElement.time
      && element.name === secondElement.name
    ) {
      return true;
    }
    return false;
  });

  return response;
}

function checkIncidentsDifferences(prev: IncidentLocation[], next: IncidentLocation[]): boolean {
  if (prev.length !== next.length) {
    return false;
  }

  const response = prev.every((element, index) => {
    const secondElement = next[index];
    if (element.latitude === secondElement.latitude
      && element.longitude === secondElement.longitude
      && element.incidentCallTypeDescription === secondElement.incidentCallTypeDescription
      && element.incidentNumberDisplay === secondElement.incidentNumberDisplay
    ) {
      return true;
    }
    return false;
  });

  return response;
}

export const mapUtils = {
  checkAVLMarkersDifferences,
  checkHazardsDifferences,
  checkCallersDifferences,
  checkIncidentGroupsDifferences,
  checkIncidentsDifferences,
  checkUnassignedUnitsDifferences,
  checkUnmappedDevicesDifferences,
};
