/* eslint-disable react/no-array-index-key */
import {
  withStyles, createStyles, makeStyles,
} from "@mui/styles";
import {
  Table, TableBody, TableCell, Theme, TableContainer, TableHead, TableRow, Paper, useMediaQuery,
} from "@mui/material";
import { Row } from "./cad-simulator-units-table-row";

export function CadSimulatorUnitsTable<T>(prop: {
  data: T[]
  header: {
    name: string | JSX.Element,
    prop: string,
    render?: ((item: T) => string | JSX.Element | JSX.Element[] | undefined) | undefined
  }[],
  title?: string
  cellClassName?: string;
  headerColor?: string
  headerCellTextColor?: string,
  cellAlign?: "left" | "right" | "inherit" | "center" | "justify" | undefined
}) {
  const small = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"));

  const StyledTableCell = withStyles(() => createStyles({
    head: {
      backgroundColor: prop.headerColor ? prop.headerColor : "#F9F9F9",
      color: prop.headerCellTextColor ? prop.headerCellTextColor : "#676768",
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const useStyles = makeStyles({
    table: {
      minWidth: small ? 650 : 400,
    },
  });
  const classes = useStyles();

  const {
    data, cellAlign, header,
  } = prop;

  return (
    <div
      style={{
        marginBottom: 20,
      }}
      className="custom-mt"
    >
      <TableContainer
        component={Paper}
        style={{
          borderRadius: 0, boxShadow: "none", maxWidth: "97vw", overflowX: small ? "scroll" : "auto",
        }}
      >
        <Table className={classes.table} aria-label="customized table">
          <TableHead style={{ border: "1px solid dark" }}>
            <TableRow style={{ border: "1px solid dark" }}>
              <StyledTableCell align={cellAlign ? cellAlign : "left"} size="small" style={{ width: "fitContent" }}>{header[0].name}</StyledTableCell>
              {header.filter((el) => el.name !== prop.header[0].name).map((el) => <StyledTableCell size="small" key={el.name as string} align={prop.cellAlign ? prop.cellAlign : "left"}>{el.name}</StyledTableCell>)}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, i) => (
              <Row
                item={row}
                index={i}
                key={i}
                StyledTableCell={StyledTableCell}
                cellAlign={prop.cellAlign}
                cellClassName={prop.cellClassName}
                header={prop.header}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
