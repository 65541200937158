import Button, { ButtonProps } from "@mui/material/Button";
import "./custom-button.css";

export function TabletButton(props: ButtonProps) {
  const { children, ...rest } = props;

  return (
    <Button
      className={`custom_button ${props.className !== undefined ? props.className : ""}`}
      variant={props.variant ?? "contained"}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children}
    </Button>
  );
}
