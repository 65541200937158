import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide, { SlideProps } from "@mui/material/Slide";
import { forwardRef, useEffect, useState } from "react";
import { TabletButton } from "./tablet-button/custom-button";

const Transition = forwardRef((
  props: SlideProps,
  ref: React.Ref<unknown>,
// eslint-disable-next-line react/jsx-props-no-spreading
) => <Slide direction="up" ref={ref} {...props} />);

export function LogoutPopUp(prop: {open: boolean}) {
  const { open } = prop;
  const [openPopup, setOpenPopup] = useState(open);

  const handleClose = () => {
    setOpenPopup(false);
  };

  useEffect(() => {
    setOpenPopup(open);
  }, [open]);

  return (
    <div>
      <Dialog
        open={openPopup}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">Session Closed</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Another web login for your account was detected. If you have any questions, please contact your account administrator.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <TabletButton onClick={handleClose}>
            OK
          </TabletButton>
        </DialogActions>
      </Dialog>
    </div>
  );
}
