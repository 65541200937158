import { NavLink } from "react-router-dom";

import { IncidentUnit, SharedSource, SharedToItem } from "@models/cad";
import classes from "../pages/admin/cad-incident-details/cad.module.css";
import { getURL, urls } from "@utils/routing";
import { UnitLabel } from "./unit-label";
import icon from "../pages/img/rts-disabled-icon.png";
import {
  IncidentExtendedLabel,
  IncidentSharedSource,
  IncidentSharedTo,
  IncidentTrainingLabel,
} from "./tc-incidents-label";

export default function CADLabel(prop: {
  incidentCallTypeDescription: string
  incidentNumber: string
  incidentNumberDisplay: string
  address: string
  units: IncidentUnit[]
  rts: boolean
  simulation: boolean
  extended: boolean
  to?: string,
  sharedTo?: SharedToItem[],
  sharedSource?: SharedSource,
}) {
  const {
    incidentCallTypeDescription,
    incidentNumber,
    incidentNumberDisplay,
    address,
    units,
    to,
    rts,
    simulation,
    extended,
    sharedTo,
    sharedSource,
  } = prop;

  let cellBgColor = "white";
  if (sharedSource && sharedSource.isExternal) {
    cellBgColor = "#fadea6";
  } else if (simulation) {
    cellBgColor = "#b8e4fc";
  }

  return (
    <div className="cad-incidents-row">
      <div className={classes.cadRowContent}>
        <NavLink
          to={to ?? `${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADIncidentDetails, { incidentNumber })}`}
          style={{ textDecoration: "none", color: "black" }}
        >
          <span style={{ fontWeight: "bold", fontSize: 16 }}>
            {incidentCallTypeDescription}
            {" "}
            {incidentNumberDisplay}
            {" "}
          </span>
          <br />
          {address ? <span>{address}</span> : <></>}
        </NavLink>
        <IncidentSharedSource sharedSource={sharedSource} cellBgColor={cellBgColor} />
        <IncidentSharedTo sharedTo={sharedTo} />
        <IncidentTrainingLabel simulation={simulation} />
        <IncidentExtendedLabel extended={extended} />
        {!rts ? (
          <div>
            <img src={icon} alt="Icon unavailable" style={{ width: 20, height: 20, marginLeft: 10 }} />
          </div>
        ) : null}
      </div>
      <div>
        {units?.map((unit) => (
          <UnitLabel
            cleared={unit.TimeCleared !== undefined && unit.TimeCleared !== ""}
            color={unit.color}
            radioName={unit.UnitID}
            styleClassName={`${classes.cadUnitChild}`}
            uniqueKey={unit.UnitDispatchNumber}
            key={unit.UnitDispatchNumber}
          />
        ))}
      </div>
    </div>
  );
}
