import { Dispatch, SetStateAction, useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";

export function useHistoryState<S>(key: string | undefined, initialState: S | (() => S)): [S, Dispatch<SetStateAction<S>>, boolean];
// eslint-disable-next-line no-redeclare
export function useHistoryState<S = undefined>(key: string | undefined): [S | undefined, Dispatch<SetStateAction<S | undefined>>, boolean];
// eslint-disable-next-line no-redeclare
export function useHistoryState<T>(key: string | undefined, initialValue?: T | (() => T)): [T, (t: T) => void, boolean] {
  const navigate = useNavigate();
  const location = useLocation();
  const [rawState, rawSetState] = useState<T>(() => {
    if (key) {
      const value = (location.state as any)?.[key];
      return value ?? (typeof initialValue === "function" ? (initialValue as () => T)() : initialValue);
    }

    return (typeof initialValue === "function" ? (initialValue as () => T)() : initialValue);
  });
  function setState(value: T) {
    if (key) {
      navigate(location, {
        ...location,
        state: {
          ...location.state as object,
          [key]: value,
        },
      });
    }
    rawSetState(value);
  }
  const value = key && (location.state as any)?.[key];
  return [rawState, setState, value !== undefined];
}
