/* eslint-disable react/jsx-props-no-spreading */
import { Box, BoxProps } from "@mui/material";
import React from "react";

interface TabPanelProps {
    children?: React.ReactNode;
    index: string;
    value: string;
    boxProps?: BoxProps;
    style?: React.CSSProperties
    padding?: number
}
export function TabPanel(props: TabPanelProps) {
  const {
    children, value, index, boxProps, padding, style, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      style={style}
      {...other}

    >
      {value === index && <Box p={boxProps?.p ?? 2.3} padding={padding} {...boxProps}>{children}</Box>}
    </div>
  );
}
