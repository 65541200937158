import {
  Button,
  Grid,
  Paper,
  // Checkbox,
} from "@mui/material";

import {
  TabletMac as TCIcon,
  PhoneIphone as TCMobileIcon,
} from "@mui/icons-material";

// import { useState } from "react";
import { useParams } from "react-router-dom";
import { QRCodeSVG } from "qrcode.react";

import { useTitle } from "@hooks/useTitle";

import logo from "./img/tcIconRed.png";

export default function PasswordRecovery() {
  useTitle("Join Incident");

  const route = useParams<{ code: string }>();

  const codeMap = [
    {
      url: `x-tabletcmd://x/incidentjoin?code=${route.code}`,
      icon: (<TCIcon style={{ height: 18 }} />),
      name: "Tablet Command",
    },
    {
      url: `x-tcmobile://x/incidentjoin?code=${route.code}`,
      icon: (<TCMobileIcon style={{ height: 18 }} />),
      name: "TC Mobile",
    },
  ];

  return (
    <div className="incident-join-page container login-background">
      <Grid container>
        <Paper className="paperBody">
          <Grid item>
            <div className="head">
              <img src={logo} alt="TabletCommand Logo" className="logo" style={{ width: "50px" }} />
              <p className="incident-join-page-message">Join Incident</p>
            </div>
            <div className="incident-join-page-content">
              <p className="incident-join-page-message">
                Open app on device and tap on button or scan QR Code.
              </p>
            </div>
          </Grid>
          {/* <div>
              Show Beta
              <Checkbox />
            </div> */}
          {(codeMap.map((x) => {
            const {
              url,
              icon,
              name,
            } = x;
            return (
              <Grid item key={url + name} className="incident-join-grid-item">
                <div>
                  <QRCodeSVG value={url} />
                </div>
                <Button
                  className="btn"
                  variant="contained"
                  size="small"
                  startIcon={icon}
                  href={url}
                >
                  {name}
                </Button>
              </Grid>
            );
          }))}
        </Paper>
      </Grid>
    </div>
  );
}
