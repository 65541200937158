import { useContext } from "react";
import { Helmet } from "react-helmet";
import { LoginContext, Role } from "@contexts/login-context";

export default function FreshDeskHelp() {
  const [user] = useContext(LoginContext);
  // The admin widget uses a different widget id
  const widgetId = user !== null && user.role === Role.User ? "8000000260" : "8000000259";

  if (user === null) {
    return <></>;
  }

  return (
    <Helmet>
      <script>
        {`
  window.fwSettings = {
    'widget_id': ${widgetId}
  };
  !function() {
    if ("function" != typeof window.FreshworksWidget) {
      var n = function() { 
        n.q.push(arguments)
      }; 
      n.q = [], 
      window.FreshworksWidget = n
    }
  }()
  `}
      </script>
      <script
        type="text/javascript"
        src={`https://widget.freshworks.com/widgets/${widgetId}.js`}
        async
        defer
      />
    </Helmet>
  );
}
