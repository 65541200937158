import { IconButton } from "@mui/material";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo, useRef, useState,
} from "react";

import classes from "./cad.module.css";

import target from "../../img/center_on_incident_icon.svg";

import { useHeight } from "@hooks/useHeight";

import { CADIncident } from "@models/cad";
import { WebMap } from "@components/arcgis-map-with-hooks/map";
import { ArcGISMapMapContext } from "@contexts/arcgis-map-context";
import { MapDetails, MapUnit } from "@models/arcgis";

export function IncidentMap({
  cadIncident,
  unitsLocation,
  sharedAVLMarkers,
  sharedAVLButton,
  mapViewRef,
  refetchData,
}: {
  cadIncident: CADIncident,
  unitsLocation: MapUnit[]
  sharedAVLMarkers: MapUnit[],
  sharedAVLButton?: boolean,
  mapViewRef: React.MutableRefObject<string>
  refetchData: (props: MapDetails) => void
}) {
  const height = useHeight();

  const [userSelected, setUserSelected] = useState(false);
  const [mapSettings, setMapSettings] = useContext(ArcGISMapMapContext);
  const [latitude, longitude] = useMemo(() => {
    const lat: number = cadIncident.Latitude
      ? +cadIncident.Latitude
      : 37.77985186145628;
    const long: number = cadIncident.Longitude
      ? +cadIncident.Longitude
      : -122.4382143264234;

    return [lat, long];
  }, [cadIncident.Latitude, cadIncident.Longitude]);
  const center = useRef<[number, number]>([latitude, longitude]);

  useEffect(() => {
    const [oldLat, oldLong] = center.current;
    if (oldLat !== latitude || oldLong !== longitude) {
      setMapSettings({
        ...mapSettings,
        isMapDragged: false,
      });
    }
  }, [latitude, longitude]);

  const centerOnIncident = useCallback(() => {
    setUserSelected(!userSelected);
  }, [userSelected]);

  const resetMapView = useCallback(() => { }, []);

  const mapURL: string = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`;

  return (
    <div style={{ height }} className={`${classes.incident_map}`}>
      <div className={`${classes.cad_incident_header}`}>
        <p className={`${classes.cad_incident_map_title_container}`}>
          <IconButton onClick={centerOnIncident}>
            <img src={target} alt="" style={{ marginRight: 8 }} />
          </IconButton>
          Center On Incident
          {" "}
        </p>
        {!(cadIncident.Latitude !== "0" && cadIncident.Longitude !== "0") ? (
          <p style={{ color: "red", fontWeight: "bold" }}>
            No Location From CAD
          </p>
        ) : <></>}
        <a
          href={mapURL}
          target="blank"
          style={{ textDecoration: "none" }}
        >
          <span className={`${classes.cad_incident_map_label}`}>
            {latitude.toFixed(6)}
            ,
            {longitude.toFixed(6)}
          </span>
        </a>
      </div>
      <WebMap
        incidents={[{
          longitude,
          latitude,
          incidentCallTypeDescription: cadIncident?.AgencyIncidentCallTypeDescription ?? "",
          incidentNumberDisplay: cadIncident?.IncidentNumberDisplay ?? "",
          type: "CAD",
        }]}
        callers={cadIncident?.callers}
        avlMarkers={unitsLocation}
        zoomToIncident={false}
        incidentZoom={18}
        incidentCenter={[longitude, latitude]}
        resetMapView={resetMapView}
        height={height - 40}
        noStoreData
        sharedAVLButton={sharedAVLButton}
        sharedAVLMarkers={sharedAVLMarkers}
        refetchData={refetchData}
        centerOnIncident={!userSelected}
        mapViewRef={mapViewRef}
      />
    </div>
  );
}
