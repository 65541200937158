import {
  createContext,
  useState,
} from "react";

export const UIStateContext = createContext<[string | null, React.Dispatch<string>]>([null!, () => null!]);

export function UIStateContextProvider(props: { children: JSX.Element }) {
  const [uiState, setUIState] = useState<string>("expanded");

  return (
    <UIStateContext.Provider value={[uiState, setUIState]}>
      {props.children}
    </UIStateContext.Provider>
  );
}
