import { CadSimulatorNavigationTabs } from "@models/cad";
import inactiveCad from "../../img/description_icon.svg";
import activeCad from "../../img/insert_drive_file_icon.svg";
import { CADSimulatorTabs, RouteParamCADSimulators } from "@models/routing";
import { useTitle } from "@hooks/useTitle";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useHeight } from "@hooks/useHeight";
import { useEffect, useState } from "react";
import { AppBar, Tab, Tabs } from "@mui/material";
import styles from "./cad-simulator.module.css";
import { TabPanel } from "@components/tab-panel";
import { CadSimulatorIncidentsList } from "./cad-simulator-list-of-incidents";
import { CadSimulatorCannedList } from "./cad-simulator-list-canned";
import { getURL, urls, getValidCADSimulatorTab } from "@utils/routing";

type SimulatorTab = {
  label: string;
  value: string;
  icon: string;
  url: string;
};
const tabs: SimulatorTab[] = [
  {
    label: "CAD Simulations",
    value: CadSimulatorNavigationTabs.Normal,
    icon: activeCad,
    url: getURL(urls.adminCADSimulatorTabs, { tab: CADSimulatorTabs.Normal }),
  },
  {
    label: "Canned Simulations",
    value: CadSimulatorNavigationTabs.Canned,
    icon: inactiveCad,
    url: getURL(urls.adminCADSimulatorTabs, { tab: CADSimulatorTabs.Canned }),
  },
];

export default function CadSimulatorsList() {
  useTitle("CAD Simulators");
  const { tab: paramTab } = useParams<RouteParamCADSimulators>();
  const selectedTab = getValidCADSimulatorTab(paramTab, CADSimulatorTabs.Normal);
  const height = useHeight();
  const [selectedValue, setSelectedValue] = useState(tabs[0].value);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === `${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADSimulatorTabs, { tab: CADSimulatorTabs.Normal })}`) {
      setSelectedValue(CADSimulatorTabs.Normal);
    } else if (location.pathname === `${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADSimulatorTabs, { tab: CADSimulatorTabs.Canned })}`) {
      setSelectedValue(CADSimulatorTabs.Canned);
    }
  }, [location.pathname]);

  const handleChange = (
    e: React.ChangeEvent<{}>,
    newValue: string | symbol,
  ) => {
    if (newValue === CADSimulatorTabs.Normal) {
      navigate(`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADSimulatorTabs, { tab: CADSimulatorTabs.Normal })}`);
    } else {
      navigate(`${getURL(urls.routingAppPrefix)}/${getURL(urls.adminCADSimulatorTabs, { tab: CADSimulatorTabs.Canned })}`);
    }
  };

  const renderTab = (tab: SimulatorTab["value"]) => {
    switch (tab) {
      case CADSimulatorTabs.Normal: return <CadSimulatorIncidentsList />;
      case CADSimulatorTabs.Canned: return <CadSimulatorCannedList />;
      default: return (
        <div>
          Unhandled tab:
          {tab}
          .
        </div>
      );
    }
  };
  return (
    <div style={{ height }}>
      <AppBar position="static" color="default">
        <Tabs
          className={styles.tab}
          variant="fullWidth"
          scrollButtons
          value={selectedValue}
          centered
          TabIndicatorProps={{
            style: {
              display: "none",
            },
          }}
          onChange={handleChange}
        >
          {tabs.map((tab) => (
            <Tab
              className={styles.tabWrapper}
              key={tab.label}
              label={tab.label}
              value={tab.value}
              icon={(
                <img
                  src={tab.icon}
                  alt=""
                  style={{ width: 20, height: 17, marginBottom: 0 }}
                />
              )}
              iconPosition="top"
            />
          ))}
        </Tabs>
        {tabs.map((tab) => (
          <TabPanel key={tab.label} value={selectedTab} index={tab.value}>
            {renderTab(tab.value)}
          </TabPanel>
        ))}
      </AppBar>
    </div>
  );
}
