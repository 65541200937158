import { createContext, useState } from "react";

export const LoginPopupContext = createContext<[{ open: boolean, message: string } | null, React.Dispatch<{ open: boolean, message: string }>]>([null!, () => null!]);

export function LoginPopupContextProvider(props: { children: JSX.Element }) {
  const [openLoginPopup, setOpenLoginPopup] = useState({
    open: false,
    message: "",
  });

  return (
    <LoginPopupContext.Provider value={[openLoginPopup, setOpenLoginPopup]}>
      {props.children}
    </LoginPopupContext.Provider>
  );
}
