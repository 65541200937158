import { Color } from "./color";

export interface VehiclesStatus {
  assignableByUser: boolean
  color: Color
  incidentNumber: string
  locationCurrent: string
  locationDestination: string
  modifiedDate: number
  options: CADVehicleOption[]
  owner: string
  radioName: string
  requestStatus: number
  requestTime: number
  responseTime: number
  status: string
  statusCode: string
  uuid: string
  vehicleId: string
}

export interface CADVehicleOption {
  key: string
  name: string
  type: string
  value: string
}

export interface CADVehicleStatusHistory {
  incidentNumber: string
  locationCurrent?: string
  locationDestination?: string
  options: CADVehicleOption[]
  radioName: string
  requested: string
  requestedBy: string
  status: string
  statusCode: string
}

export enum CADVehicleStatusHistoryOptionKeys {
  DistanceTraveled = "distanceTraveled",
}
