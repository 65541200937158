import {
  Button,
  capitalize,
} from "@mui/material";
import {
  Lock,
  LockOpen,
} from "@mui/icons-material";

import {
  changeManagedIncidentState,
  ChangeManagedState,
} from "@services/tc-incidents";

export default function CloseManagedIncidentButton(prop: {
  itemId: string,
  closed: boolean,
  canClose: boolean,
  reload: () => void
}) {
  const {
    itemId,
    closed,
    canClose,
    reload,
  } = prop;

  let nextButtonColor: string = "#CC0033";
  let nextState: ChangeManagedState = ChangeManagedState.Close;
  let nextIcon = (
    <Lock style={{ height: 20 }} />
  );

  if (closed) {
    nextButtonColor = "#339933";
    nextState = ChangeManagedState.Restore;
    nextIcon = (
      <LockOpen style={{ height: 20 }} />
    );
  }

  if (itemId === "") {
    return null;
  }

  if (!canClose) {
    return null;
  }

  const nextActionLabel: string = `${capitalize(nextState)} Incident`;
  return (
    <Button
      style={{
        backgroundColor: nextButtonColor,
        color: "white",
        borderRadius: "3px",
        boxShadow: "none",
        padding: "4px 10px",
      }}
      className="btn"
      variant="contained"
      size="small"
      onClick={async () => {
        await changeManagedIncidentState(itemId, nextState);
        await reload();
      }}
    >
      {nextIcon}
      {nextActionLabel}
    </Button>
  );
}
