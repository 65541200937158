import { useContext } from "react";
import { LoginContext } from "@contexts/login-context";
import { useData } from "@hooks/useData";
import { getDepartmentById } from "@services/department";
import { useParams } from "react-router-dom";
import PushNotifications from "./admin/settings/settings-tabs/push-notifications";

export default function AccountPushNotificationsConfig() {
  const [user] = useContext(LoginContext);

  const route = useParams<{ departmentId: string }>();

  const { data: department } = useData((as) => getDepartmentById(route.departmentId || user?.departmentId, as));

  if (!department) {
    return <div />;
  }

  return <PushNotifications department={department} />;
}
