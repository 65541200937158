import { CADVehicleStatusHistory, VehiclesStatus } from "@models/cad-vehicles-status";
import { appFetch } from "./config";
import { CADVehicleStatusesSortPayload } from "@models/cad-statuses";

export const getVehiclesStatus = async (unconfirmed: boolean, signal: AbortSignal): Promise<VehiclesStatus[]> => {
  const minutesAgo = 24 * 60;
  const now = new Date();
  const changedAfter = new Date(now.valueOf() - minutesAgo * 60 * 1000);
  const query = unconfirmed ? "unconfirmed=true" : `changedAt=${changedAfter.toISOString()}`;
  const response: Response = await appFetch(`api/v2/cad-vehicle-status/?${query}`, { signal });
  return response.json();
};

export const getVehicleStatusInfo = async (vehicleId: string): Promise<CADVehicleStatusHistory[]> => {
  const response: Response = await appFetch(`api/v2/cad-vehicle-status/info/${vehicleId}`);
  return response.json();
};

export const confirmCadVehicleStatus = async (item: VehiclesStatus) => {
  const nowUnix = (new Date().valueOf() / 1000.0);

  const vehicle = {
    incidentNumber: item.incidentNumber,
    modifiedDate: nowUnix,
    options: item.options,
    owner: item.owner,
    radioName: item.radioName,
    requestStatus: item.requestStatus,
    requestTime: nowUnix - 1,
    responseTime: nowUnix,
    status: item.status,
    statusCode: item.statusCode,
    uuid: item.uuid,
    vehicleId: item.vehicleId,
  };

  const response: Response = await appFetch("api/v2/cad-vehicle-status/", {
    method: "POST",
    body: JSON.stringify(vehicle),
  });

  return response.json();
};

export const sortCADVehicleStatusMapSort = async (data: CADVehicleStatusesSortPayload): Promise<void> => {
  const response: Response = await appFetch("api/a1/admin/cad-vehicle-status-map-sort", {
    method: "POST",
    body: JSON.stringify(data),
  });
  return response.json();
};

export const getCADVehicleStatusesSuperAdmin = async (departmentId: string, backupRequested: boolean, signal: AbortSignal): Promise<VehiclesStatus[]> => {
  const response: Response = await appFetch(`api/a1/admin/cad-vehicle-status/${departmentId}?backupRequested=${backupRequested}`, { signal });

  return response.json();
};

export const clearCADVehicleStatusesSuperAdmin = async (departmentId: string, restore: boolean): Promise<void> => {
  const response: Response = await appFetch(`api/a1/admin/cad-vehicle-status/clear/${departmentId}?restore=${restore}`, {
    method: "POST",
  });
  return response.json();
};
