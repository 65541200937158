import {
  Typography,
} from "@mui/material";

import { getIncidentNotifiedLog } from "@services/cad";
import { IncidentNotifiedLogItem } from "@models/cad";

import { useDataWithoutLoading } from "@hooks/useDataWithoutLoading";

import classes from "../pages/admin/cad-incident-details/cad.module.css";
import { useState } from "react";
import IncidentTableHeaderWithToggle from "../pages/admin/incident-table-header-with-toggle";
import { CustomMaterialTable } from "./custom-material-table";
import { strDateFormat } from "./incident-details-shared-to";

export default function IncidentNotifiedLogPanel(prop: {
  incidentNumber: string,
  isAdmin: boolean,
}) {
  const {
    incidentNumber,
    isAdmin,
  } = prop;

  const [expand, setExpand] = useState(false);

  const {
    data: incidentNotificationLog,
  } = useDataWithoutLoading((as) => getIncidentNotifiedLog(incidentNumber ?? "-", as));

  if (!isAdmin) {
    return (
      <></>
    );
  }

  let items: IncidentNotifiedLogItem[] = [];
  (incidentNotificationLog ?? []).forEach((x) => {
    items = items.concat(x.sent ?? []);
  });

  return (
    <div style={{ marginBottom: 20 }}>
      <CustomMaterialTable<IncidentNotifiedLogItem>
        cellClassName={classes.cellWidth}
        hideContent={!expand}
        data={items.sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf()) ?? []}
        title={<IncidentTableHeaderWithToggle title="Notifications" expand={expand} setExpand={setExpand} />}
        cellAlign="left"
        header={
          [{
            name: "Modified",
            prop: "",
            render: (item) => (
              <div style={{
                display: "flex", columnGap: 10, alignItems: "center",
              }}
              >
                {strDateFormat(item.date)}
                <Typography variant="body2">{`[${item.type}]`}</Typography>
              </div>
            ),
          },
          {
            name: "Name",
            prop: "name",
          },

          ]
        }
      />
    </div>
  );
}
