import { useParams } from "react-router-dom";
import { getCADIncidentById, postSimulatorIncident } from "@services/cad";
import { useDataWithoutLoading } from "@hooks/useDataWithoutLoading";
import { CadSimulatorComments } from "./cad-simulator-comments";
import { CadSimulatorIncidentDetails } from "./cad-simulator-incident-details";
import { CadSimulatorUnits } from "./cad-simulator-units";
import { Form, Formik, FormikHelpers } from "formik";
import { Grid } from "@mui/material";
import { SimulatorIncident } from "@models/cad";
import moment from "moment";
import { useLoading } from "@hooks/useLoading";
import { useTitle } from "@hooks/useTitle";
import { getKeys } from "@utils/utilsFunctions";
import { useEffect, useMemo, useState } from "react";

export function EditCadSimulator() {
  const { incidentId } = useParams<{ incidentId: string }>();
  const { data: cadSimulatedIncident } = useDataWithoutLoading((as) => getCADIncidentById(incidentId!, as));

  useTitle("CAD Simulator");
  const { withLoading } = useLoading();
  const [resetData, setResetData] = useState(false);

  const onSubmit = withLoading(
    "loading...",
    async (obj: SimulatorIncident, onSubmitProps: FormikHelpers<SimulatorIncident>) => {
      onSubmitProps.resetForm({
        values: obj,
      });
      const { units, ...payload } = obj;
      await postSimulatorIncident({ ...payload, Unit: units });
      onSubmitProps.setSubmitting(false);
    },
  );

  const onReset = (obj: SimulatorIncident) => {
    obj?.units?.splice(0, obj.units?.length);
    obj?.Comment?.splice(0, obj.Comment?.length);
    setResetData(!resetData);
  };

  const [initialValues, setInitialValues] = useState<SimulatorIncident>({
    IncidentNumber: "",
    EntryDateTime: "",
    ClosedDateTime: "",
    expiration_date: "",
    AgencyID: "",
    CADSimulator: "",
    TransactionID: 0,
    closeIn: 3,
    StreetNumber: "",
    StreetName: "",
    CallerNumber: "",
    CommandChannel: "",
    Suite: "",
    CityOrLocality: "",
    StateOrProvince: "",
    CommonPlaceName: "",
    cross_streets: "",
    AgencyIncidentCallTypeDescription: "",
    Latitude: "",
    Longitude: "",
    FireMap: "",
    MapPages: "",
    TacticalChannel: "",
    units: [],
    Comment: [],
    notify: false,
    rts: true,
    simulation: true,
    callers: [],
  });

  useEffect(() => {
    if (cadSimulatedIncident && cadSimulatedIncident.Comment) {
      setInitialValues({
        IncidentNumber: cadSimulatedIncident.IncidentNumber,
        EntryDateTime: cadSimulatedIncident.EntryDateTime,
        ClosedDateTime: cadSimulatedIncident.ClosedDateTime,
        expiration_date: cadSimulatedIncident.expiration_date,
        AgencyID: cadSimulatedIncident.AgencyID,
        CADSimulator: cadSimulatedIncident.CADSimulator,
        TransactionID: cadSimulatedIncident.TransactionID,
        closeIn: cadSimulatedIncident.closeIn,
        StreetNumber: cadSimulatedIncident.StreetNumber,
        StreetName: cadSimulatedIncident.StreetName,
        CallerNumber: cadSimulatedIncident.CallerNumber,
        CommandChannel: cadSimulatedIncident.CommandChannel,
        Suite: cadSimulatedIncident.Suite,
        CityOrLocality: cadSimulatedIncident.CityOrLocality,
        StateOrProvince: cadSimulatedIncident.StateOrProvince,
        CommonPlaceName: cadSimulatedIncident.CommonPlaceName,
        cross_streets: cadSimulatedIncident.cross_streets,
        AgencyIncidentCallTypeDescription: cadSimulatedIncident.AgencyIncidentCallTypeDescription,
        Latitude: cadSimulatedIncident.Latitude,
        Longitude: cadSimulatedIncident.Longitude,
        FireMap: cadSimulatedIncident.FireMap,
        MapPages: cadSimulatedIncident.MapPages,
        TacticalChannel: cadSimulatedIncident.TacticalChannel,
        units: cadSimulatedIncident.units ? cadSimulatedIncident.units.map((el) => ({
          active: el.active,
          AlarmAtDispatch: el.AlarmAtDispatch,
          Personnel: el.Personnel,
          PersonnelCount: el.PersonnelCount,
          status: el.status,
          TimeArrived: el.TimeArrived ?? "",
          TimeAtHospital: el.TimeAtHospital ?? "",
          TimeCleared: el.TimeCleared ?? "",
          TimeDispatched: el.TimeDispatched ?? "",
          TimeEnroute: el.TimeEnroute ?? "",
          TimePatient: el.TimePatient ?? "",
          TimeStaged: el.TimeStaged ?? "",
          TimeTransporting: el.TimeTransporting ?? "",
          UnitDispatchNumber: el.UnitDispatchNumber,
          UnitID: el.UnitID,
        })) : [],
        Comment: cadSimulatedIncident.Comment ? cadSimulatedIncident.Comment.map((el) => ({
          Comment: el.Comment,
          CommentSource: el.CommentSource,
          CommentDateTime: el.CommentDateTime,
        })) : [],
        notify: cadSimulatedIncident.notify,
        rts: cadSimulatedIncident.rts,
        simulation: cadSimulatedIncident.simulation,
        callers: cadSimulatedIncident.callers,
      });
    }
  }, [cadSimulatedIncident]);

  const keys = getKeys<SimulatorIncident>({
    IncidentNumber: 1,
    EntryDateTime: 1,
    ClosedDateTime: 1,
    expiration_date: 1,
    CallerNumber: 1,
    AgencyID: 1,
    TransactionID: 1,
    CommonPlaceName: 1,
    CommandChannel: 1,
    FireMap: 1,
    CityOrLocality: 1,
    cross_streets: 1,
    MapPages: 1,
    AgencyIncidentCallTypeDescription: 1,
    CADSimulator: 1,
    Latitude: 1,
    Suite: 1,
    Longitude: 1,
    StateOrProvince: 1,
    StreetName: 1,
    StreetNumber: 1,
    TacticalChannel: 1,
    closeIn: 1,
    units: 1,
    Comment: 1,
    notify: 1,
    rts: 1,
    simulation: 1,
    simulationId: 1,
    callers: 1,
    simulationSequences: 0,
  });

  const form = useMemo(() => (
    <div>
      <Formik<SimulatorIncident>
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({
          values, handleChange, setFieldValue, resetForm, dirty, submitForm, isSubmitting,
        }) => (
          <Form>
            <Grid container spacing={3} style={{ width: "100%", margin: 0 }}>
              <CadSimulatorIncidentDetails
                isSubmitting={resetData}
                closeDate={values.expiration_date ?? ""}
                dirty={dirty}
                values={values}
                keys={keys}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                formSubmitCount={1}
                handleResetForm={async () => {
                  // eslint-disable-next-line no-alert
                  const confirmed = window.confirm("Are you sure want to close this incident ?");
                  if (confirmed) {
                    values.ClosedDateTime = moment(new Date()).format();
                    const { units, ...payload } = values;
                    await postSimulatorIncident({ ...payload, Unit: units });
                    resetForm({
                      values: initialValues,
                    });
                    onReset(initialValues);
                  }
                }}
              />
              <CadSimulatorComments comments={values.Comment ?? []} isDataCleared={resetData} submitForm={submitForm} />
              <CadSimulatorUnits units={values.units} isSubmitting={isSubmitting} isDataCleared={resetData} submitForm={submitForm} keys={keys} setFieldValue={setFieldValue} />
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  ), [initialValues, cadSimulatedIncident]);

  if (!cadSimulatedIncident) {
    return <div />;
  }

  return (
    <>
      {form}
    </>
  );
}
