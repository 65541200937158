import { getNameFontSize, getRectColor } from "@utils/utilsFunctions";
import { IncidentPersonnelIconWithCount } from "./incident-personnel-icon-with-count";
import { IncidentTruckIconWithCount } from "./incident-truck-icon-with-count";

export function GroupMarker(prop: {
  name: string
  unitsNumber: number
  personnel: number
}) {
  const { unitsNumber, name, personnel } = prop;

  return (
    <svg style={{ display: "block" }} xmlns="http://www.w3.org/2000/svg" width="80" height="60">
      <rect x="0" y="0" width="80" height="60" rx="8" ry="8" style={{ fill: "#46464b" }} />
      <text
        x="50%" y="60%" dominantBaseline="middle" textAnchor="middle"
        style={{
          isolation: "isolate",
          textAlign: "center",
          fontSize: getNameFontSize(name.length),
          fill: "#fff",
          fontFamily: "Arial-BoldMT, Arial",
        }}
      >
        {name}
      </text>
      <IncidentTruckIconWithCount unitsNumber={unitsNumber} />
      <IncidentPersonnelIconWithCount personnel={personnel} />
    </svg>
  );
}



