import {
  CADIncident,
  IncidentNotifiedLog,
} from "@models/cad";
import {
  Page, Text, View, Document, StyleSheet, Image,
} from "@react-pdf/renderer";
import image from "../../../img/tcIconRed.png";
import moment from "moment";
import { SafetyPriorityKeyword } from "@models/department";
import {
  PDFRenderInformationField,
  PDFRenderNotificationIcon,
  PDFRenderTableHeader,
  PDFRenderTableDateField,
  PDFRenderCommentRow,
  PDFRenderChangesDetails,
  PDFRenderEventsDetails,
} from "./cad-incident-pdf-components";

const unitsTableHeader = ["Unit", "DSP", "RSP", "STG", "ONS", "TR", "AH", "AVL", "Personnel"];

const styles = StyleSheet.create({
  textBig: {
    fontSize: 16,
  },
  textNormal: {
    fontSize: 14,
  },
  textSmall: {
    fontSize: 10,
  },
  page: {
    padding: 10,
    paddingBottom: 20,
    width: "100%",
  },
  detailsFormWrapper: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#EDEEED",
    padding: 5,
    width: "100%",
    rowGap: 5,
  },
  documentHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  unitsHeaderCell: {
    fontSize: 10,
    padding: "5px 2px",
    textAlign: "center",
    color: "white",
  },
  cellMinWidth: {
    minWidth: 45,
  },
  pageNumber: {
    textAlign: "right",
    fontSize: 10,
    bottom: 0,
    left: 0,
    width: "100%",
    padding: 5,
    position: "absolute",
  },
});

export function CADIncidentPDFDocument({
  incident, imageUrl, priorityKeywords, incidentNotification,
}: {
  incident: CADIncident,
  imageUrl: string,
  priorityKeywords: SafetyPriorityKeyword[]
  incidentNotification: IncidentNotifiedLog[]
}) {
  return (
    <Document author="tabletcommand" pageLayout="oneColumn" title={incident.IncidentNumber} style={{ width: "100%" }}>
      <Page style={styles.page} size="A4">
        <View style={styles.documentHeader}>
          <View style={{
            display: "flex", flexDirection: "row", columnGap: 5, fontSize: 16, alignItems: "center",
          }}
          >
            <Text>
              Incident:
            </Text>
            <Text style={{
              backgroundColor: "grey", color: "white", padding: 5, borderRadius: 5,
            }}
            >
              {incident.IncidentNumber}
            </Text>
          </View>
          <View style={{
            display: "flex", flexDirection: "row", alignItems: "center", columnGap: 5,
          }}
          >
            <Image source={image} style={{ width: 40, height: 40 }} />
            <Text style={{ fontSize: 20 }}>Tablet Command</Text>
          </View>
        </View>
        <View id="document-details-wrapper" style={styles.detailsFormWrapper}>
          <PDFRenderInformationField name="Incident Number:" value={incident.IncidentNumber} />
          <PDFRenderInformationField name="Call Type:" value={incident.AgencyIncidentCallTypeDescription} />
          <PDFRenderInformationField name="Incident Name:" value={incident.CommandName} />
          <PDFRenderInformationField name="Common Place Name" value={incident.CommonPlaceName} />
          <PDFRenderInformationField name="Location Comment: " value={incident.LocationComment} />
          <PDFRenderInformationField name="Agency" value={incident.AgencyID} />
          <PDFRenderInformationField name="Map Pages" value={incident.MapPages} />
          <PDFRenderInformationField name="Fire Map" value={incident.FireMap} />
          <PDFRenderInformationField name="Start Time:" value={incident.start_unix_date} />
          <PDFRenderInformationField name="End Time:" value={incident.closed_unix_date} />
          <PDFRenderInformationField name="Modified Time:" value={incident.modified_unix_date} />
          <PDFRenderInformationField name="Address:" value={incident.full_address} />
          <PDFRenderInformationField name="Cross Streets:" value={incident.cross_streets} />
          <PDFRenderInformationField name="Call Number:" value={incident.CallerNumber} />

          <View wrap={false}>
            <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <View style={{ minWidth: 150 }}>
                <Text style={styles.textSmall}>Channels:</Text>
              </View>
              <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                {incident.CommandChannel && (
                  <Text style={styles.textSmall}>
                    {`Command: ${incident.CommandChannel}  `}
                  </Text>
                )}
                {incident.TacticalChannel && (
                  <Text style={styles.textSmall}>
                    {`Tactical: ${incident.TacticalChannel}`}
                  </Text>
                )}
                {incident.radioChannels?.length
                  ? incident.radioChannels.map((channel) => (
                    <Text style={styles.textSmall} key={channel.channel + channel.name}>
                      {`${channel.channel}: ${channel.name}`}
                    </Text>
                  ))
                  : null}
              </View>
            </View>
          </View>

          {
            incident.sharedSource && incident.sharedSource.isExternal
              ? (
                <View wrap={false}>
                  <PDFRenderInformationField name="Shared By:" value={incident.sharedSource.name} />
                </View>
              )
              : null
          }
          {
            incident.sharedTo?.length
              ? (
                <View wrap={false}>
                  <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <View style={{ minWidth: 150 }}>
                      <Text style={styles.textSmall}>Radio Channels:</Text>
                    </View>
                    <View style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                      {incident.sharedTo.map((shared) => (
                        <Text style={styles.textSmall} key={shared.name}>
                          {shared.name}
                        </Text>
                      ))}
                    </View>
                  </View>
                </View>
              )
              : null
          }
          <View
            wrap={false}
            style={{
              marginTop: 5, display: "flex", flexDirection: "row", alignItems: "center",
            }}
          >
            {(incident.notificationType ?? []).filter((el) => el.name !== "Any").length ? (
              <PDFRenderNotificationIcon />
            ) : null}
            {(incident.notificationType ?? []).map((item) => (
              item.name !== "Any" ? <Text key={item.value + item.name} style={{ ...styles.textSmall, color: "#7a7d7d", paddingRight: 5 }}>{item.name}</Text> : null
            ))}
          </View>
        </View>
        <View id="document-map-wrapper" style={{ padding: "20px 35px" }}>
          {imageUrl && <Image source={imageUrl} style={{ width: 505, height: "auto" }} />}
          <View style={{ textAlign: "center", backgroundColor: "#3da1ca" }}><Text style={{ ...styles.textNormal, color: "white" }}>{`${incident.Latitude},${incident.Longitude} `}</Text></View>
        </View>
        <Text style={styles.pageNumber} fixed render={({ pageNumber, totalPages }) => (`Page ${pageNumber}/${totalPages}`)} />
      </Page>
      <Page id="document-units" wrap style={styles.page} size="A4">
        <View fixed style={{ ...styles.documentHeader, paddingLeft: 5, paddingRight: 5 }}>
          <Text style={{ ...styles.textSmall, color: "grey" }}>
            {incident.IncidentNumber}
          </Text>
          <Image source={image} style={{ width: 20, height: 20 }} />
        </View>
        <View>
          <PDFRenderTableHeader name="Units" value={incident.units?.length ?? 0} />
          <View
            fixed
            style={{
              display: "flex", flexDirection: "row", backgroundColor: "lightgrey", columnGap: 2,
            }}
          >
            {unitsTableHeader.map((headerText) => (
              <View
                key={headerText}
                style={{ ...styles.unitsHeaderCell, ...styles.cellMinWidth }}
              >
                <Text>{headerText}</Text>
              </View>
            ))}
          </View>
          <View style={{ display: "flex", flexDirection: "column" }}>
            {incident.units?.map((unit) => (
              <View
                wrap={false}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  borderBottom: "1px solid lightgrey",
                  padding: "2px 0",
                  columnGap: 2,
                }}
                key={unit.UnitID ?? "" + unit.uuid ?? ""}
              >
                <View style={{ ...styles.cellMinWidth, justifyContent: "center" }}>
                  <Text
                    style={{
                      padding: 2,
                      fontSize: 10,
                      textAlign: "center",
                    }}
                  >
                    {unit.UnitID}
                  </Text>
                </View>
                <View style={styles.cellMinWidth}>
                  {!!unit.TimeDispatched && (
                    <PDFRenderTableDateField value={unit.TimeDispatched} />
                  )}
                </View>
                <View style={styles.cellMinWidth}>
                  {!!unit.TimeEnroute && (
                    <PDFRenderTableDateField value={unit.TimeEnroute} />
                  )}
                </View>
                <View style={styles.cellMinWidth}>
                  {!!unit.TimeStaged && (
                    <PDFRenderTableDateField value={unit.TimeStaged} />
                  )}
                </View>
                <View style={styles.cellMinWidth}>
                  {!!unit.TimeArrived && (
                    <PDFRenderTableDateField value={unit.TimeArrived} />
                  )}
                </View>
                <View style={styles.cellMinWidth}>
                  {!!unit.TimeTransporting && (
                    <PDFRenderTableDateField value={unit.TimeTransporting} />
                  )}
                </View>
                <View style={styles.cellMinWidth}>
                  {!!unit.TimeAtHospital && (
                    <PDFRenderTableDateField value={unit.TimeAtHospital} />
                  )}
                </View>
                <View style={styles.cellMinWidth}>
                  {!!unit.TimeCleared && (
                    <PDFRenderTableDateField value={unit.TimeCleared} />
                  )}
                </View>
                <View style={{ display: "flex", flexDirection: "column" }}>
                  {unit.Personnel?.map((personnel) => (
                    <View key={personnel.PersonnelID} style={{ fontSize: 10, margin: "2px 0px" }}>
                      {!!personnel.PersonnelRank && <Text>{`${personnel.PersonnelRank} -`}</Text>}
                      <Text style={{ marginLeft: 10 }}>{personnel.PersonnelName}</Text>
                    </View>
                  ))}
                </View>
              </View>
            ))}
          </View>
        </View>
        <Text style={styles.pageNumber} fixed render={({ pageNumber, totalPages }) => (`Page ${pageNumber}/${totalPages}`)} />
      </Page>
      <Page style={styles.page} size="A4">
        <View fixed style={{ ...styles.documentHeader, paddingLeft: 5, paddingRight: 5 }}>
          <Text style={{ ...styles.textSmall, color: "grey" }}>
            {incident.IncidentNumber}
          </Text>
          <Image source={image} style={{ width: 20, height: 20 }} />
        </View>
        <View id="document-comments-wrapper">
          <PDFRenderTableHeader name="Comments" value={incident.Comment?.length ?? 0} />
          <View>
            {(incident.Comment ?? []).length ? (
              <View>
                {(incident.Comment ?? []).sort((a, b) => (b.CommentUnixTime ?? 0) - (a.CommentUnixTime ?? 0)).map((item) => (
                  <PDFRenderCommentRow key={item.Comment + item.CommentDateTime + item.CommentUnixTime} item={item} priorityKeywords={priorityKeywords} />
                ))}
              </View>
            ) : null}
          </View>
        </View>
        <View id="document-changes-wrapper" style={{ marginTop: 20 }}>
          <PDFRenderTableHeader name="Changes" value={incident.changes?.length ?? 0} />
          <PDFRenderChangesDetails incident={incident} />
        </View>
        <View id="document-events-wrapper" style={{ marginTop: 20 }}>
          <PDFRenderTableHeader name="Events" value={incident.events?.length ?? 0} />
          <PDFRenderEventsDetails incident={incident} />
        </View>
        <View id="document-notifications-wrapper" style={{ marginTop: 20 }}>
          <Text style={styles.textBig}>Notifications</Text>
          <View style={{ marginTop: 10, marginLeft: 10 }}>
            {incidentNotification.length ? (
              incidentNotification.flatMap((notification) => notification.sent).sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf()).map((item) => (
                <View
                  key={item.type + item.name + item.date}
                  wrap={false}
                >
                  <Text style={styles.textNormal}>
                    {moment(item.date).format("HH:mm:ss")}
                    <Text>
                      [
                      {item.type}
                      ]
                    </Text>
                  </Text>
                  <Text style={styles.textSmall}>
                    {" "}
                    {item.name}
                  </Text>
                </View>
              ))
            ) : (
              <Text style={styles.textNormal}>None.</Text>
            )}
          </View>
        </View>
        <Text style={styles.pageNumber} fixed render={({ pageNumber, totalPages }) => (`Page ${pageNumber}/${totalPages}`)} />
      </Page>
    </Document>
  );
}
