/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable camelcase */

import {
  Box,
  Grid,
  IconButton,
  Modal,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";

import {
  useContext, useEffect, useMemo, useRef, useState,
} from "react";
import { useParams } from "react-router-dom";

import { LoginContext, Role, roleIsAdmin } from "@contexts/login-context";
import { useDataWithoutLoading } from "@hooks/useDataWithoutLoading";
import { useTitle } from "@hooks/useTitle";
import classes from "./managed-incidents-list.module.css";

import TCIncidentEvents from "@components/incident-details-events";
import { getIncidentById } from "@services/tc-incidents";
import { getDepartmentById } from "@services/department";

import { RouteParamManagedIncidentDetails } from "@models/routing";

import { TCFields } from "./tc-incident-details-fields";
import { TCIncidentChecklists } from "./tc-incident-details-checklists";
import { TCIncidentHistory } from "./tc-incident-details-history";
import { TCIncidentMap } from "./tc-incident-details-map";
import { TCIncidentChanges } from "./tc-incident-details-changes";
import { TCIncidentTransfers } from "./tc-incident-details-transfers";
import { UnitsTableContainer } from "./tc-incident-details-units-table";
import PriorIncidentsTable from "../cad-incident-details/cad-incident-details-prior-incident";
import { getCADIncidentById } from "@services/cad";
import { TVViewButton } from "@components/TVViewButton";
import { TCIncidentPDFDocument } from "./tc-incident-pdf/tc-incident-pdf-document";
import { pdf } from "@react-pdf/renderer";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { MapDetails } from "@models/arcgis";
import { useQuery } from "@tanstack/react-query";
import { TCIncidentHazardsTable } from "./tc-incident-details-hazards-table";
import { TCIncidentNotes } from "./tc-incident-details-notes";
import { getStatusSettings } from "@services/cad-statuses";
import { Color } from "@models/color";
import { TCIncidentSharedTo } from "./tc-incident-details-sharedTo";
import { useSharedAVLUnits } from "@hooks/shared-avl-units/useSharedAVLUnits";
import { useAVLUnits } from "@hooks/avl-units/useAVLUnits";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 1,
  backgroundColor: "lightgrey",
};

export default function ManagedIncidentDetails() {
  useTitle("TC Incidents Details");
  const { incidentNumber } = useParams<RouteParamManagedIncidentDetails>();
  const [user] = useContext(LoginContext);
  const mapView = useRef<string>("");
  const [pdfSource, setPDFSource] = useState<string | null>(null);
  const [locationStaleMinutes, setLocationStaleMinutes] = useState(0);
  const [pdfModal, setPDFModal] = useState(false);
  const [incidentClosed, setIncidentClosed] = useState(false);
  const [shareIncidentEnabled, setShareIncidentEnabled] = useState<boolean>(false);
  const [canCreateShareIncidentCode, setCanCreateShareIncidentCode] = useState<boolean>(false);

  const [mapDetails, setMapDetails] = useState<MapDetails>({
    diagonal: 0, latitude: 0, longitude: 0, zoom: 14,
  });

  const fetchMapDetails = (props: MapDetails) => {
    setMapDetails((prev) => {
      if (props.latitude !== prev.latitude || props.longitude !== prev.longitude) {
        return props;
      }
      return prev;
    });
  };

  const { data: managedIncident, refetch: managedIncidentRefetch } = useQuery({
    queryKey: ["managed-incident-details", incidentNumber],
    refetchInterval: 10000,
    retry: false,
    enabled: incidentClosed,
    queryFn: async () => await getIncidentById(incidentNumber),
  });

  const {
    data: departmentData,
  } = useQuery({
    queryKey: ["user-department", user?.departmentId],
    retry: false,
    refetchInterval: 20000,
    enabled: !!user?.departmentId,
    queryFn: () => getDepartmentById(user?.departmentId),
    placeholderData: (prev) => prev,
  });

  const { data: featureSettings } = useDataWithoutLoading(() => getStatusSettings());

  const orientationSetting = useMemo(() => {
    const setting = featureSettings?.find((setting) => setting.key === 424);
    if (setting) {
      return (setting?.json as unknown as { color: Color }).color;
    }
    return { background: "#000000", text: "#FFFFFF" };
  }, [featureSettings]);

  const { data: cadIncident } = useQuery({
    queryKey: ["cad-incident-details", incidentNumber],
    retry: false,
    refetchInterval: 10000,
    enabled: incidentClosed,
    queryFn: async () => await getCADIncidentById(incidentNumber!),
  });

  useEffect(() => {
    if (managedIncident?.end_unix_time !== 0) {
      setIncidentClosed(true);
    }

    let canIncidentCanShareCode = true; // Use this if CAD Incident does not exist
    if (cadIncident && cadIncident.sharedSource) {
      canIncidentCanShareCode = cadIncident.sharedSource.isExternal === false;
    }

    let canManagedIncidentCanShareCode = true; // Use this if Managed Incident does not exist
    if (managedIncident && managedIncident.sharedSource) {
      canManagedIncidentCanShareCode = managedIncident.sharedSource.isExternal === false;
    }

    const incidentCanShareCode = canIncidentCanShareCode && canManagedIncidentCanShareCode;
    const userCanShareCode = user?.canCreateShareIncidentCode === true;
    setCanCreateShareIncidentCode(incidentCanShareCode && userCanShareCode);
  }, [managedIncident, cadIncident]);

  const { isSharedAVLEnabled, sharedAVLLocations } = useSharedAVLUnits("managed-details", mapDetails, departmentData?.shareAVL?.fadeZoomLevel);
  const { avlUnitsLocations } = useAVLUnits(locationStaleMinutes, true, mapDetails);

  const isAdmin = roleIsAdmin(user?.role);
  const small = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    if (departmentData) {
      setLocationStaleMinutes(departmentData.locationStaleMinutes / 60);
      setShareIncidentEnabled(departmentData.shareIncident?.enabled === true);
    }
  }, [departmentData]);

  const unassignedUnitsOnMap = useMemo(() => {
    const groupsUnits = (managedIncident?.groups ?? []).flatMap((el) => el.units ?? []);
    const unitsIds = new Set(groupsUnits.map(({ UnitID }) => UnitID));

    const filteredUnits = (managedIncident?.units ?? []).filter(({ UnitID }) => !unitsIds.has(UnitID));

    return filteredUnits;
  }, [managedIncident]);

  const [signal] = useState({
    openPopup: false,
    unitName: "",
  });

  if (!managedIncident || !departmentData) {
    return <div />;
  }

  return (
    <div className={`${classes.incidentDetails}`}>
      <Grid container spacing={3}>
        <Grid item sm={11} lg={6} className={`${classes.leftSide}`}>
          <div className="">
            <div className={`${classes.tc_incident_detail_header}`}>
              <span className={`${classes.tc_incident_detail_title}`}>
                Incident:
                {" "}
              </span>
              <span className={`${classes.tc_incident_detail_label}`}>
                {managedIncident?.name}
              </span>
              <div style={{ marginLeft: "auto" }}>
                <IconButton
                  className={classes.tc_incident_detail_pdf_button}
                  disabled={!mapView.current}
                  onClick={async () => {
                    if (mapView.current) {
                      try {
                        setPDFModal(!small);
                        const blob = await pdf(
                          <TCIncidentPDFDocument
                            priorityKeywords={departmentData.safetyPriorityKeywords}
                            incident={managedIncident}
                            cadIncident={cadIncident}
                            imageUrl={mapView.current ?? ""}
                          />,
                        ).toBlob();
                        const pdfURL = URL.createObjectURL(blob);
                        if (!small) {
                          setPDFSource(URL.createObjectURL(blob));
                        } else {
                          const downloadAnchor = document.createElement("a");
                          downloadAnchor.href = pdfURL;
                          downloadAnchor.download = `${managedIncident.api_incident_number}.pdf`;
                          document.body.appendChild(downloadAnchor);
                          downloadAnchor.click();
                          document.body.removeChild(downloadAnchor);
                        }
                      } catch (error) {
                        // eslint-disable-next-line no-console
                        console.log("PDF error generation error", error);
                      }
                    }
                  }}
                >
                  {mapView.current ? "Generate" : "Loading"}
                  <PictureAsPdfIcon style={{ marginLeft: 5 }} />
                </IconButton>
                <TVViewButton incidentNumber={incidentNumber ?? ""} />
              </div>
            </div>
            {/* fields under incident number and TV icon */}
            <TCFields
              incident={managedIncident}
              cadIncident={cadIncident}
              permissions={{
                isAdmin,
                canCreateShareIncidentCode,
                shareIncidentEnabled,
              }}
              reload={managedIncidentRefetch}
            />
            <UnitsTableContainer managedIncident={managedIncident} cadIncident={cadIncident} />
            <PriorIncidentsTable priorIncidents={cadIncident?.PriorIncident ?? []} />
            <TCIncidentHazardsTable incident={managedIncident} />
            <TCIncidentChecklists incident={managedIncident} />
            <TCIncidentHistory incident={managedIncident} priorityKeywords={departmentData?.safetyPriorityKeywords} cadIncident={cadIncident} />
            <TCIncidentNotes incident={managedIncident} />
          </div>
        </Grid>
        <Grid item sm={11} lg={6} className={`${classes.rightSide}`}>
          <TCIncidentMap
            incident={managedIncident}
            unitsLocation={avlUnitsLocations}
            signal={signal}
            unassignedUnitsOnMap={unassignedUnitsOnMap}
            sharedAVLMarkers={isSharedAVLEnabled ? sharedAVLLocations : []}
            sharedAVLButton={departmentData.shareAVL?.enabled}
            refetchData={fetchMapDetails}
            mapViewRef={mapView}
            orientationSetting={orientationSetting}
          />
          <TCIncidentSharedTo
            incident={managedIncident}
            incidentId={managedIncident.cadIncidentId ?? ""}
            isAdmin={isAdmin}
            departmentName={departmentData.department}
            reload={managedIncidentRefetch}
          />
          <TCIncidentChanges incident={managedIncident} />
          <TCIncidentTransfers incident={managedIncident} />
          {user?.role !== Role.User && <TCIncidentEvents events={managedIncident.events ?? []} />}
          {/* Disabled as mentioned by Will & John we don't need it | Re-enable if otherwise */}
          {/* <TCIncidentDebug incident={managedIncident} /> */}
        </Grid>
      </Grid>
      <Modal open={pdfModal} id="pdf-iframe-modal" onClose={() => { setPDFModal(false); setPDFSource(null); }}>
        <Box sx={style}>
          <div style={{ width: 900, height: 700, backgroundColor: "white" }}>
            {pdfSource ? <iframe title="PDF Iframe Container" id="pdf-iframe-container" width={900} height={700} key={pdfSource} src={pdfSource} /> : <Typography>Loading PDF...</Typography>}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
