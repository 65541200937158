import {
  Suspense, useContext, useEffect, useState,
} from "react";
import { SizeMe } from "react-sizeme";
import Menu from "../../menu/menu";
import MainPageHeader from "../main-page-header";
import { Outlet } from "react-router-dom";
import { UIStateContext } from "@contexts/ui-state-context";
import { urls } from "@utils/routing";

export default function AdminMainPage() {
  const [viewHeader, setViewHeader] = useState(true);
  const [, setState] = useContext(UIStateContext);

  useEffect(() => {
    const shouldSetViewHeader = window.location.pathname.match(urls.adminManagedIncidentLargeScreenView({ incidentNumber: "" })) !== null;
    setViewHeader(!shouldSetViewHeader);
    if (shouldSetViewHeader) {
      setState("collapsed");
    }
  }, [window.location.pathname]);
  return (
    <SizeMe>
      {({ size }) => (
        <div className="container-fluid">
          <Menu size={size.width} />
          <div className={viewHeader ? "right-area" : "right-area-modified"}>
            {viewHeader ? <MainPageHeader /> : null}
            <Suspense fallback={<div />}>
              <div className="right-content">
                <Outlet />
              </div>
            </Suspense>
          </div>
        </div>
      )}
    </SizeMe>
  );
}
