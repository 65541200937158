/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { createContext, useEffect, useState } from "react";

export type MapLayers = {
  sharedAVL: boolean
};

export const MapLayersContext = createContext<
  [MapLayers | null, React.Dispatch<MapLayers | null>]
>([null, () => null]);

export function MapLayersContextProvider(props: { children: JSX.Element }) {
  const [mapLayersState, setMapLayersState] = useState<MapLayers | null>(() => {
    const mapLayers = localStorage.getItem("mapLayers");
    if (mapLayers !== null) {
      return JSON.parse(mapLayers);
    }
    return { sharedAVL: true };
  });

  useEffect(() => {
    if (mapLayersState !== null) {
      const newMapLayerState = { ...mapLayersState, sharedAVL: mapLayersState?.sharedAVL };
      localStorage.setItem("mapLayers", JSON.stringify(newMapLayerState));
    }
  }, [mapLayersState]);

  useEffect(() => {
    const updateLocalStorage = () => {
      const mapLayers = localStorage.getItem("mapLayers");
      if (mapLayers !== null) {
        const activeLayers: MapLayers = JSON.parse(mapLayers);
        setMapLayersState(activeLayers);
      } else {
        setMapLayersState({ sharedAVL: true });
      }
    };
    window.addEventListener("storage", updateLocalStorage);

    return () => window.removeEventListener("storage", updateLocalStorage);
  }, []);

  return (
    <MapLayersContext.Provider value={[mapLayersState, setMapLayersState]}>
      {props.children}
    </MapLayersContext.Provider>
  );
}
